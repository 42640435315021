import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import {DialogController} from 'aurelia-dialog';
import mdt from 'services/md-tools';

@inject(Api, KaDialog, KaToast, DialogController)
export class UtentiDialogMavApproval {
  mdt = mdt;
  resource = {
    controls: {},
    schema: {
      status: {
        label: 'Stato richiesta',
        control: 'combo',
        datasource: [
          { value: 'PENDING-ACTIVATION', text: 'Approva' },
          { value: 'REFUSED', text: 'Rifiuta' },
          { value: 'ACTIVE', text: 'Conferma' }
        ],
        datavalue: 'value',
        datatext: 'text',
        datapreload: true,
        datamultiple: false,
        required: true
      },
      refusalReason: {
        label: 'Motivazione del rifiuto',
        control: 'text',
        required: true
      },
      trusteeServiceCode: {
        label: 'Codice servizio',
        control: 'text',
        required: true
      }
    },
    data: {
      status: 'PENDING-ACTIVATION',
    },
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      let errorPayload = mdt.parsers.apiError(error, this.resource.controls);
      if (errorPayload) this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>'+errorPayload.title+':</strong><div style="margin-top:1rem;border:1px solid #e0e0e0;padding:1rem;max-height:400px;overflow:auto;background-color:#ffffff">' + errorPayload.body + '</div>'});
    }
  }

  constructor(api, dialog, toast, dialogController) {
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
    this.controller = dialogController;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.request = params.request;
    if (this.request.status === 'PENDING-ACTIVATION') {
      this.resource.schema.status.datasource = [
        { value: 'ACTIVE', text: 'Conferma' },
        { value: 'REFUSED', text: 'Rifiuta' }
      ];
      this.resource.data.status = 'ACTIVE';
    } else if (this.request.status === 'ACTIVE') {
      this.resource.schema.status.datasource = [{ value: 'REFUSED', text: 'Rifiuta' }];

      this.resource.data.status = 'REFUSED';
    } else {
      this.resource.schema.status.datasource = [
        { value: 'PENDING-ACTIVATION', text: 'Approva' },
        { value: 'REFUSED', text: 'Rifiuta' }
      ];
      this.resource.data.status = 'PENDING-ACTIVATION';
    }

    if (this.request?.trustee?.serviceCode) {
      this.resource.data.trusteeServiceCode = this.request.trustee.serviceCode;
      this.resource.schema.trusteeServiceCode.readonly = true;
    }
  }

  save($event) {
    $event.model.busy = true;
    this.resource.validate().then(() => {
      if (this.resource.data.status === 'ACTIVE') {
        if (this.resource.data.refusalReason) delete this.resource.data.refusalReason;
        if (this.request.trustee.serviceCode) delete this.resource.data.trusteeServiceCode;
      }
      else if (this.resource.data.status === 'REFUSED') {
        if (this.resource.data.trusteeServiceCode) delete this.resource.data.trusteeServiceCode;
      }

      this.api.patch(`bo/users/${this.request.owner.id}/billing-profiles/${this.request.billingProfileUuid}/mav-order-self-printing-authorizations/${this.request.uuid}`, this.resource.data).then(() => {
        this.controller.ok(this.resource.data.status);
      }).catch(error => {
        console.error();
        this.resource.error(error);
      }).finally(() => {
        $event.model.busy = false;
      });
    }).catch(() => {
      setTimeout(() => { $event.model.busy = false; }, 300);
    });
  }
}
