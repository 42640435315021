import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { DialogController } from 'aurelia-dialog';

export class DialogContractCreate {
  cashbackContract;
  pendingCreateCashbackContracts;
  resource = {
    data: {},
    controls: {},
    schema: { htmlTemplate: { control: 'editor', label: 'Inserire contratto in formato HTML', required: true }},
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        control.validator.errors = [];
        control.element.classList.remove('error');
        promises.push(new Promise((resolve, reject) => { 
          control.validate().then((result) => { 
            if (result.valid) {
              resolve();
            } else {
              reject('Control didn\'t pass validation');
            }
          }); 
        }));
      });
      return Promise.all(promises);
    },
    error: (error) => {
      this.api.dialogError(error, this.resource.controls);
    }
  }

  static inject = [Api, DialogController, KaDialog, KaToast];
  constructor(api, controller, KaDialog, kaToast) {
    this.api = api;
    this.dialog = KaDialog;
    this.toast = kaToast;
    this.controller = controller;
  }

  activate(params) {
    this.params = params;
  }

  openDialogConfirmContractCreation() {
    return this.dialog.open({
      title: 'Attenzione!', 
      class: 'medium', 
      type: 'confirm', 
      body: `<strong>Stai creando un nuovo contratto cashback per ${this.params.type === 'FLAT-SERVICE' ? 'sottoscrizioni servizi' : 'spedizioni MultInvio'}.</strong><br>Confermi di voler creare una nuova versione di questo contratto?`
    });
  }

  createCashbackContracts($event) {
    $event.model.busy = true;
    this.pendingCreateCashbackContracts = true;
    this.resource.data.type = this.params.type;
    return this.openDialogConfirmContractCreation().whenClosed((response) => {
      if (!response.wasCancelled) {
        this.resource.validate().then(() => {
          return this.api.post('bo/cashback-contracts', this.resource.data).then(() => {
            this.toast.show('Modifiche salvate con successo!', 'success');
            this.controller.ok();
          }).catch((error) => {
            console.error('createCashbackContracts', error);
            this.resource.error(error);
          }).finally(() => {
            this.pendingCreateCashbackContracts = false;
            $event.model.busy = false;
          });
        }).catch(() => {
          $event.model.busy = false;
        });
      } else {
        $event.model.busy = false;
        this.pendingCreateCashbackContracts = false;
      }
    });
  }
}
