import { Aurelia, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { KaDialog } from 'ka-components';

@inject(Aurelia, Router, Api, KaDialog)
export class vmEmailConfirm {
  constructor(aurelia, router, api, dialog) {
    this.aurelia = aurelia;
    this.router = router;
    this.dialog = dialog;
    this.api = api;
  }

  activate(params) {
    this.params = params;
    this.api.post(`users/login`, { emailConfirmationCode: this.params.code, grantType: 'firstAccessLogin' }).then(xhr => {
      const response = xhr.response;
      this.api.user = null;
      localStorage.clear();
      sessionStorage.clear();
      this.api.accessToken = response.token;
      this.api.refreshToken = response.refreshToken;
      return this.api.startUser();
    }).catch(xhr => {
      let response = null;
      console.log('POST email-confirmations', xhr);
      try { response = JSON.parse(xhr?.response) } finally {
        return this.dialog.open({ 
          title: 'Attenzione!', 
          class: 'large', 
          type: 'alert', 
          body: response?.detail ? response.detail : xhr?.response 
        }).whenClosed(() => {
          return location.href = '/login';
        });
      }
    });
  }
}
