import { DialogController } from 'aurelia-dialog';
import { Router } from "aurelia-router";

export class DialogHelpServicesAgreement {
  services = {
    'MultiDialogo': {
      target: "",
      text: "MultiDialogo"
    },
    'MultiInvio': {
      text: "MultiInvio",
      target: "https://termsofservice.multidialogo.it/multidialogo/multiposta/condizioni-contrattuali/"
    },
    'MultiCaf': {
      text: "MultiCaf",
      target : "https://termsofservice.multidialogo.it/multidialogo/multicaf/condizioni-contrattuali/"
    },
    'MultiFatture': {
      text: "MultiFatture",
      target: 'https://termsofservice.multidialogo.it/multidialogo/multifatture/condizioni-contrattuali/'
    }
  }

  resource = {
    schema: {
      service: {
        control: 'combo',
        label: 'Seleziona il servizio',
        datasource: this.setDatasourceServices(),
        datatext: 'text',
        datavalue: 'value'
      }
    },
    data: {
      service: 'MultiDialogo',
    }
  }

  static inject = [DialogController, Router];
  constructor(controller, router) {
    this.controller = controller;
    this.router = router;
  }

  setDatasourceServices() {
    let datasource = [];
    for (const[key, value] of Object.entries(this.services)) {
      datasource.push({ value: key, text: value.text, target: value.target});
    } 
    return datasource;
  }

  openServiceAgreement() {
    const selectedService = this.resource.data.service;
    
    const serviceData = this.resource.schema.service.datasource.find(x => x.value === selectedService);
    
    if (selectedService !== 'MultiDialogo') {
      window.open(serviceData.target, '_blank');
    } else {
      this.router.navigateToRoute('impostazioni/agreements', { id: 'last' });
    }
    
    this.controller.ok();
  }
}
