import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaToast, KaDialog} from 'ka-components';
import environment from 'environment';

@inject(Router, Api, KaToast, KaDialog)
export class Login {
  environment = environment;
  username = '';
  password = '';
  resource = {
    schema: {
      username: {
        field: 'username',
        label: 'Username',
        control: 'text',
        required: true
      },
      password: {
        field: 'password',
        label: 'Password',
        control: 'password',
        required: true
      },
    },
    data: {}
  }

  constructor(router, api, toast, dialog) {
    this.router = router;
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
  }

  activate(params) {
    this.email = params.email;
    this.source = params.source;
  }

  attached() {
    if (this.api.isAuthenticated) this.api.logout();
    if (this.email) this.resource.data.username = this.email;
  }

  login($event) {
    if ($event && $event.model) $event.model.busy = true;
    this.api.login(this.resource.data.username, this.resource.data.password).catch(error => {
      if (error.statusCode !== 423) {
        this.toast.show('Autenticazione utente fallita!', 'error');
      }
    }).finally(() => {
      if ($event && $event.model) $event.model.busy = false;
    });
  }
}
