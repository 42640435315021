import '@babel/polyfill';
import {PLATFORM} from 'aurelia-pal';
import ENVIRONMENT from './environment';

export function configure(aurelia) {
  aurelia.use
    .standardConfiguration()

    .globalResources([PLATFORM.moduleName('resources/value-converters/date-format')])
    .globalResources([PLATFORM.moduleName('resources/value-converters/filesize-format')])
    .globalResources([PLATFORM.moduleName('resources/value-converters/object-format')])
    .globalResources([PLATFORM.moduleName('resources/value-converters/queue-status-format')])
    .globalResources([PLATFORM.moduleName('resources/value-converters/queue-channel-format')])
    .globalResources([PLATFORM.moduleName('resources/value-converters/combo-format')])
    .globalResources([PLATFORM.moduleName('resources/value-converters/stringify')])
    .globalResources([PLATFORM.moduleName('resources/value-converters/striphtml')])
    .globalResources([PLATFORM.moduleName('resources/elements/md-prefsel/md-prefsel')])
    .globalResources([PLATFORM.moduleName('resources/elements/md-resource/md-resource')])
    .globalResources([PLATFORM.moduleName('resources/elements/md-input/md-input')])
    .globalResources([PLATFORM.moduleName('resources/elements/md-control/md-control')])
    .globalResources([PLATFORM.moduleName('resources/elements/md-input-array/md-input-array')])
    .globalResources([PLATFORM.moduleName('resources/attributes/md-tooltip')])
    .globalResources([PLATFORM.moduleName('resources/attributes/static-tooltip')])
    .globalResources([PLATFORM.moduleName('resources/elements/md-accordion-button/md-accordion-button')])
    .globalResources([PLATFORM.moduleName('resources/elements/md-table-pagination/md-table-pagination')])
    .globalResources([PLATFORM.moduleName('resources/elements/md-table-progressbar/md-table-progressbar')])
    .globalResources([PLATFORM.moduleName('resources/elements/md-table-sort/md-table-sort')])
    .globalResources([PLATFORM.moduleName('resources/elements/md-table-toolbar/md-table-toolbar')])
    .globalResources([PLATFORM.moduleName('resources/elements/md-table-wrapper/md-table-wrapper')])
    .globalResources([PLATFORM.moduleName('resources/elements/md-counter/md-counter')])
    .globalResources([PLATFORM.moduleName('resources/elements/md-nav-utenti/md-nav-utenti')])


    .plugin(PLATFORM.moduleName('aurelia-http-client'))
    .plugin(PLATFORM.moduleName('aurelia-dialog'))
    .plugin(PLATFORM.moduleName('aurelia-validation'))
    .plugin(PLATFORM.moduleName('ka-components'))
    .plugin(PLATFORM.moduleName('km-components'));

  aurelia.use.developmentLogging(ENVIRONMENT.APP_DEBUG === 'true' ? 'debug' : 'warn');

  aurelia.start().then(() => {
    aurelia.setRoot(PLATFORM.moduleName('views/app/app'), document.body);
  });
}
