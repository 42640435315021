import {PLATFORM, inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaToast} from 'ka-components';

@inject(Api, KaToast)
export class vmOfferte {
  tabs = [
    {
      id: 'gestione',
      label: 'Gestione offerte',
      vm: PLATFORM.moduleName('views/backoffice/offerte/tabs/offerte-gestione')
    },
    {
      id: 'prenotate',
      label: 'Offerte prenotate',
      vm: PLATFORM.moduleName('views/backoffice/offerte/tabs/offerte-prenotate')
    },
    {
      id: 'riscattate',
      label: 'Offerte riscattate',
      vm: PLATFORM.moduleName('views/backoffice/offerte/tabs/offerte-riscattate')
    }
  ];

  tools = {
    copyText: (text, context = 'Testo') => {
      if (!text) return;
      navigator.clipboard.writeText(text);
      this.toast.show(`${context} copiato con successo!`, 'success');
    }
  }

  constructor(api, toast) {
    this.api = api;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    this.privilege();
  }

  privilege() {
    this.privileges = { tabs: { gestione: false, prenotate: false, riscattate: false } };
    if (this.api.hasRole(['LIST_ANY_USER_PROMOTION_OFFER_ACTIVATION', 'LIST_ANY_PROMOTION_OFFER'])) {
      this.privileges.tabs.gestione = this.privileges.tabs.prenotate = this.privileges.tabs.riscattate = true;
    }
  }
}
