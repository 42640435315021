import {inject} from 'aurelia-framework';
import {Api} from 'services/api';

@inject(Api)
export class ProfiloDeleteBillingProfile {
  
  constructor(api) {
    this.api = api;
  }

  activate(params) {
    this.params = params;
  }

}
