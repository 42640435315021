import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { DialogController } from 'aurelia-dialog';

export class DialogEditRequest {
  statusSchema = {
    'REFUSED' : 'Rifiutata',
    'CONFIRMED' : 'Confermata'
  }
  resource = {
    data: {},
    controls: {},
    schema: {
      statusReason: {
        required: true,
        label: 'Motivo',
        control: 'text'
      }
    }
  }

  static inject = [Api, DialogController, KaDialog, KaToast];
  constructor(api, controller, KaDialog, kaToast) {
    this.api = api;
    this.dialog = KaDialog;
    this.toast = kaToast;
    this.controller = controller;
  }

  activate(params) {
    this.params = params;
    this.record = params.record;
    this.status = params.status;
  }

  async saveEditRequest($event) {
    $event.model.busy = true;
    const data = this.parseData(this.resource.data);
    return this.api.patch(`bo/users/${this.record.userId}/cashback-withdrawal-requests/${this.record.id}`, data).then(() => {
      this.toast.show(`Richiesta di prelievo ${this.statusSchema[this.status]} con successo!`, 'success');
      this.controller.ok();
    }).catch((error) => {
      console.log('Patch cashback-withdrawal-requests', error);
      this.api.dialogError(error, this.resource.controls);
    }).finally(() => {
      $event.model.busy = true;
    });
  }

  parseData(data) {
    let clone = structuredClone(data);
    return clone = {
      ...clone,
      status: this.status
    }
  }
}
