import {Aurelia, inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import mdt from 'services/md-tools';

@inject(Aurelia, Router, Api, KaDialog, KaToast)
export class vmLandingOfferta {
  offer = null;
  offerExpired = null;
  currentDate = new Date().toISOString();
  promoOffers = { 'email': null, 'redeemCodes': [] };
  localStorageOffer = JSON.parse(localStorage.getItem('promo-offers')) || localStorage.setItem('promo-offers', JSON.stringify(this.promoOffers));
  resource = {
    controls: {},
    schema: {
      email: {
        label: 'Email', 
        control: 'text',
        placeholder: 'Inserisci un indirizzo email',
        required: true
      }
    },
    data: {},
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        if (!control) return;
        control.validator.errors = [];
        control.element.classList.remove('error');
        promises.push(new Promise((resolve, reject) => {
          if (control.validate)
            control.validate().then(result => {
              if (result.valid) resolve();
              else reject('Control didn\'t pass validation');
            });
          else if (control.input?.validate) control.input.validate().then(() => resolve()).catch(() => reject('Control didn\'t pass validation'));
        }));
      });
      return Promise.all(promises);
    },
    error: error => { mdt.parsers.apiError(error, this.resource.controls); }
  }

  constructor(aurelia, router, api, dialog, toast) {
    this.aurelia = aurelia;
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    this.api.get(`promotion-offers/${this.params.code}`).then((xhr) => {
      this.offer = xhr.response;
    }).catch((xhr) => {
      if (xhr.statusCode === 410) this.offerExpired = true;
      else location.href = '/not-found';
    });

    if (this.api.isAuthenticated) {
      this.api.get(`users/me/promotion-offer-activations/${this.params.code}`).then((xhr) => {
        this.authUserRedeemDate = this.aurelia.resources.valueConverters.dateFormat.toView(xhr.response.redeemedAt, 'DD/MM/YYYY');
        this.offerStatus = 'AUTH-USER-ACTIVATED';
      }).catch(error => {
        if (error.statusCode === 403) this.offerStatus = 'SUBUSER-WITHOUT-PERMISSIONS';
      });
    }
  }

  attached() {
    this.reroute = encodeURIComponent(this.router.currentInstruction.fragment);
    if (this.localStorageOffer?.email && this.localStorageOffer?.redeemCodes.includes(this.params.code)) this.offerStatus = 'EMAIL-ALREADY-STORED';
  }

  activateOffer() {
    this.resource.validate().then(() => {
      let data = { email: this.resource.data.email, redeemCode: this.params.code };
      let endpoint = this.api.isAuthenticated ? 'users/me/promotion-offer-activations' : 'promotion-offer-activations';

      this.api.post(endpoint, data).then(() => {
        // set local storage data
        if (data.email) this.promoOffers.email = data.email;
        if (!this.promoOffers.redeemCodes.includes(data.redeemCode)) this.promoOffers.redeemCodes.push(data.redeemCode);
        this.updateLocalStorage();

        if (this.api.isAuthenticated) this.offerStatus = 'AUTH-USER-ACTIVATED-NOW'; 
        else this.offerStatus = 'GUEST-USER-ACTIVATED';
      }).catch(error => {
        // conflict case - if the submitted email is already used, overwrite promo-offers in local storage
        if (error.statusCode === 409) {
          if (!this.promoOffers.redeemCodes.includes(data.redeemCode) && data.email) this.promoOffers.email = data.email; 
          this.updateLocalStorage();
          this.offerStatus = 'EMAIL-ALREADY-STORED';
        }
        if (error.statusCode !== 423) this.resource.error(error);
      });
    })
  }

  updateLocalStorage() {
    localStorage.setItem('promo-offers', JSON.stringify(this.promoOffers));
    this.localStorageOffer = JSON.parse(localStorage.getItem('promo-offers'));
  }
}