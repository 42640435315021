import {inject, InlineViewStrategy} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaToast, KaDialog} from 'ka-components';
import mdt from 'services/md-tools';
import environment from 'environment';

@inject(Api, KaDialog, KaToast)
export class Toolbar {
  constructor(api, dialog, toast) {
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
    this.items = [];
    this.notiIntervalCounter = 0;
    this.init();
  }
  init() {
    this.items = [{
      authRoles: ['LIST_USER_UI_NOTIFICATION'],
      label: 'Notifiche',
      badge: {
        promise: (resolve) => {
          let storage = localStorage.getItem(btoa('ui-notifications-counter')) || null;
          let element = document.querySelector('.km-layout-toolbar');
          if (storage && element ) {
            if (JSON.parse(storage).totalUnreadNotifications) {
              if (JSON.parse(storage).totalUnreadNotifications === 'error') {
                if (!element.classList.contains('notifications-error')) element.classList.add('notifications-error');
                return resolve('!');
              } else if (JSON.parse(storage).totalUnreadNotifications !== 'error') {
                if (element.classList.contains('notifications-error')) element.classList.remove('notifications-error');
                return resolve(parseInt(JSON.parse(storage).totalUnreadNotifications, 10) || null);
              }
            }
          }
        },
        interval: 2000
      },
      icon: 'notifications',
      href: 'notifiche',
      route: ['notifiche'],
      moduleId: PLATFORM.moduleName('views/notifiche-ui/notifiche')
    }, {
        authGroups: ['MASTER_USER', 'BASIC_USER'],
        label: 'Imposta',
        icon: 'settings',
        href: null,
        settings: true, // usato come riferimento per pagina elenco settings
        nav: [{
          authGroups: ['MASTER_USER', 'BASIC_USER'],
          label: 'Generali',
          icon: 'settings',
          href: 'impostazioni/generali',
          route: ['impostazioni/generali'],
          moduleId: PLATFORM.moduleName('views/impostazioni/generali')
        }, {
          authGroups: ['MASTER_USER', 'BASIC_USER'],
          label: 'Posta',
          icon: 'markunread_mailbox',
          href: 'impostazioni/posta',
          route: ['impostazioni/posta'],
          moduleId: PLATFORM.moduleName('views/impostazioni/posta')
        }, {
          authGroups: ['MASTER_USER', 'BASIC_USER'],
          label: 'Email',
          icon: 'email',
          href: 'impostazioni/email',
          route: ['impostazioni/email'],
          moduleId: PLATFORM.moduleName('views/impostazioni/email')
        }, {
          authGroups: ['MASTER_USER', 'BASIC_USER'],
          label: 'Multibox',
          icon: 'all_inbox',
          href: 'impostazioni/multibox',
          route: ['impostazioni/multibox'],
          moduleId: PLATFORM.moduleName('views/impostazioni/multibox')
        }, {
          authGroups: ['MASTER_USER', 'BASIC_USER'],
          label: 'MultiCerta',
          icon: 'verified_user',
          href: 'impostazioni/multicerta',
          route: ['impostazioni/multicerta'],
          moduleId: PLATFORM.moduleName('views/impostazioni/multicerta')
        }, {
          authGroups: ['MASTER_USER', 'BASIC_USER'],
          label: 'SMS',
          icon: 'sms',
          href: 'impostazioni/sms',
          route: ['impostazioni/sms'],
          moduleId: PLATFORM.moduleName('views/impostazioni/sms')
        }, {
          authGroups: ['MASTER_USER', 'BASIC_USER'],
          label: 'Fax',
          icon: 'print',
          href: 'impostazioni/fax',
          route: ['impostazioni/fax'],
          moduleId: PLATFORM.moduleName('views/impostazioni/fax')
        }, {
          authGroups: ['MASTER_USER', 'BASIC_USER'],
          label: 'Notifiche',
          icon: 'notification_important',
          href: 'impostazioni/notifiche',
          route: ['impostazioni/notifiche'],
          moduleId: PLATFORM.moduleName('views/impostazioni/notifiche')
        }, {
          authGroups: ['MASTER_USER', 'BASIC_USER'],
          hidden: true,
          label: 'Condizioni di servizio',
          icon: null,
          href: 'impostazioni/agreements',
          route: 'impostazioni/agreements/:id?',
          moduleId: PLATFORM.moduleName('views/impostazioni/agreements')
        }]
      }, {
        authGroups: ['MASTER_USER', 'BASIC_USER'],
        label: 'Aiuto',
        icon: 'help_outline',
        href: 'help',
        route: ['help'],
        moduleId: PLATFORM.moduleName('views/help/help')
      }, {
        hidden: !this.api.isImpersonated,
        label: 'Rientra',
        icon: 'switch_account',
        call: () => { this.api.disimpersonate(); }
      }, {
        hidden: this.api.isImpersonated || !this.api.hasRole('IMPERSONATE_ANY_USER_WITH_SUPPORT_TICKET'),
        label: 'Impersona',
        icon: 'switch_account',
        call: () => { this.impersonate(); }
      }, {
        label: 'Esci',
        icon: 'exit_to_app',
        href: 'logout',
        route: ['logout'],
        moduleId: PLATFORM.moduleName('views/accesso/logout')
      }
    ];
  }
  impersonate() {
    let params = { codice: null };
    this.dialog.open({
      title: 'Login con codice assistenza',
      class: 'medium',
      type: 'confirm',
      viewStrategy: new InlineViewStrategy(`<template>Inserisci il codice assistenza per effettuare l'accesso:<br><br><ka-input config.bind="{control: 'text', label: 'Codice assistenza'}" value.bind="params.viewStrategyParams.codice"></ka-input></template>`),
      viewStrategyParams: params
    }).whenClosed(response => {
      if (!response.wasCancelled) {
        this.api.impersonate(null, params.codice).then(x => {
        }).catch(error => {
          konsole.error(error);
          let response = error.response && typeof error.response === 'string' ? JSON.parse(error.response) : null;
          this.toast.show('Errore nella procedura di accesso' + (response && response.detail ? `: ${response.detail}` : '!'), 'error');
        });
      }
    });
  }
}
