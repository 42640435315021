import {PLATFORM, inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaDialog} from 'ka-components';

@inject(Api, KaDialog)
export class vmLegacyViewer {

  constructor(api, dialog) {
    this.api = api;
    this.dialog = dialog;
  }

  activate(params) {
    this.params = params;
  }

  attached() { this.init(); }

  init() {
    if (this.params.view === 'billing-profiler') {
      this.config = {
        view: PLATFORM.moduleName('views/anagrafiche/utenti/views/fatturazione-editor'),
        model: {
          userId: this.params.userId,
          profileUuid: this.params.profileUuid,
          callback: (completed) => {
            if (!completed) {
              this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'confirm', body: `Confermi di voler uscire senza salvare il profilo di fatturazione?`}).whenClosed(response => {
                if (!response.wasCancelled && this.params.encodedCallbackUri) location.href = window.atob(decodeURIComponent(this.params.encodedCallbackUri));
              });
            } else {
              this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'alert', body: `Profilo di fatturazione correttamente salvato!`}).whenClosed(response => {
                if (this.params.encodedCallbackUri) location.href = window.atob(decodeURIComponent(this.params.encodedCallbackUri));
              });
            }
          }
        }
      }
    }
  }


}