import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';
import mdt from 'services/md-tools';

@inject(Router, Api, KaDialog, KaToast)
export class vmRicaricheElenco {
  mdt = mdt;

  users = {
    selected: null,
    fetched: null
  };

  constructor(router, api, dialog, toast) {
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(model) {
    this.params = model.params;
    this.privileges = model.privileges;
  }

  attached() {
    this.init();
  }

  init() {
    // INIT TableInterface
    this.tableInterface = new TableInterface({
      name: 'tab-ricariche-elenco-table',
      client: this.api,
      endpoint: null,
      query: null
    });
    this.tableInterface.initialize().then(() => { this.tableInterface.limit = 100; }).catch(error => { console.log('tableInterface initialization failed', error) });

    // INIT SearchInterface
    this.searchInterface = new SearchInterface({
      name: 'tab-ricariche-elenco-search',
      table: this.tableInterface,
      schema: {
        context: {
          control: 'combo',
          label: 'Quali ricariche visualizzare?',
          datasource: [
            { value: 'me', text: 'Le ricariche aquistabili da me' },
            { value: 'subusers', text: 'Le ricariche aquistabili da un mio ' + this.mdt.parsers.contextualize(this.api.user, 'utente') }
          ],
          datavalue: 'value',
          datatext: 'text',
          datamultiple: false,
          datapreload: true,
          required: true
        },
        user: { 
          control: 'combo', 
          label: this.mdt.parsers.contextualize(this.api.user, 'Utente'),
          datasource: { table: `${ this.privileges.isOperator ? 'bo/users' : 'users/me/users' }?include=user-profiles`, query: { search: 'text-search', item: 'id-in'} },
          datavalue: 'id', 
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false,
          required: true
        },
        types: {
          control: 'combo',
          label: 'Tipologia',
          datasource: [{ code: 'null', title: 'Tutte' }],
          datavalue: 'code',
          datatext: 'title',
          datapreload: true,
          datamultiple: false,
          required: true,
          query: 'type-code'
        },
        visible: {
          control: 'combo',
          label: 'Pacchetti visualizzabili',
          datasource: [{ value: 'null', text: 'Tutti' }, { value: 'YES', text: 'Visibili all\'utente' }],
          datapreload: true,
          query: 'visible'
        }
      },
      data: {
        context: !this.privileges.isOperator ? 'me' : null,
        types: 'null',
        visible: !this.api.hasRole('LIST_INVISIBLE_SHOP_CATALOG_REFILL_PACKAGES') ? 'YES' : 'null'
      }
    });
    this.searchInterface.initialize().then(() => {}).catch(error => { console.log('searchInterface initialization failed', error) });
  }

  userChange() {
    let userId = null;
    if (this.searchInterface.data.context === 'me') { 
      userId = this.api.user.id;
      this.searchInterface.data.user = null;
    } else if (this.searchInterface.data.user) {
      userId = this.searchInterface.data.user;
    } else return;

    if (!this.users[userId]) {
      this.users[userId] = {
        id: userId,
        displayName: null,
        packageTypes: [],
        packages: [],
        cart: JSON.parse(localStorage.getItem(`cart-${userId}`)) || {
          quantity: 0,
          amount: { net: 0, vat: 0 },
          packages: {}
        }
      };
      if (userId === this.api.user.id) {
        this.users[userId].displayName = this.api.user.profile.displayName;
      } else {
        setTimeout(() => { this.users[userId].displayName = this.searchInterface.controls.user.objectValue.profile[0].displayName; }, 500);
      }
    }

    this.users.selected = this.users[userId];
    this.api.get(`users/${userId}/shop/catalog/refill-package-types`).then(xhr => {
      this.users.selected.packageTypes = xhr.response.filter(x => x.packageCount > 0);
      this.resetTypesDatasource();
    });
  }

  typesChange() {
    if (!this.searchInterface.data.types) this.searchInterface.data.types = 'null';
    if (this.users.selected) this.search();
  }

  resetTypesDatasource() {
    let datasource = [{ code: 'null', title: 'Tutte' }];
    this.users.selected.packageTypes.forEach(type => {
      datasource.push({ code: type.code, title: type.title });
    });
    this.searchInterface.controls.types.schemaChanged(Object.assign(this.searchInterface.schema.types, { datasource: datasource }));
    this.searchInterface.data.types = 'null';
  }

  search() {
    this.tableInterface.endpoint = `users/${this.users.selected.id}/shop/catalog/refill-packages`;
    this.searchInterface.search().then(() => {
      this.users.selected.packages = [];
      this.users.selected.packageTypes.forEach(type => {
        let packages = this.tableInterface.data.filter(x => x.typeCode === type.code);
        this.users.selected.packages.push(Object.assign({ packages: packages }, type));
      });
      this.users.fetched = this.users[this.users.selected.id];
      this.updateCart();
    }).catch(error => {
      console.log('Search error', error);
    });
  }

  addToCart(pkg) {
    this.users.fetched.cart.packages[pkg.code] = 1;
    this.updateCart();
  }

  setQuantity(pkg, quantity) {
    quantity = parseInt(this.users.fetched.cart.packages[pkg.code], 10) + parseInt(quantity, 10);
    quantity = quantity > 0 ? quantity : 1;
    this.users.fetched.cart.packages[pkg.code] = quantity;
    this.updateCart();
  }

  removeFromCart(pkg) {
    this.users.fetched.cart.packages[pkg.code] = 0;
    this.updateCart();
  }

  getCartPayload() {
    return Object.entries(this.users.fetched.cart.packages).filter(x => x[1] > 0).map(x => { return { code: x[0], quantity: x[1], type: 'REFILL-PACKAGE' } });
  }

  emptyCart() {
    this.users.fetched.cart = {
      quantity: 0,
      amount: { net: 0, vat: 0 },
      packages: {}
    };
    this.updateCart();
  }

  updateCart() {
    let cartbar = document.querySelector('.cartbar');
    let data = this.getCartPayload();
    if (data.length) {

      this.api.post(`users/${this.users.fetched.id}/shop/cart-cost-forecast`, data).then(xhr => {
        setTimeout(() => { 
          this.users.fetched.cart.quantity = xhr.response.totalBasketItems;
          this.users.fetched.cart.amount.net = xhr.response.totalBasketNetAmount;
          this.users.fetched.cart.amount.vat = xhr.response.totalBasketVat;
          localStorage.setItem(`cart-${this.users.fetched.id}`, JSON.stringify(this.users.fetched.cart));
        }, 500);
      }).catch(error => {
        this.api.dialogError(error);
        let storageCart = JSON.parse(localStorage.getItem(`cart-${this.users.fetched.id}`));
        if (storageCart) {
          this.users.fetched.cart = storageCart;
        } else {
          this.emptyCart();
        }
      }).finally(() => {
        if (!(this.getCartPayload()).length) return;
        if (cartbar.classList.contains('filled')) cartbar.classList.add('added');
        else setTimeout(() => { cartbar.classList.add('filled'); }, 500);
        setTimeout(() => { cartbar.classList.remove('added'); }, 1000);
      });
    } else {
      localStorage.removeItem(`cart-${this.users.fetched.id}`);
      cartbar.classList.remove('filled');
    }
  }

  checkout(event) {
    let data = this.getCartPayload();
    if (!data.length) return;
    event.model.busy = true;
    this.api.post(`users/${this.users.fetched.id}/shop/cart/items`, data).then(() => {
      this.gotoLegacy();
    }).catch(error => {
      this.api.dialogError(error);
      console.error('Errore. Pacchetti non aggiunti al carrello', error);
    }).finally(() => {
      event.model.busy = false;
    });
  }

  gotoLegacy() {
    this.toast.show('Caricamento in corso', 'loading', true);
    this.api.get('users/me/cross-login-tokens/current').then(xhr => {
      let token = xhr.response.token;
      window.open(`${ENVIRONMENT.APP_URL_LEGACY}crosslogin.php?service-token=${token}&action=cart&requiredUser=${this.users.fetched.id}`, '_blank');
      this.emptyCart();
    }).catch(error => { console.error(error)}).finally(() => { this.toast.consume(); });
  }
}

