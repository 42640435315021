import { Api } from 'services/api';
import { Aurelia } from 'aurelia-framework';
import { KaToast } from 'ka-components';
import { DialogController } from 'aurelia-dialog';

export class DialogBankTransfer {
  prececkout = null;

  static inject = [Api, Aurelia, KaToast, DialogController];
  constructor(api, aurelia, toast, controller) {
    this.api = api;
    this.aurelia = aurelia;
    this.toast = toast;
    this.controller = controller;
  }

  activate(params) {
    this.params = params;
    this.getPrececkout();
  }

  getPrececkout() {
    this.api.get(`users/${this.params.queue.owner.id}/queues/${this.params.queue.uuid}/pre-checkout/current`).then(xhr => {
      this.prececkout = xhr.response;
      this.email = {
        address: this.prececkout.paymentCoors.customerCareEmail,
        subject: `Pagamento spedizione con causale ${this.prececkout.paymentCoors.confirmationShortCode}`,
        body: `Comunico di aver eseguito il pagamento tramite bonifico tradizionale per la spedizione relativa a ${this.prececkout.paymentCoors.payeeDisplayName} del ${this.aurelia.resources.valueConverters.dateFormat.toView(this.prececkout.createdAt, 'DD/MM/YYYY HH:mm')}.\n\nIn allegato la distinta di  pagamento.\n\nSi prega di non modificare il contenuto dell'email.`
      }
      this.email.mailto = encodeURI(`mailto:${this.email.address}?subject=${this.email.subject}&body=${this.email.body}`);
      this.customerCareConfirmation = this.prececkout.paymentCoors.customerCareConfirmation.replace(/SLA\./, '<a href="https://termsofservice.multidialogo.it/multidialogo/sla" target="blank_">SLA.</a>');
    }).catch(xhr => {
      console.error('Error - GET pre-checkout/current', xhr);
      this.api.dialogError(xhr);
    });
  }

  copyText(text, item) {
    navigator.clipboard.writeText(text);
    this.toast.show(`${item} copiato con successo!`, 'success');
  }
}
