import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';
import mdt from 'services/md-tools';

@inject(Router, Api, KaDialog, KaToast)
export class vmAbbonamentiGestione {
  mdt = mdt;
  endpointServices = 'bo/flat-services';
  endpointSponsors = 'bo/users?include=user-profiles&user-group-in=SPONSOR';

  constructor(router, api, dialog, toast) {
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(model) {
    model.tab.controller = this;
    this.parent = model.parent;
    this.params = model.params;
    this.privileges = model.privileges;
    this.tools = model.tools;
  }

  attached() {
    this.init();
  }

  init() {
    // INIT TableInterface
    this.tableInterface = new TableInterface({
      name: 'tab-abbonamenti-gestione-table',
      client: this.api,
      endpoint: this.endpointServices,
      query: null
    });
    this.tableInterface.initialize().catch(error => { console.log('tableInterface initialization failed', error) });

    // INIT SearchInterface
    this.searchInterface = new SearchInterface({
      name: 'tab-abbonamenti-gestione-search',
      table: this.tableInterface,
      schema: {
        productCode: {
          control: 'combo',  
          label: 'Prodotto', 
          datasource: { table: 'products' }, 
          datavalue: 'code', 
          datatext: 'label',
          datamultiple: false,
          datapreload: true,
          query: 'product-code-in'
        },
        type: {
          control: 'combo', 
          label: 'Piano',
          datasource: { table: 'flat-service-types' },
          datavalue: 'code',
          datatext: 'description',
          datamultiple: false,
          datapreload: true,
          query: 'type-in'
        },
        code: { 
          control: 'text', 
          label: 'Codice servizio',
          query: 'code-in'
        },
        title: {
          control: 'text',
          label: 'Titolo',
          query: 'title-like'
        },
        description: {
          control: 'text',
          label: 'Descrizione',
          query: 'description-like'
        },
        activableOn: {
          control: 'combo',  
          label: 'Sottoscrivibile da', 
          datasource: [{ value: 'MASTER', text: 'Utenti' }, { value: 'SUBUSER', text: 'Sottoutenti' }, { value: '', text: 'Utenti e Sottoutenti' }],
          datavalue: 'value', 
          datatext: 'text', 
          datapreload: true,
          datamultiple: false,
          query: 'activable-on'
        },
        payableBy: {
          control: 'combo',  
          label: 'Addebitabile a', 
          datasource: [{ value: 'SPONSOR', text: 'Sponsor' }, { value: 'MASTER', text: 'Utenti' }, { value: 'SUBUSER', text: 'Sottoutenti' }, { value: 'PARENT-OR-SUBUSER', text: 'Utenti e Sottoutenti' }],
          datavalue: 'value', 
          datatext: 'text', 
          datapreload: true,
          datamultiple: false,
          query: 'payable-by'
        },
        cashbackAmount: {
          label: 'Cashback',
          control: 'combo',
          datasource: [
            { value: 'null', text: 'Qualsiasi' },
            { value: 'YES', text: 'Servizi con cashback' },
            { value: 'NO', text: 'Servizi senza cashback' }
          ],
          query: 'has-cashback'
        }
      },
      data: {
        cashbackAmount: 'null'
      }
    });

    this.productCodeChanged(this.searchInterface.data.productCode); // Il control "type" dipende dal control "productCode", qui inizializzo il primo in base all'eventuale valore del secondo
    this.searchInterface.initialize().then(() => { 
      this.searchInterface.search();
      this.getSponsors();
    }).catch(error => { 
      console.log('searchInterface initialization failed', error);
    });
  }

  productCodeChanged(value) {
    if (this.searchInterface.controls?.type) {
      this.searchInterface.data.type = null;
      this.searchInterface.controls.type.datasource.table = value ? `flat-service-types?product-code-in=${value}` : 'flat-service-types';
      this.searchInterface.controls.type.comboItemsSource = null;
      this.searchInterface.controls.type.comboItems = null;
    } else {
      this.searchInterface.schema.type.datasource.table = value ? `flat-service-types?product-code-in=${value}` : 'flat-service-types';
    }
  }

  createService() {
    if (!this.api.hasRole('CREATE_FLAT_SERVICES')) return;
    this.dialog.open({
      title: 'Crea nuovo servizio in abbonamento',
      class: 'abbonamenti-editor large',
      viewModel: PLATFORM.moduleName('views/servizi/abbonamenti/dialogs/abbonamenti-editor')
    }, true).whenClosed(response => {
      if (!response.wasCancelled) { this.searchInterface.search(); }
    });  
  }

  editService($event, service) {
    if (!this.api.hasRole('MODIFY_FLAT_SERVICES')) return;
    if ($event.model) $event.model.busy = true;
    this.dialog.open({
      title: 'Servizio in abbonamento',
      class: 'abbonamenti-editor large',
      viewModel: PLATFORM.moduleName('views/servizi/abbonamenti/dialogs/abbonamenti-editor'),
      viewModelParams: { uuid: service.id }
    }, true).whenClosed(response => {
      if (!response.wasCancelled) { this.searchInterface.search(); }
      if ($event.model) $event.model.busy = false;
    });
  }

  serviceDescription(description) {
    this.dialog.open({ title: 'Descrizione servizio', class: 'medium', type: 'alert', body: `${description}` });
  }
}
