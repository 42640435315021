import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { DialogController } from 'aurelia-dialog';

export class DialogVoucherBrainware {
  resource = {
    data: {
      amount: 0
    },
    controls: {},
    schema: { amount: { control: 'number', label: 'Valore del voucher in €', required: true }},
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        control.validator.errors = [];
        control.element.classList.remove('error');
        promises.push(new Promise((resolve, reject) => { 
          control.validate().then((result) => { 
            if (result.valid) {
              resolve();
            } else {
              reject('Control didn\'t pass validation');
            }
          }); 
        }));
      });
      return Promise.all(promises);
    },
    error: (error) => {
      this.api.dialogError(error, this.resource.controls);
    }
  }

  static inject = [Api, DialogController, KaDialog, KaToast];
  constructor(api, controller, dialog, toast) {
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
    this.controller = controller;
  }

  activate(params) {
    this.params = params;
  }

  async saveTransferVoucher($event) {
    $event.model.busy = true;
    const data = this.parseData(this.resource.data);

    return this.resource.validate().then(() => {
      return this.dialogConfirm().whenClosed((response) => {
        if (!response.wasCancelled) {
          return this.api.post('users/me/cashback-transfer-voucher-movements', data).then(() => {
            this.toast.show('Trasferimento avvenuto con successo!', 'success');
            this.controller.ok();
          }).catch((error) => {
            console.error('Get cashback-transfer-voucher-movements', error);
            this.resource.error(error);
          }).finally(() => {
            $event.model.busy = false;
          });
        } else {
          $event.model.busy = false;
        }
      });
    }).catch(() => {
      $event.model.busy = false;
    });
  }

  parseData(data) {
    let clone = structuredClone(data);
    return clone = {
      ...clone,
      amount: Number(clone.amount)
    }
  }

  dialogConfirm() {
    return this.dialog.open({
      title: 'Attenzione!',
      class: 'confirm-transfer-voucher',
      type: 'confirm',
      body: `<p class="m-0"><strong>L'acquisto del Voucher è riservato ai clienti Brainware Cloud. Questa operazione è irreversibile!</strong></p><p class="mx-0 mb-0">Confermi di voler acquistare un voucher BrainWare <span class="text-nowrap">dal valore di ${this.resource.data.amount}€ ?</span></p>`
    });
  }
}
