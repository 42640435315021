import {inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaToast, KaDialog} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';

@inject(Api, KaToast, KaDialog)
export class MulticertaInvite {
  endpoint = 'users/me/multicerta-invitations';
  data = {};

  constructor(api, toast, dialog) {
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
  }

  attached() {
    this.data.mittente = this.api.user.profile.displayName;
    this.data.email = this.api.user.profile.email;

    /* Table interface */
    this.multicertaInviteTableInterface = new TableInterface({
      name: 'multicerta-invite-table',
      client: this.api,
      endpoint: this.endpoint,
      query: null
    });

    /* Table Search */
    this.multicertaInviteSearchInterface = new SearchInterface({
      name: 'multicerta-invite-table-search',
      table: this.multicertaInviteTableInterface,
      schema: {
        email: {
          label:   "Email",
          control: "text",
          query: 'email-like'
        }
      },
      data: {}
    });

    /* Initialize & Search function */
    this.multicertaInviteTableInterface.initialize().then(() => {
      this.multicertaInviteSearchInterface.initialize().then(() => {
        this.multicertaInviteSearchInterface.search().catch(error => { console.error('TableSearchInterface-search multicertaInvite failed', error); });
      }).catch(error => { console.error('TableSearchInterface-initialize multicertaInvite failed', error); });
    }).catch(error => { console.error('TableInterface-initialize multicertaInvite failed', error); });
  }

  test($event) {
    $event.model.busy = true;
    let data = {
      displayName: this.data.mittente || this.api.user.profile.displayName,
      demoRecipient: this.data.email
    };
    this.api.post(this.endpoint, data).then(x => {
      this.toast.show(`Invito di prova inviato con successo all'indirizzo ${data.demoRecipient}!`, 'success');
      this.multicertaInviteTableInterface.load();
    }).catch(x => {
      console.warn(x);
      this.toast.show(`Errore nell'invio dell'invito di prova all'indirizzo ${data.demoRecipient}!`, 'error');
    }).finally(() => {
      $event.model.busy = false;
    });
  }

  send($event) {
    if (!this.data.file || this.data.file.length === 0) return this.toast.show('Carica il file csv!', 'error');
    $event.model.busy = true;
    let reader = new FileReader();
    reader.readAsDataURL(this.data.file[0]);
    reader.onload = () => {
      let data = {
        displayName: this.data.mittente || this.api.user.profile.displayName,
        fileData: reader.result
      };
      this.api.post(this.endpoint, data).then(x => {
        this.toast.show('Inviti inviati con successo!', 'success');
        this.multicertaInviteTableInterface.load();
        this.data.file = null;
      }).catch(x => {
        console.warn(x);
        let response = JSON.parse(x.response);
        let errorMessages = [];
        if (response.status === 400 && response.source) {
          if (response.source.parameters) {
            for (let parameter of response.source.parameters) {
              if (parameter.messages && parameter.messages.length) {
                for (let message of parameter.messages) {
                  errorMessages.push(message);
                }
              }
            }
          }
        }
        else errorMessages.push('Errore nell\'invio degli inviti!');
        this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'alert', body: errorMessages.join('<br>')});
        this.data.file = null;
      }).finally(() => {
        $event.model.busy = false;
      });
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
      this.toast.show('Errore nel caricamento del file!', 'error');
      $event.model.busy = false;
    };
  }
}
