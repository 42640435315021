import { PLATFORM, inject } from 'aurelia-framework';
import mdt from 'services/md-tools';
import { Api } from 'services/api';
import { KaToast } from 'ka-components';
import { QueueProperties } from '../../../classes/queueProperties';

@inject(Api, KaToast)
export class vmReport {
  mdt = mdt;
  privileges;
  queueProperties = new QueueProperties();
  tabs = [
    {
      id: 'inviate',
      label: 'Inviate',
      vm: PLATFORM.moduleName('views/spedizioni/report/tabs/sent')
    },
    {
      id: 'annullate',
      label: 'Annullate',
      vm: PLATFORM.moduleName('views/spedizioni/report/tabs/canceled')
    },
    {
      id: 'archiviate',
      label: 'Archiviate',
      vm: PLATFORM.moduleName('views/spedizioni/report/tabs/archived')
    }
  ];

  constructor(api, toast) {
    this.api = api;
    this.toast = toast;
    this.tools = {
      copyText: (text, item) => {
        return navigator.clipboard.writeText(text),
        this.toast.show(`${item} copiato con successo!`, 'success');
      },
      getUserData: (id) => {
        return this.api.get(`users/${id}?include=user-profiles,user-roles`).catch((error) => {
          console.error(`GET - users`, error);
          this.toast.show('Errore durante il caricamento dei dati!', 'error');
        });
      },
      setDefaultDateRange: (days) => {
        // If "days"(integer), subtracts days from today's date.
        const currentDate = new Date();

        if (days) {
          currentDate.setDate(currentDate.getDate() - days);
        }
        return currentDate.toISOString();
      },
      parseMessagesStatusCount: (response) => {
        response.forEach((element) => {
          if (element.messagesStatusCount && element.totalMessagesCount) {
            element._messagesStatusCount = [];
            Object.entries(element.messagesStatusCount).forEach((StatusCount) => {
              const [status, count] = StatusCount;
              element._messagesStatusCount.push({ status, count });
            });
          }
        });
        return response;
      },
      setChannelDatasource: () => {
        const channels = structuredClone(this.queueProperties.channels);
        const datasource = channels.map((channel) => ({
          text: channel.text,
          value: channel.value.toLowerCase()
        }));
        return datasource;
      },
      setStatusDatasource: (values) => {
        const statuses = this.queueProperties?.statuses;
        const datasource = [{ text: 'Tutti', value: 'null' }];
        values.forEach((value) => {
          // if you are looking for a status
          let status = statuses.filter((status) => status.value === value)?.[0];
          // if you are looking for grouped statuses (multiple states with the same group)
          if (!status) {
            let  groupedStatuses = statuses.filter((status) => status.group === value);
            let groupedValues = groupedStatuses.reduce((accumulator, current) => {
              return `${accumulator.value},${current.value}`;
            });
            status = { text: groupedStatuses[0].group, value: groupedValues };
          }
          datasource.push(Object.assign({}, status));
        });
        return datasource;
      },
      setContextDatasource: (userData) => {
        const datasource = [{ value: 'USER', text: `Le spedizioni del mio ${mdt.parsers.contextualize(userData, 'account')}` }];
        if ((!this.privileges.isOperator && this.api.user.group === 'MASTER_USER') || (this.privileges.isOperator && userData?.group === 'MASTER_USER')) {
          if (!this.privileges.isOperator && this.api.user.group === 'MASTER_USER') {
            datasource.unshift({ value: 'USER&SUBUSERS', text: `Tutte` });
          }
          datasource.push({ value: 'SUBUSERS', text: `Le spedizioni dei miei ${mdt.parsers.contextualize(userData, 'utenti')}` });
        } return datasource;
      },
      setDefault: (searchInterface, dataProperties) => {
        searchInterface.data.context = this.privileges.isOperator ? 'null' : this.api.user.group === 'MASTER_USER' ? 'USER&SUBUSERS' : 'USER';
        dataProperties.forEach((property) => {
          const key = Object.keys(property)[0];
          const value = Object.values(property)[0];
          searchInterface.data[key] = value;
        });
      },
      downloadWorkOrderList: (queue) => {
        this.toast.show('Caricamento in corso', 'loading', true);
        const uri = `users/${queue.owner.id}/queues/${queue.uuid}.pdf`;

        return this.api.client.createRequest(uri)
          .asGet()
          .withHeader("Accept", "application/pdf")
          .withHeader("Content-Type", "application/pdf")
          .withResponseType('blob')
          .send()
          .then((xhr) => {
            const regexp = new RegExp('filename=(.+\.pdf$)');
            const matches = xhr.headers.headers['content-disposition'].value.match(regexp);
            const anchor = document.createElement("a");
            const blob = new Blob([xhr.response], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);

            anchor.href = url;
            anchor.download = matches[1];
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            this.toast.show("Distinta scaricata correttamente", 'success');
          }).catch((error) => {
            console.log("impossibile scaricare il pdf", error);
            this.toast.show('impossibile scaricare la distinta', 'error');
          }).finally(() => {
            this.toast.consume();
          })
      }
    };
    this.privileges = {
      tabs: { inviate: false, annullate: false, archiviate: false },
      isOperator: this.api.hasRole('LIST_ANY_QUEUE')
    };
  }

  activate(params) {
    this.params = params;
    this.params.queueProperties = this.queueProperties;
  }

  attached() {
    this.init();
  }

  init() {
    this.pendingInit = true;
    if (this.privileges.isOperator) return this.getClients();
    return this.privilege();
  }

  privilege() {
    if (this.api.hasRole('LIST_QUEUE')) {
      this.privileges.tabs.inviate = this.privileges.tabs.annullate = this.privileges.tabs.archiviate = true;
    }
    this.pendingInit = false;
  }

  toLegacy() {
    this.toast.show('Caricamento in corso', 'loading', true);
    this.api.get('users/me/cross-login-tokens/current').then((xhr) => {
      let token = xhr.response.token;
      window.open(`${ENVIRONMENT.APP_URL_LEGACY}crosslogin.php?service-token=${token}&action=mi_report&multiqueue_uuid=${this.params.queueUuid}`);
    }).catch((error) => { console.error(error) }).finally(() => { this.toast.consume(); });
  }

  getClients() {
    return this.api.get('clients?declarable=YES').then((xhr) => {
      const response = xhr.response;
      this.params.clients = response;
    }).catch((error) => {
      console.error('clients?declarable=YES', error);
    }).finally(() => {
      return this.privilege();
    });
  }
}
