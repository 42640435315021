import {inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';

@inject(DialogController)
export class DialogIntents {
  vms = {
    "PROVIDE-CERTIFIED-DOCUMENTS": PLATFORM.moduleName('resources/dialogs/intents/vms/provide-certified-documents'),
    "PROVIDE-LEGAL-FORM": PLATFORM.moduleName('resources/dialogs/intents/vms/provide-legal-form'),
    "PROVIDE-IBAN": PLATFORM.moduleName('resources/dialogs/intents/vms/provide-iban'),
    "LOGIN": PLATFORM.moduleName('resources/dialogs/intents/vms/login'),
    "CONFIRM-TRANSMISSION-DELAY": PLATFORM.moduleName('resources/dialogs/intents/vms/confirm-transmission-delay'),
    "SDD-ENABLED-REQUIRED": PLATFORM.moduleName('resources/dialogs/intents/vms/provide-sdd'),
    "PAY-ORDERS": PLATFORM.moduleName('resources/dialogs/intents/vms/pay-orders'),
    "ACCEPT-CASHBACK-CONTRACT": PLATFORM.moduleName('resources/dialogs/intents/vms/accept-cashback-contract')
  };

  constructor(dialogController) {
    this.controller = dialogController;
  }
  
  activate(xhr) {
    this.response = xhr.response;

    // Check for a single intent
    this.intent = this.response.source.intents.find((intent) => ['LOGIN', 'CONFIRM-TRANSMISSION-DELAY', 'EMAIL-CONFIRMATION-REQUIRED'].includes(intent.code));
    if (this.intent) {
      this.intent.vm = this.vms[this.intent.code];
    } else {
      // Loop intents
      this.intents = [];
      for (let intent of this.response.source.intents) {
        if (intent.vm = this.vms[intent.code]) this.intents.push(intent);
      }
    }
  }

  includesCode(code) {
    const intents = this.intents.map((intent) => intent.code);
    return intents.includes(code);
  }
}
