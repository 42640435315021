import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { TableInterface, SearchInterface } from 'classes/md-table';
import mdt from 'services/md-tools';

@inject(Router, Api, KaDialog, KaToast)
export class vmPromozioniAssegnate {
  mdt = mdt;

  constructor(router, api, dialog, toast) {
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(model) {
    this.params = model.params;
    this.privileges = model.privileges;
    this.tools = model.tools;
  }

  attached() {
    this.init();
  }

  init() {
    // INIT TableInterface
    this.tableInterface = new TableInterface({
      name: 'tab-promozioni-assegnate-table',
      client: this.api,
      endpoint: null,
      query: null
    });
    this.tableInterface.initialize().catch(error => { console.log('tableInterface initialization failed', error) });

    // INIT SearchInterface
    this.searchInterface = new SearchInterface({
      name: 'tab-promozioni-assegnate-search',
      table: this.tableInterface,
      schema: {
        context: {
          control: 'combo',
          label: 'Quali promozioni assegnate visualizzare?',
          datasource: [
            { value: 'me', text: 'Le promozioni assegnate a me' },
            { value: 'subusers', text: 'Le promozioni assegnate a un mio ' + this.mdt.parsers.contextualize(this.api.user, 'utente') }
          ],
          datavalue: 'value',
          datatext: 'text',
          datamultiple: false,
          datapreload: true,
          required: true
        },
        user: { 
          control: 'combo', 
          label: this.mdt.parsers.contextualize(this.api.user, 'Utente'),
          datasource: { table: `${ this.privileges.isOperator ? 'bo/users' : 'users/me/users' }?include=user-profiles`, query: { search: 'text-search', item: 'id-in'} },
          datavalue: 'id', 
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false,
          required: true
        },
        status: {
          control: 'combo',
          label: 'Stato',
          datasource: [{ text: 'Attiva', value: 'ACTIVE' }, { text: 'Terminata', value: 'EXPIRED' }],
          datavalue: 'value',
          datatext: 'text',
          datamultiple: false,
          datapreload: false,
          query: 'status'
        }
      },
      data: {
        status: 'ACTIVE'
      }
    });
    this.searchInterface.initialize().then(() => {
      if (this.searchInterface.data.context === 'me') this.search();
      else if (this.searchInterface.data.user) this.search();
    }).catch(error => { console.log('searchInterface initialization failed', error) });
  }

  reset() {
    this.selectedUserId = null;
    this.selectedUserData = null;
    this.tableInterface.reset();
    this.searchInterface.reset(true);
  }

  search() {
    if (this.searchInterface.data.context === 'me') { this.selectedUserId = 'me'; }
    else if (this.searchInterface.data.user) this.selectedUserId = this.searchInterface.data.user;
    else {
      this.selectedUserId = null;
      this.selectedUserData = null;
    }

    this.tableInterface.endpoint = `users/${this.selectedUserId}/promotion-activations`;
    this.searchInterface.search().then(() => {
      this.getUserProfile();
    });
  }

  getUserProfile() {
    this.api.get(`users/${this.selectedUserId}?include=user-profiles`).then(xhr => {
      let response = xhr.response;
      if (response.profile && Array.isArray(response.profile)) response.profile = response.profile[0];
      this.selectedUserData = response;
    }).catch(xhr => {
      console.log('Error GET users', xhr);
    });
  }

  addPromotion() {
    this.dialog.open({
      title: 'Assegna una promozione a',
      class: 'large add-promotion',
      viewModel: PLATFORM.moduleName('views/servizi/promozioni/dialogs/promozioni-assegna'),
      viewModelParams: { user: this.selectedUserData }
    }, true).whenClosed(response => {
      if (!response.wasCancelled) this.searchInterface.search();
    });
  }
  
  endsPromotion($event, promotion) {
    $event.model.busy = true;
    this.dialog.open({
      title: 'Termina promozione',
      class: 'edit-promotion small',
      viewModel: PLATFORM.moduleName('views/servizi/promozioni/dialogs/promozioni-modifica'),
      viewModelParams: { type: 'TERMINATE', userId: this.selectedUserId, promotion }
    }, true).whenClosed(response => {
      if (!response.wasCancelled) this.searchInterface.search();
      $event.model.busy = false; 
    });
  }

  editPromotion($event, promotion) {
    $event.model.busy = true;
    this.dialog.open({
      title: 'Modifica promozione',
      class: 'edit-promotion small',
      viewModel: PLATFORM.moduleName('views/servizi/promozioni/dialogs/promozioni-modifica'),
      viewModelParams: { type: 'EDIT', userId: this.selectedUserId, promotion }
    }, true).whenClosed(response => {
      if (!response.wasCancelled) this.searchInterface.search();
      $event.model.busy = false; 
    });
  }
}
