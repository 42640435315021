import {PLATFORM, inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaToast} from 'ka-components';

@inject(Api, KaToast)
export class vmAutorizzazioni {
  tabs = [
    {
      id: 'mav',
      label: 'MAV BANCARI',
      vm: PLATFORM.moduleName('views/riscossioni/autorizzazioni/tabs/mav')
    }
  ];

  constructor(api, toast) {
    this.api = api;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    this.privilege();
  }

  privilege() {
    this.privileges = { tabs: { mav: false }};

    if (this.api.hasRole(['LIST_MAV_ORDER_SELF_PRINTING_AUTHORIZATION', 'LIST_ANY_MAV_ORDER_SELF_PRINTING_AUTHORIZATION'])) {
      this.privileges.tabs.richieste = true;
      if (this.api.hasRole('LIST_ANY_MAV_ORDER_SELF_PRINTING_AUTHORIZATION')) this.privileges.isOperator = true;
    }
  }
}
