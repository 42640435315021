import moment from 'moment';
import { Api } from 'services/api';
import mdt from 'services/md-tools';
import { inject } from 'aurelia-framework';
import { KaDialog, KaToast } from 'ka-components';
import { DialogController } from 'aurelia-dialog';
import { MdDatasources } from 'services/md-datasources';

@inject(Api, KaDialog, KaToast, DialogController, MdDatasources)
export class ServiceDialogAbbonamentiEditor {
  service;
  pendingInit;
  paymentMethods;
  payers = [
    { value: 'MASTER', text: 'Utenti' }, 
    { value: 'SUBUSER', text: 'Sottoutenti' }, 
    { value: 'PARENT-OR-SUBUSER', text: 'Utenti e Sottoutenti' }
  ];

  constructor(api, kaDialog, kaToast, dialogController, MdDatasources) {
    this.api = api;
    this.dialog = kaDialog;
    this.toast = kaToast;
    this.controller = dialogController;
    this.paymentMethods = this.getPaymentMethods(MdDatasources);
  }

  getPaymentMethods(MdDatasources) {
    MdDatasources?.paymentMethods.forEach((method) => { method.readonly = method.availableByDefault; });
    return MdDatasources?.paymentMethods;
  }

  // Control type (label: Piano) related to productCode (label: Prodotto)
  changeProductCode(value) {
    if (!this.params?.uuid) {
      if (this.resource.controls?.type) {
        this.resource.data.type = null;
        this.resource.controls.type.comboItems = null;
        this.resource.controls.type.comboItemsSource = null;
      }
      this.resource.controls.type.datasource.table = value ? `flat-service-types?product-code-in=${value}` : 'flat-service-types';
    }
  }

  
  changeActivableByPrivilegedUserOnly() {
    const data = { ...this.resource.data };
    if (!this.params?.uuid) {
      const payableByDatasource = [ ...this.payers ];
      if (data.activableByPrivilegedUserOnly === 'SPONSOR') {
        payableByDatasource.push({ value: 'SPONSOR', text: 'Offerta dallo Sponsor' });
        this.resource.schema.payableBy = Object.assign({}, this.resource.schema.payableBy, { datasource: payableByDatasource });
        if (data.paymentMethods.every((method) => method !== 'ON-SIGHT')) data.paymentMethods.push('ON-SIGHT');
        data.payableBy = 'SPONSOR';
      } else {
        if (data.payableBy === 'SPONSOR') data.payableBy = null;
        if (data.paymentMethods.some((method) => method === 'ON-SIGHT')) {
          const item = data.paymentMethods.find((method) => method === 'ON-SIGHT');
          const index = data.paymentMethods.indexOf(item);
          data.paymentMethods.splice(index, 1);
        }
      }
    };
    return this.resource.data = data;
  }

  changeSubscriptionExpireAt() {
    const data = { ...this.resource.data };
    if (!this.params?.uuid && data.subscriptionExpireAt && !data.endAt) {
      data.endAt = data.subscriptionExpireAt;
    }
    return this.resource.data = data;
  }

  changePaymentMethods() {
    const data = { ...this.resource.data };
    if (!this.params?.uuid) {
      const isFreeCharge = data.paymentMethods.includes('FREE-CHARGE');
      if (isFreeCharge) {
        data.vatAmount = data.price = 0;
        data.activableByPrivilegedUserOnly = 'YES';
        data.paymentMethods = ['MIGRATION', 'FREE-CHARGE'];
      }
      this.resource.controls.price.control.readonly = isFreeCharge;
      this.resource.controls.price.control.schema.readonly = isFreeCharge
      this.resource.controls.vatAmount.control.readonly = isFreeCharge;
      this.resource.controls.activableByPrivilegedUserOnly.control.readonly = isFreeCharge;
    }
    return this.resource.data = data;
  }

  activate(params) {
    this.params = params;
    this.init();
  }

  getDefaultPaymentMethods() {
    return this.paymentMethods.filter(method => method?.availableByDefault).map(method => method?.code);
  }

  initResource() {
    this.resource = {
      controls: {},
      schema: {
        productCode: {
          control: 'combo',  
          label: 'Prodotto', 
          datasource: { table: 'products' },
          datavalue: 'code', 
          datatext: 'label',
          datamultiple: false,
          datapreload: true,
          client: this.api,
          required: this.params ? false : true,
          readonly: this.params ? true : false
        },
        type: {
          control: 'combo', 
          label: 'Piano',
          datasource: { table: 'flat-service-types' },
          datavalue: 'code',
          datatext: 'description',
          datamultiple: false,
          datapreload: true,
          client: this.api,
          required: this.params ? false : true,
          readonly: this.params ? true : false
        },
        title: {
          control: 'text',
          label: 'Titolo',
          required: this.params ? false : true,
          readonly: this.params ? true : false
        },
        description: {
          control: 'text',
          label: 'Descrizione',
          required: this.params ? false : true,
          readonly: this.params ? true : false
        },
        code: {
          control: 'text',
          label: 'Codice servizio',
          required: this.params ? false : true,
          readonly: this.params ? true : false
        },
        activableByPrivilegedUserOnly: {
          control: 'combo',  
          label: 'Attivabile da', 
          datasource: [
            { value: 'NO', text: 'Proprietario account' }, 
            { value: 'YES', text: 'Solo utente privilegiato' }, 
            { value: "SPONSOR", text: 'Sponsor' }
          ],
          datavalue: 'value', 
          datatext: 'text', 
          datapreload: true,
          required: this.params ? false : true,
          readonly: this.params ? true : false
        },
        sponsorUuid: {
          control: 'combo', 
          label: 'Sponsor', 
          datasource: { table: 'bo/users?include=user-profiles&user-group-in=SPONSOR' }, 
          datavalue: 'uuid', 
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: true,
          required: this.params ? false : true,
          readonly: this.params ? true : false
        },
        activableOn: {
          control: 'combo',  
          label: 'Sottoscrivibile da', 
          datasource: this.payers,
          datavalue: 'value', 
          datatext: 'text', 
          datapreload: true,
          required: this.params ? false : true,
          readonly: this.params ? true : false
        },
        allowableBusinessTypes: {
          control: 'combo',
          label: 'Vincola a specifiche attività',
          datasource: [],
          datatext: 'label',
          datavalue: 'code', 
          datamultiple: true,
          datapreload: true,
          required: false,
          readonly: this.params ? true : false
        },
        subscriptionExpiryType: {
          control: 'combo',
          label: 'Tipologia scadenza sottoscrizione',
          datasource: [
            { value: 'DAYS', text: 'Durata in giorni' },
            { value: 'DATE', text: 'Scadenza fissa' }
          ], 
          datavalue: 'value', 
          datatext: 'text', 
          datapreload: true,
          datamultiple: false,
          required: this.params ? false : true,
          readonly: this.params ? true : false
        },
        duration: {
          control: 'number',
          label: 'Durata gg. sottoscrizione',
          required: this.params ? false : true,
          readonly: this.params ? true : false,
          min: 0
        },
        subscriptionExpireAt: {
          control: 'datetime',
          label: 'Data scadenza sottoscrizione',
          required: this.params ? false : true,
          readonly: this.params ? true : false
        },
        autoRenewable: {
          control: 'check',  
          label: 'Rinnovo del servizio', 
          description: 'Con possibilità di autorinnovo',
          readonly: this.params ? true : false,
        },
        price: {
          control: 'number',
          label: 'Prezzo in €',
          required: this.params ? false : true,
          readonly: this.params ? true : false,
          min: 0
        },
        vatAmount: {
          control: 'number',
          label: 'IVA in €',
          required: this.params ? false : true,
          readonly: this.params ? true : false,
          min: 0
        },
        payableBy: {
          control: 'combo',  
          label: 'Addebitabile a',
          datasource: [
            { value: 'MASTER', text: 'Utenti' },
            { value: 'SUBUSER', text: 'Sottoutenti' }, 
            { value: 'PARENT-OR-SUBUSER', text: 'Utenti e Sottoutenti' }
          ],
          datavalue: 'value', 
          datatext: 'text', 
          datapreload: true,
          required: this.params ? false : true,
          readonly: this.params ? true : false
        },
        paymentMethods: {
          control: 'combo',  
          label: 'Metodi di pagamento',
          datasource: this.paymentMethods,
          datavalue: 'code', 
          datatext: 'label', 
          datapreload: true,
          datamultiple: true,
          required: this.params ? false : true,
          readonly: this.params ? true : false
        },
        startAt: {
          control: 'datetime',
          label: 'Inizio periodo di validità',
          required: this.params ? false : true
        },
        endAt: {
          control: 'datetime',
          label: 'Termine periodo di validità',
          placeholder: 'Illimitato',
          required: false
        },
        promotionNeeded: {
          control: 'combo', 
          label: 'Promozione', 
          datasource: [
            { value: 'YES', text: 'Necessaria per l\'attivazione' }, 
            { value: 'NO', text: 'Non necessaria per l\'attivazione'}
          ], 
          datavalue: 'value', 
          datatext: 'text', 
          datapreload: true,
          datamultiple: false,
          required: this.params ? false : true
        },
        createPortabilityPromotionOnActivation: {
          control: 'check',  
          label: 'Promozione di portabilità', 
          description: 'Crea promozione di portabilità',
        },
        associatedPromoCodes: {
          control: 'combo', 
          label: 'Promozioni associate', 
          datasource: { table: 'bo/promotions', query: { search: 'code-like', item: 'code-in' } }, 
          datavalue: 'code', 
          datatext: 'code', 
          datapreload: false,
          datamultiple: true,
          required: this.params ? false : true
        },
        cashbackAmount: {
          control: 'number',
          label: 'Cashback in €',
          readonly: this.params ? true : false
        }
      },
      data: {
        price: 20,
        duration: 0,
        vatAmount: 0,
        vault: 'EUR',
        cashbackAmount: 0,
        autoRenewable: false,
        subscriptionExpiryType: 'DATE',
        startAt: this.params ? null : moment().utc().format(),
      },
      validate: () => {
        let promises = [];
        Object.values(this.resource.controls).forEach(control => {
          if (!control) return;
          promises.push(new Promise((resolve, reject) => {
            console.log(control);
            if (control.validate)
              control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); });
            else if (control.input?.validate)
              control.input.validate().then(() => resolve()).catch(() => reject('Control didn\'t pass validation'));
          }));
        });
        return Promise.all(promises);
      },
      error: error => {
        let errorPayload = mdt.parsers.apiError(error, this.resource.controls);
        if (errorPayload) this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>'+errorPayload.title+':</strong><div style="margin-top:1rem;border:1px solid #e0e0e0;padding:1rem;max-height:400px;overflow:auto;background-color:#ffffff">' + errorPayload.body + '</div>'});
      }
    }
  }

  async init() {
    this.pendingInit = true;
    this.initResource();
    this.resource.data.paymentMethods = this.getDefaultPaymentMethods();
    let promises = [this.setAllowableBusinessTypesDatasource()];
    if (this.params?.uuid) promises.push(this.getFlatService());
    await Promise.all(promises).then((xhrs) => {
      const flatService = xhrs[1] ? xhrs[1][0] : null;
      if (flatService) {
        this.flatService = flatService;
        this.flatServiceDataParse();
      }
    }).finally(() => {
      this.pendingInit = false;
    });
  }

  setAllowableBusinessTypesDatasource() {
    return this.api.get(`user-business-types?public=YES`).then((xhr) => {
      let response = xhr.response;
      return this.resource.schema.allowableBusinessTypes = Object.assign({}, this.resource.schema.allowableBusinessTypes, { datasource: response });
    }).catch((error) => {
      console.error('SetAllowableBusinessTypesDatasource failed', error);
      return this.api.dialogError(error);
    });
  }

  getFlatService() {
    return this.api.get(`bo/flat-services?uuid-in=${this.params.uuid}`).then((xhr) => {
      let response = xhr.response;
      return response;
    }).catch((error) => {
      console.error('GetFlatService failed', error); 
      return this.api.dialogError(error);
    });
  }

  flatServiceDataParse() {
    const flatService = { ...this.flatService };
    let data = {
      code: flatService.code,
      type: flatService.type,
      productCode: flatService.productCode,
      title: flatService.title,
      subscriptionExpiryType: flatService.subscriptionExpireAt ? 'DATE' : 'DAYS',
      subscriptionExpireAt: flatService?.subscriptionExpireAt || null,
      activableByPrivilegedUserOnly: flatService?.sponsorUuid ? 'SPONSOR' : flatService.activableByPrivilegedUserOnly,
      activableOn: flatService?.activableOnUser && flatService?.activableOnSubuser ? 'PARENT-OR-SUBUSER' : !flatService?.activableOnSubuser ? 'MASTER' : 'SUBUSER',
      duration: flatService.duration,
      autoRenewable: flatService.autoRenewable,
      price: flatService.price,
      vatAmount: flatService.vatAmount,
      payableBy: flatService?.payableBySponsor ? 'SPONSOR' : flatService?.payableByUser && flatService?.payableBySubuser ? 'PARENT-OR-SUBUSER' : !flatService?.payableBySubuser ? 'MASTER' : 'SUBUSER',
      paymentMethods: flatService.paymentMethods,
      startAt: flatService.startAt,
      endAt: flatService.endAt || null,
      promotionNeeded: flatService.promotionNeeded,
      createPortabilityPromotionOnActivation: flatService.createPortabilityPromotionOnActivation === 'YES' ? true : false
    };
    if (flatService.description) data.description = flatService.description;
    if (flatService.sponsorUuid) data.sponsorUuid = flatService.sponsorUuid;
    if (flatService.cashbackAmount) data.cashbackAmount = flatService.cashbackAmount;
    if (flatService.allowableBusinessTypes) data.allowableBusinessTypes = flatService.allowableBusinessTypes;
    if (flatService.associatedPromoCodes) data.associatedPromoCodes = flatService.associatedPromoCodes.length ? Array.from(flatService.associatedPromoCodes) : [];
    return this.resource.data = data;
  }

  save() {
    this.resource.validate().then(() => {
      let endpoint = 'bo/flat-services';
      let data = structuredClone(this.resource.data);

      if (this.params) {
        endpoint = `bo/flat-services/${this.params.uuid}`;
        data = {
          startAt: this.resource.data.startAt,
          endAt: this.resource.data.endAt || null,
          promotionNeeded: this.resource.data.promotionNeeded,
          createPortabilityPromotionOnActivation: this.resource.data.createPortabilityPromotionOnActivation
        }
        if (this.flatService.startAt === data.startAt) delete data.startAt;
        if (this.flatService.endAt === data.endAt) delete data.endAt;

        this.api.patch(endpoint, data).then(() => {
          if (!this.resource.data.associatedPromoCodes) this.resource.data.associatedPromoCodes = [];
          let addedPromoCode = this.flatService.associatedPromoCodes ? this.resource.data.associatedPromoCodes.filter(x => !this.flatService.associatedPromoCodes.includes(x)) : this.resource.data.associatedPromoCodes;
          let removedPromoCode = this.flatService.associatedPromoCodes ? this.flatService.associatedPromoCodes.filter(x => !this.resource.data.associatedPromoCodes.includes(x)) : [];

          this.api.get(`bo/promotions?code-in=${addedPromoCode.concat(removedPromoCode).toString()}`).then(xhr => {
            let promises = [];
            xhr.response.forEach(promo => {
              if (addedPromoCode.includes(promo.code)) promises.push(this.api.post(`bo/promotions/${promo.uuid}/flat-services`, { flatServiceUuid: this.params.uuid }));
              else if (removedPromoCode.includes(promo.code)) promises.push(this.api.delete(`bo/promotions/${promo.uuid}/flat-services/${this.params.uuid}`));
            });
            Promise.all(promises).then(() => {
              this.toast.show('Servizio modificato con successo!', 'success'); 
              this.controller.ok();
            })
          }).catch(error => { 
            console.error('Activation of promotions failed', error); 
            this.resource.error(error);
          })
        }).catch(error => { 
          console.error('Edit service failed', error); 
          this.resource.error(error);
        })
      } else {
        if (this.resource.data.activableByPrivilegedUserOnly === 'SPONSOR') data.activableByPrivilegedUserOnly = 'NO';
        data.createPortabilityPromotionOnActivation = this.resource.data.createPortabilityPromotionOnActivation === true ? 'YES' : 'NO';
        data.activableOnUser = ['MASTER', 'PARENT-OR-SUBUSER'].includes(this.resource.data.activableOn);
        data.activableOnSubuser = ['SUBUSER', 'PARENT-OR-SUBUSER'].includes(this.resource.data.activableOn);
        data.payableByUser = ['MASTER', 'PARENT-OR-SUBUSER'].includes(this.resource.data.payableBy);
        data.payableBySubuser = ['SUBUSER', 'PARENT-OR-SUBUSER'].includes(this.resource.data.payableBy);
        data.payableBySponsor = ['SPONSOR'].includes(this.resource.data.payableBy);

        // Transform string into number
        if (data.price) data.price = +this.resource.data.price
        if (data.vatAmount) data.vatAmount = +this.resource.data.vatAmount
        if (data.duration) data.duration = +this.resource.data.duration

        if (data.subscriptionExpiryType === 'DAYS') delete data.subscriptionExpireAt
        if (data.subscriptionExpiryType === 'DATE') delete data.duration
        delete data.subscriptionExpiryType
        
        delete data.payableBy;
        delete data.activableOn;
        if (!this.resource.data.SponsorUuid) delete data.SponsorUuid;

        this.api.post(endpoint, data).then(() => { 
          this.toast.show('Servizio creato con successo!', 'success'); 
          this.controller.ok(); 
        }).catch(error => { 
          console.error('Create service failed', error);
          this.resource.error(error);
        });
      }
    });
  }
}
