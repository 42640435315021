export async function checkSettingsLinkedSoftware(scope, fragment) {
  if (/\/(anagrafiche|gestione)\/(utenti|profilo)\/me\/.+/.test(fragment)) return checkResult(false, fragment);
  const response = await scope.rest.get('users/me/advanced-preferences');
  if (response.status !== 200) return checkResult();
  if (!!response.data?.data?.attributes?.multifatture?.accountingClientSoftwareUuid) {
    return checkResult(true);
  } 
  await dialogMissingBillingProfile(scope);
  return checkResult(false, '/anagrafiche/utenti/me/impostazioni');
}

export function dialogMissingBillingProfile(scope) {
  return new Promise((resolve) => {
    scope.dialog.open({
      type: 'alert',
      class: 'small',
      title: 'Attenzione!',
      body: 'È necessario impostare il software gestionale utilizzato per la gestione della contabilità'
    }).whenClosed(() => {
      resolve();
    });
  });
}

export function checkResult(hasPassed, redirect) {
  const result = {};
  if (hasPassed !== undefined) result.hasPassed = hasPassed;
  if (redirect !== undefined) result.redirect = redirect;
  return result;
}
