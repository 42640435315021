import { Api } from 'services/api';
import { Router } from 'aurelia-router';
import { inject } from 'aurelia-framework';
import { KaToast, KaDialog } from 'ka-components';
import { QueueProperties } from 'classes/queueProperties';
import { TableInterface, SearchInterface } from 'classes/md-table';
import { MdDatasources } from '../../../services/md-datasources';

@inject(Router, Api, KaToast, KaDialog, MdDatasources)
export class ViewReportDetail {
  queue;
  sender;
  usedChannels = [];
  messageStatuses = {};
  postages = {};
  vector;
  urlPosteItaliane = 'https://www.poste.it/cerca/index.html#/risultati-spedizioni/';

  constructor(router, api, toast, dialog, MdDatasources) {
    this.router = router;
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
    this.queueProperties = new QueueProperties();
    this.channels = this.queueProperties.channels;
    this.mdDatasources = MdDatasources;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    const promises = [this.getQueueOutlines(), this.getSender(), this.initMessages(), this.getPostages()];
    Promise.allSettled(promises);
  }

  initMessages() {
    this.initMessagesInterface().then(() => {
      this.getMessageStatuses().then(() => {
        return this.messagesSearchInterface.search();
      });
    });
  }

  getChannelDatasource() {
    let datasource = Object.values(this.channels).map(({ value, text }) => ({ value, text }));
    return datasource;
  }

  getQueueOutlines() {
    return this.api.get(`users/${this.params.queueOwnerId}/queue-outlines/${this.params.queueUuid}`).then((xhr) => {
      const response = xhr.response;
      this.usedChannels = xhr.originalResponse.meta.usedChannels;
      this.queue = response;
    }).catch((error) => {
      console.error('GET queue-outlines', error);
    });
  }

  getSender() {
    return this.api.get(`users/${this.params.queueOwnerId}/queues/${this.params.queueUuid}/sender`).then((xhr) => {
      const response = xhr.response;
      this.sender = response;
    }).catch((xhr) => {
      console.error('GET sender', xhr);
    });
  }

  async initMessagesInterface() {
    this.messagesTableInterface = new TableInterface({
      name: 'table-report-detail-queue-messages',
      client: this.api,
      endpoint: `users/${this.params.queueOwnerId}/queues/${this.params.queueUuid}/messages`
    });

    this.messagesSearchInterface = new SearchInterface({
      name: 'search-queue-report-detail',
      table: this.messagesTableInterface,
      schema: {
        textSearch: {
          control: 'text',
          label: 'Ricerca destinatario',
          query: 'label-text-search'
        },
        channel: {
          control: 'combo',
          label: 'Canale',
          datatext: 'text',
          datavalue: 'value',
          datamultiple: false,
          datapreload: false,
          datasource: this.getChannelDatasource(),
          query: 'channel'
        },
        status: {
          control: 'combo',
          label: 'Stato',
          datatext: 'text',
          datavalue: 'value',
          datamultiple: false,
          datapreload: false,
          datasource: [
            { value: 'PROCESSING', text: `In elaborazione` },
            { value: 'DELIVERED', text: `Consegnato` },
            { value: 'NOT-DELIVERED', text: `Non consegnato` }
          ],
          query: 'processing-status'
        },
      },
      data: {}
    });

    await this.messagesTableInterface.initialize();
    await this.messagesSearchInterface.initialize();
    return this.messagesSearchInterface.reset(true);
  }

  getPostages() {
    const postalService = this.mdDatasources?.postalServices;

    if (postalService.vectors) {
      this.vectors = Object.fromEntries(postalService.vectors.map(vector => [vector.code, vector.name]));
    }

    if (postalService.postages) {
      this.postages =  Object.fromEntries(postalService.postages.map(postage => [postage.code, postage.description]));
      this.postages = {
        ...this.postages,
        'MB': 'Multibox'
      }
    }

  }

  getMessageStatuses() {
    return this.api.get(`queue-message-status-codes`).then((xhr) => {
      let response = xhr.response;
      response.forEach((status) => { this.messageStatuses[status.code] = status; });
    }).catch((xhr) => {
      console.error('GET queue-messages-statuses', xhr);
      this.api.dialogError(xhr);
    });
  }

  dialogStateTransitionsHistory(message) {
    this.dialog.open({
      title: 'Storico dei passaggi di stato',
      class: 'message-statuses',
      viewModel: PLATFORM.moduleName('views/spedizioni/dialogs/message-statuses'),
      viewModelParams: { message }
    }, true);
  }

  downloadReceipt3A(record) {
    this.toast.show('Caricamento in corso', 'loading', true);
    let path = `users/me/queues/${this.params.queueUuid}/messages/${record.uuid}/certificate-download`;

    this.api.client.createRequest(path)
    .asGet()
    .withHeader('Accept', 'application/pdf')
    .withResponseType('blob')
    .send()
    .then(xhr => {
      let anchor = document.createElement('a');
      let blob = new Blob([xhr.response], { type: 'application/pdf' });
      let url = URL.createObjectURL(blob);
      anchor.href = url;
      anchor.download = `${record.legacyId}-ricevuta-3A`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }).catch(xhr => {
      console.log('ERROR - certificate-download GET', xhr);
    }).finally(() => {
      this.toast.consume();
    });
  }

  downloadCertificatesTSD(record) {
    this.toast.show('Caricamento in corso', 'loading', true);
    let path = `users/me/queues/${this.params.queueUuid}/messages/${record.uuid}/timestamps-download`;

    this.api.client.createRequest(path)
    .asGet()
    .withHeader('Accept', 'application/zip')
    .withResponseType('blob')
    .send()
    .then(xhr => {
      let anchor = document.createElement('a');
      let blob = new Blob([xhr.response], { type: 'application/zip' });
      let url = URL.createObjectURL(blob);
      anchor.href = url;
      anchor.download = `${record.legacyId}-certficati-TSD`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }).catch(xhr => {
      console.log('ERROR - timestamps-download GET', xhr);
    }).finally(() => {
      this.toast.consume();
    });
  }

  navigateToLegacyReport() {
    this.toast.show('Caricamento in corso', 'loading', true);
    return this.api.get('users/me/cross-login-tokens/current').then((xhr) => {
      let token = xhr.response.token;
      window.open(`${ENVIRONMENT.APP_URL_LEGACY}crosslogin.php?service-token=${token}&action=mi_report&multiqueue_uuid=${this.params.queueUuid}`);
    }).catch(xhr => {
      console.error('Error - GET cross-login-tokens/current', xhr);
      this.api.dialogError(xhr);
    }).finally(() => {
      this.toast.consume();
    });
  }

  dialogAttachments(message) {
    const messageUuid = message.uuid;
    const queueUuid = this.params.queueUuid;
    const queueOwnerId = this.params.queueOwnerId;
    const messageChannel = message.channel;
    this.dialog.open({
      class: 'medium report-attachments',
      title: 'Elenco allegati',
      viewModelParams: { queueOwnerId, queueUuid, messageUuid, messageChannel},
      viewModel: PLATFORM.moduleName('views/spedizioni/dialogs/report-attachments')
    }, true);
  }

  dialogMultibox(multibox) {
    this.dialog.open({
      class: 'small report-multibox-detail',
      title: 'Dettaglio Multibox',
      viewModelParams: { multibox },
      viewModel: PLATFORM.moduleName('views/spedizioni/dialogs/report-multibox-detail')
    }, true);
  }

  navigateToQueue(queueUuid) {
    if (this.params.queueOwnerId && queueUuid) {
      return this.router.navigate(`spedizioni/reportistica/${this.params.queueOwnerId}/${queueUuid}`);
    }
  }

  navigateToEnvelopeLogo(uuid) {
    this.router.navigate(`impostazioni/posta?ka-tab-t=loghi&envelopeLogoUuid=${uuid}`);
  }

  downloadWorkOrderList($event){
    $event.model.busy = true;
    const uri = `users/${this.params.queueOwnerId}/queues/${this.params.queueUuid}.pdf`;

    return this.api.client.createRequest(uri)
      .asGet()
      .withHeader("Accept", "application/pdf")
      .withHeader("Content-Type", "application/pdf")
      .withResponseType('blob')
      .send()
      .then((xhr) => {
        const regexp = new RegExp('filename=(.+\.pdf$)');
        const matches = xhr.headers.headers['content-disposition'].value.match(regexp);
        const anchor = document.createElement("a");
        const blob = new Blob([xhr.response], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        anchor.href = url;
        anchor.download = matches[1];
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        this.toast.show("Distinta scaricata correttamente", 'success');
      }).catch((error) => {
        console.log("impossibile scaricare il pdf", error);
        this.toast.show('impossibile scaricare la distinta', 'error');
      }).finally(() => {
        $event.model.busy = false;
      })
  }

  downloadLogMessages($event) {
    $event.model.busy = true;

    const uri = `users/${this.params.queueOwnerId}/queues/${this.params.queueUuid}/message-log/current.txt`;
    this.toast.show('Caricamento in corso', 'loading');

    return this.api.client.createRequest(uri)
      .asGet()
      .withHeader('Accept', 'text/plain')
      .withResponseType('blob')
      .send()
      .then(xhr => {
        const regexp = new RegExp('filename=(.+\.txt$)');
        const matches = xhr.headers.headers['content-disposition'].value.match(regexp);
        const anchor = document.createElement('a');
        const blob = new Blob([xhr.response], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);

        anchor.href = url;
        anchor.download = matches[1];
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        this.toast.show(`Documento scaricato con successo!`, 'success');

      }).catch((error) => {
        this.toast.show(`Impossibile scaricare il file!`, 'error');
        console.log('ERROR - log-messages download - GET', error);
      }).finally(() => {
        $event.model.busy = false;
      });
  }
}
