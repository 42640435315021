import {customElement, bindable} from 'aurelia-framework';

@customElement('md-counter')
export class MdCounter {
  @bindable() label = null;
  @bindable() prefix = null;
  @bindable() suffix = null;
  @bindable() start = 0;
  @bindable() end = null;
  @bindable() duration;
  progress = true;
  bind() {
    this.animateCounter() 
  }
  animateCounter() {
    this.progress = true;
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / this.duration, 1);
      const digits = progress * (this.end - this.start) + this.start;
      const counterElement = document.getElementById("counterElement");
      if (counterElement) counterElement.innerHTML = digits.toFixed(2);
      if (progress < 1) window.requestAnimationFrame(step);
      else {
        setTimeout(() => {
          const counterWrapper = document.getElementById("counterWrapper");
          if (counterWrapper) counterWrapper.animate([{ opacity: 0 }], { duration: 1000 }).onfinish = () => { this.progress = false; }
        }, 3000);
      }
    };
    window.requestAnimationFrame(step);
  }
}
