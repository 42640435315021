export class ObjectFormatValueConverter {
  toView(value, format) {
    if (Array.isArray(value)) {
      var new_value = [];
      for (let obj of value) {
        if (obj[format]) new_value.push(obj[format]);
      }
      return new_value.join(', ');
    }
    else if (value === Object(value) && value[format])
      return value[format];
    else return value;
  }
}
