import { Api } from 'services/api';
import mdt from 'services/md-tools';
import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { MdDatasources } from 'services/md-datasources';

@inject(Api, DialogController, MdDatasources)
export class ServiceDialogAbbonamentiDetail {
  ownerId;
  resource;
  mdt = mdt;
  flatService;
  pendingInit;  
  paymentMethods;
  flatServiceUuid;
  statuses = {
    'ACTIVE' : 'Attivo',
    'PENDING' : 'In attesa di pagamento',
    'SUSPENDED' : 'Sospeso',
    'EXPIRED' : 'Scaduto',
    'TERMINATED' : 'Terminato'
  }

  constructor(api, dialogController, MdDatasources) {
    this.api = api;
    this.mdDatasources = MdDatasources;
    this.controller = dialogController;
    this.paymentMethods = this.mdDatasources.paymentMethods;
  }

  activate(params) {
    this.params = params;
    this.ownerId = this.params.ownerId;
    this.flatServiceUuid = this.params.flatServiceUuid;

    this.init();
  }

  setStatusDatasource() {
    const datasource = []
    for (const [key, value] of Object.entries(this.statuses)) {
      datasource.push({ value: key, text: value });
    }
    return datasource;
  }

  setPaymentMethodDatasource() {
    const datasource = [];
    this.paymentMethods.forEach((method) => {
      datasource.push({ text: method.label, value: method.code });
    });
    return datasource;
  }

  async init() {
    this.resource = {
      controls: {},
      schema: {
        flatServiceCode: {
          control: 'text',
          label: 'Codice servizio',
          readonly: true
        },
        flatServiceProductCode: {
          control: 'combo',  
          label: 'Prodotto', 
          datasource: { table: 'products' },
          datavalue: 'code', 
          datatext: 'label',
          datamultiple: false,
          datapreload: true,
          client: this.api,
          readonly: true
        },
        flatServiceType: {
          control: 'combo', 
          label: 'Piano',
          datasource: { table: 'flat-service-types' },
          datavalue: 'code',
          datatext: 'description',
          datamultiple: false,
          datapreload: true,
          client: this.api,
          readonly: true
        },
        flatServiceTitle: {
          control: 'text',
          label: 'Titolo',
          readonly: true
        },
        flatServiceDescription: {
          control: 'text',
          label: 'Descrizione',
          readonly: true
        },
        orderId: {
          control: 'number',
          label: 'Id ordine',
          readonly: true
        },
        ownerDisplayName: {
          control: 'text',
          label: 'Nome proprietario',
          readonly: true
        },
        ownerId: {
          control: 'number',
          label: 'Id proprietario',
          readonly: true
        },
        ownerFiscalCode: {
          control: 'text',
          label: 'Codice fiscale proprietario',
          readonly: true
        },
        ownerParentDisplayName: {
          control: 'text',
          label: `${mdt.parsers.contextualize(this.api.user, 'Account')}`,
          readonly: true
        },
        status: {
          control: 'combo',
          label: 'Stato attivazione',
          datasource: this.setStatusDatasource(),
          datavalue: 'value',
          datatext: 'text',
          datapreload: true,
          readonly: true
        },
        statusReason: {
          control: 'text',
          label: 'Motivo',
          readonly: true
        },
        paymentMethod: {
          control: 'combo',
          label: 'Metodi di pagamento',
          datasource: this.setPaymentMethodDatasource(),
          datavalue: 'value',
          datatext: 'text',
          datapreload: true,
          readonly: true
        },
        activatedAt: {
          control: 'datetime',
          label: 'Data scadenza sottoscrizione',
          readonly: true
        },
        expireAt: {
          control: 'datetime',
          label: 'Data scadenza sottoscrizione',
          readonly: true
        },
        autoRenew: {
          control: 'combo', 
          label: 'Autorinnovo', 
          datasource: [
            { value: 'ENABLED', text: 'Attivo' }, 
            { value: 'DISABLED', text: 'Disabilitato'}
          ], 
          datavalue: 'value', 
          datatext: 'text', 
          datapreload: true,
          datamultiple: false,
          readonly: true
        },
        autoRenewable: {
          control: 'check',  
          label: 'Rinnovo del servizio', 
          description: 'Con possibilità di autorinnovo',
          readonly: true,
        },
        flatServicePrice: {
          control: 'number',
          label: 'Prezzo in €',
          readonly: true
        },
        flatServiceCashbackAmount: {
          control: 'number',
          label: 'Cashback in €',
          readonly: true,
        },
        payeeDisplayName: {
          control: 'text',
          label: 'Fatturazione a',
          readonly: true
        }
      },
      data: {}
    }

    this.pendingInit = true;
    await this.getFlatService().finally(() => {
      this.pendingInit = false;
    });
  }

  async getFlatService() {
    const url = `users/${this.ownerId}/flat-services-activations/${this.flatServiceUuid}`;
    return this.api.get(url).then((xhr) => {
      let response = xhr.response;
      this.setResourceData(response);
    }).catch((error) => {
      console.error('GET flat-services-activations', error);
      this.api.dialogError(error);
    });
  }

  setResourceData(response) {
    Object.keys(response).forEach((key) => {
      const dataProperty = {};
      dataProperty[key] = response[key];
      if (dataProperty[key] === 'NO') dataProperty[key] = false;
      if (dataProperty[key] === 'YES') dataProperty[key] = true;
      this.resource.data = {
        ...this.resource.data,
        ...((response[key] || response[key] === 0) && dataProperty)
      }
    });
  }
}
