import { inject } from 'aurelia-framework';
import { Api } from 'services/api';
import { DialogController } from 'aurelia-dialog';

@inject(Api, DialogController)
export class DialogDownloadReceipts {
  constructor(api, controller){
    this.api = api;
    this.controller = controller;
  }

  activate(params) {
    this.params = params;
  }
}
