import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import mdt from 'services/md-tools';

@inject(Api, KaDialog, KaToast)
export class DialogCreditMovement {
  mdt = mdt;
  resource = {
    controls: {},
    schema: {
      amount: {
        vatSubject: {
          label: 'Importo servizio',
          control: 'number',
          required: true,
          min: 0
        },
        noVatSubject: {
          label: 'Importo affrancatura',
          control: 'number',
          required: true,
          min: 0
        }
      },
      sign: {
        label: 'Tipologia del movimento',
        control: 'combo',
        datasource: [
          { value: 'CREDIT', text: 'Ricarica'},
          { value: 'DEBIT', text: 'Addebito'}
        ],
        datavalue: 'value',
        datatext: 'text',
        datapreload: true,
        required: true
      },
      reason: {
        description: {
          label: 'Causale',
          control: 'text',
          required: true
        },
      }
    },
    data: {
      amount: { vatSubject: 0, noVatSubject: 0 },
    },
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      let errorPayload = mdt.parsers.apiError(error, this.resource.controls);
      if (errorPayload) this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>'+errorPayload.title+':</strong><div style="margin-top:1rem;border:1px solid #e0e0e0;padding:1rem;max-height:400px;overflow:auto;background-color:#ffffff">' + errorPayload.body + '</div>'});
    }
  }

  constructor(api, dialog, kaToast) {
    this.api = api;
    this.dialog = dialog;
    this.toast = kaToast;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
    if (this.params.context === 'transfer') {
      // Modify datasource of sign
      let signTransaction = { value: 'null', text: `Addebito. Trasferimento da ${this.mdt.parsers.contextualize(this.api.user, 'utente')} ${this.params.user.displayName} a ${this.params.user.parent.displayName}`};
      this.resource.schema.sign.datasource.push(signTransaction);

      // Modify reason
      this.resource.schema.reason = { label: 'Causale', control: 'text', required: true }

      // Master user credit
      this.api.get(`users/${this.params.user.parent.id}/credit-transactions`).then(xhr => {
        this.masterUser = { noVatSubjectAmount:  xhr.originalResponse.meta.noVatSubjectAmount, vatSubjectAmount: xhr.originalResponse.meta.vatSubjectAmount };
      });
    }
    this.resource.data.sign = this.params.context === 'transaction' ? 'CREDIT' : 'null';
  }

  save() {
    // Transaction
    if (this.params.context === 'transaction') {
      this.resource.validate().then(() => {
        this.dialog.open({
          title: 'Attenzione!',
          class: 'small',
          viewModel: PLATFORM.moduleName('views/servizi/credito/dialog-transaction-confirm'),
        }, true).whenClosed(response => {
          if (!response.wasCancelled) {
            this.api.post(`bo/users/${this.params.user.id}/credit-transactions`, this.resource.data).then(() => {
              this.toast.show(`Movimento inserito con successo!`, 'success');
              this.params.dialog.ok();
            }).catch(error => {
              console.error();
              this.resource.error(error);
            });
          }
        });
      });
    } 
    // Transfer
    else if (this.params.context === 'transfer') {
      this.resource.validate().then(() => {
        delete this.resource.data.sign;
        this.api.post(`users/${this.params.user.id}/credit-transfer`, this.resource.data).then(() => {
          this.toast.show(`Trasferimento inserito con successo!`, 'success');
          this.params.dialog.ok();
        }).catch(error => {
          console.error();
          this.resource.error(error);
        });
      });
    }
  } 
}
