import { Api } from 'services/api';
import { DialogController } from 'aurelia-dialog';

export class DialogReportMultiboxDetail {
  static inject = [Api, DialogController];
  constructor(api, controller) {
    this.api = api;
    this.controller = controller;
  }

  activate(params) {
    this.params = params;
    this.multibox = params.multibox;
  }
}
