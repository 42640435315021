import moment from 'moment';

export class DateFormatValueConverter {
  toView(value, format = 'DD/MM/YYYY HH:mm') {
    if (!value) return;
    if (moment.utc(value).isValid()) {
      konsole.debug('Valid UTC datetime conversion', moment.utc(value));
      return moment.utc(value).local().format(format);
    }
    else if (moment.utc(value, 'YYYY-MM-DD HH:mm:ss a').isValid()) {
      konsole.debug('Valid "YYYY-MM-DD HH:mm:ss a" datetime conversion', moment.utc(value, 'YYYY-MM-DD HH:mm:ss a'));
      return moment.utc(value, 'YYYY-MM-DD HH:mm:ss a').local().format(format);
    }
    else if (moment.utc(value, 'HH:mm:ss a').isValid()) {
      konsole.debug('Valid "HH:mm:ss a" datetime conversion', moment.utc(value, 'HH:mm:ss a'));
      return moment.utc(value, 'HH:mm:ss a').local().format(format);
    }
    konsole.debug('No datetime conversion', value);
    return value;
  }
}
