import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';
import mdt from 'services/md-tools';

@inject(Router, Api, KaDialog, KaToast)
export class vmOfferteGestione {
  mdt = mdt;
  currentDate = new Date().toISOString();

  constructor(router, api, dialog, toast) {
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(model) {
    this.params = model.params;
    this.privileges = model.privileges;
    this.tools = model.tools;
  }

  attached() {
    this.init();
  }

  init() {
    // INIT TableInterface
    this.tableInterface = new TableInterface({
      name: 'tab-offerte-gestione-table',
      client: this.api,
      endpoint: 'bo/promotion-offers'
    });
    this.tableInterface.initialize().catch(error => { console.log('tableInterface initialization failed', error) });

    // INIT SearchInterface
    this.searchInterface = new SearchInterface({
      name: 'tab-offerte-gestione-search',
      table: this.tableInterface,
      schema: {
        code: {  
          label: 'Codice offerta',
          control: 'text',
          query: 'code'
        },
        status: {
        label: 'Stato offerta',
        control: 'combo',
        datasource: [
          { value: 'ACTIVE', text: 'Attiva' },
          { value: 'EXPIRED', text: 'Scaduta' },
          { value: 'INCOMING', text: 'Non ancora attivabile' }
        ],
        query: 'status'
        },
        promotionCode: {
          control: 'combo',
          label: 'Codice promozione', 
          datasource: { table: 'bo/promotions', query: { search: 'code-like', item: 'code-in' }},
          datavalue: 'code', 
          datatext: 'code', 
          datapreload: false,
          datamultiple: false,
          query: 'promotion-code'
        },
        createdAtGte: {
          label: 'Offerta creata dal',
          control: 'date',
          query: 'created-at-gte'
        },
        createdAtLte: {
          label: 'al',
          control: 'date',
          query: 'created-at-lte'
        },
        startAtGte: {
          label: 'Inizio validità dal',
          control: 'date',
          query: 'start-at-gte'
        },
        startAtLte: {
          label: 'al',
          control: 'date',
          query: 'start-at-lte'
        },
        endAtGte: {
          label: 'Fine validità dal',
          control: 'date',
          query: 'end-at-gte'
        },
        endAtLte: {
          label: 'al',
          control: 'date',
          query: 'end-at-lte'
        },
        deadlineType: {
          label: 'Seleziona la tipologia di scadenza',
          control: 'combo',
          datasource: [
            { value: 'USER-CREATION', text: 'Per iscrizione utente' }, 
            { value: 'FLAT-SERVICE-SUBSCRIPTION', text: 'Per sottoscrizione servizio' }
          ],
          datavalue: 'value',
          datatext: 'text',
          query: 'deadline-type'
        },
        deadlineAtGte: {
          label: null,
          control: 'date',
          query: 'deadline-at-gte'
        },
        deadlineAtLte: {
          label: 'al',
          control: 'date',
          query: 'deadline-at-lte'
        },
        serviceCreationDeadlineGte: {
          label: 'Scadenza sottoscrizione servizio dal',
          control: 'date',
          query: 'user-creation-deadline-gte'
        },
        serviceCreationDeadlineLte: {
          label: 'al',
          control: 'date',
          query: 'user-creation-deadline-lte'
        },
        targetUserType: {
          label: 'Applicabile a',
          control: 'combo',
          datasource: [
            { value: 'PARENT', text: 'Utenti' }, 
            { value: 'SUBUSER', text: 'Sottoutenti' }, 
          ],
          datavalue: 'value',
          datatext: 'text',
          readonly: this.params?.offer ? true : false,
          query: 'target-user-type'
        },
        public: {
          label: 'Offerte private',
          control: 'combo',
          datasource: [
            { value: 'NO', text: 'Mostra solo offerte private' },
            { value: 'YES', text: 'Mostra solo offerte pubbliche' },
          ],
          query: 'public'
        }
      },
      data: {}
    });
    this.searchInterface.initialize().then(() => { 
      this.searchInterface.data.createdAtGte = this.searchInterface.data.createdAtLte = null;
      this.searchInterface.data.startAtGte = this.searchInterface.data.startAtLte = null;
      this.searchInterface.data.endAtGte = this.searchInterface.data.endAtLte = null;
      this.searchInterface.data.userCreationDeadlineGte = this.searchInterface.data.userCreationDeadlineLte = null;
      this.searchInterface.search().catch(error => { 
        console.log('searchInterface search failed', error);
      });
    }).catch(error => { 
      console.log('searchInterface initialization failed', error);
    });
  }

  offerLink(offer) {
    this.tools.copyText(`${window.location.origin}/landing/offerta/${offer.code}`, 'Link offerta');
  }

  deadlineTypeChange() {
    if (this.searchInterface.data) {
      if (this.searchInterface.data.deadlineType) {
        let deadline = this.searchInterface.data.deadlineType === 'USER-CREATION' ? 'iscrizione utente' : 'sottoscrizione servizio';
        return this.searchInterface.controls.deadlineAtGte.label = `Scadenza ${deadline} dal`;
      } else {
        return this.searchInterface.data.deadlineAtGte = this.searchInterface.data.deadlineAtLte = null;
      }
    } else return;
  }

  createOffer() {
    this.dialog.open({
      title: 'Crea nuova offerta',
      class: 'offerte-editor large',
      viewModel: PLATFORM.moduleName('views/backoffice/offerte/dialogs/offerte-editor')
    }, true).whenClosed(response => {
      if (!response.wasCancelled) { this.tableInterface.load(); }
    });  
  }

  editOffer($event, offer) {
    if ($event.model) $event.model.busy = true;
    this.dialog.open({
      title: 'Modifica offerta',
      class: 'offerte-editor large',
      viewModel: PLATFORM.moduleName('views/backoffice/offerte/dialogs/offerte-editor'),
      viewModelParams: { offer: {...offer} }
    }, true).whenClosed(response => {
      if (!response.wasCancelled) { this.searchInterface.search(); }
      if ($event.model) $event.model.busy = false;
    });
  }

  assignOffer($event, offer) {
    if ($event.model) $event.model.busy = true;
    this.dialog.open({
      title: 'Assegnazione offerta',
      class: 'assign-editor large',
      viewModel: PLATFORM.moduleName('views/backoffice/offerte/dialogs/assign-editor'),
      viewModelParams: { offer: {...offer} }
    }, true).whenClosed(response => {
      if (!response.wasCancelled) { this.searchInterface.search(); }
      if ($event.model) $event.model.busy = false;
    });
  }
}
