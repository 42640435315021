import {inject, customElement, bindable} from 'aurelia-framework';
import { v4 as uuidv4 } from 'uuid';

@customElement('md-table-wrapper')
@inject(Element)
export class MdTableWrapper {
  @bindable() decrease = 0;
  @bindable() height = 'full';
  constructor (element) {
    this.element = element;
    this.uuid = 'mdtw-' + uuidv4();
    this.element.id = this.uuid;
  }
  attached() {
    // Create evaled resize linstener function
    const resizeHandler = () => {
      console.log(this.uuid);
      let element = eval(`document.querySelector('#${this.uuid}');`);
      if (!element) return;
      if (this.height === 'full') {
        element.style.height = '0px';
        element.style.height = (window.innerHeight - element.getBoundingClientRect().top - parseInt(this.decrease, 10)) + 'px';
      }
    }
    eval(`window['resize-handler-${this.uuid}'] = resizeHandler;`);
    window.addEventListener('resize', window[`resize-handler-${this.uuid}`]);
    setTimeout(() => { resizeHandler(); }, 1000);
  }

  detached() {
    window.removeEventListener('resize', window[`resize-handler-${this.uuid}`]);
    delete(window[`resize-handler-${this.uuid}`]);
  }
}

