import {inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import mdt from 'services/md-tools';

@inject(Api, KaDialog, KaToast)
export class DialogIntentsProvideSdd {
  mdt = mdt;
  edit = true;
  saved = false;
  data = {};
  schema = {
    sdd: {
      label: '',
      control: 'check',
      description: 'Abilita domiciliazione bancaria',
      required: true
    }
  }

  constructor(api, dialog, toast) {
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
  }

  save() {
    let data = { payments: { banking: { sepaDirectDebitEnabled: true } } }
    if (this.params.legalFormCode) data.legalFormCode = this.params.legalFormCode;
    this.api.patch(`users/${this.params.userId}/billing-profiles/${this.params.billingProfileUuid}`, data).then(() => {
      this.saved = true;
      this.edit = false;
      this.toast.show(`Domiciliazione bancaria attivata con successo!`, 'success');
    }).catch(error => {
      console.log(`ERROR billing-profiles - PATCH`, error);
      this.resource.error(error);
    });
  }
}
