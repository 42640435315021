import { Api } from 'services/api';
import { Router } from 'aurelia-router';
import { inject } from 'aurelia-framework';
import { TableInterface } from 'classes/md-table';

@inject(Api, Router)
export class viewUserArchive {
  constructor(api, router) {
    this.api = api;
    this.router = router;
  }

  activate(model) {
    this.tools = model.tools;
    this.params = model.params;
    model.tab.controller = this;
  }

  attached() {
    this.init();
  }

  init() {
    const promises = [ this.serviceContractSubscriptions(), this.multinvioContractSubscriptions() ];
    Promise.all(promises);
  }

  serviceContractSubscriptions() {
    this.tableInterfaceServiceContractSubscriptions = new TableInterface({
      name: 'table-cashback-archive',
      client: this.api,
      endpoint: 'users/me/cashback-contract-acceptances',
      query: 'contract-type=FLAT-SERVICE'
    });
    
    this.tableInterfaceServiceContractSubscriptions.initialize().then(() => { 
      return this.tableInterfaceServiceContractSubscriptions.load();
    });
  }

  multinvioContractSubscriptions() {
    this.tableInterfaceMultinvioContractSubscriptions = new TableInterface({
      name: 'table-archived-contracts',
      client: this.api,
      endpoint: 'users/me/cashback-contract-acceptances',
      query: 'contract-type=MULTINVIO-VOLUMES'
    });
    
    this.tableInterfaceMultinvioContractSubscriptions.initialize().then(() => { 
      return this.tableInterfaceMultinvioContractSubscriptions.load();
    });
  }
}
