import {inject, customAttribute} from 'aurelia-framework';

@customAttribute('md-tooltip')
@inject(Element)
export class MdTooltipCustomAttribute {

  constructor(element) {
    this.element = element;
  }

  attached() {
    if (!this.element.tagName) return;
    let target = this.element;
    let icon = document.createElement('i');
    icon.classList.add('tooltip-icon', 'material-icons');
    icon.textContent = 'help';
    setTimeout(() => {
      if (['md-input', 'ka-input'].includes(this.element.tagName.toLowerCase())) {
        target = icon;
        let container = this.element.querySelector('label') || this.element.querySelector('.ka-input-checkbox-description');
        container.append(icon);
      } else if (['md-control', 'ka-control'].includes(this.element.tagName.toLowerCase())) {
        target = icon;
        let container = this.element.querySelector('label') || this.element.querySelector('ka-control-check .description');
        container.append(icon);
      } else if (this.element.innerHTML === '') {
        this.element.append(icon);
      }
      target.addEventListener('mouseover', (e) => {
        if (this.tooltip) return;
        let bounds = e.target.getBoundingClientRect();
        this.tooltip = document.createElement('div');
        this.tooltip.classList.add('md-tooltip');
        this.tooltip.style.top = (bounds.top + bounds.height) + 'px';
        this.tooltip.style.left = (bounds.left + (bounds.width / 2)) + 'px';
        this.tooltip.innerHTML = this.value;
        document.getElementsByTagName('body')[0].insertBefore(this.tooltip, document.getElementsByTagName('body')[0].children[0]);
        setTimeout(()=>{ if (!this.tooltip) return; this.tooltip.classList.add('visible'); }, 300);
      });
      target.addEventListener('mouseout', (e) => {
        if (!this.tooltip) return;
        document.getElementsByTagName('body')[0].removeChild(this.tooltip);
        this.tooltip = null;
      });
    }, 100);
  }
  detached() {
    if (!this.tooltip) return;
    document.getElementsByTagName('body')[0].removeChild(this.tooltip);
    this.tooltip = null;
  }
}
