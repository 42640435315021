import { Api } from 'services/api';
import { DialogController } from 'aurelia-dialog';

export class DialogContractDetail {
  htmlTemplate;
  pendingGetCashbackContract;

  static inject = [Api, DialogController];
  constructor(api, controller) {
    this.api = api;
    this.controller = controller;
  }

  activate(params) {
    this.params = params;
    this.init();
  }

  init() {
    if (!this.params.htmlTemplate) this.getCashbackContract();
    else this.htmlTemplate = this.params.htmlTemplate;
  }

  getCashbackContract() {
    this.pendingGetCashbackContract = true;
    return this.api.get(this.params.url).then((xhr) => {
      const response = xhr.response;
      return this.htmlTemplate = response.htmlTemplate || response.userRenderableHtml;
    }).catch((error) => {
      console.error('GET url', error);
      return this.resource.error(error);
    }).finally(() => {
      this.pendingGetCashbackContract = false;
    });
  }
}
