import {Aurelia, inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {activationStrategy} from 'aurelia-router';
import {KaToast, KaDialog} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';

@inject(Aurelia, Api, KaToast, KaDialog)
export class multicafSac {
  endpoint = 'users/me/tax-withholding-transmission-sessions';
  usergroup = null;
  isOperator = null;
  schema = {
    confirmedAt: {
      label: 'Invio dal', 
      control: 'date',
      query: 'confirmedAt-gte'
    },
    createdAt: {
      label: 'Invio al', 
      control: 'date',
      query: 'confirmedAt-lte'
    },
    uuid: { 
      control: 'text', 
      label: 'Uuid',
      query: 'uuid'
    },
    protocol: { 
      control: 'text', 
      label: 'Protocollo',
      query: 'transmissionReceiptIdentifier'
    },
    status: { 
      control: 'combo',
      label: 'Stato',
      datatext: 'text',
      datavalue: 'value',
      datamultiple: true,
      datapreload: true,
      datasource: [{ value: 'TRANSMITTED', text: 'Trasmesso'}, { value: 'REFUSED', text: 'Rifiutato'}, { value: 'COMPLETED', text: 'Completato'}],
      query: 'statusCode-in'
    },
    description: { 
      control: 'text', 
      label: 'Descrizione',
      query: 'label-like'
    }
  };
  data = {};

  constructor(aurelia, api, toast, dialog) {
    this.aurelia = aurelia;
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
    this.isStaging = ENVIRONMENT.APP_DEBUG === 'true';
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate() {
    this.transmissionType = 'SAC';
  }

  attached() {
    this.usergroup = this.api.user && this.api.user.group ? this.api.user.group : null;
    this.isOperator = ['TAX_CONSULTANT', 'ADMIN', 'BACKOFFICE'].includes(this.usergroup);
    if (this.isOperator) this.endpoint = 'bo/tax-withholding-transmission-sessions';
    
    /* Table interface */
    this.multicafSacTableInterface = new TableInterface({
      name: 'multicaf-SAC-table',
      client: this.api,
      endpoint: this.endpoint,
      query: 'type=SAC'
    });
    if (this.multicafSacTableInterface) this.limit = [{ value:'10', text:'10' }, { value:'25', text:'25' }, { value:'50', text:'50' }];

    /* Table Search */
    this.multicafSacSearchInterface = new SearchInterface({
      name: 'multicaf-SAC-table-search',
      table: this.multicafSacTableInterface,
      schema: this.schema,
      data: this.data
    });
    if (!this.isOperator) { this.multicafSacSearchInterface.schema.status.datasource.unshift({ value: 'RECEIVED', text: 'Ricevuto'});}

    /* Initialize & Search function */
    this.multicafSacTableInterface.initialize().then(() => {
      this.multicafSacSearchInterface.initialize().then(() => {
        this.multicafSacSearchInterface.search().catch(error => {
          console.error('TableSearchInterface-search multicafSAC failed', error);
        });
      }).catch(error => {
        console.error('TableSearchInterface-initialize multicafSAC failed', error);
      });
    }).catch(error => {
      console.error('TableInterface-initialize multicafSAC failed', error);
    });
  }

  send($event) {
    if (!this.data.file || this.data.file.length === 0) return this.toast.show('Seleziona il file da caricare!', 'error');
    if (!this.data.description) return this.toast.show('Inserisci una descrizione!', 'error');
    $event.model.busy = true;
    let reader = new FileReader();
    reader.readAsDataURL(this.data.file[0]);
    reader.onload = () => {
      let data = {
        countryCode: 'it',
        label: this.data.description,
        type: this.transmissionType || 'CU',
        track: {
          fileData: reader.result.replace(/^data:.*?;base64/gi, 'data:text/plain;base64') // Force mime type of transmitted file
        }
      };
      this.api.post(this.endpoint, data).then(x => {
        this.toast.show('Documento inviato con successo!', 'success');
      }).catch(x => {
        let response = JSON.parse(x.response);
        let errorMessages = [];
        if (String(response.status).indexOf('40') === 0 && response.source) {
          if (response.source.parameters) {
            for (let parameter of response.source.parameters) {
              if (parameter.messages && parameter.messages.length) {
                for (let message of parameter.messages) {
                  errorMessages.push({code: parameter.code, message});
                }
              }
            }
          }
        }
        else errorMessages.push('Errore nell\'invio del documento!');
        if (errorMessages.length) errorMessages.forEach((error, index) => {
          errorMessages[index] = `<li style="padding-top: 8px;padding-bottom: 8px;border-bottom: 1px solid #f0f0f0"><!--<a href="http://www.netbuilder.it/rest-help/MULTICAF/${error.code}" target="_blank" style="cursor:help">-->${error.message}<!--</a>--></li>`;
        });
        this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>Si sono verificati i seguenti errori:</strong><div style="margin-top:1rem;border:1px solid #e0e0e0;padding:1rem;max-height:400px;overflow:auto;background-color:#ffffff"><ol>'+errorMessages.join('')+'</ol></div>'});
      }).finally(() => {
        this.data = null;
        this.multicafSacTableInterface.load();
        $event.model.busy = false;
      });
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
      this.toast.show('Errore nel caricamento del file!', 'error');
      $event.model.busy = false;
    };
  }

  downloadCu(record) {
    if (!record.trackFileUri) return;
    this.toast.show('Caricamento in corso', 'loading', true);
    this.api.get(record.trackFileUri.replace(this.api.baseUrl, '')).then(x => {
      let anchor = document.createElement('a');
      anchor.download = `${record.uuid}.txt`;
      anchor.href = x.response.fileData;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }).finally(() => {
      this.toast.consume();
    });
  }

  downloadRicevuta(record) {
    if (!record.transmissionReceipt || !record.transmissionReceipt.fileUri) return;
    this.toast.show('Caricamento in corso', 'loading', true);
    this.api.get(record.transmissionReceipt.fileUri.replace(this.api.baseUrl, '')).then(x => {
      let anchor = document.createElement('a');
      anchor.download = `${x.response.filename}`;
      anchor.href = x.response.fileData;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }).finally(() => {
      this.toast.consume();
    });
  }

  downloadRicevutaPagamento(record) {
    if (!record.paymentReceipt || !record.paymentReceipt.fileUri) return;
    this.toast.show('Caricamento in corso', 'loading', true);
    this.api.get(record.paymentReceipt.fileUri.replace(this.api.baseUrl, '')).then(x => {
      let anchor = document.createElement('a');
      anchor.download = `${x.response.filename}`;
      anchor.href = x.response.fileData;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }).finally(() => {
      this.toast.consume();
    });
  }

  dettaglio(record) {
    this._selectedSession = record.uuid;
    //let endpoint = `${this.endpoint}/${record.uuid}`;
    this.dialog.open({
      class: 'large multicaf-dialog-dettaglio',
      viewModel: PLATFORM.moduleName('views/multicaf/multicaf-dialog-dettaglio'),
      viewModelParams: { endpoint: this.endpoint, transmissionType: this.transmissionType, session: record }
    }, true).whenClosed(() => { this.multicafSacTableInterface.load(); });
  }

  avviso(record) {
    this._selectedSession = record.uuid;
    //let endpoint = `${this.endpoint}/${record.uuid}`;
    this.dialog.open({
      type: 'alert',
      title: `Avviso su caricamento ${this.transmissionType}`,
      class: 'large',
      body: `${record.notes}`
     }, true);
  }

  conferma(record, data = {}) {
    this.dialog.open({
      title: 'Conferma invio',
      class: 'small',
      type: 'confirm',
      body: 'Il file verrà inviato al nostro Caf per poi essere successivamente trasmesso all\'Agenzia delle Entrate. <br/><strong>Confermi l\'invio del file?</strong>'
    }, true).whenClosed(response => {
      if (!response.wasCancelled) {
        let patchData = { statusCode: 'TRANSMITTED' };
        if (this.isStaging) Object.assign(patchData, data);
        this.api.patch(`${this.endpoint}/${record.uuid}`, { data: { attributes: patchData } }).catch(error => {
          console.log('Error Patch', error);
        }).finally(() => {
          this.multicafSacTableInterface.load();
        })
      }
    });
  }
  
  elimina(record) {
    this.dialog.open({
      title: 'Conferma eliminazione',
      class: 'small',
      type: 'confirm',
      body: '<strong>Confermi l\'eliminazione del file?</strong>'
    }, true).whenClosed(response => {
      if (!response.wasCancelled) {
        this.api.delete(`${this.endpoint}/${record.uuid}`).then(x => {
          this.multicafSacTableInterface.load();
        }).catch(error => {
          console.log('Error Delete', error);
        });
      }
    });
  }

  listino() {
    /* this.dialog.open({ title: 'Listino servizio', class: 'medium', type: 'alert', body: '<table class="ka-table-like"><thead><tr><th></th><th>Costo a condominio</th><th>Cashback a condominio</th></tr></thead><tbody><tr><th>Fatturazione a studio</th><td>19€<em>+iva</em></td><td>0</td></tr><tr><th>Fatturazione a condominio</th><td>120€<em>+iva</em></td><td>50€ *</td></tr></tbody></table><p style="opacity:0.7"><strong>*</strong> Per poter usufruire del cashback occorre sottoscrivere un regolare contratto. Per chi non l\'avesse ancora fatto, inviare un\'email ad <a href="mailto:assistenza@multidialogo.it">assistenza@multidialogo.it</a> con oggetto "Contratto cashback".</p>'}); */
    this.dialog.open({ title: 'Listino servizio', class: 'small', viewModel: PLATFORM.moduleName('views/multicaf/multicaf-dialog-listino')}, true);
  }

  copyCode(code) {
    navigator.clipboard.writeText(code);
    this.toast.show('Codice coda copiato con successo!', 'success');
  }

  export() {
    let records = this.multicafSacTableInterface.data.map(x => {
      return {
        uuid: x.uuid,
        data: this.aurelia.resources.valueConverters.dateFormat.toView(x.confirmedAt).replace(/\s/, '-'),
        stato: x.statusCode === 'TRANSMITTED' ? 'Trasmesso' : x.statusCode === 'REFUSED' ? 'Rifiutato' : x.statusCode === 'COMPLETED' ? 'Completato' : '',
        descrizione: x.label,
        protocollo: x.transmissionReceipt?.identifier
      }
    });
    let csv = [Object.keys(records[0]).join(';')];
    for (let record of records) {
      let values = [];
      for (let k of Object.keys(record)) {
        let value = record[k];
        values.push(value);
      }
      csv.push(values.join(';'));
    }
    csv = csv.join('\n');
    let blob = new Blob([csv], {type: 'text/csv'});
    let anchor = document.createElement('a');
    anchor.download = `${this.api.user.id}-esportazione-SAC.csv`;
    anchor.href = window.URL.createObjectURL(blob);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
}
