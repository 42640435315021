import {inject, bindable} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import mdt from 'services/md-tools';
import {KaToast, KaDialog} from 'ka-components';
import datasets from 'md-datasets.json';
import environment from 'environment';

@inject(Api, KaToast, KaDialog)
export class ImpostazioniMultibox {
  @bindable() prefsel = null;
  environment = environment;
  constructor(api, toast, dialog) {
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
  }
 
  attached() {
  }

  prefselChanged(value, old) {
    if (value === old) return;
    setTimeout(() => {
      this.preferences.isOverride = null;
      this.preferences.reload();
    }, 0);
  }

  preferences = {
    endpoint: null,
    schema: {
      firstname: { control: 'text', label: 'Nome' },
      lastname: { control: 'text', label: 'Cognome' },
      companyName: { control: 'text', label: 'Nome azienda' },
      addressee: { control: 'text', label: 'Destinatario azienda' },
      streetAddress: { control: 'text', label: 'Indirizzo' },
      zipCode: { control: 'text', label: 'CAP' },
      admLvl2: { control: 'text', label: 'Provincia' },
      admLvl3: { control: 'text', label: 'Città' },
      envelope: {
        packagePresentation: { control: 'combo', label: 'Proprietà buste', datasource: [{value: 'CLOSED', text: 'Chiuse'}, {value: 'OPEN', text: 'Aperte'}], datavalue: 'value', datatext: 'text', datapreload: true },
        recipientList: {
          subject: { control: 'text', label: 'Oggetto foglio destinatari' }
        }
      },
      emailForwarding: { control: 'check', label: 'Anticipa tramite mail la comunicazione inviata con Multibox' }
    },
    data: {},
    checks: {
      recipientList: false
    },
    reload: () => {
      this.preferences.isCal = String(this.prefsel) !== String(this.api.user.id);
      this.api.get(`users/${this.prefsel}/preferences`).then(x => {
        if (this.preferences.isOverride === null) this.preferences.isOverride = (this.preferences.isCal && x.response.requested?.sender?.multibox) || (!this.preferences.isCal && x.response.current?.sender?.multibox);
        this.preferences.data = mdt.preferences.mergeModelData(this.preferences.schema, ['sender', 'multibox'], x.response);
        this.preferences._data = JSON.parse(JSON.stringify(this.preferences.data));
        this.preferences.checks.recipientList = this.preferences.data.envelope.recipientList?.subject && this.preferences.data.envelope.recipientList?.subject.length > 0 ? true : false;
      });
    },
    override: ($event) => {
      $event.model.busy = true;
      this.api.patch(`users/${this.prefsel}/preferences/sender/multibox`, {enableOverride: !this.preferences.isOverride}).then(x => {
        this.preferences.isOverride = !this.preferences.isOverride;
      }).catch(e => {}).finally(() => {
        this.preferences.reload();
        $event.model.busy = false;
      });
    },
    save: ($event) => {
      $event.model.busy = true;
      if (this.preferences.checks.recipientList === false) {
        this.preferences.data.envelope.recipientList = {subject: null};
      }
      let data = mdt.helpers.diffObject(this.preferences._data, this.preferences.data);
      if (data && Object.entries(data).length) {
        this.preferences.resource.save(data, `users/${this.prefsel}/preferences/sender/multibox`, 'patch').then(x => {
          this.toast.show('Modifiche salvate con successo!', 'success');
          this.preferences.reload();
        }).catch(e => {
          this.toast.show('Errore nel salvataggio delle modifiche!', 'error');
        }).finally(() => {
          $event.model.busy = false;
        });
      }
      else $event.model.busy = false;
    }
  };

}
