export class MdAuth {
  constructor() {
    this.stored = JSON.parse(sessionStorage.getItem('MdAuth'));
  }

  get accessToken() {
    return this.stored?.accessToken || null;
  }
  set accessToken(value) {
    this.store('accessToken', value);
  }
  get refreshToken() {
    return this.stored?.refreshToken || null;
  }
  set refreshToken(value) {
    this.store('refreshToken', value);
  }

  store(key, value) {
    if (value) {
      this.stored[key] = value;
    } else if (key in this.stored) {
      delete this.stored[key];
    }
    sessionStorage.setItem('MdAuth', JSON.stringify(this.stored));
  }
}
