import { inject } from 'aurelia-framework';
import { Api } from 'services/api';
import { DialogController } from 'aurelia-dialog';
import { KaDialog, KaToast } from 'ka-components';
import mdt from 'services/md-tools';

@inject(Api, DialogController, KaDialog, KaToast)
export class DialogMigrateSubuser {
  pendingSave;
  activableOn = 'Utenti';
  payableBy = 'Utenti';
  selectedUser;
  resource = {
    controls: {},
    schema: {
      migrationRecipient: {
        control: 'combo',
        label: 'Nuovo utente padre',
        required: true,
        datatext: 'profile[0].displayName',
        datavalue: 'id',
        datasource: { table: 'bo/users?include=user-profiles&status=ACTIVE&user-group-in=MASTER_USER', query: { search: 'text-search', item: 'id-in' }},
        query: 'text-search'
      },
      migrationReason: {
        control: 'text',
        label: 'Nota',
        required: true
      }
    },
    data: {},
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        if (!control) return;
        promises.push(new Promise((resolve, reject) => {
          if (control.validate)
            control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); });
          else if (control.input?.validate)
            control.input.validate().then(() => resolve()).catch(() => reject('Control didn\'t pass validation'));
        }));
      });
      return Promise.all(promises);
    },
    error: error => {
      let errorPayload = mdt.parsers.apiError(error, this.resource.controls);
      if (errorPayload) this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>'+errorPayload.title+':</strong><div style="margin-top:1rem;border:1px solid #e0e0e0;padding:1rem;max-height:400px;overflow:auto;background-color:#ffffff">' + errorPayload.body + '</div>'});
    }
  }

  constructor(api, DialogController, kaDialog, kaToast) {
    this.api = api;
    this.toast = kaToast;
    this.dialog = kaDialog;
    this.controller = DialogController;
  }

  activate(params) {
    this.params = params;
  }

  save() {
    if (this.pendingSave) return;
    this.pendingSave = true;
    this.resource.validate().then(() => {
      let data = {};
      data.migrationReason = this.resource.data.migrationReason;
      data.migrationSubuserUuid = this.params.uuid;
      this.api.post(`bo/users/${this.resource.data.migrationRecipient}/users`, data).then(() => {
        this.params.dialog.ok();
        this.toast.show('Migrazione avvenuta con successo', 'success');
      }).catch(error => {
        console.error(error);
        this.resource.error(error);
      }).finally(() => {
        this.pendingSave = false;
      })
    });
  }
}
