import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';

@inject(Api)
export class ServiceDialogNotificationDetail {
  constructor(api) {
    this.api = api;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
    this.userId = this.params.userId;
    this.userDisplayName = this.params.userDisplayName;
    this.notificationUuid = this.params.notificationUuid;
    this.getNotification();
  }

  getNotification() {
    this.api.get(`bo/users/${this.userId}/system-notifications/${this.notificationUuid}`).then(xhr => {
      this.notification = xhr.response;
      this.dataParse();

      // Iframe
      setTimeout(() => {
        let emailFrame = document.getElementById("emailFrame");
        emailFrame.srcdoc = this.notification.emailHtmlBody;
      }, 100)
    });
  }

  dataParse() {
    let channels = [];
    if (this.notification.scheduleEmail === 'YES') {
      channels.push('email');
      const buildEmail = (pattern = '') => {
        return {
          recipientEmailAddress: this.notification[`recipientEmailAddress${pattern}`],
          emailDispatchedAt: this.notification[`email${pattern}DispatchedAt`],
          emailDispatchErrorAt: this.notification[`email${pattern}DispatchErrorAt`],
          emailDispatchErrorMessage: this.notification[`email${pattern}DispatchErrorMessage`],
          emailDeliveredAt: this.notification[`email${pattern}DeliveredAt`],
          emailBouncedAt: this.notification[`email${pattern}BouncedAt`],
          emailBounceReason: this.notification[`email${pattern}BounceReason`]
        }
      }
      let emails = [];
      emails.push(buildEmail());
      if (this.notification.recipientEmailAddress2) emails.push(buildEmail('2'));
      if (this.notification.recipientEmailAddress3) emails.push(buildEmail('3'));
      this.notification.emails = emails;
    }
    if (this.notification.scheduleSms === 'YES') channels.push('sms');
    if (this.notification.scheduleUiNotice === 'YES') channels.push('ui');

    this.notification.channels = channels;
  }
}
