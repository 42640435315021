import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';

import mdt from 'services/md-tools';
import {TableInterface, SearchInterface} from 'classes/md-table';
import moment from 'moment';

@inject(Router, Api, KaDialog, KaToast)
export class viewDeposito {
  mdt = mdt;
  environment = ENVIRONMENT;
  currentBalance = null;
  constructor(router, api, dialog, toast) {
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    this.isOperator = this.api.hasRole('LIST_ANY_USER_BAILMENT_DEPOSIT_TRANSACTION');
  }

  attached() {
    this.init();
  }

  init() {
    // Bailment table interface
    this.bailmentTableInterface = new TableInterface({
      name: 'bailment-deposit-transactions-table',
      client: this.api,
      endpoint: `users/me/bailment-deposit-transactions`,
      query: null
    });

    // Bailment search interface
    this.bailmentSearchInterface = new SearchInterface({
      name: 'bailment-deposit-transactions-search',
      table: this.bailmentTableInterface,
      schema: {
        userId: { 
          control: 'combo', 
          label: this.mdt.parsers.contextualize(this.api.user, 'Utente'),
          datasource: { table: `${ this.isOperator ? 'bo/users' : 'users/me/users'}?include=user-profiles`, query: { search: 'text-search', item: 'id-in'} },
          datavalue: 'id', 
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false,
          required: this.isOperator
        },
        orderId: {
          label: 'ID ordine',
          control: 'text',
          query: 'order-id-in'
        },
        type: {
          label: 'Tipologia', 
          control: 'combo',
          datasource: [
            { value: 'CREDIT', text: 'Entrata'},
            { value: 'DEBIT', text: 'Uscita'}
          ],
          datavalue: 'value',
          datatext: 'text',
          datapreload: true,
          query: 'transaction-type'
        },
        dateStart: {
          label: 'Creato dal', 
          control: 'date',
          query: 'created-at-gte'
        },
        dateEnd: {
          label: 'al', 
          control: 'date',
          query: 'created-at-lte'
        }
      },
      data: {
        dateStart: moment().utc().startOf('month').toISOString(),
        dateEnd: moment().utc().endOf('month').toISOString()
      }
    });
    // Initialize interfaces
    this.bailmentTableInterface.initialize().then(() => {
      this.bailmentSearchInterface.initialize().then(() => {
        if (this.params.user) this.bailmentSearchInterface.data.userId = this.params.user; 
        if (!this.isOperator || this.bailmentSearchInterface.data.userId) this.ricerca();
      }).catch(error => { console.log('bailment-deposit-transactions-search intialize failed', error); });
    }).catch(error => { console.log('bailment-deposit-transactions-table initialize failed', error); });
  }

  ricerca() {
    if (this.isOperator) {
      this.userId = this.params.user || this.bailmentSearchInterface.data.userId || null;
      if (this.userId) this.bailmentTableInterface.endpoint = `users/${this.userId}/bailment-deposit-transactions`;
    }
    this.bailmentSearchInterface.search().then(xhr => { 
      this.currentBalance = xhr.originalResponse.meta.currentBalance;
    }).catch(error => { console.log('bailment-deposit-transactions-search failed', error); });
  }

  ripristina() {
    this.bailmentSearchInterface.reset(true).then(() => {
      this.userId = null;
      if (!this.isOperator || this.bailmentSearchInterface.data.userId) this.ricerca();
      else {
        this.init();
        this.bailmentSearchInterface.data.userId = null;
      }
    });
  }

  depositTransaction() {
    if (this.userId) {
      this.dialog.open({
        title: 'Ricarica credito Sms/Fax/Posta',
        class: 'small',
        viewModel: PLATFORM.moduleName('views/servizi/credito/dialog-deposit-transaction'),
        viewModelParams: { userId: this.userId, userProfile: this.bailmentSearchInterface.controls.userId.objectValue.profile[0] }
      }, true).whenClosed(response => {
        if (!response.wasCancelled) this.ricerca();
      });
    }
  }
}
