import {inject} from 'aurelia-framework';
import {Router, activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';
import mdt from 'services/md-tools';

@inject(Router, Api, KaDialog, KaToast)
export class viewRubricaGruppi {
  mdt = mdt;
  group = {
    loaded: false,
    endpoint: 'users/me/contact-groups',
    query: '',
    schema: {
      name: {
        control: 'text', 
        label: 'Nome gruppo'
      },
    },
    data: {},
    save: ($event) => {
      $event.model.busy = true;
      if (this.group.data.id) {
        this.group.resource.save(this.group.data, `users/me/contact-groups/${this.group.data.id}`, 'patch').then(x => {
          this.toast.show('Modifiche salvate con successo!', 'success');
          this.load();
          this.router.navigateToRoute('anagrafiche/rubrica-gruppi');
        }).catch(e => {
          this.toast.show('Errore nel salvataggio delle modifiche!', 'error');
        }).finally(() => {
          $event.model.busy = false;
        });
      } else {
        this.group.resource.save(this.group.data, 'users/me/contact-groups', 'post').then(x => {
          this.toast.show('Contatto creato con successo!', 'success');
          this.router.navigateToRoute('anagrafiche/rubrica-gruppi');
        }).catch(e => {
          console.error(e);
          this.toast.show('Errore nella creazione del contatto!', 'error');
        }).finally(() => {
          $event.model.busy = false;
        });
      }
    }
  };

  constructor(router, api, dialog, toast) {
    this.api = api;
    this.router = router;
    this.dialog = dialog;
    this.toast = toast;
  }
  
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    /* Table Interface */
    this.groupsTableInterface = new TableInterface({
      name: 'groups-table',
      client: this.api,
      endpoint: 'users/me/contact-groups',
      query: null
    });

    /* Initialize table */
    this.groupsTableInterface.initialize().then(() => { this.load(); }).catch(error => { console.log('groupsTable-initialize failed', error); });
  }

  load() {
    if (this.params.id && this.params.id !== 'new') {
      this.api.get(`users/me/contact-groups/${this.params.id}`).then(xhr => {
        this.group.data = xhr.response;
      });
    } else if (!this.params.id) {
      this.groupsTableInterface.load().catch(error => { console.log('groupsTable-load failed', error); });
    }
  }

  annulla() {
    this.router.navigateToRoute('anagrafiche/rubrica-gruppi');
  }

  nuovo() {
    this.router.navigateToRoute('anagrafiche/rubrica-gruppi', { id: 'new' });
  }

  elimina(record) {
    this.dialog.open({
      title: 'Attenzione!',
      class: 'small',
      viewModel: PLATFORM.moduleName('views/anagrafiche/rubrica/rubrica-dialog-elimina-gruppo'),
      viewModelParams: record
    }, true).whenClosed(response => {
      if (!response.wasCancelled) {
        if (response.output.deleteContacts) {
          this.api.delete(`users/me/contact-groups/${record.id}/contacts`).then(() => {
            this.api.delete(`users/me/contact-groups/${record.id}`).then(() => {
              this.load();
              this.toast.show('Gruppo e relativi contatti eliminati con successo!', 'success');
            }).catch(x => {
              this.api.dialogError(x);
            });
          }).catch(x => {
            this.api.dialogError(x);
          });
        } else {
          this.api.delete(`users/me/contact-groups/${record.id}`).then(() => {
            this.load();
            this.toast.show('Gruppo eliminato con successo!', 'success');
          }).catch(x => {
            this.api.dialogError(x);
          });
        }
      }
    });
  }

  modifica(record) {
    this.router.navigateToRoute('anagrafiche/rubrica-gruppi', { id: record.id });
  }

  contatti() {
    this.router.navigateToRoute('anagrafiche/rubrica');
  }
}
