import { Api } from 'services/api';
import { TableInterface } from 'classes/md-table';
import { DialogController } from 'aurelia-dialog';
import { KaToast } from 'ka-components'

export class DialogReportAttachments {
  queueUuid;
  errorInit;
  pendingInit;
  messageUuid;
  attachments;
  queueOwnerId;
  globalStaple;
  fastDelivery;

  static inject = [Api, DialogController, KaToast];
  constructor(api, controller, toast) {
    this.api = api;
    this.controller = controller;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    this.queueUuid = params.queueUuid;
    this.messageUuid = params.messageUuid;
    this.queueOwnerId = params.queueOwnerId;
    this.messageChannel = params.messageChannel;
  }

  attached() {
    this.init();
  }

  init() {
    this.pendingInit = true;
    this.tableInterface = new TableInterface({
      client: this.api,
      name: 'table-report-attachments',
      endpoint: `users/${this.queueOwnerId}/queues/${this.queueUuid}/messages/${this.messageUuid}/file-attachments`
    });

    this.tableInterface.initialize()
      .then(() => {
        this.tableInterface.load().then((xhr) => {
          this.globalStaple = xhr.originalResponse.meta.globalStaple;
          this.fastDelivery = xhr.originalResponse.meta.fastDelivery;
          this.pendingInit = false;
        });
      }).catch(() => {
        this.errorInit = true;
      }).finally(() => {

      });
  }

  downloadFile(fileType, attachment) {
    const uri = `${this.tableInterface.endpoint}/${attachment.uuid}/${fileType}`

    return this.api.client.createRequest(uri)
        .asGet()
        .withHeader("Accept", "application/pdf")
        .withHeader("Content-Type", "application/pdf")
        .withResponseType('blob')
        .send()
        .then((xhr) => {
          const anchor = document.createElement("a");
          const blob = new Blob([xhr.response], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);

          anchor.href = url;
          anchor.download = `${attachment.uuid}-${fileType}`;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          this.toast.show('Documento scaricato con successo!', 'success');
        }).catch((error) => {
          console.error(`GET - ${fileType} file`, error);
          this.toast.show('Impossibile scaricare il file!', 'error');
        }).finally(() => {
        })
  }
}
