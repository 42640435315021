import {inject, customElement, bindable, bindingMode, InlineViewStrategy} from 'aurelia-framework';

@customElement('md-input-array')
@inject(Element)
export class MdInputArray {

  // Field configuration overrides
  @bindable() name = null;
  @bindable() label = null;
  @bindable() default = null;
  @bindable() required = null;
  @bindable() readonly = null;
  @bindable() description = null;
  @bindable() validation = true;
  
  items = null;

  constructor(element) {
    this.element = element;
  }

  bind(bindingContext) {
    this.parent = bindingContext;
  }

  attached() {
    setTimeout(() => {
      let parent = this.parent;
      this.value = eval(`parent.data.${this.name}`);
      this.schema = eval(`parent.schema.${this.name}`);
    }, 500);
  }

  set value(items) {
    this.items = [];
    if (!items) return;
    if (!items.length) {
      this.items.push({ value: null });
    } else {
      for (let item of items) {
        this.items.push({ value: item });
      }
    }
  }

  get value() {
    let value = [];
    for (let item of this.items) {
      if (item.value) value.push(item.value);
    }
    return value;
  }

  update() {
    eval(`this.parent.data.${this.name} = this.value`);
  }

  add() {
    this.items.push({ value: null });
    this.update();
  }
  remove(index) {
    for (let i = 0; i < this.items.length; i++) delete this.parent.controls[`${this.name}[${i}]`];
    let value = this.value;
    value.splice(index, 1);
    this.value = value;
    this.update();
  }
}
