import { Aurelia, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { TableInterface, SearchInterface } from 'classes/md-table';
import { KaDialog, KaToast } from 'ka-components';
import mdt from 'services/md-tools';

@inject(Aurelia, Router, Api, KaDialog, KaToast)
export class Segnalatori {
  mdt = mdt;
  isInitializing = true;
  isLoading = true;
  readEnabled = false;
  writeEnabled = false;
  downloadHistoryEnabled = false;
  noAssociations = false;
  services = [];
  serviceGroups = []; 
  newReseller = { uuid: null, resellerCodes: null, selectedCode: null, serviceGroupCode: null };

  constructor(aurelia, router, api, dialog, toast) {
    this.aurelia = aurelia;
    this.api = api;
    this.router = router;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    if (this.params.id) this.init();
  }

  init() {
    if (this.params.id !== 'me') {
      if (this.api.hasRole('DOWNLOAD_RESELLERS_HISTORY')) this.downloadHistoryEnabled = true;
      if (this.api.hasRole('CREATE_ANY_ACCOUNT_RESELLER_ASSOCIATION')) this.writeEnabled = true;
      else if (this.api.hasRole('LIST_ANY_ACCOUNT_RESELLER_ASSOCIATION')) this.writeEnabled = false;
    }

    // if there are not associations, do not show the download history button in the dom
    this.api.get(`bo/users/${this.params.id}/resellers-history-download`).catch((error) => {
      if (error.statusCode === 404) this.noAssociations = true;
      else this.noAssociations = false;
    });

    // INIT TableInterface
    this.tableInterface = new TableInterface({
      name: 'segnalatori-table',
      client: this.api,
      endpoint: `bo/users/${this.params.id}/resellers`,
      query: 'reseller-association-user-status=ACTIVE'
    });
    this.tableInterface.initialize().then(() => {
      this.datasourceServiceGroups();
      this.load();
    }).catch(error => { 
      console.log('tableInterface initialization failed', error);
      this.toast.show('Errore in fase di inizializzazione!', 'error');
    });
  }

  datasourceServiceGroups() {
    this.api.get('resell/service-groups').then(xhr => { 
      this.serviceGroups = xhr.response;
    }).catch(error => {
      console.log('ERROR - resell/service-groups - GET', error);
      this.api.dialogError(error);
    });
  }

  load() {
    this.isLoading = true;
    this.tableInterface.load().then(() => {
      let promises = [];
      if (this.tableInterface.data?.length) {
        this.tableInterface.data.forEach(reseller => {
          reseller.serviceGroups = [];
          promises.push(this.api.get(`bo/users/${this.params.id}/resellers/${reseller.resellerCode}/service-groups`, { filters: 'user-reseller-association-service-group-status=ACTIVE'}).then(xhr => {
            reseller.serviceGroups = xhr.response;
          }));
        });
        Promise.all(promises).catch(errors => {
          console.log('ERROR - resellerServiceGroups - PROMISES', errors);
          this.api.dialogError(errors);
        }).finally(() => {
          this.isInitializing = this.isLoading =  false;
        });
      } else this.isInitializing = this.isLoading = false;
    }).catch(() => {
      this.isInitializing = this.isLoading = false;
      this.toast.show('Errore in fase di caricamento!', 'error');
    });
  }

  addServiceGroup(reseller) {
    this.api.post(`bo/users/${this.params.id}/resellers/${reseller.resellerCode}/service-groups`, { serviceGroupCode: reseller.group.code }).then(() => {
      this.toast.show('Servizio aggiunto con successo', 'success');
      this.load();
    }).catch(error => {
      this.api.dialogError(error);
    }).finally(() => {
      reseller.group = null;
    });
  }

  deleteServiceGroup(reseller, group) {
    this.api.delete(`bo/users/${this.params.id}/resellers/${reseller.resellerCode}/service-groups/${group.serviceGroupCode}`, {}).then(() => {
      this.toast.show('Servizio eliminato con successo', 'success');
      this.load();
    }).catch(error => {
      this.api.dialogError(error);
    })
  }

  addReseller() {
    this.dialog.open({
      title: 'Associa nuovo segnalatore',
      class: 'profilo-add-reseller small',
      viewModel: PLATFORM.moduleName('views/anagrafiche/utenti/dialogs/profilo-add-reseller'),
      viewModelParams: { id: this.params.id }
    }, true).whenClosed(response => {
      if (!response.wasCancelled) {
        this.toast.show('Segnalatore aggiunto con successo', 'success');
        this.load();
      }
    });
  }

  deleteReseller(reseller) {
    this.api.delete(`bo/users/${this.params.id}/resellers/${reseller.resellerCode}`).then(() => {
      this.toast.show('Segnalatore eliminato con successo', 'success');
      this.load();
    }).catch(error => {
      this.api.dialogError(error);
    })
  }

  annulla() {
    this.params = null;
    this.router.navigate('anagrafiche/utenti');
  }

  downloadHistory() {
    if (this.downloadHistoryPending) return;
    this.downloadHistoryPending = true;
    this.api.client.createRequest(`/bo/users/${this.params.id}/resellers-history-download`)
    .asGet()
    .withHeader('Accept', 'application/csv')
    .withResponseType('blob')
    .send()
    .then(xhr => {
      let regexp = new RegExp('filename=(.+\.csv$)');
      let matches = xhr.headers.headers['content-disposition'].value.match(regexp);
      let anchor = document.createElement('a');
      let blob = new Blob([xhr.response], { type: 'text/csv' });
      let url = URL.createObjectURL(blob);
      anchor.href = url;
      anchor.download = matches[1];
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      this.toast.show('Storico scaricato con successo', 'success');
    }).catch(xhr => {
      console.log('ERROR - export-users GET', xhr);
      this.toast.show('Errore in fase di scaricamento', 'error');
    }).finally(() =>{
      this.downloadHistoryPending = false;
    });
  }
}