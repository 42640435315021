import {Aurelia, inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';

import mdt from 'services/md-tools';
import {TableInterface, SearchInterface} from 'classes/md-table';
import moment from 'moment';

@inject(Aurelia, Router, Api, KaDialog, KaToast)
export class viewCrediti {
  mdt = mdt;
  environment = ENVIRONMENT;
  userId = 'me';
  currentBalance = null;
  selectedParentIsMaster = false;
  constructor(aurelia, router, api, dialog, toast) {
    this.aurelia = aurelia;
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    this.isOperator = this.api.hasRole('LIST_ANY_USER_CREDIT_TRANSACTION');
  }

  attached() {
    this.init();
  }

  init() {
    // Credit table interface
    this.creditTableInterface = new TableInterface({
      name: 'credit-transactions-table',
      client: this.api,
      endpoint: null,
      query: 'sort=-createdAt'
    });

    // Credit search interface
    this.creditSearchInterface = new SearchInterface({
      name: 'credit-transactions-table-search',
      table: this.creditTableInterface,
      schema: {
        context: {
          control: 'combo',
          label: 'Quali movimenti visualizzare?',
          datasource: [
            { value: 'me', text: 'I miei movimenti' },
            { value: 'subusers', text: 'Di un mio ' + this.mdt.parsers.contextualize(this.api.user, 'utente') }
          ],
          required: false
        },
        userId: {
          control: 'combo', 
          label: this.mdt.parsers.contextualize(this.api.user, 'Utente'),
          datasource: { table: (this.isOperator ? 'bo/users' : 'users/me/users') + '?include=user-profiles', query: { search: 'text-search', item: 'id-in'} },
          datavalue: 'id', 
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false,
          required: false
        },
        parent: { 
          control: 'combo',
          label: 'Utente',
          datasource: { table: `bo/users?include=user-profiles`, query: { search: 'text-search', item: 'id-in'} },
          datatext: 'profile[0].displayName',
          datavalue: 'id', 
          datamultiple: false,
          datapreload: false,
          required: false
        },
        child: {
          control: 'combo', 
          label: 'Eventuale sottoutente',
          datasource: { table:'bo/users?include=user-profiles', query: { search: 'text-search', item: 'id-in'} },
          datavalue: 'id', 
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false,
          required: false
        },
        orderId: {
          label: 'ID ordine',
          control: 'text',
          query: 'reason-order-id',
          required: false
        },
        type: {
          label: 'Tipologia', 
          control: 'combo',
          datasource: [
            { value: 'null', text: 'Tutti'},
            { value: 'CREDIT', text: 'Entrata'},
            { value: 'DEBIT', text: 'Uscita'}
          ],
          datavalue: 'value',
          datatext: 'text',
          datapreload: true,
          query: 'sign',
          required: false
        },
        dateStart: {
          label: 'Creato dal', 
          control: 'date',
          query: 'created-at-gte',
          required: false
        },
        dateEnd: {
          label: 'al', 
          control: 'date',
          query: 'created-at-lte',
          required: false
        }
      },
      data: {
        type: 'null',
        dateStart: moment().utc().startOf('month').toISOString(),
        dateEnd: moment().utc().endOf('month').toISOString()
      }
    });

    // Initialize interfaces
    this.creditTableInterface.initialize().then(() => {
      this.creditSearchInterface.initialize().then(() => {
        if (this.isOperator && this.params.user) {
          this.creditSearchInterface.data.parent = this.params.user;
          this.creditSearchInterface.data.child = null;
        }
        else if (!this.isOperator && this.params.user) {
          this.creditSearchInterface.data.userId = this.params.user;
        }
        // Ricerca in fase di avvio
        if (this.creditSearchInterface.data.orderId) this.ricerca();
        else if (this.isOperator && this.creditSearchInterface.data.parent) this.ricerca();
        else if (!this.isOperator && this.creditSearchInterface.data.context === 'me') this.ricerca();
        else if (!this.isOperator && this.creditSearchInterface.data.userId) this.ricerca();
      }).catch(error => { console.log('credit-transactions-search intialize failed', error); });
    }).catch(error => { console.log('credit-transactions-table initialize failed', error); });
  }

  setParent() {
    if (this.creditSearchInterface.controls.parent?.objectValue?.group === 'MASTER_USER') {
      this.selectedParentIsMaster = true;
      this.creditSearchInterface.schema.userId.datasource.table = `bo/users?include=user-profiles`+ (this.creditSearchInterface?.data?.parent ? `&parent-uuid-in=${this.creditSearchInterface.data.parent}`: '');
    } else {
      this.selectedParentIsMaster = false;
    }
    console.log(this.selectedParentIsMaster);
  }

  ricerca() {
    // Handle userId
    if (this.creditSearchInterface.data.context === 'me') {
      this.userId = 'me';
    } else if (this.creditSearchInterface.data.userId) {
      this.userId = this.creditSearchInterface.data.userId;
    } else if (this.creditSearchInterface.data.child) {
      this.userId = this.creditSearchInterface.data.child;
    } else if (this.creditSearchInterface.data.parent) {
      this.userId = this.creditSearchInterface.data.parent;
    }
    // Handle required
    if (!this.isOperator) {
      (this.creditSearchInterface.controls.context || this.creditSearchInterface.schema.context).required = !this.creditSearchInterface.data.orderId;
      this.creditSearchInterface.schema.userId.required = !this.creditSearchInterface.data.orderId && this.creditSearchInterface.data.context !== 'me';
    } else {
      this.creditSearchInterface.schema.parent.required = !this.creditSearchInterface.data.orderId;
    }
    // Validate
    this.creditSearchInterface.validate().then(() => {
      this.creditTableInterface.endpoint = `users/${this.userId}/credit-transactions`;
      this.userProfile = null;
      // Search
      this.creditSearchInterface.search().then(xhr => {
        // Credit amount
        this.currentBalance = { filteredNoVatSubjectAmount: xhr.originalResponse.meta.filteredNoVatSubjectAmount, filteredVatSubjectAmount: xhr.originalResponse.meta.filteredVatSubjectAmount, noVatSubjectAmount:  xhr.originalResponse.meta.noVatSubjectAmount, vatSubjectAmount: xhr.originalResponse.meta.vatSubjectAmount };
        // User profile
        if (this.userId !== 'me') {
          let endpoint = this.isOperator ? 'bo/users' : 'users/me/users';
          this.api.get(`${endpoint}?include=user-profiles&id-in=${this.userId}`).then(xhr => {
            this.userProfile = xhr.response[0].profile[0];
            if (this.userProfile.parent && xhr.response[0].parentId) this.userProfile.parent.id = xhr.response[0].parentId;
            this.userProfile.credit = this.currentBalance;
          });
        }
      }).catch(error => { console.log('credit-transactions-search failed', error); });
    });
  }

  ripristina() {
    this.creditSearchInterface.reset(true).then(() => { this.userId = null; });
  }

  creditMovement(context) {
    // Roles
    if (context === 'transaction' && !this.api.hasRole('CREATE_ANY_USER_CREDIT_TRANSACTION')) return;
    else if (context === 'transfer' && !this.api.hasRole('CREATE_SUB_USER_CREDIT_TRANSFER')) return;

    if (this.userProfile) {
      this.dialog.open({
        title: context === 'transaction' ? 'Movimento credito' : 'Trasferimento credito',
        class: 'medium',
        viewModel: PLATFORM.moduleName('views/servizi/credito/dialog-credit-movement'),
        viewModelParams: { context: context, user: this.userProfile }
      }, true).whenClosed(response => {
        if (!response.wasCancelled) this.ricerca();
      });
    }
  }

  export() {
    let records = this.creditTableInterface.data.map(x => {
      return {
        "Data": this.aurelia.resources.valueConverters.dateFormat.toView(x.createdAt).replace(/\s/, '-'),
        "Account": this.userProfile ? this.userProfile.displayName : this.api.user.profile.displayName, 
        "Tipo": x.sign === 'CREDIT' ? 'Ricarica' : 'Addebito',
        "Riferimento": x.reason?.migrationVerse ? 'TRASFERIMENTO CREDITO' : 'MOVIMENTO CREDITO',
        "Importo servizio": x.amount?.vatSubject,
        "Importo affrancatura (esente da IVA)": x.amount?.noVatSubject
      }
    });
    let csv = [Object.keys(records[0]).join(';')];
    for (let record of records) {
      let values = [];
      for (let k of Object.keys(record)) {
        let value = record[k];
        values.push(value);
      }
      csv.push(values.join(';'));
    }
    csv = csv.join('\n');
    let blob = new Blob([csv], {type: 'text/csv'});
    let anchor = document.createElement('a');
    anchor.download = `${this.api.user.id}-esportazione-movimenti-credito.csv`;
    anchor.href = window.URL.createObjectURL(blob);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
}
