export async function checkCashbackContracts(scope, fragment) {
  if (['/cashback/contracts'].includes(fragment)) return checkResult(false);
  const nextFlatResponse = await scope.rest.get(`users/me/cashback-contracts/next-flat-service`);
  const nextVolumeResponse = await scope.rest.get(`users/me/cashback-contracts/next-multinvio-volumes`);
  
  if ([nextFlatResponse, nextVolumeResponse].some((response) => response.data?.data?.attributes?.mandatory === 'YES')) {    
    setTimeout(() => { toastCashbackContractsSubscription(scope); }, 3000);
    return checkResult(true);
  } else if ([nextFlatResponse, nextVolumeResponse].every((response) => [200, 404].includes(response.status))) {
    return checkResult(true);
  }
  return checkResult();
}

export function toastCashbackContractsSubscription(scope) {
  const body = `<p><span class="exclamation"></span><a href="cashback/contracts?ka-tab-main=userSubscriptions">Vai alla sezione contratti.</a></p><div class="close-button" onclick="this.parentElement.remove();"></div>`;
  return scope.toast.show(body, 'cashback', true);
}

export function checkResult(hasPassed, redirect) {
  const result = {};
  if (hasPassed !== undefined) result.hasPassed = hasPassed;
  if (redirect !== undefined) result.redirect = redirect;
  return result;
}
