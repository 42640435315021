import {customElement, bindable, inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import mdt from 'services/md-tools';

@customElement('md-nav-utenti')
@inject(Element, Api, Router)
export class MdNavUtenti {
  @bindable() userId = null;
  
  constructor(element, api, router) {
    this.element = element;
    this.api = api;
    this.router = router;

    let stored = JSON.parse(sessionStorage.getItem('md-nav-utenti') || '{ "links": [], "title": null}');
    this.links = stored.links;
    this.title = stored.title;
  }
  userIdChanged() {
    this.init();
  }
  init() {
    let links = [], title = null;
    if (!this.userId) {
      links = [
        { view: undefined, label: 'Utenti'},
        { view: 'fatturazione' , label: 'Profili di fatturazione'}
      ];
      if (this.api.hasRole('LIST_ANY_USER')) {
        links.push({ view: 'documenti_approvazione' , label: 'Approvazione documenti'});
      } else {
        links.push({ view: 'documenti' , label: 'Documenti'});
      }
      return this.apply(links, title);
    }
    if (this.userId && this.userId === 'new') {
      links = [{ view: 'anagrafica' , label: 'Anagrafica'}];
      title = `Stai creando un nuovo ${mdt.parsers.contextualize(this.api.user, 'utente')}`;
      return this.apply(links, title);
    }
    this.api.get(`users/${this.userId}?include=user-profiles,user-roles`).then(xhr => {
      const userData = xhr.response;
      title = `Stai modificando ${userData.profile[0].displayName}`;

      // Add link: Anagrafica
      links.push({ view: 'anagrafica' , label: 'Anagrafica'});
      // Add link: Documenti
      if (this.api.hasRole('LIST_USER_CERTIFIABLE_DOCUMENTS')) {
        links.push({ view: 'documenti' , label: 'Documenti'});
      }
      // Add link: Fatturazione
      if (this.userId !== 'new') {
        links.push({ view: 'fatturazione' , label: 'Fatturazione'});
      }
      // Add link: Segnalatori
      if (this.userId !== 'me' && !userData.parentUuid && this.api.hasRole(['CREATE_ANY_ACCOUNT_RESELLER_ASSOCIATION', 'LIST_ANY_ACCOUNT_RESELLER_ASSOCIATION'])) {
        links.push({ view: 'segnalatori' , label: 'Segnalatori'});
      }
      // Add link: Compensi
      if ( (this.userId !== 'me' && !userData.parentUuid && this.api.hasRole('LIST_ANY_RESELLER_COMMISSIONS')) || (this.userId === 'me' && this.api.hasRole('LIST_RESELLER_COMMISSIONS')) ) {
        links.push({ view: 'compensi' , label: 'Compensi'});
      }
      // Add link: Cashback
      if (userData.roles?.some(role => ['LIST_USER_CASHBACK_SETTINGS'].includes(role.id))) {
        links.push({ view: 'cashback' , label: 'Cashback'});
      }
      // Add link: Impostazioni
      if (!userData.parentUuid && this.api.hasRole(['MODIFY_ANY_USER_PRIVILEGED_PREFERENCES', 'LIST_USER_ADVANCED_PREFERENCES'])) {
        links.push({ view: 'impostazioni' , label: 'Impostazioni avanzate'});
      }
      // Add link: Pagamenti
      if (!userData.parentUuid && this.api.hasRole(['MODIFY_ANY_USER_PRIVILEGED_PREFERENCES', 'MODIFY_USERS_PRIVILEDGED_PREFERENCES'])) {
        links.push({ view: 'pagamenti', label: 'Pagamenti' });
      }
      this.apply(links, title);
    });
  }
  apply(links, title) {
    // Store links configuration
    sessionStorage.setItem('md-nav-utenti', JSON.stringify({links, title}));
    // Apply links and title
    this.links = links;
    this.title = title;
    // Set active link
    this.links.map((link) => { link.class = link.view === this.router.currentInstruction.params.view ? 'active' : null; });
  }
  navigate(view) {
    if (this.userId) this.router.navigateToRoute('anagrafiche/utenti', { id: this.userId, view: view });
    else if (view) this.router.navigate(`anagrafiche/utenti/${view}`);
    else  this.router.navigate('anagrafiche/utenti');
  }
}
