import { inject } from "aurelia-framework";
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { TableInterface, SearchInterface } from 'classes/md-table';
import { DateTime } from 'luxon';

@inject(Router, Api, KaDialog, KaToast)
export class VMMultitrax {
  endpoint = 'mtrax/outbox-messages';
  editEnabled = false;

  constructor(router, api, dialog, toast) {
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    this.initTable();
  }

  async initTable() {
    this.tableInterface = new TableInterface({
      name: 'tableInterface',
      endpoint: this.endpoint,
      client: this.api
    });
    this.searchInterface = new SearchInterface({
      name: 'searchInterface',
      table: this.tableInterface,
      client: this.api,
      schema: {
        strategyIn: {
          label: 'Modalità', 
          control: 'text',  
          query: 'strategy-in'
        },
        typeIn: {
          label: 'Tipologia', 
          control: 'text',  
          query: 'type-in'
        },
        createdAtStart: {
          label: 'Data creazione dal', 
          control: 'date',  
          query: 'created-at-gte'
        },
        createdAtEnd:  {
          label: 'al', 
          control: 'date',
          query: 'created-at-lte'
        },
        lastAttemptStart: {
          label: 'Ultimo tentativo dal', 
          control: 'date',  
          query: 'last-failed-attempt-gte'
        },
        lastAttemptEnd:  {
          label: 'al', 
          control: 'date',
          query: 'last-failed-attempt-lte'
        },
        deliveredAtStart: {
          label: 'Data elaborazione mtrax dal', 
          control: 'date',  
          query: 'delivered-at-gte'
        },
        deliveredAtEnd:  {
          label: 'al', 
          control: 'date',
          query: 'delivered-at-lte'
        },
        notDelivered: {
          description: '',
          control: 'check',
          query: 'delivered-at'
        },
        excludeFromRetry: {
          label: 'Escludi da retry automatico',
          control: 'combo',
          datasource: [{
            "text": "Sì",
            "value": "YES"
          }, {
            "text": "No",
            "value": "NO"
          }],
          query: 'exclude-from-retry'
        }
      },
      data: {}
    });

    this.tableInterface.events.addEventListener('dataLoad', () => {
      this.editEnabled = false;
    });
    this.tableInterface.events.addEventListener('dataLoaded', () => {
      setTimeout(() => { this.editEnabled = true; }, 500);
    });

    try {
      await this.tableInterface.initialize();
      this.searchInterface.initialize().then(() => {
        return this.searchInterface.search();
      }).catch(error => { console.log('searchInterface initialization failed', error) });
    } catch (error) {
      console.error('tableInterface initialization failed', error);
    }
  }

  deleteRecord(record) {
    this.dialog.open({
      title: 'Attenzione!',
      class: 'small',
      type: 'confirm',
      body: `Confermi di voler eliminare questo record?`
    }).whenClosed(response => {
      if (!response.wasCancelled) {
        this.api.delete(`mtrax/outbox-messages/${record.id}`).then(() => {
          this.toast.show('Segnalatore eliminato con successo', 'success');
          this.tableInterface.load();
        }).catch(error => {
          console.error(error);
        });
      }
    });
  }

  setExcludeFromRetry(record) {
    if (!this.editEnabled) return;
    let data = record;
    this.api.patch(`mtrax/outbox-messages/${record.id}`, data).then(() => {
      this.tableInterface.load();
    }).catch((error) => {
      console.error(error);
    });
  }
  
  setNotDelivered(record) {
    if (!this.editEnabled) return;
    let data = record;
    data.deliveredAt = null;
    this.api.patch(`mtrax/outbox-messages/${record.id}`, data).then(() => {
      this.tableInterface.load();
    }).catch((error) => {
      console.error(error);
    });
  }
}

export class DaysFormatValueConverter {
  toView(value) {
    if (!value) return value;
    let difference = DateTime.fromISO(value).diffNow('days').days;
    let result = Math.floor(Math.abs(difference));
    return `${result > 0 ? result : '' } ${result === 1 ? 'giorno' : (result === 0 ? 'meno di 24h' : 'giorni')} fa`;
  }
}