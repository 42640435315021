import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { activationStrategy } from 'aurelia-router';
import { KaToast, KaDialog } from 'ka-components';
import { MdUser } from 'services/md-user';

@inject(Router, Api, KaToast, KaDialog, MdUser)
export class ViewAgreements {
  redirect = null;
  agreement = {
    route: PLATFORM.moduleName('views/_agreement/agreement'),
    view: {},
    back: false
  }

  constructor(router, api, toast, dialog, mdUser) {
    this.router = router;
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
    this.mdUser = mdUser;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
    this.init(this.params?.id);
  }

  init(id) {
    if (id && id === 'last') this.agreement.back = true;
    let url = id === 'last' ? 'accepted-user-agreements/last' : 'user-agreements/current';
    this.allClausesAccepted = false;

    // Current agreement
    this.api.get(`users/me/${url}`).then((xhr) => {
      const response = xhr.response;
      this.agreement.view = (response?.constructor === Object && Object.keys(response || '')?.length === 0) ? null : response;
      if (this.agreement.view?.clauses?.length) {
        this.agreement.view.clauses.forEach(clause => { clause.accepted = false; });

        // Clauses already accepted
        this.api.get(`users/me/user-agreements/${this.agreement.view.uuid}/clause-acceptances`).then(xhr => {
          this.acceptances = xhr.response;
          if (this.acceptances?.length) {
            this.agreement.view.clauses.forEach(clause => {
              if (this.acceptances.find(x => x.userAgreementClauseUuid === clause.id)) clause.accepted = true;
            });
          }
        }).catch(error => {
          console.log('Clause acceptances', error);
        });
      }
    }).catch(error => {
      let status = [404, 405, 406, 407, 408, 409, 410];
      if (status.includes(error.statusCode)) {
        this.agreement.view = null;
        this.redirect = localStorage.getItem(`u${this.api.user.id}-eula-href`);
        console.log('checkAgreements - no current agreement', error, `u${this.api.user.id}-eula-href refirect href`, this.redirect);
        this.toast.show('condizioni di servizo accettate', 'success');
      } else {
        console.log('Agreements subscription', error);
        this.toast.show('Sottoscrizione fallita', 'error');
      }
    })
  }

  accept(event) {
    if (!this.acceptances) return
    event.model.busy = true;
    this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'confirm', body: 'Stai accettando le nostre condizioni di servizio. Continua per poter accedere alla piattaforma.'}).whenClosed(response => {
      if (!response.wasCancelled) {
        let promises = [];
        this.agreement.view.clauses.forEach(clause => {
          if (clause.accepted && !this.acceptances.find(x => x.userAgreementClauseUuid === clause.id)) {
            promises.push(this.api.post(`users/me/user-agreements/${this.agreement.view.uuid}/clause-acceptances`, [{ userAgreementClauseUuid: clause.id }]));
          }
        });
        Promise.all(promises).then(() => {
          this.init();
          event.model.busy = false;
          this.mdUser.checkFlow.getCheck('checkAgreements').hasPassed = true;
        }).catch(errors => {
          console.log('Agreements approval promises', errors);
        });
      } else event.model.busy = false;
    });
  }

  areAllClausesAccepted() {
    console.log('agreement.view.clauses', this.agreement);
    setTimeout(() => {
      if (this.agreement.view.clauses.some(clause => clause.accepted === false)) {
        return this.allClausesAccepted = false;
      } else return this.allClausesAccepted = true;
    }, 0);
  }

  cancel() {
    this.router.navigateToRoute('help');
  }
}

