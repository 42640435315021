import { inject } from 'aurelia-framework';
import { Api } from 'services/api';
import {DialogController} from 'aurelia-dialog';

@inject(Api, DialogController)
export class DialogAddResellerTag {
  pendingInit = true;
  resource = {
    controls: {},
    schema: {
      uuid: {
        label: 'Segnalatore', 
        control: 'combo',
        datasource: { 
          table: `bo/resellers?user-status-in=ACTIVE&reseller-status=ACTIVE`,
          query: { search: 'full-text-search', item: 'uuid-in' }
        },
        datavalue: 'uuid',
        datatext: '{firstname} {lastname}',
        datamultiple: false,
        datapreload: false,
        readonly: false,
        required: true
      },
      resellerCode: {
        label: 'Codice', 
        control: 'combo',
        datasource: [],
        datavalue: 'resellerCode', 
        datatext: 'resellerCode',
        datamultiple: false,
        datapreload: false,
        required: true
      },
      rewardedServiceCode: {
        label: 'Servizio da associare',
        control: 'combo',
        datasource: [],
        datavalue: 'code', 
        datatext: 'name', 
        datamultiple: false,
        datapreload: false,
        required: true
      }
    },
    data: {},
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        control.validator.errors = [];
        control.element.classList.remove('error');
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      this.api.dialogError(error, this.resource.controls);
    }
  }
  
  constructor(api, dialogController) {
    this.api = api;
    this.controller = dialogController;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    this.init();
  }

  init() {
    this.getServiceGroups().finally(() => { this.pendingInit = false; });
  }

  getServiceGroups() {
    return this.api.get('resell/service-groups').then(xhr => {
      let serviceGroups = xhr.response;
      this.resource.schema.rewardedServiceCode.datasource = serviceGroups;
    }).catch(error => {
      console.log('ERROR - resell/service-groups - GET', error);
    });
  }

  changeReseller(uuidReseller = this.resource.data.uuid) {
    this.api.get(`bo/resellers/${uuidReseller}`, { filters: 'reseller-status=ACTIVE' }).then(xhr => {
      let codes = [];
      this.reseller = xhr.response;
      this.reseller.resellerCodes.forEach(resellerCode => { codes.push({ resellerCode: resellerCode.code }); });
      this.resource.schema.resellerCode = Object.assign({}, this.resource.schema.resellerCode, { datasource: codes });
    }).catch(error => {
      console.log('ERROR - bo/resellers - GET', error);
    });
  }

  save($event) {
    $event.model.busy = true;
    this.resource.validate().then(() => {
      this.api.post(`bo/prospect-landing-pages/${this.params.referralCode}/reseller-tags`, this.resource.data).then(() => {
        this.controller.ok();
      }).catch((error) => {
        console.log('reseller-tags', error);
        this.resource.error(error);
      }).finally(() => {
        $event.model.busy = false;
      });    
    }).catch(() => {
      $event.model.busy = false;
    });
  }
}
