import { Api } from './api';
import mdt from './md-tools';
import { Router } from 'aurelia-router';
import environment from '../environment';
import { inject } from 'aurelia-framework';
import { RestClient } from './rest-client.ts';
import { KaDialog, KaToast } from 'ka-components';
import { 
  CheckFlow, 
  checkAgreements, 
  checkUserProfileData, 
  checkCashbackContracts,
  checkUserBillingProfile,
  checkSettingsSenderPosta,
  checkCashbackBillableAmount,
  checkSettingsLinkedSoftware
} from '../classes/checkflow/checkflow';

@inject(Router, Api, KaDialog, KaToast)
export class MdUser {
  mdt = mdt;
  get isCashbackHidden () {
    const uuids = [
      '3d33cad3-b0a4-55a7-9ce6-0f4ff8dfd2fd',
      'baf59ac2-b835-11ed-b7f3-02cf161847a7',
      '8672d7c5-b838-11ed-b7f3-02cf161847a7',
      '83f7cb00-bc12-11ed-b7f3-02cf161847a7',
      '550ef0b1-bc14-11ed-b7f3-02cf161847a7',
      'de6edad4-bc15-11ed-b7f3-02cf161847a7',
      '3dd24010-bc18-11ed-b7f3-02cf161847a7',
      '0bac73a2-bc21-11ed-b7f3-02cf161847a7',
      '25695e28-bc29-11ed-b7f3-02cf161847a7',
      '3d909757-bc2b-11ed-b7f3-02cf161847a7',
      '5c0b2574-bc2c-11ed-b7f3-02cf161847a7',
      '4545f84f-bc30-11ed-b7f3-02cf161847a7',
      'ac7f1349-c726-11ed-b7f3-02cf161847a7',
      '5aa3cd08-c72e-11ed-b7f3-02cf161847a7'
    ]
    const userUuid = this.mdApi?.user?.uuid;
    return uuids.includes(userUuid);
 }
  constructor(router, mdApi, kaDialog, kaToast) {
    // Classes
    this.router = router;
    this.mdApi = mdApi;
    this.rest = new RestClient();
    this.dialog = kaDialog;
    this.toast = kaToast;
    // Storage
    this.storage = (environment.APP_STORAGE || localStorage);
    this._data = JSON.parse(this.storage.getItem('user')) || null;
    // CheckFlow
    this.checkFlow = new CheckFlow();
  }

  get data() {
    return JSON.parse(this.storage.getItem('user'));
  }
  set data(value) {
    value ? this.storage.setItem('user', JSON.stringify(value)): this.storage.removeItem('user');
  }

  async initData() {
    const apiResponse = await this.rest.get('users/me?include=user-profiles,user-roles,user-modules');
    const flattenApiResponseData = mdt.parsers.flattenApiResponseData(apiResponse.data);
    this.setUserData(flattenApiResponseData);
    return flattenApiResponseData;
  }

  setUserData(apiResponseData) {
    const user = structuredClone(apiResponseData);
    user.username = user.profile.username;
    user.displayName = user.profile.displayName;
    this.data = user;
  }

  async check(fragment) {
    if (!this.data) {
      console.warn('[md-user] Check bypassed because of missing user data');
      return Promise.resolve({ hasPassed: true });
    }
    if (!this.rest.auth.isAuthenticated) {
      console.warn('[md-user] Check bypassed because user is not authenticated');
      return Promise.resolve({ hasPassed: true });
    }
    if (this.mdApi.isImpersonated) {
      console.warn('[md-user] Check bypassed because user is impersonated');
      return Promise.resolve({ hasPassed: true });
    }
    if (['MASTER_USER', 'BASIC_USER'].includes(this.data.group)) {
      this.checkFlow.addCheck({ isMandatory: true, function: checkAgreements });
    }
    if (['MASTER_USER'].includes(this.data.group) && !this.hasRole('LIST_ANY_USER')) {
      this.checkFlow.addCheck({ isMandatory: true, function: checkUserProfileData });
    }
    if (!this.isCashbackHidden && ['MASTER_USER'].includes(this.data.group) && !this.hasRole('LIST_ANY_USER')) {
      this.checkFlow.addCheck({ isMandatory: false, function: checkCashbackContracts });
    }
    if (!this.isCashbackHidden && ['MASTER_USER'].includes(this.data.group) && !this.hasRole('LIST_ANY_USER')) {
      this.checkFlow.addCheck({ isMandatory: false, function: checkCashbackBillableAmount });
    }
    if (['MASTER_USER'].includes(this.data.group) && !this.hasRole('LIST_ANY_USER')) {
      this.checkFlow.addCheck({ isMandatory: false, function: checkUserBillingProfile });
    }
    if (['MASTER_USER'].includes(this.data.group) && !this.hasRole('LIST_ANY_USER')) {
      this.checkFlow.addCheck({ isMandatory: false, function: checkSettingsLinkedSoftware });
    }
    /* if (['MASTER_USER'].includes(this.data.group) && this.data.profile.businessType === 'BUILDING-ADMINISTRATOR' && this.hasRole('LIST_CLIENTS')) {
      this.checkFlow.addCheck({ isMandatory: false, function: checkSettingsSenderPosta });
    } */

    const result = await this.checkFlow.run(this, fragment);
    return result;
  }

  setData(attribute, value) {
    this._data[attribute] = value;
    this.data = this._data;
  }

  removeData(attribute) {
    delete this._data[attribute];
    this.data = this._data;
  }

  hasRole(roles) {
    if (!this.data.roles) return null;
    if (!Array.isArray(roles)) roles = [roles];
    return this.data.roles?.some(e => roles.includes(e.id));
  }
}
