import { Api } from 'services/api';
import mdt from 'services/md-tools';
import { Router } from 'aurelia-router';
import { inject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { KaToast, KaDialog } from 'ka-components';
import { TableInterface, SearchInterface } from 'classes/md-table';

@inject(Router, Api, KaToast, KaDialog, DialogService)
export class ViewSent {
  mdt = mdt;
  statuses;
  channels;
  userData;
  loadingId;
  deadlineEvent;
  statusMessages;
  constructor(router, api, toast, dialog, dialogService) {
    this.router = router;
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
    this.dialogService = dialogService;
  }

  activate(model) {
    this.tabs = model.tabs;
    this.params = model.params;
    this.tools = model.tools;
    this.privileges = model.privileges;
    this.statusMessages = this.params.queueProperties.statusMessages;
    this.statuses = this.params.queueProperties.statuses;
    this.channels = this.params.queueProperties.channels;
    this.deadlineEvent = this.params.queueProperties.deadlineEvent;
  }

  attached() {
    this.tableInterface = new TableInterface({
      name: 'table-sent-queues',
      client: this.api,
      endpoint: null,
      query: 'status-in=COMPLETED,PENDING',
      isLoading: false
    });

    this.tableInterface.initialize();

    this.searchInterface = new SearchInterface({
      name: 'search-sent-queues',
      table: this.tableInterface,
      schema: {
        context: {
          label: 'Quali spedizioni visualizzare?',
          control: 'combo',
          datavalue: 'value',
          datatext: 'text',
          datasource: this.tools.setContextDatasource(this.api.user),
          required: true,
        },
        owner: {
          control: 'combo',
          label: mdt.parsers.contextualize(this.api.user, 'Utente'),
          datavalue: 'id',
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false,
          datasource: {
            table: `${this.privileges.isOperator ? 'bo/users' : 'users/me/users'}?include=user-profiles&status=ACTIVE`,
            query: { search: 'text-search', item: 'id-in' }
          }
        },
        clients: {
          control: 'combo',
          label: 'Gestionale utilizzato',
          datavalue: 'id',
          datatext: 'name',
          datamultiple: false,
          datapreload: true,
          datasource: this.params.clients,
          query: 'client-uuid'
        },
        channel: {
          control: 'combo',
          label: 'Canale d\'invio',
          datavalue: 'value',
          datatext: 'text',
          datamultiple: false,
          datapreload: true,
          datasource: this.tools.setChannelDatasource(),
          query: 'channel'
        },
        createdAtGte: {
          label: 'Spedizione creata dal',
          control: 'date',
          query: 'created-at-gte'
        },
        createdAtLte: {
          label: 'al',
          control: 'date',
          query: 'created-at-lte'
        },
        totalGrossGte: {
          label: 'Importo minimo',
          control: 'number',
          query: 'total-gross-gte'
        },
        totalGrossLte: {
          label: 'Importo massimo',
          control: 'number',
          query: 'total-gross-lte'
        },
        recipient: {
          label: 'Destinatario',
          control: 'text',
          query: 'recipient-text-search-like'
        },
        shipment: {
          label: 'Spedizione',
          control: 'text',
          query: 'text-search-like'
        },
        paymentMethod: {
          label: 'Metodo di pagamento',
          control: 'combo',
          datavalue: 'value',
          datatext: 'text',
          datamultiple: false,
          datapreload: false,
          datasource: [
            { value: 'null', text: 'Qualsiasi' },
            { value: 'SDD', text: 'SDD' },
            { value: 'BANK-TRANSFER', text: 'Bonifico bancario' },
          ],
          query: 'payment-method'
        },
        shortConfirmationCode: {
          label: 'Codice di conferma pagamento',
          control: 'text',
          query: 'short-confirmation-code'
        },
        status: {
          label: 'Stato',
          control: 'combo',
          datavalue: 'value',
          datatext: 'text',
          datamultiple: false,
          datapreload: false,
          datasource: this.tools.setStatusDatasource(['COMPLETED', 'PENDING']),
          query: 'status-in'
        },
      },
      data: {
        status: 'null',
        totalGrossGte: 0,
        paymentMethod: 'null',
        context: this.api.user.group === 'MASTER_USER' ? 'USER&SUBUSERS' : 'USER'
      }
    });

    // setDefaultDateRange(var) -> var is the amount of days to substract if needed
    this.searchInterface.data = {
      ...this.searchInterface.data,
      ...(this.privileges.isOperator && { createdAtGte: this.tools.setDefaultDateRange(60) }),
      ...(this.privileges.isOperator && { createdAtLte: this.tools.setDefaultDateRange() }),
    }

    this.tableInterface.parseResponse = (response) => {
      return this.tools.parseMessagesStatusCount(response);
    };

    this.searchInterface.initialize().then(() => { return this.search(); });
  }

  search() {
    this.setEndpoint();

    return this.searchInterface.search().then(() => {
      if (!this.api.hasRole('LIST_ANY_QUEUE')) return this.updateWaitingQueuesStorage();
    });
  }

  updateWaitingQueuesStorage() {
    return this.api.get(`users/me/queue-outlines/~all?status=ACCEPTANCE`).then((xhr) => {
      return localStorage.setItem('queue-outlines', JSON.stringify({ totalQueuesPendingPayment: xhr.originalResponse.meta.total }));
    });
  }

  setContextSuffix() {
    let contextSuffix = '';

    if (this.searchInterface?.data?.context === 'USER') {
      contextSuffix = '';
    } else if (this.searchInterface?.data?.context === 'SUBUSERS') {
      switch (true) {
        case this.privileges.isOperator:
          contextSuffix = '~subs';
          break;
        case !this.searchInterface.data.owner:
          contextSuffix = '~subs';
          break;
        case this.searchInterface.data.owner:
          contextSuffix = '';
          break;
      }
    } else {
      contextSuffix = '~all';
    }

    if (contextSuffix.length) {
      contextSuffix = `/${contextSuffix}`;
    }

    return contextSuffix;
  }

  setEndpoint() {
    const contextSuffix = this.setContextSuffix();

    this.searchInterface.data.owner = this.searchInterface?.data?.context !== 'SUBUSERS' && !this.privileges.isOperator ? null : this.searchInterface.data.owner;
    let endpoint = this.privileges.isOperator && !this.searchInterface.data.owner ? 'bo/queue-outlines' : `users/${this.searchInterface.data.owner || 'me'}/queue-outlines${contextSuffix}`;
    return this.tableInterface.endpoint = endpoint;
  }

  reset() {
    this.searchInterface.reset();
    this.tools.setDefault(this.searchInterface, [{ paymentMethod: 'null' }, { totalGrossGte: 0 }, { status: 'null' }, { context: null }]);
    this.search();
  }

  vmArchiveReload() {
    let vmArchived = this.tabs.find(x => x.id === 'archiviate').controller.currentViewModel;
    return vmArchived.search();
  }

  archive(queue) {
    let data = { directory: "ARCHIVE" };
    this.api.patch(`users/me/queues/${queue.uuid}`, data).then(() => {
      this.search();
      this.vmArchiveReload();
      this.toast.show('Spedizione archiviata con successo', 'success');
    }).catch(error => {
      console.log('Error queues/ PATCH', error);
      this.api.dialogError(error);
    })
  }

  goToDetail(queue) {
    return this.router.navigate(`spedizioni/reportistica/${queue.owner.id}/${queue.uuid}`);
  }

  goToQueue(queue) {
    if (queue.primaryQueueUuid) {
      return this.router.navigate(`spedizioni/reportistica/${queue.owner.id}/${queue.primaryQueueUuid}`);
    } else if (queue.secondaryQueueUuid) {
      return this.router.navigate(`spedizioni/reportistica/${queue.owner.id}/${queue.secondaryQueueUuid}`);
    }
  }

  setContext() {
    if (this.privileges.isOperator) {
      this.searchInterface.data.context = null;
      if (this.searchInterface.data.owner) {
        this.tools.getUserData(this.searchInterface.data.owner).then((xhr) => {
          const response = xhr.response;
          response.profile = response.profile[0];
          this.userData = response;
          if (this.userData.parentId) {
            this.searchInterface.data.context = null;
          } else {
            this.searchInterface.schema.context.datasource = this.tools.setContextDatasource(this.userData);
          }
        });
      } else {
        this.searchInterface.data.context = null;
        this.userData = null;
      }
    }
  }

  updateQueueMessages(record) {
    if (!record.loading) {
      record.loading = true;
      this.api.client.createRequest(`users/${this.searchInterface.data.owner || 'me'}/queue-outlines/${record.uuid}`)
        .asGet()
        .withHeader('Prefer', 'Refresh')
        .send()
        .then((xhr) => {
          const response = xhr.response;

          Object.assign(record, response);
          this.tools.parseMessagesStatusCount(this.tableInterface.data);
          this.toast.consume();
          this.toast.show('Stato spedizione aggiornato!', 'success');
        }).catch((error) => {
          console.log('GET queue-outlines ', error);
          this.toast.consume();
          this.toast.show('Errore aggiornamento stato spedizione!', 'error');
        }).finally(() => {
          record.loading = false;
        });
    }
  }
}

