import { inject } from 'aurelia-framework';
import { Api } from 'services/api';
import { KaToast, KaDialog } from 'ka-components';

@inject(Api, KaToast, KaDialog)
export class RevisioneAbiCab {
  endpoint = 'bo/unknown-iban-coordinates-it/current';
  editEnabled = false;
  priceLists = null;
  resource = {
    controls: {},
    schema: {
      abiCode: {
        label: 'ABI',
        control: 'text',
        readonly: true
      },
      cabCode: {
        label: 'CAB',
        control: 'text',
        readonly: true
      },
      instituteName: {
        label: 'Istituto',
        control: 'text'
      },
      officeName: {
        label: 'Nome ufficio',
        control: 'text'
      },
      countryCode: {
        label: 'Nazione',
        control: 'combo',
        datatext: 'name',
        datavalue: 'code',
        datamultiple: false,
        datapreload: false,
        datasource: { table: `geo/countries`, query: { search: 'text-search', item: 'code'} },
        query: 'country-code-in'
      },
      admLvl1: {
        label: 'Regione',
        control: 'combo',
        datatext: 'endonym',
        datavalue: 'uuid',
        datamultiple: false,
        datapreload: false,
        datasource: { table: null, query: { search: 'text-search', item: 'uuid'} },
        query: 'adm-level-1-uuid-in'
      },
      admLvl2: {
        label: 'Provincia',
        control: 'combo',
        datatext: 'endonym',
        datavalue: 'uuid',
        datamultiple: false,
        datapreload: false,
        datasource: { table: null, query: { search: 'text-search', item: 'uuid'} },
        query: 'adm-level-2-uuid-in'
      },
      admLvl3: {
        label: 'Comune',
        control: 'combo',
        datatext: 'endonym',
        datavalue: 'uuid',
        datamultiple: false,
        datapreload: false,
        datasource: { table: null, query: { search: 'text-search', item: 'uuid'} },
        query: 'adm-level-3-uuid-in'
      },
      streetAddress: {
        label: 'Indirizzo',
        control: 'text'
      },
      zipCode: {
        label: 'Cap',
        control: 'text'
      },
    },
    data: {},
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        control.validator.errors = [];
        control.element.classList.remove('error');
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      this.api.dialogError(error, this.resource.controls);
    }
  }

  constructor(api, toast, dialog) {
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
  }

  attached() {
    this.loadData();
  }

  loadData() {
    return this.api.get(this.endpoint).then((xhr) => {
      this.resource.data = xhr.response;
      this.resource.data.countryCode = 'IT';
    });
  }

  initGeo(type, init = false) {
    if (type === 'countryCode') {
      let url = `geo/countries/${this.resource.data.countryCode}/levels/1/divisions`;
      this.resource.controls.admLvl1.control.schema.datasource.table = url;
      if (this.resource.controls[type].control._initialized) this.resource.data.admLvl1 = null;
    } else if (type === 'admLvl1') {
      let url = `geo/countries/${this.resource.data.countryCode}/levels/2/divisions?parent-uuid=${this.resource.data.admLvl1}`;
      this.resource.controls.admLvl2.control.schema.datasource.table = url;
      if (this.resource.controls[type].control._initialized) this.resource.data.admLvl2 = null;
    } else if (type === 'admLvl2') {
      let url = `geo/countries/${this.resource.data.countryCode}/levels/3/divisions?parent-uuid=${this.resource.data.admLvl2}`;
      this.resource.controls.admLvl3.control.schema.datasource.table = url;
      if (this.resource.controls[type].control._initialized) this.resource.data.admLvl3 = null;
    }
    this.resource.controls[type].control._initialized = true;
  }

  reset() {
    const controls = this.resource.controls;
    for (const controlKey in controls) {
      const control = controls[controlKey];
      if (!['countryCode', 'abiCode', 'cabCode'].includes(control.name)) control.clear();
    }
  }

  save() {
    if (this.pendingSave) return;
    this.pendingSave = true;
    const data = {
      abiCode: this.resource.data.abiCode,
      cabCode: this.resource.data.cabCode,
      instituteName: this.resource.data.instituteName,
      officeName: this.resource.data.officeName,
      countryCode: this.resource.data.countryCode,
      admLvl2: (this.resource.controls.admLvl2?.control?.valueModel?.endonym) || null,
      admLvl3: (this.resource.controls.admLvl3?.control?.valueModel?.endonym) || null,
      streetAddress: (this.resource.data?.streetAddress) || null,
      zipCode: (this.resource.data?.zipCode) || null
    };

    this.resource.validate().then(() => {
      this.api.post(`bo/bank-offices`, data).then(() => {
        this.toast.show('Dati salvati con successo!', 'success');
        this.loadData().then(() => {
          this.reset();
        });
      }).catch(error => {
        console.error(error);
        this.resource.error(error);
      }).finally(() => {
        this.pendingSave = false;
      })
    }).catch(() => {
      this.pendingSave = false;
    });
  }
}