class MdTools {
  preferences = {
    mergeModelData: (model, context, response) => {
      let data = {};
      for (let [k, v] of Object.entries(model)) {
        let requested = eval(`response.requested?.${context.join('?.')}?.${k} ?? null`);
        let current = eval(`response.current?.${context.join('?.')}?.${k} ?? null`);
        let parent = eval(`response.parent?.${context.join('?.')}?.${k} ?? null`);
        let preset = eval(`response.preset?.${context.join('?.')}?.${k} ?? null`);
        let responseValue = requested ?? current ?? parent ?? preset ?? null;
        if (responseValue === null) {
          //continue;
          data[k] = null;
        } else if (this.helpers.isObject(v) && !v.control) {
          data[k] = this.preferences.mergeModelData(v, context.concat([k]), response);
        } else {
          data[k] = responseValue;
        }
      }
      return data;
    }
  };
  helpers = {
    isObject: (object) => {
      return object instanceof Object && object.constructor === Object;
    },
    diffObject: (object1, object2) => {
      /*
      object1 = original data
      object2 = modified data
      */
      let data = {};
      for (let [k, v] of Object.entries(object1)) {
        if (typeof object2[k] === 'undefined') continue;
        if (this.helpers.isObject(v)) {
          let value = this.helpers.diffObject(v, object2[k]);
          if (value) data[k] = value;
        } else if (v !== object2[k]) {
          data[k] = object2[k];
        }
      }
      return Object.entries(data).length ? data : null;
    },
    removeEmptyAttributes: (object) => {
      let data = {};
      for (let [k, v] of Object.entries(object)) {
        if (typeof v === 'undefined') continue;
        if (v === null) continue;
        if (v === '') continue;
        if (this.helpers.isObject(v)) {
          let value = this.helpers.removeEmptyAttributes(v);
          if (value && Object.entries(value).length) data[k] = value;
        } else {
          data[k] = v;
        }
      }
      return Object.entries(data).length ? data : null;
    },
    nullifyEmptyStringAttributes: (object) => {
      let data = {};
      for (let [k, v] of Object.entries(object)) {
        if (v === '') v = null;
        if (this.helpers.isObject(v)) {
          let value = this.helpers.nullifyEmptyStringAttributes(v);
          if (value && Object.entries(value).length) data[k] = value;
        } else {
          data[k] = v;
        }
      }
      return Object.entries(data).length ? data : null;
    }
  };
  parsers = {
    flattenApiResponseData: (originalApiResponseData) => {
      const apiResponseDataRecords = [].concat(structuredClone(originalApiResponseData.data));
      apiResponseDataRecords.forEach((record) => {
  
        // Parse relationships
        Object.entries(record.relationships || {}).forEach(([key, value]) => {
          const parseRelationship = (relationshipData) => {
            return originalApiResponseData.included?.find((x) => x.type === relationshipData.type && x['id' || 'uuid'] === relationshipData['id' || 'uuid']);
          }
          [].concat(value).forEach((relationship) => {
            if (!record.attributes[key]) record.attributes[key] = [];
            let parsedRelationship = parseRelationship(relationship?.data);
            if (parsedRelationship) {
              Object.assign(parsedRelationship, parsedRelationship.attributes);
              delete(parsedRelationship.attributes);
              record.attributes[key].push(parsedRelationship);
            }
          });
          if (!Array.isArray(value)) {
            record.attributes[key] = record.attributes[key][0];
          }
        });

        // Flatten
        record = Object.assign(record, record.attributes);
        delete(record.attributes);
      });

      return Array.isArray(originalApiResponseData.data) ? apiResponseDataRecords : apiResponseDataRecords[0];
    },
    apiError: (xhr, controls = {}, unlabelParameters) => {
      let response;
      try { response = JSON.parse(xhr.response); } catch { return; }
      let errorMessages = [];
      let errorBody = '';
      let errorCode = String(response.status);
      if (errorCode.indexOf('40') === 0 && response.source) {
        if (response.source.parameters) {
          for (let parameter of response.source.parameters) {
            let field = parameter.parameter?.replace(/(data\.attributes|queryString)\./, '') || null;
            let control = controls[field] || Object.values(controls).find(x => x.schema?.query === field) || null;
            if (field && control) {
              for (let message of parameter.messages) {
                console.log('errore su campo', field, control);
                if (control.setError) control.setError(message); // ka-control
                else if (control.validator) { // ka-input
                  control.validator.errors.push(message);
                  control.element.classList.add('error');
                }
              }
            } else if (parameter.messages && parameter.messages.length) {
              for (let message of parameter.messages) {
                let p = field || parameter.parameter;
                p = p === 'data' ? null : p;
                errorMessages.push({code: parameter.code, parameter: p, message});
              }
            }
          }
        } else if (response.source.permissions) {
          for (let permission of response.source.permissions) {
            if (permission.messages && permission.messages.length) {
              for (let message of permission.messages) {
                errorMessages.push({code: permission.code, message});
              }
            }
          }
        }
      } else if (response.detail) {
        errorMessages.push({code: null, message: response.detail});
      } else {
        errorMessages.push({code: null, message: 'Si è verificato un errore nella procedura!'});
      }
      if (errorMessages.length === 1 && unlabelParameters && Array.isArray(unlabelParameters)) {
        errorBody = `${errorMessages[0].parameter && !unlabelParameters.includes(errorMessages[0].parameter) ? `<strong>${errorMessages[0].parameter}</strong>: ` : ''}${errorMessages[0].message}`;
      } else if (errorMessages.length) {
        errorMessages.forEach((error, index) => {
          errorMessages[index] = `<li style="padding-top: 8px;padding-bottom: 8px;border-bottom: 1px solid #f0f0f0"><!--<a href="http://www.netbuilder.it/rest-help/MULTICAF/${error.code}" target="_blank" style="cursor:help">-->${error.parameter ? `<strong>${error.parameter}</strong>: ` : ''}${error.message}<!--</a>--></li>`;
        });
        errorBody = '<ol>' + errorMessages.join('') + '</ol>';
      }
      if (errorBody.length) return { code: errorCode, title: (errorCode === '403' ? 'È necessario avere i seguenti permessi per procedere con l\'operazione' : 'Si sono verificati i seguenti errori'), body: errorBody };
    },
    contextualize: (user, value) => {
      if (user?.profile?.businessType === 'BUILDING-ADMINISTRATOR') {
        value = this.dictionary[value] || value;
      }
      if (user?.profile?.businessType === 'BUILDING') {
        value = this.dictionary[value] || value;
      }
      return value;
    }
  };
  dictionary = {
    'Utente': 'Condominio',
    'utente': 'condominio',
    'Sottoutente': 'Condominio',
    'sottoutente': 'condominio',
    'del sottoutente': 'del condominio',
    'all\'utente': 'al condominio',
    'sull\'utente': 'sul condominio',
    'dell\'utente': 'del condominio',
    'Utenti': 'Condomìni',
    'utenti': 'condomìni',
    'Account': 'Studio',
    'account': 'studio',
    'all\'account': 'allo studio',
    'sull\'account': 'sullo studio',
    'dell\'account': 'dello studio',
  };
}
export default new MdTools();
