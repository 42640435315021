import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import {DialogController} from 'aurelia-dialog';

@inject(Api, KaDialog, KaToast, DialogController)
export class UtentiDialogDocumentiApproval {
  schema = {
    status: {
      label: 'Stato documento',
      control: 'combo',
      datasource: [{ value: 'APPROVED', text: 'Approva' }, { value: 'REFUSED', text: 'Rifiuta' }],
      datavalue: 'value',
      datatext: 'text',
      datapreload: true,
      datamultiple: false,
      required: true
    },
    statusReason: {
      label: 'Motivazione',
      control: 'text'
    }
  };
  data = {};

  constructor(api, dialog, toast, dialogController) {
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
    this.controller = dialogController;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.userId = params.userId || null;
    this.document = params.document;
    this.type = params.type;
    this.data = { status: this.document.approvedAt ? 'REFUSED' : 'APPROVED' };
  }

  save($event, data) {
    if (!data) return
    if (data.status === 'REFUSED') {
      this.dialog.open({ 
        title: 'Attenzione!', 
        class: 'small', 
        type: 'confirm',
        body: `<strong>Questa azione è irreversibile.</strong><br>Confermi comunque di voler procedere?`}).whenClosed(response => {
        if (!response.wasCancelled) this.editStatus($event, this.userId || this.document.userId, this.document, data);
      });
    } else {
      this.editStatus($event, this.userId || this.document.userId, this.document, data);
    }
  }

  editStatus($event, userId, document, data) {
    $event.model.busy = true;
    this.api.patch(`users/${userId}/certifiable-document-buckets/current/certifiable-documents/${document.uuid}`, data).then(() => {
      this.controller.ok(data.status);
    }).catch(error => {
      console.log('ERROR - certifiable-document-buckets - PATCH', error);
      this.toast.show(`Errore!. Documento ${data.status === 'APPROVED' ? 'non approvato' : 'non rifiutato'}`, 'error');
      this.api.dialogError(error);
    }).finally(() => {
      $event.model.busy = false;
    });
  }
}
