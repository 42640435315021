import { inject } from 'aurelia-framework';
import { Api } from 'services/api';
import { DialogController } from 'aurelia-dialog';

@inject(Api, DialogController)
export class DialogSponsorAgreement {
  pendingSave;
  resource = {
    controls: {},
    schema: {
      agreementText: {
        label: 'Inserisci il testo dell\'email.',
        required: true,
        control: 'editor'
      }
    },
    data: {}
  }
  
  constructor(api, dialogController) {
    this.api = api;
    this.controller = dialogController;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    this.loadAgreementText();
  }

  loadAgreementText() {
    this.params.agreementText ? this.resource.data.agreementText = this.params.agreementText : null;
  }

  save() {
    this.pendingSave = true;
    this.api.patch(`bo/prospect-landing-pages/${this.params.referralCode}`, this.resource.data).then(() => {
       this.controller.ok();
    }).catch((error) => {
       console.log('Prospect-landing-pages - PATCH', error);
       this.api.dialogError(error);
    }).finally(() => {
      this.pendingSave = false;
    });
  }
}
