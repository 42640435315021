import { Api } from 'services/api';
import mdt from 'services/md-tools';
import { Router } from 'aurelia-router';
import { inject } from 'aurelia-framework';
import { KaDialog, KaToast } from 'ka-components';
import { TableInterface, SearchInterface } from 'classes/md-table';

@inject(Api, Router, KaDialog, KaToast)
export class tabMovements {
  transactionDisabledDate = new Date('2025-03-01T00:00:01'); // this date MUST be instantiated like new Date('2026-12-03T03:24:00')
  inhibitionCashbackMessage = `<div class="inhibition-cashback"><p><strong>Grazie per la tua fedeltà!</strong><p/><p>Abbiamo tante novità per te e per questo motivo stiamo aggiornando la tua area cashback.</p><p>A breve sarà nuovamente possibile richiedere il tuo premio e molto altro!<p/><p> Non preoccuparti se in questa fase vedi il tuo saldo non disponibile, è assolutamente normale!<p/><p> Durante questa fase temporanea, infatti, continuerai comunque a maturare il tuo cashback, come di consueto.<p/><p> Al termine dell'aggiornamento visualizzerai il tuo saldo corretto.<p/><p> Multidialogo ti informerà prontamente, appena gli aggiornamenti saranno terminati.</p><p> Grazie per la collaborazione!<p/></div>`;
  metaResponse;
  lastAcceptedFlatServiceContract;
  lastAcceptedMultinvioVolumesContract;
  sourceTypeSchema = {
    'IN': {
      'text': 'Entrata',
      'values': {
        'HISTORY': 'Storicizzato',
        'QUEUE': 'Spedizione MultInvio',
        'FLAT-SUBSCRIPTION-MULTICAF': 'Abbonamento MultiCaf',
        'FLAT-SUBSCRIPTION-MULTINVIO': 'Abbonamento MultInvio',
        'FLAT-SUBSCRIPTION-MULTIFATTURE': 'Abbonamento MultiFatture',
        'OPERATOR': 'Intervento operatore',
      }
    },
    'OUT': {
      'text': 'Uscita',
      'values': {
        'HISTORY': 'Storiczzato',
        'TRANSFER-WITHDRAWAL': 'Prelievo',
        'TRANSFER-CREDIT': 'Trasferimento',
        'TRANSFER-VOUCHER': 'Trasferimento voucher BrainWare',
        'OPERATOR': 'Intervento operatore'
      }
    }
  }

  constructor(api, router, kaDialog, kaToast) {
    this.api = api;
    this.toast = kaToast;
    this.router = router;
    this.dialog = kaDialog;
  }

  activate(model) {
    this.params = model.params;
    this.parent = model.$parent;
    this.privileges = model.privileges;
    this.tools = model.tools;
  }

  attached() {
    this.initTab();
    this.controllerTabRequests = this.parent.tabs.find((tab) => tab.id === 'requests').controller
  }

  setEndpoint(userId) {
    const endpoint = `users/${userId || 'me'}/cashback-movements`;
    return endpoint;
  }

  setDirectionDatasource() {
    const datasource = [{
      value: 'null',
      text: 'Tutti'
    }];
    Object.entries(this.sourceTypeSchema).forEach(([key, value]) => {
      datasource.push({ value: key, text: value.text });
    });
    return datasource
  }

  setSourceTypeDatasource() {
    let datasource = [];
    const schema = structuredClone(this.sourceTypeSchema);
    const direction = this.searchInterface?.data?.direction || null;
    const irregularTypes = [
      'OPERATOR',
      'FLAT-SUBSCRIPTION-MULTICAF',
      'FLAT-SUBSCRIPTION-MULTINVIO',
      'FLAT-SUBSCRIPTION-MULTIFATTURE'
    ];

    delete schema.IN.values.HISTORY;
    delete schema.OUT.values.HISTORY;

    if (!direction || direction === 'null') {
      ['IN', 'OUT'].forEach((direction) => {
        datasource.push({ value: direction, text: schema[direction].text, optgroup: true });
        Object.entries(schema[direction].values).forEach(([key, value]) => {
          if (irregularTypes.includes(key)) {
            datasource.push({ value: `${key}-${direction}`, text: value });
          } else {
            datasource.push({ value: key, text: value });
          }
        });
      });
    } else {
      Object.entries(schema[direction].values).forEach(([key, value]) => {
        if (irregularTypes.includes(key)) {
          datasource.push({ value: `${key}-${direction}`, text: value });
        } else {
          datasource.push({ value: key, text: value });
        }
      });
    }

    return datasource;
  }

  changeDirection() {
    this.searchInterface.data.sourceType = null;
    this.searchInterface.schema.sourceType = {
      ...this.searchInterface.schema.sourceType,
      datasource: this.setSourceTypeDatasource()
    }
  }

  async initTableInterface() {
    this.tableInterface = new TableInterface({
      client: this.api,
      name: 'table-cashback-movements',
      endpoint: this.setEndpoint()
    });

    return this.tableInterface.initialize();
  }

  async initSearchInterface() {
    let sourceUser = {};
    const sourceUuids = {
      control: 'combo',
      label: 'Generati da',
      datasource: {
        table: 'users/me/users?include=user-profiles',
        query: { search: 'text-search', item: 'uuid-in' }
      },
      datavalue: 'uuid',
      datamultiple: true,
      datapreload: false,
      query: 'source-user-uuid-in',
      datatext: 'profile[0].displayName'
    };

    this.searchInterface = new SearchInterface({
      name: 'table-cashback-movements-search',
      table: this.tableInterface,
      schema: {
        createdAtGte: {
          control: 'date',
          label: 'Inserito dal',
          query: 'created-at-gte'
        },
        createdAtLte: {
          label: 'al',
          control: 'date',
          query: 'created-at-lte'
        },
        direction: {
          datatext: 'text',
          control: 'combo',
          datapreload: true,
          label: 'Tipologia',
          datavalue: 'value',
          query: 'direction',
          datamultiple: false,
          datasource: this.setDirectionDatasource(),
        },
        sourceType: {
          datatext: 'text',
          control: 'combo',
          datapreload: true,
          datavalue: 'value',
          datamultiple: true,
          query: 'source-in',
          datasource: this.setSourceTypeDatasource(),
          label: 'Mostra solo le seguenti operazioni',
        }
      },
      data: {
        direction: 'null',
        sourceUser: !this.privileges.isOperator ? true : ''
      }
    });

    if (this.privileges.isOperator) {
      sourceUser = {
        required: true,
        label: 'Utente',
        datavalue: 'id',
        control: 'combo',
        datapreload: false,
        datamultiple: false,
        datatext: 'profile[0].displayName',
        datasource: { table: 'bo/users?include=user-profiles&status=ACTIVE&user-group-in=MASTER_USER', query: { search: 'text-search', item: 'id-in' } }
      }
    } else {
      sourceUser = {
        control: 'check',
        label: 'Includi generati dal',
        query: 'source-user-not-me',
        description: `Mio ${mdt.parsers.contextualize(this.api.user, 'account')}`
      }
    }

    this.searchInterface.schema = {
      sourceUser,
      ...this.searchInterface.schema,
      ...(!this.privileges.isOperator && { sourceUuids })
    }

    return this.searchInterface.initialize();
  }

  async initTab() {
    const isOperator = this.privileges.isOperator;
    let promises = [this.initTableInterface(), this.initSearchInterface()];
    if (this.api.hasRole('LIST_USER_CASHBACK_CONTRACTS') && !isOperator) {
      promises.push(this.getLastAcceptedFlatServiceContract(), this.getLastlastAcceptedMultinvioVolumesContract());
    }
    Promise.allSettled(promises).then((xhrs) => {
      xhrs.forEach((xhr) => {
        if (xhr.status === 'rejected' && xhr.reason.statusCode !== 404) {
          throw xhr.reason;
        }
      });
      if (!isOperator) this.search();
      if (isOperator && this.searchInterface.data.sourceUser) this.search();
    }).catch((error) => {
      console.log('initTab', error);
      this.toast.show('Errore al caricamento della pagina!', 'error');
    });
  }

  getLastAcceptedFlatServiceContract() {
    return this.api.get('users/me/cashback-contracts/last-accepted-flat-service').then((xhr) => {
      const response = xhr.response;
      this.lastAcceptedFlatServiceContract = response;
    });
  }

  getLastlastAcceptedMultinvioVolumesContract() {
    return this.api.get('users/me/cashback-contracts/last-accepted-multinvio-volumes').then((xhr) => {
      const response = xhr.response;
      this.lastAcceptedMultinvioVolumesContract = response;
    });
  }

  async search() {
    const data = structuredClone(this.searchInterface.data);

    if (this.privileges.isOperator) {
      this.tableInterface.endpoint = this.setEndpoint(data.sourceUser);
    } else {
      data.sourceUser = data.sourceUser ? 'NO' : 'YES';
      if (data.sourceUuids?.length && data.sourceUser === 'NO') {
        data.sourceUuids.push(this.api.user.uuid);
      }
    }

    const tableInterface = await this.searchInterface.search(data);
    this.metaResponse = tableInterface?.originalResponse?.meta;
  }

  async reset() {
    await this.searchInterface.reset(true);
    this.searchInterface.data.direction = 'null';
    this.search();
  }

  isTransactionDisabledUntilDate(disabledUntilDate) {
    let isDisabled = false;
    if (disabledUntilDate) {
      const currentDate = new Date();
      isDisabled = currentDate < disabledUntilDate.getTime() ? true : false;
    }
    return isDisabled;
  }

  isTransactionDisabledAfterDate(disabledStartDate) {
    let isDisabled = false;
    if (disabledStartDate) {
      const currentDate = new Date();
      isDisabled = currentDate > disabledStartDate.getTime() ? true : false;
    }
    return isDisabled;
  }

  getCashbackTransferEligibilityCheck($event, scope) {
    if (this.isTransactionDisabledAfterDate(this.transactionDisabledDate)) {
      this.openTemporaryDialog(this.inhibitionCashbackMessage);
    } else {
      $event.model.busy = true;
      this.api.get(`users/me/cashback-transfer-eligibility-check?scope=${scope}`).then(() => {
        if (scope === 'TRANSFER-CREDIT') return this.dialogCreditTransfer();
        if (scope === 'TRANSFER-VOUCHER') return this.dialogTransferVoucher();
        if (scope === 'TRANSFER-WITHDRAWAL') return this.dialogWithdrawalRequest();
      }).catch((xhr) => {
        console.log('Get cashback-transfer-eligibility-check', xhr);
        return this.api.dialogError(xhr);
      }).finally(() => {
        $event.model.busy = false;
      });
    }
  }

  openTemporaryDialog(bodyMessage) {
    this.dialog.open({
      title: 'Attenzione',
      class: 'medium',
      type: 'alert',
      body: bodyMessage,
    });
  }

  dialogWithdrawalRequest() {
    return this.dialog.open({
      class: 'medium withdrawal-request',
      title: 'Preleva saldo fatturabile',
      viewModel: PLATFORM.moduleName('views/cashback/transactions/dialogs/withdrawal-request'),
      viewModelParams: {
        billableAmount: this.metaResponse.billableAmount
      }
    }).whenClosed((response) => {
      if (!response.wasCancelled) {
        this.search();
        this.controllerTabRequests.search();
      }
    });
  }

  dialogCreditTransfer() {
    return this.dialog.open({
      class: 'small credit-transfer',
      title: 'Trasferisci sul mio credito servizio',
      viewModel: PLATFORM.moduleName('views/cashback/transactions/dialogs/credit-transfer')
    }).whenClosed((response) => {
      if (!response.wasCancelled) {
        this.search();
      }
    });
  }

  dialogTransferVoucher() {
    return this.dialog.open({
      class: 'small transfer-voucher',
      title: 'Aquista voucher BrainWare',
      viewModel: PLATFORM.moduleName('views/cashback/transactions/dialogs/transfer-voucher')
    }).whenClosed((response) => {
      if (!response.wasCancelled) {
        this.search();
      }
    });
  }

  dialogInsertMovement() {
    const userId = this.searchInterface.data.sourceUser || null;
    return this.dialog.open({
      class: 'small insert-movement',
      title: 'Inserisci movimento',
      viewModelParams: { userId },
      viewModel: PLATFORM.moduleName('views/cashback/transactions/dialogs/insert-movement')
    }).whenClosed((response) => {
      if (!response.wasCancelled) {
        this.search();
      }
    });
  }

  dialogServiceDetail(record) {
    return this.dialog.open({
      class: 'large abbonamenti-detail',
      title: 'Dettaglio abbonamento',
      viewModel: PLATFORM.moduleName('views/servizi/abbonamenti/dialogs/abbonamenti-detail'),
      viewModelParams: { ownerId: record.sourceUserId, flatServiceUuid: record.sourceFlatServiceSubscriptionUuid }
    });
  }

  navigateToQueueDetail(record) {
    return this.router.navigate(`spedizioni/reportistica/${record.sourceUserId}/${record.sourceQueueUuid}`);
  }

  navigateToLegacyReport(record) {
    this.toast.show('Caricamento in corso', 'loading', true);
    return this.api.get(`users/me/cross-login-tokens/current`).then((xhr) => {
      let token = xhr.response.token;
      window.open(`${ENVIRONMENT.APP_URL_LEGACY}crosslogin.php?service-token=${token}&action=mi_report&multiqueue_uuid=${record.sourceQueueUuid}`);
    }).catch(xhr => {
      console.error('Error - GET cross-login-tokens/current', xhr);
      this.api.dialogError(xhr);
    }).finally(() => {
      this.toast.consume();
    });
  }

  movementDetail(record) {
    if (record.sourceUserId) {
      if (record.sourceFlatServiceSubscriptionUuid) {
        return this.dialogServiceDetail(record);
      } else if (record.sourceQueueUuid) {
        // The queue detail currently links to legacy but will be replaced
        return this.navigateToLegacyReport(record);
        // return this.navigateToQueueDetail(record);
      }
    }
  }
}
