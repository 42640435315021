import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import mdt from 'services/md-tools';
import {TableInterface, SearchInterface} from 'classes/md-table';

@inject(Router, Api, KaDialog, KaToast)
export class view_tabName2 {
  mdt = mdt;

  constructor(router, api, dialog, toast) {
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(model) {
    this.params = model.params;
    this.privileges = model.privileges;
  }

  attached() {
    this.init();
  }

  init() {
  }
}
