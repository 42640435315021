import {inject, InlineViewStrategy} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaToast, KaDialog} from 'ka-components';
import mdt from 'services/md-tools';

@inject(Router, Api, KaToast, KaDialog)
export class viewUtentiImportazione {
  endpoint = 'users/me/users';
  records = false;
  loading = false;
  selectAllProfile = true;
  selectAllBilling = true;
  autocomplete = true;
  importStatus = {
    promises: [],
    errors: [],
    pending: false,
    profile: {
      countSelected: 0,
      countProcessing: null,
      countErrors: null,
      countImported: null
    },
    billing: {
      countSelected: 0,
      countProcessing: null,
      countErrors: null,
      countImported: null
    }
  };
  schema = {
    userData: {
      profile: {
        businessType: {
          label: 'Attività',
          control: 'combo',
          datatext: 'label',
          datavalue: 'code',
          datamultiple: false,
          datapreload: true,
          datasource: [],
          colClassName: 'medium_size'
        },
        displayName: {
          label: 'Denominazione',
          control: 'text',
          colClassName: 'max_size'
        },
        holderName: {
          companyName: {
            label: 'Ragione sociale',
            control: 'text',
            colClassName: 'max_size'
          },
        },
        fiscalCode: {
          label: 'Codice fiscale',
          control: 'text',
          colClassName: 'medium_size'
        },
        location: {
          countryCode: {
            label: 'Nazione', 
            control: 'text', 
            min: 2,
            max: 2,
            datatext: 'name',
            datavalue: 'code', 
            datamultiple: false,
            datapreload: true,
            datasource: [],
            colClassName: 'medium_size'
          },
          admLvl1: { 
            label: 'Regione', 
            control: 'text',
            colClassName: 'medium_size'
          },
          admLvl2: { 
            label: 'Provincia',
            control: 'text',
            colClassName: 'medium_size'
          },
          admLvl3: {
            label: 'Comune',
            control: 'text',
            colClassName: 'medium_size'
          },
          zipCode: {
            label: 'CAP',
            control: 'text'
          },
          locality: {
            label: 'Località',
            control: 'text',
            colClassName: 'medium_size'
          },
          streetAddress: {
            label: 'Indirizzo',
            control: 'text',
            colClassName: 'max_size'
          }
        }
      }
    },
    billingProfileData: {
      type: {
        label: 'Tipo profilo fatturazione',
        control: 'combo',
        datatext: 'name',
        datavalue: 'code',
        datamultiple: false,
        datapreload: true,
        datasource: [],
        colClassName: 'medium_size'
      },
      name: {
        label: 'Denominazione',
        control: 'text',
        colClassName: 'max_size'
      },
      fiscalCode: {
        label: 'Codice fiscale',
        control: 'text',
        colClassName: 'medium_size'
      },
      location: {
        countryCode: {
          label: 'Nazione',
          control: 'text',
          min: 2,
          max: 2,
          datatext: 'name',
          datavalue: 'code', 
          datamultiple: false,
          datapreload: true,
          datasource: []
        },
        admLvl1: { 
          label: 'Regione', 
          control: 'text',
          colClassName: 'medium_size'
        },
        admLvl2: { 
          label: 'Provincia',
          control: 'text',
          colClassName: 'medium_size'
        },
        admLvl3: {
          label: 'Comune',
          control: 'text',
          colClassName: 'medium_size'
        },
        zipCode: {
          label: 'CAP',
          control: 'text'
        },
        locality: {
          label: 'Località',
          control: 'text',
          colClassName: 'medium_size'
        },
        streetAddress: {
          label: 'Indirizzo',
          control: 'text',
          colClassName: 'max_size'
        }
      },
      payments: {
        banking: {
          iban: {
            label: 'IBAN',
            control: 'text',
            colClassName: 'max_size'
          },
          sepaDirectDebitEnabled: {
            label: 'Abilita',
            control: 'check',
            colClassName: 'max_size'
          }
        }
      }
    }
  }
  constructor(router, api, toast, dialog) {
    this.api = api;
    this.router = router;
    this.toast = toast;
    this.dialog = dialog;
    this.api.get('user-business-types').then(xhr => {
      this.schema.userData.profile.businessType.datasource = xhr.response;
    });
    this.api.get('geo/countries').then(xhr => {
      this.schema.userData.profile.location.countryCode.datasource = xhr.response;
      this.schema.billingProfileData.location.countryCode.datasource = xhr.response;
    });
    this.api.get('billing-profile-types').then(xhr => {
      this.schema.billingProfileData.type.datasource = xhr.response;
    });
  }
  attached() {
    
  }
  objToArray(obj) {
    return Object.entries(obj);
  }
  carica($event) {
    if (!this.file || this.file.length === 0) return this.toast.show('Carica il file csv!', 'error');
    $event.model.busy = true;
    this.loading = true;
    let reader = new FileReader();
    reader.readAsDataURL(this.file[0]);
    reader.onload = () => {
      let data = {
        filename: this.file[0].name,
        fileData: reader.result,
        fillBillingProfileWithUserProfileValues: this.autocomplete
      };
      this.api.post('users/me/users/bulk-import-requests', data).then(x => {
        this.toast.show('File importato con successo!', 'success');
        this.file = null;
        this.loading = true;
        this.api.get(`users/me/users/bulk-import-requests/${x.response.id}/items`).then(xhr => {
          this.selectAllProfile = true;
          this.selectAllBilling = true;
          this.importStatus.pending = false;
          this.importStatus.profile.countImported = this.importStatus.billing.countImported = null;
          this.importStatus.profile.countErrors = this.importStatus.billing.countErrors = null;
          this.importStatus.profile.countProcessing = this.importStatus.billing.countProcessing = null;
          let records = xhr.response;
          for (let record of records) {
            record.profile = { _selected: true, _imported: false, controls: {} };
            record.billing = { _selected: true, _imported: false, controls: {} };
            if (!record.userData.profile.location.countryCode) record.userData.profile.location.countryCode = 'IT';
            if (!record.billingProfileData.location.countryCode) record.billingProfileData.location.countryCode = 'IT';
            if (record.matchedUserUuid) record.profile._status = 'duplicated';
            if (record.matchedBillingProfileUuid) record.billing._status = 'duplicated';
            if (record.billingProfileData.payments?.banking?.iban && record.billingProfileData.payments?.banking?.iban.length > 0) record.billingProfileData.payments.banking.sepaDirectDebitEnabled = true;
          }
          this.records = records;
          this.countSelected();
          setTimeout(() => {
            this.importTable.querySelector('colgroup col.profile').animate([{width: 'calc(100% / 12)'}], { duration: 500, easing: 'ease-in' }).onfinish = () => {
              this.importTable.classList.add('expanded');
            };
          }, 0);
        }).finally(() => {
          this.loading = false;
        });
      }).catch(x => {
        let response = JSON.parse(x.response);
        let errorMessages = [];
        if (response.status === 400 && response.source) {
          if (response.source.parameters) {
            for (let parameter of response.source.parameters) {
              if (parameter.parameter === 'data.attributes.fileData') {
                errorMessages.push('Formato file non valido');
              }
              else if (parameter.messages && parameter.messages.length) {
                for (let message of parameter.messages) {
                  errorMessages.push(message);
                }
              }
            }
          }
        }
        else errorMessages.push('Errore nel caricamento del file!');
        this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'alert', body: errorMessages.join('<br>')});
        this.data.file = null;
      }).finally(() => {
        this.loading = false;
        $event.model.busy = false;
      });
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
      this.toast.show('Errore nel caricamento del file!', 'error');
      this.loading = false;
      $event.model.busy = false;
    };
  }

  annulla() {
    if (!this.records) return this.router.navigate('anagrafiche/utenti');
    this.records = false;
  }
  add() {
    this.records.push({
      profile: { _selected: true, _imported: false, controls: {} },
      billing: { _selected: true, _imported: false, controls: {} },
      userData: {
        profile: {
          holderName: {},
          location: {}
        }
      },
      billingProfileData: {
        location: {},
        payments: {
          banking: {}
        }
      }
    });
  }
  toggleAllProfile(value) {
    for (let record of this.records) {
      record.profile._selected = value;
      record.billing._selected = record.profile._selected;
    }
    this.countSelected();
  }
  toggleAllBilling(value) {
    for (let record of this.records) {
      record.billing._selected = value;
    }
    this.countSelected();
  }
  selectProfile(record) {
    record.billing._selected = record.profile._selected;
    this.countSelected();
    this.importStatus.pending = false;
  }
  selectBilling(record) {
    this.countSelected();
    this.importStatus.pending = false;
  }
  countSelected() {
    this.importStatus.profile.countSelected = this.records.filter(x => x.profile._selected).length;
    this.importStatus.billing.countSelected = this.records.filter(x => x.billing._selected).length;
  }
  resetErrors() {
    for (let record of this.records) {
      for (let [field, item] of Object.entries(record.profile.controls)) {
        if (!item) {
          konsole.warn(`Field ${field} was not found!`);
          continue;
        }
        if (!item.validator) {
          konsole.warn(`Field ${field} validator was not found!`);
          continue;
        }
        item.validator.errors = [];
        item.element.classList.remove('error');
      }
      for (let [field, item] of Object.entries(record.billing.controls)) {
        if (!item) {
          konsole.warn(`Field ${field} was not found!`);
          continue;
        }
        if (!item.validator) {
          konsole.warn(`Field ${field} validator was not found!`);
          continue;
        }
        item.validator.errors = [];
        item.element.classList.remove('error');
      }
    }
  }
  importa() {
    this.resetErrors();
    this.importStatus.pending = true;
    this.importStatus.promises = [];
    this.importStatus.errors = [];
    this.importStatus.profile.countProcessing = this.importStatus.billing.countProcessing = 0;
    this.importStatus.profile.countImported = this.importStatus.billing.countImported = 0;
    this.importStatus.profile.countErrors = this.importStatus.billing.countErrors = 0;
    for (let record of this.records) {
      this.importStatus.promises.push(this.importProfile(record));
    }
    Promise.all(this.importStatus.promises).finally(() => {
      this.importStatus.pending = false;
      if (this.importStatus.errors.length) this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>Si sono verificati i seguenti errori:</strong><div style="margin-top:1rem;border:1px solid #e0e0e0;padding:1rem;max-height:400px;overflow:auto;background-color:#ffffff">' + this.importStatus.errors.join('<br>') + '</div>'});

    });
  }
  importProfile(record) {
    if (record.profile._selected && record.profile._status !== 'success' && !record.profile._imported) {
      let api, update = !!record.profile._overwrite;
      this.importStatus.profile.countProcessing += +1;
      record.profile._status = 'pending';
      record.profile._disabled = record.billing._disabled = true;
      let data = JSON.parse(JSON.stringify(record.userData));
      data.group = 'BASIC_USER';
      data = mdt.helpers.removeEmptyAttributes(data);
      
      if (update && record.matchedUserUuid) {
        api = this.api.put(`users/me/users/${record.matchedUserUuid}`, data);
      }
      else {
        api = this.api.post('users/me/users', data);
      }
      return api.then(xhr => {
        record.profile._status = 'success';
        this.importStatus.profile.countProcessing += -1;
        this.importStatus.profile.countImported += +1;
        record.profile._imported = true;
        record._user = xhr.response.id;
        this.importStatus.promises.push(this.importBilling(record));
      }, xhr => {
        record.profile._status = String(xhr.statusCode) === '409' ? 'duplicated' : 'error';
        this.importStatus.profile.countProcessing += -1;
        record.profile._disabled = record.billing._disabled = false;
        let errorPayload = mdt.parsers.apiError(xhr, record.profile.controls);
        if (errorPayload) this.importStatus.errors.push(errorPayload.body);
      });
    } else if (record.billing._selected && record.profile._status === 'success' && !record.billing._imported) {
      return this.importBilling(record);
    }
  }
  importBilling(record) {
    if (record.billing._selected && record.billing._status !== 'success' && !record.billing._imported) {
      let api, update = !!record.profile._overwrite;
      this.importStatus.billing.countProcessing += +1;
      record.billing._status = 'pending';
      let data = JSON.parse(JSON.stringify(record.billingProfileData));
      //data.type = 'BUILDING';
      data = mdt.helpers.removeEmptyAttributes(data);

      if (update && record.matchedBillingProfileUuid) {
        api = this.api.put(`users/me/billing-profiles/${record.matchedBillingProfileUuid}`, data);
      }
      else {
        api = this.api.post(`users/${record._user}/billing-profiles`, data);
      }
      return api.then(xhr => {
        record.billing._status = 'success';
        this.importStatus.billing.countProcessing += -1;
        this.importStatus.billing.countImported += +1;
        record.billing._imported = true;
      }, xhr => {
        record.billing._status = String(xhr.statusCode) === '409' ? 'duplicated' : 'error';
        this.importStatus.billing.countProcessing += -1;
        record.billing._disabled = false;
        let errorPayload = mdt.parsers.apiError(xhr, record.billing.controls);
        if (errorPayload) this.importStatus.errors.push(errorPayload.body);
      });
    } else return Promise.resolve();
  }
  dialogDuplicate(record, scope) {
    this.dialog.open({
      title: `Gestione sovrascrittura`,
      class: 'small',
      viewModel: PLATFORM.moduleName('views/anagrafiche/utenti/dialogs/utenti-importazione-dialog-sovrascrittura'),
      viewModelParams: { record, scope }
    }, true).whenClosed(response => {
      if (!response.wasCancelled) {
        record[scope]._overwrite = true;
      } else {
        record[scope]._overwrite = false;
        if (scope === 'profile') delete record.matchedUserUuid;
        if (scope === 'billing') delete record.matchedBillingProfileUuid;
      }
    });
  }
}

