import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';
import mdt from 'services/md-tools';

@inject(Router, Api, KaDialog, KaToast)
export class vmPromozioniGestione {
  mdt = mdt;

  constructor(router, api, dialog, toast) {
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(model) {
    this.params = model.params;
    this.privileges = model.privileges;
    this.tools = model.tools;
  }

  attached() {
    this.init();
  }

  init() {
    // INIT TableInterface
    this.tableInterface = new TableInterface({
      name: 'tab-promozioni-gestione-table',
      client: this.api,
      endpoint: 'bo/promotions',
      query: null
    });
    this.tableInterface.initialize().catch(error => { console.log('tableInterface initialization failed', error) });
    // INIT SearchInterface
    this.searchInterface = new SearchInterface({
      name: 'tab-promozioni-gestione-search',
      table: this.tableInterface,
      schema: {
        code: {
          control: 'text',
          label: 'Codice',
          query: 'code-like'
        },
        description: {
          control: 'text',
          label: 'Descrizione',
          query: 'description-like'
        },
      },
      data: {}
    });
    this.searchInterface.initialize().then(() => {
      this.searchInterface.search();
    }).catch(error => { console.log('searchInterface initialization failed', error) });
  }

  createPromotion() {
    if (!this.api.hasRole('CREATE_PROMOTIONS')) return;
    this.dialog.open({
      title: 'Crea una nuova promozione',
      class: 'small',
      viewModel: PLATFORM.moduleName('views/servizi/promozioni/dialogs/promozioni-crea'),
      viewModelParams: {}
    }, true).whenClosed(response => {
      if (!response.wasCancelled) { this.searchInterface.search(); }
    });
  }
}
