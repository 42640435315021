export class viewGestioneUtenti {

/* REGISTER SUB VIEWS */

  views = {
    elenco:                 { vm: PLATFORM.moduleName('views/anagrafiche/utenti/views/elenco')},
    importazione:           { vm: PLATFORM.moduleName('views/anagrafiche/utenti/views/importazione')},
    anagrafica:             { vm: PLATFORM.moduleName('views/anagrafiche/utenti/views/anagrafica')},
    documenti:              { vm: PLATFORM.moduleName('views/anagrafiche/utenti/views/documenti')},
    documenti_approvazione: { vm: PLATFORM.moduleName('views/backoffice/documenti/tabs/documenti-gestione')},
    fatturazione:           { vm: PLATFORM.moduleName('views/anagrafiche/utenti/views/fatturazione')},
    segnalatori:            { vm: PLATFORM.moduleName('views/anagrafiche/utenti/views/segnalatori')},
    compensi:               { vm: PLATFORM.moduleName('views/anagrafiche/utenti/views/compensi')},
    cashback:               { vm: PLATFORM.moduleName('views/anagrafiche/utenti/views/cashback')},
    impostazioni:           { vm: PLATFORM.moduleName('views/anagrafiche/utenti/views/impostazioni')},
    pagamenti:              { vm: PLATFORM.moduleName('views/anagrafiche/utenti/views/pagamenti')},
  };

  /* AURELIA'S COMPONENT LIFECYCLE'S HOOK METHODS */

  activate(params, routeConfig, currentInstruction) {
    /* ROUTE CAN BE
    anagrafiche/utenti
    anagrafiche/utenti/666
    anagrafiche/utenti/666/anagrafica
    anagrafiche/utenti/{id}/{view}
    anagrafiche/utenti/importazione
    anagrafiche/utenti/documenti
    anagrafiche/utenti/fatturazione
    gestione/profilo/me
    */
    if (routeConfig.route === 'gestione/profilo/me') {
      params.id = 'me';
    }
    if (params.id && !params.view) {
      params.view = 'anagrafica';
    }
    // Overwrite default class name for <router> element
    currentInstruction.config.className = currentInstruction.config.name.replace('/','-') + (params.view ? `-${params.view}` : '');

    this.params = params;
  }
}
