import { inject } from 'aurelia-framework';
import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { DialogController } from 'aurelia-dialog';

@inject(Api, KaDialog, KaToast, DialogController)
export class ServiceDialogAssignEditor {
  resource = {
    controls: {},
    schema: {
      user: {
        label: 'Utente assegnatario',
        control: 'combo',
        datasource: { table: 'bo/users?include=user-profiles', query: { search: 'text-search', item: 'id-in'} },
        datavalue: 'id', 
        datatext: 'profile[0].displayName',
        datamultiple: false,
        query: 'owner-uuid-in'
      },
      email: {
        label: 'Email nuovo utente da registrare',
        control: 'text'
      }
    },
    data: {},
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        control.validator.errors = [];
        control.element.classList.remove('error');
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      this.api.dialogError(error, this.resource.controls);
    }
  }

  constructor(api, kaDialog, kaToast, dialogController) {
    this.api = api;
    this.dialog = kaDialog;
    this.toast = kaToast;
    this.controller = dialogController;
  }

  activate(params) {
    this.params = params;
  }

  save($event) {
    $event.model.busy = true;
    this.resource.validate().then(() => {
      let data = null;
      // two different logics: multidialogo existent user | email of user who needs to register
      if (this.resource.data.user) {
        data = {
          redeemCode: this.params.offer.code
        };
        this.api.post(`users/${this.resource.data.user}/promotion-offer-activations`, data).then(() => {
          this.toast.show(`Offerta assegnata con successo!`, 'success');
          this.controller.ok();
        }).catch(error => {
          console.log('ERROR - promotion-offer-activations - POST', error);
          this.resource.error(error);
        }).finally(() => {
          $event.model.busy = false;
        });
      } else {
        data = {
          email: this.resource.data.email,
          redeemCode: this.params.offer.code
        };
        this.api.post(`promotion-offer-activations`, data).then(() => {
          this.toast.show(`Offerta assegnata con successo!`, 'success');
          this.controller.ok();
        }).catch(error => {
          console.log('ERROR - promotion-offer-activations - POST', error);
          this.resource.error(error);
        }).finally(() => {
          $event.model.busy = false;
        });
      }
    }).catch(() => {
      $event.model.busy = false;
    });
  }
}
