import { inject } from 'aurelia-framework';
import { Api } from 'services/api';
import { KaToast, KaDialog } from 'ka-components';
import { TableInterface, SearchInterface } from 'classes/md-table';

@inject(Api, KaToast, KaDialog)
export class vmTracciatiEsito {
  endpoint = 'mav-payment-order-receipts/mine';
  selectedArray = null;

  select = {
    recordsSelected: {},
    someRecordsSelected: false,
    allRecordsSelected: false,
    someRecords: (record) => {
      this.select.recordsSelected[record.uuid] = !this.select.recordsSelected[record.uuid];
      this.select.allRecordsSelected = Object.values(this.select.recordsSelected).every(x => x === true);
      this.select.someRecordsSelected = Object.values(this.select.recordsSelected).some(x => x === true);
      this.selectedArray = Object.keys(this.select.recordsSelected).filter(key => this.select.recordsSelected[key] === true) || null;
    },
    allRecords: () => {
      this.select.allRecordsSelected = !this.select.allRecordsSelected;
      this.select.someRecordsSelected = this.select.allRecordsSelected;
      Object.keys(this.select.recordsSelected).forEach((key) => { 
        this.select.recordsSelected[key] = this.select.allRecordsSelected ? true : false;   
      });
      this.select.allRecordsSelected ? (this.select.selectedLength = Object.values(this.select.recordsSelected).length) : 0;
      this.selectedArray = Object.keys(this.select.recordsSelected).filter(key => this.select.recordsSelected[key] === true) || null;
    }
  }

  constructor(api, toast, dialog) {
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    this.init();
    this.selectedArray = Object.keys(this.select.recordsSelected).filter(key => this.select.recordsSelected[key] === true) || null;
  }
  
  init() {
    this.tableInterface = new TableInterface({
      name: 'tracciati-esito-mav-table',
      client: this.api,
      endpoint: this.endpoint
    });
    this.tableInterface.initialize().catch(error => { console.log('tableInterface initialization failed', error); });

    this.searchInterface = new SearchInterface({
      name: 'tracciati-esito-mav-search',
      table: this.tableInterface,
      schema: {
        iban: {
          label: 'IBAN',
          control: 'text',
          query: 'iban-code'
        },
        billingProfileOwnerDisplayName: {
          label: 'Intestatari',
          control: 'combo',
          datasource: { table: 'users/me/users?include=user-profiles', query: { search: 'text-search', item: 'uuid-in'} },
          datavalue: 'uuid', 
          datatext: 'profile[0].displayName',
          datamultiple: true,
          description: 'Seleziona uno o più intestatari',
          query: 'owner-uuid-in'
        },
        createdAtStart: {
          label: 'Periodo dal', 
          control: 'date',  
          query: 'created-at-gte'
        },
        createdAtEnd:  {
          label: 'al', 
          control: 'date',
          query: 'created-at-lte'
        },
        createdAtStart: {
          label: 'Periodo dal', 
          control: 'date',  
          query: 'created-at-gte'
        },
        createdAtEnd:  {
          label: 'al', 
          control: 'date',
          query: 'created-at-lte'
        },
        accounted: {
          label: 'Stato contabilizzazione',
          control: 'combo',
          datasource: [{ value: 'YES', text: 'Contabilizzati' }, { value: 'NO', text: 'Non contabilizzati' }],
          query: 'accounted'

        },
        downloaded: {
          label: '',
          control: 'check',
          query: 'downloaded',
          description: 'Escludi tracciati già scaricati'
        },
        showDisabled: {
          label: "",
          control: 'check',
          query: "show-disabled",
          description: 'Mostra anche autorizzazioni dismesse'
        }
      },
      data: {
        createdAtGte: null,
        createdAtLte: null,
        downloaded: true
      }
    });
    this.searchInterface.initialize().then(() => {
      this.search();
    }).catch(error => { 
      console.log('searchInterface initialization failed', error);
    });
  }

  search() {
    const data = structuredClone(this.searchInterface.data);
    if(data.accounted === true) data.accounted = 'NO';
    if(data.downloaded === true) data.downloaded = 'NO';
    if (data.showDisabled === true) data.showDisabled = "YES";
    this.searchInterface.search(data);
  }

  download(file) {
    this.toast.show('Caricamento in corso', 'loading');
    let uri = `mav-payment-order-receipts/downloads/${file.uuid}`;

    this.api.client.createRequest(uri)
    .asGet()
    .withHeader('Accept', 'text/plain')
    .withResponseType('blob')
    .send()
    .then(xhr => {
      let regexp = new RegExp('filename=(.+\.txt$)');
      let matches = xhr.headers.headers['content-disposition'].value.match(regexp);
      let anchor = document.createElement('a');
      let blob = new Blob([xhr.response], { type: "text/plain" });
      let url = URL.createObjectURL(blob);

      anchor.href = url;
      anchor.download = matches[1];
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      this.toast.show(`Documento scaricato con successo!`, 'success');
    }).catch(xhr => {
      this.toast.show(`Impossibile scaricare il file!`, 'error');
      console.log('ERROR - mav-payment-order-receipts download - GET', xhr);
    });
  }

  accountFor(record) {
    let data = {'accounted': (record.accounted === 'YES') ? 'NO' : 'YES'};
    this.api.patch(`mav-payment-order-receipts/${record.uuid}`, data).then(() => {
      this.toast.show(`Operazione effettuata con successo!`, 'success');
      this.tableInterface.load();
    });
  }

  resetSelectedCbis() {
    this.select.recordsSelected = {};
    this.select.someRecordsSelected = false;
    this.select.allRecordsSelected = false;
  }

  exportSelectedCbis() {
    let data = { receiptUuidIn: null };
    data.receiptUuidIn = this.selectedArray;

    if (this.searchInterface.data.accounted === 'YES') {
      this.downloadSelectedCbis(false, data.receiptUuidIn);
    } else {
      this.dialog.open({
        title: `Attenzione!`,
        class: 'small',
        viewModel: PLATFORM.moduleName('views/riscossioni/pagamento/dialogs/account-for-receipts'),
        viewModelParams: {}
      }).whenClosed((response) => {
        if (!response.wasCancelled) {
          this.downloadSelectedCbis(response.output, data.receiptUuidIn);
        }
      });
    }
  }

  downloadSelectedCbis(markReceiptsAsAccountedOnSuccess, receiptUuidIn) {
    let data = { markReceiptsAsAccountedOnSuccess, receiptUuidIn };
    this.api.client.createRequest('mav-payment-order-receipts/merged-downloads/')
    .asPost()
    .withContent(data)
    .withHeader('Accept', 'text/plain')
    .withResponseType('blob')
    .send()
    .then(xhr => {
      let regexp = new RegExp('filename=(.+\.txt$)');
      let matches = xhr.headers.headers['content-disposition'].value.match(regexp);
      let anchor = document.createElement('a');
      let blob = new Blob([xhr.response], { type: "text/plain" });
      let url = URL.createObjectURL(blob);

      anchor.href = url;
      anchor.download = matches[1];
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      
      this.search();
      this.toast.show(`Documento scaricato con successo!`, 'success');
    }).catch(xhr => {
      this.toast.show(`Impossibile scaricare il documento!`, 'error');
      console.log('ERROR - mav-payment-order-receipts - downlaod merged file of selected cbis - POST', xhr);
    });
  }
}
