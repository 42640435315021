import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import mdt from 'services/md-tools';

@inject(Api, KaDialog, KaToast)
export class DialogCreatePromotion {
  activableOn = 'Utenti';
  payableBy = 'Utenti';
  resource = {
    controls: {},
    schema: {
      code: {
        control: 'text',
        label: 'Codice',
        required: true
      },
      description: {
        control: 'text',
        label: 'Descrizione',
        required: true
      }
    },
    data: {},
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        if (!control) return;
        promises.push(new Promise((resolve, reject) => {
          console.log(control);
          if (control.validate)
            control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); });
          else if (control.input?.validate)
            control.input.validate().then(() => resolve()).catch(() => reject('Control didn\'t pass validation'));
        }));
      });
      return Promise.all(promises);
    },
    error: error => {
      let errorPayload = mdt.parsers.apiError(error, this.resource.controls);
      if (errorPayload) this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>'+errorPayload.title+':</strong><div style="margin-top:1rem;border:1px solid #e0e0e0;padding:1rem;max-height:400px;overflow:auto;background-color:#ffffff">' + errorPayload.body + '</div>'});
    }
  }

  constructor(api, kaDialog, kaToast) {
    this.api = api;
    this.dialog = kaDialog;
    this.toast = kaToast;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) { 
    this.params = params;
  }

  save() {
    this.resource.validate().then(() => {
      this.api.post('bo/promotions', this.resource.data).then(() => {
        this.toast.show('Promozione creata con successo', 'success'); 
        this.params.dialog.ok(); 
      }).catch(error => { console.log('Promotion creation promise failed'); this.resource.error(error); });
    });
  }
}
