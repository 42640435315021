import { Api } from 'services/api';
import { Router } from 'aurelia-router';
import { MdUser } from 'services/md-user';
import { KaDialog, KaToast } from 'ka-components';
import { PLATFORM, inject } from 'aurelia-framework';

@inject(Api, KaDialog, KaToast, MdUser, Router)
export class vmAdvices {
  privileges = {};
  tabs = [
    {
      id: 'activeAdvices',
      label: 'Avvisi Wall Attivi',
      vm: PLATFORM.moduleName('views/backoffice/avvisi/tabs/active-advices')
    },
    {
      id: 'expiredAdvices',
      label: 'Avvisi Wall Non Attivi',
      vm: PLATFORM.moduleName('views/backoffice/avvisi/tabs/expired-advices')
    },
  ];

  constructor(api, kaDialog, kaToast, mdUser, router) {
    this.api = api;
    this.mdUser = mdUser;
    this.router = router;
    this.toast = kaToast;
    this.dialog = kaDialog;
    this.privileges.tabs = this.tabs.reduce((accumulator, value) => ({ ...accumulator, [value.id]: false }), {});
    this.tools = {
      dialogEditorWallAdvice: (record) => {
        return this.dialog.open({
          title: `${record ? 'Modifica' : 'Crea nuovo'} avviso`,
          class: 'editor-wall-advice',
          viewModelParams: { wallAdvice: record },
          viewModel: PLATFORM.moduleName('views/backoffice/avvisi/dialogs/editor-wall-advice')
        });
      },
      dialogWallAdvice: (record) => {
        return this.dialog.open({
          title: ' ',
          class: 'medium wall-advice',
          viewModelParams: { wallAdvice: record },
          viewModel: PLATFORM.moduleName('views/backoffice/avvisi/dialogs/wall-advice')
        });
      },
      normalizeStartAtDateTime: (dateToNormalize) => {
        let date = new Date(dateToNormalize);
        date.setHours(0,0,0)
        return date.toISOString().replace(/\.\d{3}/, '');
      },
      normalizeEndAtDateTime: (dateToNormalize) => {
        let date = new Date(dateToNormalize);
        date.setHours(23,59,59)
        return date.toISOString().replace(/\.\d{3}/, '');
      }
    }
  }

  activate(params) {
    this.params = params;
    this.privilege();
  }

  privilege() {
    if (this.api.hasRole('LIST_WALL_ADVICES')) {
      ['activeAdvices', 'expiredAdvices'].forEach((id) => { this.privileges.tabs[id] = true; })
    }
  }
}