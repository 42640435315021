import { Api } from 'services/api';
import { KaToast } from 'ka-components';
import { DialogController } from 'aurelia-dialog';

export class DialogContractRescission {
  isConfirmed = false;
  userUuid;
  currentDate = new Date().toLocaleDateString('it-IT');
  userId;
  latestVersions = [];
  schemaMessages = {
    'NO': {
      value: 'Messaggio personalizzato',
      text: null
    },
    'PEC': {
      value: 'Messaggio per recesso tramite PEC',
      text: `Ti confermiamo di aver provveduto, come da tua richiesta avvenuta tramite PEC in data ${this.currentDate}, al recesso del contratto cashback`
    },
    'POSTA': {
      value: 'Messaggio per recesso tramite Raccomandata',
      text: `Ti confermiamo di aver provveduto, come da tua richiesta avvenuta tramite raccomandata in data ${this.currentDate}, al recesso del contratto cashback`
    }
  }
  resource = {
    data: {
      defaultMessage: 'NO'
    },
    controls: {},
    schema: {
      userUuid: {
        required: true,
        label: 'Utente',
        readonly: false,
        datavalue: 'uuid',
        control: 'combo',
        datapreload: false,
        datamultiple: false,
        datatext: 'profile[0].displayName',
        datasource: { 
          table: 'bo/users?include=user-profiles', 
          query: { search: 'text-search', item: 'uuid-in' } 
        },
        validationRule: {
          satisfies: () => { return this.userId !== null; },
          withMessage: 'Questo utente non ha mai sottoscritto contratti cashback.',
        }
      },
      contractType: {
        control: 'combo',
        label: 'Tipologia contratto',
        datasource: [
          { text: 'Qualsiasi', value: 'null' },
          { text: 'Servizi', value: 'FLAT-SERVICE' },
          { text: 'MultInvio', value: 'MULTINVIO-VOLUMES' }
        ],
        datavalue: 'value',
        datatext: 'text'
      },
      defaultMessage: {
        required: true,
        control: 'combo',
        label: 'Seleziona un messaggio predefinito',
        datasource: this.setDatasourceDefaultMessage(),
        datavalue: 'value',
        datatext: 'text'
      },
      reason: {
        required: true,
        control: 'textarea',
        placeholder: 'Scrivi qui il tuo messaggio...',
        label: 'Messaggio per il cliente'
      },
      isConfirmed: {
        required: true ,
        control: 'check',
        label: 'Accetta e prosegui',
        description: 'Confermi di aver compreso i termini dell\'operazione?'
      }
    },
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        control.validator.errors = [];
        control.element.classList.remove('error');
        promises.push(new Promise((resolve, reject) => {
          control.validate().then((result) => {
            if (result.valid) {
              resolve();
            } else {
              reject('Control didn\'t pass validation');
            }
          });
        }));
      });
      return Promise.all(promises);
    },
    error: (error) => {
      this.api.dialogError(error, this.resource.controls);
    }
  }

  static inject = [Api, DialogController, KaToast];
  constructor(api, controller, kaToast) {
    this.api = api;
    this.toast = kaToast;
    this.controller = controller;
  }

  activate(params) {
    this.params = params;
    this.userUuid = params.userUuid;
    this.contractType = params.contractType;
    if (this.userUuid) this.resource.data.userUuid = this.userUuid;
    if (this.contractType) this.resource.data.contractType = this.contractType;
  }

  setDatasourceDefaultMessage() {
    let datasource = [];
    Object.entries(this.schemaMessages).forEach(([key, value]) => {
      datasource.push({ value: key, text: value.value })
    });
    return datasource;
  }

  changeDefaultMessage() {
    this.resource.data.reason = this.schemaMessages[this.resource.data.defaultMessage].text;
  }

  setLatestContractVersion(response) {
    const latestVersions = [];
    response.forEach((acceptance) => {
      // the last acceptances of contracts are always the first two in the array
      if (latestVersions.length === 2) return;
      if (latestVersions.every((item) => Object.keys(item)[0] !== acceptance.contractType)) {
        const contractType = `${acceptance.contractType}`;
        latestVersions.push({ [contractType]: acceptance.contractVersion });
      }
    });
    return latestVersions;
  }

  changeUserUuid() {
    if (this.resource.data.userUuid) {
      return this.api.get(`bo/cashback-contract-acceptances?user-uuid=${this.resource.data.userUuid}`).then((xhr) => {
        const response = xhr.response;
        if (response.length) {
          this.latestVersions = this.setLatestContractVersion(response);
          this.userId = response[0].userId;
        } else {
          this.userId = null;
          this.latestVersions = null;
        }
      }).catch((error) => {
        console.error('`bo/cashback-contract-acceptances', error);
        this.toast.show('Errore durante l\'elaborazione dei dati', 'error');
      });
    }
  }

  parseData() {
    let data = structuredClone(this.resource.data); 
    data = {
      ...(![undefined, null, 'null'].includes(data.contractType) && { contractType: data.contractType }),
      ...(data.reason && { reason: data.reason })
    }
    return data;
  }

  rescindContract(event) {
    let data = this.parseData();
    event.model.busy = true;
    return this.resource.validate().then(() => {
      this.api.post(`bo/users/${this.userId}/cashback-contract-acceptance-rescissions`, data).then(() => {
        this.toast.show("Contratto rescisso con successo", "success");
      }).catch((error) => {
        console.error("bo/users/${this.userId}/cashback-contract-acceptance-rescissions", error);
        this.resource.error(error);
      }).finally(() => {
        event.model.busy = false;
        this.controller.cancel();
      });
    }).catch(() => {
      event.model.busy = false; 
    });
  }
}
