import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import { DialogController } from 'aurelia-dialog';
import mdt from 'services/md-tools';
@inject(Api, DialogController)
export class ProfiloDocumentUpload {
  mdt = mdt;
  pending = false;
  buttonPending = false;
  error = null;
  ibanCodes = null;
  userProfile = null;
  resource = {
    controls: {},
    schema: {
      file: {
        label: 'Documento',
        control: 'file',
        required: true
      },
      placeholder: {
        label: 'Etichetta',
        control: 'text',
        required: true
      }
    },
    data: {},
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        console.log(control);
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    }
  }

  constructor(api, dialogController) {
    this.api = api;
    this.controller = dialogController;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.userId = params.userId;
    this.uploadSessionUuid = params.uploadSessionUuid;
    this.type = params.type;
    this.document = params.document || null;
  }

  attached() {
    if (this.type?.placeholderType === 'IBAN' && this.api.hasRole(['LIST_USER_BILLING_PROFILE_IBAN_CODES', 'LIST_ANY_USER_BILLING_PROFILE_IBAN_CODES'])) {
      this.pending = true;
      if (this.userId === 'me') this.getIbanCodes();
      else this.getUser().then(() => { this.getIbanCodes(); });   
    } else {
      this.resource.schema.placeholder.placeholder = this.type.placeholderType || null;
    }
  }

  getIbanCodes() {
    this.api.get(`users/${this.userId}/billing-profile-iban-codes`).then(xhr => {
      this.ibanCodes = xhr.response;
      this.ibanCodes.forEach(ibanCode => {
        ibanCode.label = `<strong>${ibanCode.label}: </strong><br>${ibanCode.code}`
      });
      this.resource.schema.placeholder = {
        label: this.userId === 'me' ? 'IBAN dai miei profili di fatturazione' : `IBAN dai profili di fatturazione di ${this.userProfile.displayName}`,
        control: 'combo',
        datasource: this.ibanCodes,
        datavalue: 'code',
        datatext: `label`,
        required: true
      }
      this.pending = false;
    }).catch(error => {
      console.log('ERROR - billing-profile-iban-codes GET', error);
      this.controller.cancel();
      this.api.dialogError(error);
    });
  }

  getUser() {
    return this.api.get(`users/${this.userId}?include=user-profiles`).then(xhr => {
      this.userProfile = xhr.response.profile[0] || null;
    }).catch(error => {
      console.log('ERROR user profile - GET', error);
      this.controller.cancel();
      this.api.dialogError(error);
    });
  }

  upload(endpoint, file) {
    return new Promise((resolve, reject) => {
      let attachment = {};
      new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          attachment.filename = file.name;
          attachment.fileData = reader.result;
          resolve(true);
        };
        reader.onerror = error => { reject(error); };
      }).then(() => {
        this.api.post(endpoint, attachment).then(xhr => {
          // Build object attachment
          attachment.mimeType = file.type;
          attachment.size = file.size;
          attachment.id = xhr.response.id;
          delete attachment.fileData;
          resolve(attachment);
        }).catch(error => reject(error));
      }).catch(error => reject(error));
    });
  }

  save($event) {
    this.buttonPending = $event.model.busy = true;
    this.resource.validate().then(() => {
      let endpoint = `users/${this.userId}/upload-sessions/${this.uploadSessionUuid}/uploaded-files`;
      this.upload(endpoint, this.resource.data.file[0]).then(xhr => {
        let data = { uploadedFileUuid: xhr.id };
        if (this.type.placeholderType) data.placeholder = this.resource.data.placeholder;

        // Buckets
        if (this.document) {
          this.api.put(`users/${this.userId}/certifiable-document-buckets/current/certifiable-documents/${this.document.uuid}`, data).then(() => {
            this.controller.ok();
            setTimeout(() => { this.buttonPending = $event.model.busy = false; }, 0);
          }).catch(error => {
            console.log('ERROR - certifiable-documents - PUT', error);
            this.api.dialogError(error);
            setTimeout(() => { this.pending = $event.model.busy = false; }, 0);
          });
        } else {
          data.documentType = this.type.code;
          this.api.post(`users/${this.userId}/certifiable-document-buckets/current/certifiable-documents`, data).then(() => {
            this.controller.ok();
            setTimeout(() => { this.buttonPending = $event.model.busy = false; }, 0);
          }).catch(error => {
            console.log('ERROR - certifiable-documents - POST', error);
            this.api.dialogError(error);
            setTimeout(() => { this.buttonPending = $event.model.busy = false; }, 0);
          });
        }       
      }).catch(error => {
        console.error('ERROR - upload file', error);
        this.api.dialogError(error);
        setTimeout(() => { this.buttonPending = $event.model.busy = false; }, 0);
      });
    }).catch(() => {
      this.buttonPending = $event.model.busy = false;
    });
  }
}
