import {inject} from 'aurelia-framework';
import {Redirect, activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {MdApi} from 'services/md-api';
import {MdUser} from 'services/md-user';
import {MdDatasources} from 'services/md-datasources';
import {KaToast} from 'ka-components';
import {Navigation} from './app-navigation';
import {Toolbar} from './app-toolbar';
import ENVIRONMENT from '../../environment';
import * as md5 from 'md5';
import { Advices } from '../../classes/advices';
import { KaDialog } from 'ka-components';

@inject(Api, Navigation, Toolbar, KaToast)
export class App {
  isAppUpdated = false;
  environment = ENVIRONMENT;
  constructor(api, navigation, toolbar, toast) {
    this.api = api;
    this.navigation = navigation;
    this.toolbar = toolbar;
    this.toast = toast;
    
    this.api.events.addEventListener('userDataChanged', () => this.initLayoutConfiguration());
    this.initLayoutConfiguration();
    
    this.appUpdateCheck();
    this.serverStatusCheck();
    this.fingerprintCode();
  }

  initLayoutConfiguration() {
    let pic = null;
    if (ENVIRONMENT.APP_DEBUG === 'true') {
      if (this.api.user?.id === 2)   pic = '/static/mitch.gif';
      if (this.api.user?.id === 329) pic = '/static/valente.gif';
    }
    this.layoutConfiguration = {
      brand: '/static/images/brand.png',
      user: {
        group: this.api.data?.groups || this.api.user?.group || null,
        roles: this.api.data?.roles || this.api.user?.roles || null,
        display: (this.api.user?.firstname && this.api.user?.lastname) ? `${this.api.user?.firstname} ${this.api.user?.lastname}` : this.api.user?.name,
        username: this.api.user?.email || this.api.user?.username
      },
      profile: {
        picture: pic,
        href: 'gestione/profilo/me/anagrafica'
      },
      navigation: this.navigation,
      toolbar: this.toolbar
    }
    this.isOperator = this.api.hasRole('LIST_ANY_USER');
  }
  configureRouter(config, router) {
    this.router = router;
    config.title = 'MultiDialogo';
    config.options.pushState = true;

    // Router routes
    const parseNavigation = (nav, inherit) => {
      for (let item of nav) {
        if (item.moduleId) {
          let route = {
            name: item.name || item.href,
            href: item.href,
            route: item.route || item.href,
            title: item.title || item.label,
            moduleId: item.moduleId,
            activationStrategy: 'replace',
            settings: {
              hasLayout: !(item.hasLayout === false),
              hasLanguages: !!item.hasLanguages,
              authRequired: (inherit && inherit.authRequired) || !!item.authRequired || (item.authGroups && item.authGroups.length > 0) || (item.authRoles && item.authRoles.length > 0),
              authGroups: item.authGroups || null,
              authRoles: item.authRoles || null
            }
          };
          config.mapRoute(route);
        }
        if (item.nav) parseNavigation(item.nav, item);
      }
    };
    parseNavigation(this.navigation.items);
    parseNavigation(this.toolbar.items);
    if (ENVIRONMENT.APP_DEBUG === 'true') {
      config.mapRoute({
        name: 'template',
        route: 'template',
        moduleId: PLATFORM.moduleName('views/_template/routeViewName'),
        settings: {
          hasLayout: true,
          authRequired: true
        }
      });
    }
    config.mapRoute({
      name: 'legacy',
      route: 'legacy/viewer/:view',
      moduleId: PLATFORM.moduleName('views/_legacy/viewer'),
      settings: {
        hasLayout: false,
        authRequired: true
      }
    });
    config.mapRoute({
      name: 'maintenance',
      route: 'maintenance/:reason?',
      moduleId: PLATFORM.moduleName('views/app/maintenance'),
      settings: {
        hasLayout: false,
        authRequired: false
      }
    });
    config.mapUnknownRoutes({
      name: 'not-found',
      route: 'not-found',
      moduleId: PLATFORM.moduleName('views/app/not-found'),
      settings: {
        hasLayout: false,
        authRequired: false
      }
    });
    //config.fallbackRoute('not-found');
  
    config.addPipelineStep('authorize', AutologinStep);
    config.addPipelineStep('authorize', AuthorizeStep);
    config.addPipelineStep('preActivate', InitializationStep);
    config.addPipelineStep('preActivate', NotificationsStep);
    config.addPipelineStep('postRender', WallAdvicesStep);
    

    konsole.debug('APP CONFIG', config);
    konsole.debug('APP ROUTER', router);
  }
  gotoLegacy() {
    this.toast.show('Caricamento in corso', 'loading', true);
    this.api.get('users/me/cross-login-tokens/current').then(xhr => {
      let token = xhr.response.token;
      window.open(`${ENVIRONMENT.APP_URL_LEGACY}crosslogin.php?service-token=${token}&action=home`, '_blank');
    }, xhr => {}).catch(error => {console.error(error)}).finally(() => { this.toast.consume(); });
  }

  set appEtag(value) {
    if (value) {
      if (!this.savedAppEtag) this.savedAppEtag = value;
      else this.isAppUpdated = this.savedAppEtag !== value;
    }
    console.debug('saved eTag', this.savedAppEtag, 'current eTag', value);
  }

  async getAppIndex() {
    const response = await fetch('/index.html');
    return this.appEtag = response?.headers?.get('Etag');
  }

  appUpdateCheck() {
    this.getAppIndex();
    return setInterval(async () => {
      this.getAppIndex();
    }, 900000);
  }

  serverStatusCheck() {
    setInterval(() => {
      this.api.get('').then(xhr => {
        this.serverStatusResponse = xhr;
      }).catch(xhr => {
        console.error('serverStatusCheck error', xhr);
        if (typeof xhr.response === 'string' && xhr.response.startsWith('{')) {
          xhr.response = JSON.parse(xhr.response);
        }
        this.serverStatusResponse = xhr;
      });
    },
    30000);
  }

  fingerprintCode() {
    if (window.localStorage.getItem('xhr-fingerprint')) return;
    let date = new Date();
    let yearMonthDate = `${date.getFullYear()}${date.getMonth()+1}${date.getDate()}`;
    let userAgent = md5(window.navigator.userAgent);
    let userAgentYmd = md5(`${yearMonthDate}${userAgent}`).slice(0, 19);
    let hourMinute = `${date.getHours()}${ date.getMinutes()}`;
    let deviceFingerprint =  `${yearMonthDate}${userAgent}${userAgentYmd}${hourMinute}`;
    window.localStorage.setItem('xhr-fingerprint', deviceFingerprint);
  }
}

@inject(Api)
class AutologinStep {

  constructor(api) {
    this.api = api;
  }

  run(navigationInstruction, next) {
    konsole.debug('AutologinStep');
    if (navigationInstruction.config.name === 'login') {
      konsole.debug('Skipping as requesting login view which resets user\'s authentication');
      return next();
    }
    let code = new URLSearchParams(window.location.search).get('st');
    if (code) {
      console.group('Autologin');
      console.log('Code:', code);
      return this.api.login(null, null, code).then(x => {
        console.log('Auth: successful');
        return next();
      }).catch(x => {
        console.log('Auth: failed');
        return next.cancel(new Redirect('logout'));
      }).finally(() => {
        console.groupEnd();
      });
    }
    return next();
  }
}

@inject(Api)
class AuthorizeStep {

  constructor(api) {
    this.api = api;
  }

  run(navigationInstruction, next) {
    if (navigationInstruction.config.name === 'login') {
      // Skipping as requested route is the login view which resets user's authentication
      return next();
    }
    let isAuthenticated = this.api.isAuthenticated;
    let currentRoute = navigationInstruction.config;
    let authRequired = currentRoute.settings && !!currentRoute.settings.authRequired;

    if (authRequired && !isAuthenticated) {
      const href = `${window.location.pathname}${window.location.search}`;
      sessionStorage.setItem(btoa(`reroute`), JSON.stringify({ href }));
      return next.cancel(new Redirect('logout'));
    }
    if (currentRoute.settings.authGroups && (!this.api.user || !this.api.user.group || !currentRoute.settings.authGroups.includes(this.api.user.group))) {
      return next.cancel(new Redirect('dashboard'));
    }
    if (currentRoute.settings.authRole && (!this.api.user || !this.api.user.roles || !this.api.hasRole(currentRoute.settings.authRole))) {
      return next.cancel(new Redirect('dashboard'));
    }
    return next();
  }
}

@inject(Api, MdApi, MdUser, MdDatasources, Navigation, Toolbar)
class InitializationStep {
  constructor(Api, MdApi, MdUser, MdDatasources, Navigation, Toolbar) {
    this.api = Api;
    this.mdApi = MdApi;
    this.mdUser = MdUser;
    this.mdDatasources = MdDatasources;
    this.navigation = Navigation;
    this.toolbar = Toolbar;
  }
  async run(navigationInstruction, next) {
    if (navigationInstruction.config.name === 'login') {
      // Skipping as requested route is the login view which resets user's authentication
      return next();
    }

    await this.mdDatasources.initialize();
    const fragment = navigationInstruction.fragment;
    const userCheck = await this.mdUser.check(fragment);

    if (userCheck.hasPassed === false && !userCheck.redirect) return next.cancel();
    if (userCheck.redirect) return userCheck.redirect === fragment ? next() : next.cancel(new Redirect(userCheck.redirect));

    this.navigation.init();
    this.toolbar.init();
    return next();
  }
}

@inject(Api, MdApi, MdUser, MdDatasources, Navigation)
class NotificationsStep {
  constructor(Api, MdApi, MdUser, MdDatasources, Navigation) {
    this.api = Api;
    this.mdApi = MdApi;
    this.mdUser = MdUser;
    this.mdDatasources = MdDatasources;
    this.navigation = Navigation;
  }
  run(navigationInstruction, next) {
    konsole.debug('NotificationsStep');
    if (navigationInstruction.config.name === 'login') {
      konsole.debug('Skipping as requesting login view which resets user\'s authentication');
      return next();
    }
    if (!this.api.isAuthenticated) {
      konsole.debug('Skipping as authentication is missing');
      return next();
    }
    let storage = { totalUnreadNotifications: null, myUnreadNotifications: null, subusersUnreadNotifications: null }
    this.api.get('users/me/ui-notification-alerts').then(xhr => {
      storage.totalUnreadNotifications = xhr.originalResponse.meta.totalUnreadNotificationsCount ? parseInt(xhr.originalResponse.meta.totalUnreadNotificationsCount, 10) : null;
      storage.myUnreadNotifications = xhr.originalResponse.meta.totalMineUnreadNotificationsCount ?  parseInt(xhr.originalResponse.meta.totalMineUnreadNotificationsCount,10): null;
      storage.subusersUnreadNotifications = xhr.originalResponse.meta.totalSubuserUnreadNotificationsCount ? parseInt(xhr.originalResponse.meta.totalSubuserUnreadNotificationsCount, 10) : null;
    }).catch(error => {
      console.error('NotificationsStep', error);
      storage.totalUnreadNotifications = storage.myUnreadNotifications = storage.subusersUnreadNotifications = 'error';
    }).finally(() => {
      localStorage.setItem(btoa('ui-notifications-counter'), JSON.stringify(storage));
    });
    return next();
  }
}

@inject(Api, Advices, KaDialog)
class WallAdvicesStep {
  constructor(api, advices, dialog) {
    this.api = api;
    this.advices = advices;
    this.dialog = dialog;
  }

  async run(navigationInstruction, next) {

    if (!this.api.hasRole("LIST_USER_WALL_ADVICES") || this.api.user.group !== 'MASTER_USER') {
      return next();
    }
    
    await this.advices.initialize();

    const advicesToShow = this.advices.get(navigationInstruction.config.name);
    if (!advicesToShow) return next();
    
    for (const advice of advicesToShow) {
      const result = await this.dialog.open({
        title: ' ',
        class: 'medium wall-advice',
        viewModelParams: { adviceToShow: advice },
        viewModel: PLATFORM.moduleName('views/backoffice/avvisi/dialogs/wall-advice'),
      }).whenClosed();
      if (!result.wasCancelled) {
        this.advices.remove(advice);
      } else {
        this.advices.setAsSeen(advice);
      }
    }
    return next();
  }
}

