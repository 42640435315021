import { inject } from 'aurelia-framework';
import { activationStrategy } from 'aurelia-router';
import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { TableInterface, SearchInterface } from 'classes/md-table';
import mdt from 'services/md-tools';

@inject(Api, KaDialog, KaToast)
export class vmPromozioniAggiungi {
  mdt = mdt;

  constructor(api, kaDialog, kaToast) {
    this.api = api;
    this.dialog = kaDialog;
    this.toast = kaToast;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) { 
    this.params = params;
  }

  attached() {
    // Table Interface
    this.tableInterface = new TableInterface({ 
      name: 'promozioni-attivabili-table', 
      client: this.api, 
      endpoint: 'bo/promotions', query: null 
    });
    this.tableInterface.parseResponse = (response) => {
      this.response = response;
      this.response.forEach(element => { element.controls = {}; });
      return this.response;
    };

    // Search Interface
    this.searchInterface = new SearchInterface({
      name: 'promozioni-attivabili-table-search',
      table: this.tableInterface,
      schema: {
        code: {
          control: 'text',
          label: 'Codice',
          query: 'code-like'
        },
        description: {
          control: 'text',
          label: 'Descrizione',
          query: 'description-like'
        },
        expireAt: {
          control: 'date',
          placeholder: 'Illimitata'
        }
      },
      data: {}
    });

    // Initialize & Search
    this.tableInterface.initialize().then(() => { 
      this.searchInterface.initialize().then(() => { 
        this.searchInterface.search(); 
      });
    });
  }

  addPromotion(event, promotion) {
    event.model.busy = true;
    let data = { promotionUuid: promotion.uuid, expireAt: promotion.expireAt || null };
    this.api.post(`bo/users/${this.params.user.id}/promotion-activations`, data).then(() => {
      this.toast.show(`Promozione assegnata con successo!`, 'success');
      this.params.dialog.ok();
    }).catch(xhr => {
      console.error('Errore. Impossibile assegnare promozione', xhr);
      let errorPayload = mdt.parsers.apiError(xhr, promotion.controls);
      if (errorPayload) this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>'+errorPayload.title+':</strong><div style="margin-top:1rem;border:1px solid #e0e0e0;padding:1rem;max-height:400px;overflow:auto;background-color:#ffffff">' + errorPayload.body + '</div>'});
    }).finally(() => { event.model.busy = false; });
  }

  copyText(text, context = 'Testo') {
    if (!text) return;
    navigator.clipboard.writeText(text);
    this.toast.show(`${context} copiato con successo!`, 'success');
  }
}
