import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import mdt from 'services/md-tools';

@inject(Api, KaDialog, KaToast)
export class UtentiDialogArchiviaNuovo {
  resource = {
    controls: {},
    schema: {
      reason: {
        label: 'Operazione',
        control: 'combo',
        datatext: 'text',
        datavalue: 'value', 
        datamultiple: false,
        datapreload: true,
        datasource: [{ value: 'ARCHIVING', text: 'Archiviazione'}, /*{ value: 'MIGRATION', text: 'Migrazione'}*/],
        required: true
      },
      newParentUserUuid: {
        label: 'Migra verso',
        control: 'combo', 
        datasource: { table: 'bo/users?include=user-profiles', query: { search: 'text-search', item: 'id-in'} },
        datavalue: 'uuid', 
        datatext: 'profile[0].displayName',
        datamultiple: false,
        datapreload: false,
        required: true
      }
    },
    data: {},
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        console.log(control);
        if (control.validator) control.validator.errors = [];
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      let errorPayload = mdt.parsers.apiError(error, this.resource.controls);
      if (errorPayload) this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>'+errorPayload.title+':</strong><div style="margin-top:1rem;border:1px solid #e0e0e0;padding:1rem;max-height:400px;overflow:auto;background-color:#ffffff">' + errorPayload.body + '</div>'});
    }
  }

  constructor(api, dialog, toast) {
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
    this.resource.client = this.api;
  }
  
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
  }

  save() {
    this.resource.validate().then(() => {
      this.api.post(`bo/users/${this.params.id}/archiving`, this.resource.data).then(() => {
        this.toast.show(`Servizio modificato con successo!`, 'success');
        this.params.dialog.ok();
        this.toast.show(`${this.resource.data.reason === 'MIGRATION' ? 'Migrazione' : 'Archiviazione'} avvenuta con successo`, 'success');
      }).catch(error => {
        console.error();
        this.resource.error(error);
      });
    });
  }
}
