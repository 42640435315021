import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';
import mdt from 'services/md-tools';

@inject(Router, Api, KaDialog, KaToast)
export class view_tabName1 {
  mdt = mdt;

  constructor(router, api, dialog, toast) {
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(model) {
    this.params = model.params;
    this.privileges = model.privileges;
  }

  attached() {
    this.init();
  }

  init() {
    // INIT TableInterface
    this.tableInterface = new TableInterface({
      name: 'tab-routerViewName-tabName1-table',
      client: this.api,
      endpoint: 'users/me/users',
      query: 'include=user-profiles'
    });
    this.tableInterface.initialize().catch(error => { console.log('tableInterface initialization failed', error) });
    // INIT SearchInterface
    this.searchInterface = new SearchInterface({
      name: 'tab-routerViewName-tabName1-search',
      table: this.tableInterface,
      schema: {
        control1: {
          control: 'text',
          label: 'Control 1',
          required: true,
          query: 'text-search'
        },
        control2: {
          control: 'text',
          label: 'Control 2',
          required: false,
          query: 'control2-query-filter-name'
        },
        control3: {
          control: 'text',
          label: 'Control 3',
          required: false,
          query: 'control3-query-filter-name'
        },
        control4: {
          control: 'text',
          label: 'Control 4',
          required: false,
          query: 'control4-query-filter-name'
        },
        control5: {
          control: 'text',
          label: 'Control 5',
          required: false,
          query: 'control5-query-filter-name'
        },
        control6: {
          control: 'text',
          label: 'Control 6',
          required: false,
          query: 'control6-query-filter-name'
        }
      },
      data: {}
    });
    this.searchInterface.initialize().then(() => {
      this.search();
    }).catch(error => { console.log('searchInterface initialization failed', error) });
  }

  search() {
    this.searchInterface.search();
  }
}
