import {inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {TableInterface, SearchInterface} from 'classes/md-table';
import {KaDialog, KaToast} from 'ka-components';
import moment from 'moment';

@inject(Api, KaDialog, KaToast)
export class vmCompensi {
  isInitializing = true;
  isLoading = true;
  resource = {
    summaryTableInterface: null,
    detailsTableInterface: null,
    usersTableInterface: null,
    usersSummaryTableInterface: null,
    summarySearchInterface: null,
    detailsSearchInterface: null,
    usersSearchInterface: null,
    usersSummarySearchInterface: null,
    readEnabled: false,
    search: {
      endpoint: {},
      resource: {},
      schema: {
        createdAtStart: {
          label: 'Periodo dal', 
          control: 'date',  
          query: 'created-at-gte'
        },
        createdAtEnd:  {
          label: 'al', 
          control: 'date',
          query: 'created-at-lte'
        },
        associatedUser: {
          label: 'Utente associato', 
          control: 'combo',  
          datatext: 'displayName',
          datavalue: 'uuid', 
          datamultiple: false,
          datapreload: false,
          datasource: { table: null, query: { search: 'text-search', item: 'associated-user-uuid-in'} },
          query: 'associated-user-uuid-in'
        },
        serviceGroup: {
          label: 'Tipologia',
          control: 'combo',
          datatext: 'name',
          datavalue: 'code',
          datamultiple: false,
          datapreload: true,
          datasource: [],
          query: 'service-group-code-in'
        }
      },
      data: {
        createdAtStart: null,
        createdAtEnd: null,
        associatedUser: null,
        serviceGroup: null
      },
      params: null,
      init: () => {
        // Field service Groups
        this.resource.loadServiceGroups();

        // Summary Table Interface
        this.resource.summaryTableInterface = new TableInterface({
          name: 'commissions-summary-table',
          client: this.api,
          endpoint: `users/${this.params.id}/commissions/summary`,
          query: null
        });
        // Summary Search Interface
        this.resource.summarySearchInterface = new SearchInterface({
          name: 'commissions-summary-search',
          table: null,
          schema: this.resource.search.schema,
          data: this.resource.search.data
        });
        // Details Table Interface
        this.resource.detailsTableInterface = new TableInterface({
          name: 'commissions-details-table',
          client: this.api,
          endpoint: `users/${this.params.id}/commissions/details`,
          query: null
        });
        // Details Search Interface
        this.resource.detailsSearchInterface = new SearchInterface({
          name: 'commissions-details-search',
          table: null,
          schema: this.resource.search.schema,
          data: this.resource.search.data
        });
        // Users Table Interface
        this.resource.usersTableInterface = new TableInterface({
          name: 'commissions-users-table',
          client: this.api,
          endpoint: `users/${this.params.id}/commissions/associated-users`,
          query: 'with-amount-summary=false'
        });
        // Users Search Interface
        this.resource.usersSearchInterface = new SearchInterface({
          name: 'commissions-users-table-search',
          table: null,
          schema: this.resource.search.schema,
          data: this.resource.search.data
        });
        // Users-Summary Table Interface
        this.resource.usersSummaryTableInterface = new TableInterface({
          name: 'commissions-users-summary-table',
          client: this.api,
          endpoint: `users/${this.params.id}/commissions/details-by-user`,
          query: null
        });
        // Users-Summary Interface
        this.resource.usersSummarySearchInterface = new SearchInterface({
          name: 'commissions-users-summary-table-search',
          table: null,
          schema: this.resource.search.schema,
          data: this.resource.search.data
        });
        this.resource.usersSummarySearchInterface.data.createdAtStart = moment().utc().subtract(2, "year").toISOString();
        let promisesT = [
          this.resource.summaryTableInterface.initialize(), 
          this.resource.detailsTableInterface.initialize(), 
          this.resource.usersTableInterface.initialize(),
          this.resource.usersSummaryTableInterface.initialize()
        ];
        return Promise.all(promisesT).then(() => {      
          this.resource.summarySearchInterface.table = this.resource.summaryTableInterface;
          this.resource.detailsSearchInterface.table = this.resource.detailsTableInterface;
          this.resource.usersSearchInterface.table = this.resource.usersTableInterface;
          this.resource.usersSummarySearchInterface.table = this.resource.usersSummaryTableInterface;
          let promises = [
            this.resource.summarySearchInterface.initialize(), 
            this.resource.detailsSearchInterface.initialize(), 
            this.resource.usersSearchInterface.initialize(),
            this.resource.usersSummarySearchInterface.initialize()
          ];
          Promise.all(promises).then(() => { 
            this.resource.loadCommissions();
          });
        });
      },
      apply: () => {
        this.resource.loadCommissions();
      }
    },
    loadServiceGroups: () => {
      let endpoint = 'resell/service-groups';
      if (this.api.user.isReseller) endpoint = `resell/service-groups?reseller-uuid=${this.api.user.uuid}`;
      this.api.get(endpoint).then(xhr => { 
        this.resource.search.schema.serviceGroup.datasource = xhr.response; 
      }).catch(error => {
        console.log('ERROR - service-groups - GET', error);
        this.api.dialogError(error);
      });
    },
    loadCommissions: () => {
      this.isLoading = true;
      this.resource.search.schema.associatedUser.datasource.table = `users/${this.params.id}/commissions/associated-users`;
      this.resource.detailsSearchInterface.data = this.resource.summarySearchInterface.data;
      this.resource.usersSearchInterface.data = { ...this.resource.summarySearchInterface.data };
      this.resource.usersSummarySearchInterface.data = Object.assign({}, this.resource.summarySearchInterface.data, {
        createdAtStart: this.resource.usersSummarySearchInterface.data.createdAtStart,
        createdAtEnd: this.resource.usersSummarySearchInterface.data.createdAtEnd
      });

      // Remove createdAt search filter from Users
      this.resource.usersSearchInterface.data.createdAtStart = null;
      this.resource.usersSearchInterface.data.createdAtEnd = null;

      Promise.all([
        this.resource.summarySearchInterface.search(), 
        this.resource.detailsSearchInterface.search(), 
        this.resource.usersSearchInterface.search(),
        this.resource.usersSummarySearchInterface.search()
      ]).then(() => {
        this.resource.usersTableInterface.data?.forEach(associatedUser => { associatedUser.tag = { old: associatedUser.tag, new: associatedUser.tag } });
        this.isInitializing = this.isLoading = false;
      });
    },
    exportSummary: () => {
      this.toast.show('Caricamento in corso', 'loading', true);
      let params = Object.assign({}, this.resource.summarySearchInterface.last.params || {}, { format: 'csv'});
      this.api.get(`users/${this.params.id}/commissions/summary`, params).then(x => {
        let anchor = document.createElement('a');
        anchor.download = `${this.api.user.id}-compensi-riepilogo.csv`;
        anchor.href = window.URL.createObjectURL(new Blob([atob(x.response.fileData)], {type: x.response.mimeType}));
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        this.toast.consume();
      });
    },
    exportDetail: () => {
      this.toast.show('Caricamento in corso', 'loading', true);
      let params = Object.assign({}, this.resource.detailsSearchInterface.last.params || {}, { format: 'csv'});
      this.api.get(`users/${this.params.id}/commissions/details`, params).then(x => {
        let anchor = document.createElement('a');
        anchor.download = `${this.api.user.id}-compensi-riepilogo-mensile.csv`;
        anchor.href = window.URL.createObjectURL(new Blob([atob(x.response.fileData)], {type: x.response.mimeType}));
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        this.toast.consume();
      });
    },
    exportUsers: () => {
      this.toast.show('Caricamento in corso', 'loading', true);
      let params = Object.assign({}, this.resource.usersSearchInterface.last.params || {}, { format: 'csv', 'with-amount-summary': true });
      this.api.get(`users/${this.params.id}/commissions/associated-users`, params).then(x => {
        let anchor = document.createElement('a');
        anchor.download = `${this.api.user.id}-compensi-utenti-associati.csv`;
        anchor.href = window.URL.createObjectURL(new Blob([atob(x.response.fileData)], {type: x.response.mimeType}));
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        this.toast.consume();
      });
    },
    exportUsersSummary: () => {
      this.toast.show('Caricamento in corso', 'loading', true);
      let params = Object.assign({}, this.resource.usersSummarySearchInterface.last.params || {}, { format: 'csv' });
      this.api.get(`users/${this.params.id}/commissions/details-by-user`, params).then(x => {
        let anchor = document.createElement('a');
        anchor.download = `${this.api.user.id}-compensi-riepilogo-utenti.csv`;
        anchor.href = window.URL.createObjectURL(new Blob([atob(x.response.fileData)], {type: x.response.mimeType}));
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        this.toast.consume();
      });
    },
    setTag: (user, context) => {
      if (user.tag.new.length > 32) {
        user.tag.new = user.tag.old;
        return this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'alert', body: 'Massimo 32 caratteri consentiti'});
      }
      if (user.tag.new === '' || context === 'delete') user.tag.new = null;
      return this.api.patch(`users/${this.params.id}/commissions/associated-users/${user.uuid}`, { tag: user.tag.new }).then(() => {
        user.tag.old = user.tag.new;
        this.toast.show('Tag salvato con successo!', 'success');
      }).catch(error => {
        konsole.error(error);
        user.tag.new = user.tag.old;
        this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'alert', body: 'Si è verificato un errore. Riprova'});
      }).finally(() => {
        user.userTagEnabled = !user.userTagEnabled
      });
    },
    cancelTag: (user) => {
      if (user.tag.new && user.tag.old) user.tag.new = user.tag.old;
      user.userTagEnabled = !user.userTagEnabled
    },
    counter: (id, start, end, duration) => {
      let isInt = true;
      let digits = null;
      if (end % 1 != 0) isInt = false;
      let startTimestamp = null;
      if (!this.counter) this.counter = {};
      if (!this.counter[id]) this.counter[id] = 0;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        if (isInt) digits = Math.floor(progress * (end - start) + start);
        else digits = progress * (end - start) + start;
        this.counter[id] = isInt ? digits : digits.toFixed(2);
        if (progress < 1) window.requestAnimationFrame(step);
      };
      window.requestAnimationFrame(step);
    },
    impersonate: (user) => {
      this.api.impersonate(user.userId).then(() => {}).catch(error => { this.api.dialogError(error); });
    }
  };

  constructor(api, dialog, toast) {
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
  }

  activate(params) {
    this.params = params;
    this.resource.search.init();
  }
}
