export { checkAgreements } from './check-agreements';
export { checkUserProfileData } from './check-user-profile-data';
export { checkCashbackContracts } from './check-cashback-contracts';
export { checkUserBillingProfile } from './check-user-billing-profile';
export { checkSettingsSenderPosta } from './check-settings-sender-posta';
export { checkSettingsLinkedSoftware } from './check-settings-linked-software';
export { checkCashbackBillableAmount  } from './check-cashback-billable-amount';

export class CheckFlow {
  constructor() {
    this.checks = [];
  }

  addCheck(check) {
    if (typeof check.function !== 'function') throw new Error("Check function must be a function!");
    check.id = check.function.name;
    const matched = this.checks.find((x) => x.id === check.id);
    if (matched) return matched;
    this.checks.push(check);
    return check;
  }

  getCheck(functionName) {
    return this.checks.find((check) => check.function.name === functionName);
  }
  
  async run(scope, fragment) {
    for (const check of this.checks) {
      console.debug(`[checkflow-run] Run check ${check.id}`, {...check});

      if (['checkUserBillingProfile', 'checkSettingsLinkedSoftware'].includes(check.id)) {
        const isBuildingAdministrator = scope.data?.profile?.businessType === 'BUILDING-ADMINISTRATOR';
        check.isMandatory = isBuildingAdministrator;
      }

      if (check.hasPassed || (check.hasPassed === false && !check.isMandatory && (!check.redirect || check.redirect !== fragment))) {
        console.debug(`[checkflow-run] Skip check ${check.id}`, {...check});
        continue;
      }
      const result = await check.function(scope, fragment);
      Object.assign(check, result);
      console.debug(`[checkflow-run] Executed check ${check.id}`, {...check});
      if (!check.hasPassed && (check.isMandatory || !!check.redirect)) return check;
    }
    return { hasPassed: true };
  }
}
