import { Aurelia, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';

@inject(Aurelia, Router, Api, KaDialog, KaToast)
export class Cashback {
  savedData;
  isLoading = true;
  readEnabled = false;
  writeEnabled = false;
  isInitializing = true;
  resource = {
    controls: {},
    schema: {
      percentageOnServices: {
        label: 'Percentuale di cashback calcolata sull\'utile del credito servizio derivante dalle spedizioni MultInvio',
        control: 'combo',
        datatext: 'text',
        datavalue: 'value', 
        datamultiple: false,
        datapreload: true,
        datasource: [
          { value: 10, text: '10' }, 
          { value: 15, text: '15' }, 
          { value: 20, text: '20' }
        ],
      }
    },
    data: {
      percentageOnServices: 10
    },
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        control.validator.errors = [];
        control.element.classList.remove('error');
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      this.api.dialogError(error, this.resource.controls);
    }
  }

  constructor(aurelia, router, api, dialog, toast) {
    this.aurelia = aurelia;
    this.api = api;
    this.router = router;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    if (this.params.id) this.init();
  }

  init() {
    if (this.api.hasRole(['MODIFY_ANY_USER_CASHBACK_STATUS', 'MODIFY_ANY_USER_CASHBACK_PERCENTAGE_ON_SERVICES'])) {
      this.readEnabled = this.writeEnabled = true;
    } else if (this.api.hasRole(['LIST_ANY_USER_CASHBACK_SETTINGS'])) {
      this.readEnabled = true;
      this.writeEnabled = false;
    } else {
      this.readEnabled = this.params.id === 'me' && this.api.hasRole('LIST_USER_CASHBACK_SETTINGS') ? true : false;
    }

    return this.load();
  }

  load() {
    this.isLoading = true;
    if (this.readEnabled) {
      this.api.get(`users/${this.params.id}/cashback-settings`).then((xhr) => {
        const response = xhr.response;
        this.resource.data = response;
        this.savedData = structuredClone(response);
      }).catch(() => {
        console.log('ERROR - /cashback-settings - GET', error);
        this.api.dialogError(error);
      }).finally(() => {
        this.isInitializing = this.isLoading = false;
      });
    } else this.isInitializing = this.isLoading = false;
  }

  openDialogEditPercentageOnServices() {
    return this.dialog.open({ 
      title: 'Attenzione!', 
      class: 'small', 
      type: 'confirm', 
      body: `Modificando la percentuale di cashback per le spedizioni MultiInvio verrà generata una nuova versione del contratto. <strong>Confermi di voler salvare?</strong>`
    });
  }

  submitAction($event) {
    if (this.params.id) {
      if (this.savedData.percentageOnServices !== this.resource.data.percentageOnServices) {
        return this.openDialogEditPercentageOnServices().whenClosed((response) => {
          if (!response.wasCancelled) return this.save($event);   
        });
      } else {
        return this.save($event);
      }
    }
  }

  save($event) {
    this.resource.validate().then(() => {
      $event.model.busy = true;
      let data = JSON.parse(JSON.stringify(this.resource.data));
      this.api.patch(`users/${this.params.id}/cashback-settings`, data).then(() => {
        this.toast.show('Modifiche salvate con successo!', 'success');
        this.load();
      }).catch(error => {
        console.log('ERROR - /cashback-settings - PATCH', error);
        this.resource.error(error);
      }).finally(() => {
        $event.model.busy = false;
      });
    });
  }
}
