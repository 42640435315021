import {inject, bindable} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import mdt from 'services/md-tools';
import {KaToast, KaDialog} from 'ka-components';
import datasets from 'md-datasets.json';
import environment from 'environment';

@inject(Api, KaToast, KaDialog)
export class ImpostazioniEmail {
  @bindable() prefsel = null;
  environment = environment;
  constructor(api, toast, dialog) {
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
  }
 
  attached() {
  }

  prefselChanged(value, old) {
    if (value === old) return;
    setTimeout(() => {
      this.preferences.isOverride = null;
      this.preferences.reload();
    }, 0);
  }

  preferences = {
    endpoint: null,
    schema: {
      displayAddress: { control: 'text', label: 'Email mittente', readonly: true },
      displayName: {control: 'text', label: 'Denominazione mittente' },
      notificationAddress: { control: 'text', label: 'Indirizzo per notifiche' },
      emailForwarding: {
        certified: { control: 'check', label: 'Anticipa tramite mail la comunicazione inviata con PEC' },
        notCertified: { control: 'check', label: 'Abilita l\'invio delle comunicazioni a tutte le caselle mail presenti in anagrafica' },
      },
      certifiedAddresses: { control: 'text' }
    },
    data: {},
    reload: () => {
      this.preferences.isCal = String(this.prefsel) !== String(this.api.user.id);
      this.api.get(`users/${this.prefsel}/preferences`).then(x => {
        if (this.preferences.isOverride === null) this.preferences.isOverride = (this.preferences.isCal && x.response.requested?.sender?.email) || (!this.preferences.isCal && x.response.current?.sender?.email);
        this.preferences.data = mdt.preferences.mergeModelData(this.preferences.schema, ['sender', 'email'], x.response);
        this.preferences._data = JSON.parse(JSON.stringify(this.preferences.data));
      });
    },
    override: ($event) => {
      $event.model.busy = true;
      this.api.patch(`users/${this.prefsel}/preferences/sender/email`, {enableOverride: !this.preferences.isOverride}).then(x => {
        this.preferences.isOverride = !this.preferences.isOverride;
      }).catch(e => {}).finally(() => {
        this.preferences.reload();
        $event.model.busy = false;
      });
    },
    save: ($event) => {
      $event.model.busy = true;
      let data = mdt.helpers.diffObject(this.preferences._data, this.preferences.data);
      if (data && Object.entries(data).length) {
        this.preferences.resource.save(data, `users/${this.prefsel}/preferences/sender/email`, 'patch').then(x => {
          this.toast.show('Modifiche salvate con successo!', 'success');
          this.preferences.reload();
        }).catch(e => {
          this.toast.show('Errore nel salvataggio delle modifiche!', 'error');
        }).finally(() => {
          $event.model.busy = false;
        });
      }
      else $event.model.busy = false;
    }
  };
  
  pec = {
    selected: false,
    endpoint: null,
    schema: {
      label: { label: 'Etichetta', control: 'text' },
      address: { label: "Indirizzo", control: 'text' },
      username: { label: "Username", control: 'text' },
      password: { label: 'Password', control: 'password' },
      smtpServer: { label: 'Server SMTP', control: 'text'},
      smtpPort: { label: 'Porta SMTP', control: 'number'}
    },
    data: {
      smtpPort: 465
    },
    reload: () => {
      this.pec.selected = false;
      this.pec.data = {};
      this.preferences.reload();
    },
    add: () => {
      this.pec.selected = true;
    },
    edit: (item) => {
      this.pec.selected = true;
      this.pec.data = item;
    },
    delete: (item) => {
      this.dialog.open({
        title: 'Attenzione!',
        class: 'small',
        type: 'confirm',
        body: 'Confermi di voler eliminare l\'indirizzo selezionato?'
      }, true).whenClosed(response => {
        if (!response.wasCancelled) {
          this.api.delete(`users/me/preferences/sender/email/certified-addresses/${item.uuid}`).then(() => {
            this.toast.show('Record eliminato con successo!', 'success');
            this.pec.reload();
          }).catch(x => {
            this.api.dialogError(x);
          });
        }
      });
    },
    save: ($event) => {
      $event.model.busy = true;
      let data = JSON.parse(JSON.stringify(this.pec.data));
      let url = data.uuid ? `users/${this.prefsel}/preferences/sender/email/certified-addresses/${data.uuid}` : `users/${this.prefsel}/preferences/sender/email/certified-addresses`;
      let method = data.uuid ? `patch` : `post`;
      if (data && Object.entries(data).length) {
        if (data.smtpPort) data.smtpPort = parseInt(data.smtpPort);
        this.pec.resource.save(data, url, method).then(x => {
          this.toast.show('Modifiche salvate con successo!', 'success');
          this.pec.reload();
        }).catch(e => {
          this.toast.show('Errore nel salvataggio delle modifiche!', 'error');
        }).finally(() => {
          $event.model.busy = false;
        });
      }
      else $event.model.busy = false;
    }
  }
}
