import { Api } from 'services/api';
import { KaToast } from 'ka-components';
import { inject } from 'aurelia-framework';
import { MdDatasources } from 'services/md-datasources';

@inject(Api, MdDatasources, KaToast)
export class Impostazioni {
  isInitPending;
  advancedPreferences;
  resource = {
    controls: {},
    schema: {
      multifatture: {
        accountingClientSoftwareUuid: {
          label: 'Software gestionale utilizzato per la gestione della contabilità',
          control: 'combo',
          datasource: [],
          datavalue: 'id',
          datatext: 'name',
          datapreload: true,
          datamultiple: false
        }
      }
    },
    data: {},
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        control.validator.errors = [];
        control.element.classList.remove('error');
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      this.api.dialogError(error, this.resource.controls);
    }
  }

  constructor(api, MdDatasources, toast) {
    this.api = api;
    this.toast = toast;
    this.mdDatasources = MdDatasources;
  }

  activate(params) {
    this.params = params;
    this.isOperator = this.api.hasRole('MODIFY_ANY_USER_PRIVILEGED_PREFERENCES');
  }

  attached() {
    this.init();
  }

  init() {
    this.isInitPending = true;
    if (this.isOperator) {
      this.resource.schema.grantLoginWithSupportTicket = {
        label: 'Assistenza Multidialogo',
        description: 'Attiva al ruolo di assistente',
        control: 'check'
      };
      this.resource.schema.multifatture.enableBetaFeatures = {
        label: 'Beta tester MultiFatture',
        description: 'Attiva al ruolo di BETA tester',
        control: 'check'
      };
    };

    this.resource.schema.multifatture.accountingClientSoftwareUuid.datasource = this.mdDatasources.multifattureSoftwares;
    this.load().finally(() => {
      this.isInitPending = false;
    });
  }

  load() {
    return this.api.get(`users/${this.params?.id}/advanced-preferences`).then((xhr) => {
      const response = xhr.response;
      this.advancedPreferences = response;
      if (this.isOperator) this.resource.data = response;
      else if (!this.isOperator && response.multifatture?.accountingClientSoftwareUuid) {
        this.resource.data.multifatture = { accountingClientSoftwareUuid: response.multifatture?.accountingClientSoftwareUuid }
      }
    }).catch((error) => {
      console.log('GET advanced-preferences', error);
    });
  }

  save($event) {
    $event.model.busy = true;
    this.resource.validate().then(() => {
      const endpoint = `${this.isOperator ? 'bo/' : ''}users/${this.params.id}/advanced-preferences`;
      this.api.patch(endpoint, this.resource.data).then(() => {
        this.toast.show('Modifiche salvate con successo!', 'success');
      }).catch((error) => {
        console.log('PATCH advanced-preferences', error);
        this.resource.error(error);
      }).finally(() => {
        $event.model.busy = false;
      });
    });
  }
}
