export class QueueProperties {
  statuses = [
    {
      key: 'COMPLETED',
      text: 'Completata',
      group: 'Completata',
      value: 'COMPLETED'
    },
    {
      key: 'PENDING',
      text: 'In elaborazione',
      value: 'PENDING'
    },
    {
      key: 'ABANDONED',
      text: 'Scaduta',
      group: 'Annullata',
      value: 'ABANDONED'
    },
    {
      key: 'ABORTED-BY-USER',
      text: 'Rifiutata',
      group: 'Annullata',
      value: 'ABORTED-BY-USER'
    }
  ];

  statusMessages = {
    'pending': 'in elaborazione',
    'error': 'in errore',
    'warning': 'con anomalie',
    'completed': 'completati'
  };

  channels = [
    {
      value: 'SENDPOSTA',
      text: 'Posta',
      icon: 'fa-mail-bulk'
    },
    {
      value: 'SENDEMAIL',
      text: 'Email',
      icon: 'fa-at'
    },
    {
      value: 'PEC',
      text: 'Pec',
      icon: 'fa-file-certificate'
    },
    {
      value: 'MULTICERTA',
      text: 'Multicerta',
      icon: 'fa-shield-check'
    },
    {
      value: 'MULTIBOX',
      text: 'Multibox',
      icon: 'fa-box-alt'
    }
  ];

  deadlineEvent = {
    'RECEIVED-NOTIFIED': 'notifica',
    'RECEIVED-OPENED': 'apertura',
    'RECEIVED-READ': 'lettura'
  };
}
