import { HttpClient } from 'aurelia-http-client';

export class vmMaintenance {
  constructor() {
    this.client = new HttpClient();
  }

  activate(params) {
    if (params.reason) this.reason = window.atob(params.reason);
  }

  attached() {
    this.getHealthcheck();
    setInterval(() => {
      this.getHealthcheck();
    }, 60000);
  }

  async getHealthcheck() {
    try {
      const xhr = await this.client.get(`${ENVIRONMENT.APP_API_BASE_URL}../../healthcheck`);
      const response = JSON.parse(xhr.response);
      if (response.status === 'WORKING') {
        location.href = '/dashboard';
      }
    } catch (error) {
      console.error('healthcheck', error);
    }
  }
}
