import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';

@inject(Api)
export class viewArchiviazioneDialog {
  preview = null;
  result = null;

  constructor(api) {
    this.api = api;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    let data = {
      attributes: {
        realPath: this.params.record.realPath,
        noDryRun: false
      }
    };
    this.api.patch('bo/mi-queue/archive-files', { data }).then(x => {
      konsole.debug(x.response);
      this.preview = x.response;
    });
  }

  archivia($event) {
    if ($event && $event.model) $event.model.busy = true;
    let data = {
      attributes: {
        realPath: this.params.record.realPath,
        noDryRun: true
      }
    };
    this.api.patch('bo/mi-queue/archive-files', { data }).then(x => {
      konsole.debug(x.response);
      this.result = x.response;
    }).finally(() => {
      if ($event && $event.model) $event.model.busy = false;
    });
  }
}
