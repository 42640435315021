import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { DialogController } from 'aurelia-dialog';

export class DialogWithdrawalRequest {
  uploadSessionUuid;
  resource = {
    controls: {},
    schema: {
      amount: { 
        required: true,
        label: 'Importo in €', 
        control: 'number',
      },
      temporaryInvoiceFileUploadUuid: {
        required: true,
        control: 'file',
        label: 'Carica documento PDF',
        placeholder: 'Seleziona o trascina un file PDF'
      }
    },
    data: {},
    
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach((control) => {
        control.validator.errors = [];
        control.element.classList.remove('error');
        promises.push(new Promise((resolve, reject) => { 
          control.validate().then((result) => { 
            if (result.valid) {
              resolve();
            } else {
              reject('Control didn\'t pass validation');
            }
          }); 
        }));
      });
      return Promise.all(promises);
    },
    error: (error) => {
      this.api.dialogError(error, this.resource.controls);
    }
  }

  static inject = [Api, DialogController, KaDialog, KaToast];
  constructor(api, controller, KaDialog, kaToast) {
    this.api = api;
    this.dialog = KaDialog;
    this.toast = kaToast;
    this.controller = controller;
  }

  activate(params) {
    this.params = params;
    this.setWithdrawalAmount(this.params.billableAmount);
  }

  setWithdrawalAmount(amount) {
    const { schema, data } = this.resource;
    schema.amount.max = data.amount = amount;
  }

  async saveWithdrawalRequest($event) {
    $event.model.busy = true;
    await this.postUploadSessions($event);
    const file = await this.fileUpload($event, this.resource.data.temporaryInvoiceFileUploadUuid[0]);
    await this.postWithdrawalRequests($event, file);
  }

  async postUploadSessions($event) {
    return this.api.post('users/me/upload-sessions', {}).then((xhr) => {
      const response = xhr.response;
      this.uploadSessionUuid = response.id;
    }).catch((error) => {
      console.log('Post users/me/upload-sessions', error);
      this.toast.show('Errore in fase di inizializzazione!', 'success');
      $event.model.busy = false;
    });
  }

  async fileUpload($event, file) {
    const endpoint = `users/me/upload-sessions/${this.uploadSessionUuid}/uploaded-files`;
    return new Promise((resolve, reject) => {
      let attachment = {};
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          attachment.filename = file.name;
          attachment.fileData = reader.result;
          resolve(true);
        };
        reader.onerror = (error) => { 
          reject(error); 
        };
      }).then(() => {
        return this.api.post(endpoint, attachment).then((xhr) => {
          attachment.mimeType = file.type;
          attachment.size = file.size;
          attachment.id = xhr.response.id;
          delete attachment.fileData;
          resolve(attachment);
        }).catch((error) => {
          $event.model.busy = false;
          console.log('Post fileUpload', error);
          this.toast.show(`Errore durante il caricamento!`, 'error');
          reject();
        });
      }).catch((error) => {
        $event.model.busy = false;
        console.log('Promise fileUpload', error);
        this.toast.show(`Errore durante il caricamento!`, 'error');
        reject();
      });
    });
  }

  async postWithdrawalRequests($event, file) {
    const data = this.parseData(this.resource.data, file);
    return this.resource.validate().then(() => {
      return this.api.post(`users/me/cashback-withdrawal-requests`, data).then(() => {
        this.toast.show('Richiesta di prelievo avvenuto con successo!', 'success');
        this.controller.ok();
      }).catch((error) => {
        console.error('Get cashback-withdrawal-requests', error);
        this.resource.error(error);
      }).finally(() => {
        $event.model.busy = false;
      });
    }).catch(() => {
      $event.model.busy = false;
    });
  }

  parseData(data, file) {
    let clone = structuredClone(data);
    delete clone.file;
    return clone = {
      ...clone,
      amount: Number(clone.amount),
      temporaryInvoiceFileUploadUuid: file.id
    }
  }
  
  downloadStaticResource(filename) {
    const anchor = document.createElement('a');
    anchor.download = filename;
    anchor.href = `/static/downloads/${filename}`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    this.toast.show('File scaricato con successo', 'success');
  }
}
