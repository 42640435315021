import {PLATFORM, inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import mdt from 'services/md-tools';
@inject(Api, KaDialog, KaToast)
export class vmNotifiche {
  mdt = mdt;
  tabs = [
    {
      id: 'elencoNotificheMie',
      label: 'Le mie notifiche',
      vm: PLATFORM.moduleName('views/notifiche-ui/tabs/elenco-notifiche-mie')
    },
    {
      id: 'elencoNotificheUtenti',
      label: null,
      vm: PLATFORM.moduleName('views/notifiche-ui/tabs/elenco-notifiche-utenti')
    }
  ];

  tools = {
    kaTabsCounter: (alerts) => {
      // Storage
      if (alerts) {
        localStorage.setItem(btoa('ui-notifications-counter'), JSON.stringify({ 
          totalUnreadNotifications: alerts.totalUnreadNotificationsCount ? parseInt(alerts.totalUnreadNotificationsCount, 10) : null, 
          myUnreadNotifications: alerts.totalMineUnreadNotificationsCount ?  parseInt(alerts.totalMineUnreadNotificationsCount,10): null, 
          subusersUnreadNotifications: alerts.totalSubuserUnreadNotificationsCount ? parseInt(alerts.totalSubuserUnreadNotificationsCount, 10) : null 
        }));
      }
      let storage = localStorage.getItem(btoa('ui-notifications-counter')) ? JSON.parse(localStorage.getItem(btoa('ui-notifications-counter'))) : null;
      let myUnreadNotifications = storage && storage.myUnreadNotifications && storage.myUnreadNotifications !== 'error' ? parseInt(storage.myUnreadNotifications, 10) : null;
      let subusersUnreadNotifications = storage && storage.subusersUnreadNotifications && storage.subusersUnreadNotifications !== 'error' ? parseInt(storage.subusersUnreadNotifications, 10) : null;

      // Ka tabs
      let kaTabs = document.querySelectorAll('ka-tabs#main > nav > a');
      if (kaTabs) {
        for (let i = 0; i < kaTabs.length; i++) {
          let counter = kaTabs[i].querySelector('span');
          if (counter) counter.remove();
          // Mine
          if (i === 0 && myUnreadNotifications) {
            if (!kaTabs[i].classList.contains('alert')) kaTabs[i].classList.add('alert');
            kaTabs[i].insertAdjacentHTML('beforeend', `<span>${myUnreadNotifications}</span>`);
          }
          // Subusers
          if (!this.privileges.isOperator) {
            if (i === 1 && subusersUnreadNotifications) {
              if (!kaTabs[i].classList.contains('alert')) kaTabs[i].classList.add('alert');
              kaTabs[i].insertAdjacentHTML('beforeend', `<span>${subusersUnreadNotifications}</span>`);
            }
          }
        } 
      }
    },
    getAlerts: () => {
      let limit = 100;
      return new Promise((resolve, reject) => {
        this.api.get(`users/me/ui-notification-alerts?limit=${limit}`).then(xhr => {
          let alerts = xhr.response;
          let metaAlerts = xhr.originalResponse.meta;

          // Ka tabs
          this.tools.kaTabsCounter(metaAlerts);

          // Subusers alert (if total subusers are more than ${limit})
          if (!this.privileges.isOperator && alerts.length > 1) {
            let total = xhr.originalResponse.meta.total;
            if (total > limit) {
              let offset = limit;
              let subusers = null;
              const fetchSubusers = () => {
                this.api.get(`users/me/ui-notification-alerts?limit=${limit}&offset=${offset}`).then(y => {
                  alerts = alerts.concat(y.response);
                  offset += limit;
                  if (offset < total) fetchSubusers();
                  else {
                    alerts = alerts.filter(x => x.legacyOwnerId !== this.api.user.id);
                    subusers = [...alerts];
                  }
                }).catch(error => {
                  console.log('ERROR ui-notifications-alerts get', error);
                  let storage = { totalUnreadNotifications: 'error', myUnreadNotifications: 'error', subusersUnreadNotifications: 'error' };
                  localStorage.setItem(btoa('ui-notifications-counter'), JSON.stringify(storage));
                  this.api.dialogError(error);
                  return reject();
                });
              };
              fetchSubusers();   
            } else {
              alerts = xhr.response.filter(x => x.legacyOwnerId !== this.api.user.id);
              return resolve(alerts);
            }
          } else {
            return resolve(alerts); 
          } 
        }).catch(error => {
          console.log('ERROR ui-notifications-alerts get', error);
          let storage = { totalUnreadNotifications: 'error', myUnreadNotifications: 'error', subusersUnreadNotifications: 'error' };
          localStorage.setItem(btoa('ui-notifications-counter'), JSON.stringify(storage));
          this.api.dialogError(error);
          return reject();
        });
      });
    },
    getUser: (user) => {
      return new Promise((resolve, reject) => {
        this.api.get(`users/${user.legacyOwnerId}?include=user-profiles`).then(xhr => {
          let user = { legacyOwnerId: xhr.response.id, ownerDisplayName: xhr.response.profile[0].displayName };
          localStorage.setItem(btoa('ui-notifications-user'), JSON.stringify(user));
          return resolve();
        }).catch(() => {
          console.log('ERROR ui-notifications-user get', error);
          this.api.dialogError(error);
          return reject();
        });
      }); 
    },
    preview: (notification, user = null) => {
      return new Promise((resolve, reject) => {
        if (!notification) return reject();
        this.dialog.open({
          title: notification.title,
          class: 'notificheui-anteprima',
          viewModel: PLATFORM.moduleName('views/notifiche-ui/dialogs/notifiche-anteprima'),
          viewModelParams: { notification }
        }, true).whenClosed(() => {
          let load = false;
          if (!notification.readByMeAt) load = true;
          if (this.privileges.isOperator && notification.userUuid !== this.api.user.uuid) load = false;
          if (load) { this.tools.notificationsReadStatus(notification, user).then(() => {
              return resolve(load); 
            }).catch(() => {
              return reject();
            })
          } else {
            return resolve(load);
          }
        });
      });     
    },
    notificationsReadStatus: (notification, user) => {
      return new Promise((resolve, reject) => {
        let data = { read: 'YES' };
        data.read = notification.readByMeAt ? 'NO' : 'YES';

        this.api.patch(`users/${user?.legacyOwnerId || 'me'}/ui-notifications/${notification.uuid}`, data).then(() => {
          return resolve(); 
        }).catch(error => {
          console.log('ERROR ui-notifications patch', error);
          this.api.dialogError(error);
          return reject();
        });
      });
    },
    setAllAsRead: (notificationsTableInterface) => {
      let promises = [];
      notificationsTableInterface.data.forEach(record => {
        promises.push(this.api.patch(`${notificationsTableInterface.endpoint}/${record.uuid}`, { read: 'YES' }));
      });
      return Promise.all(promises).then(() => {
        this.toast.show('Operazione effettuata con successo!', 'success');
      }).catch((errors) => {
        console.error('ERRORS ui-notifications - patch', errors);
        this.toast.show('Si sono verificati errori durante l\'operazione!', 'error');
      });
    },
    takeOver: (notification) => {
      return new Promise((resolve, reject) => {
        if (notification.assigneeUuid) {
        this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'confirm', body: `<strong>Questa notifica è già assegnata ad un altro utente</strong><br>Confermi di volerla prendere in carico?`}).whenClosed(response => {
          if (!response.wasCancelled) {
            this.api.patch(`bo/ui-notifications/bulk-assign/${notification.shareCode}`, {}).then(() => {
              this.toast.show('Notifica presa in carico con successo!', 'success');
              return resolve();
            }).catch(error => {
              console.error('ERRORS ui-notifications/bulk-assign - patch', error);
              this.toast.show('Si sono verificati errori durante l\'operazione!', 'error');
              return reject();
            });
          }
        });
      } else {
        this.api.patch(`bo/ui-notifications/bulk-assign/${notification.shareCode}`, {}).then(() => {
          this.toast.show('Notifica presa in carico con successo!', 'success');
          return resolve();
        }).catch(error => {
          console.error('ERRORS ui-notifications/bulk-assign - patch', error);
          this.toast.show('Si sono verificati errori durante l\'operazione!', 'error');
          return reject();
        });
      }
      });
    },
    notificationTooltip: (notification) => {
      return `<div class="notification-tooltip"><strong class="title">${notification.title}</strong><p class="content">${notification.plainTextBody}</p></div>`;
    },
  }

  constructor(api, dialog, toast) {
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    this.privilege();
    this.tools.kaTabsCounter();
  }

  privilege() {
    this.privileges = { tabs: { elencoNotificheMie: false, elencoNotificheSottoutenti: false, elencoNotificheUtenti: false  }};
    this.privileges.isOperator = false;
    if (this.api.hasRole('LIST_USER_UI_NOTIFICATION')) {
      this.privileges.tabs.elencoNotificheMie = true;
      this.privileges.tabs.elencoNotificheUtenti = this.api.user.group === 'MASTER_USER' ? true : false;
      this.tabs.find(tab => tab.id === 'elencoNotificheUtenti').label = `Le notifiche dei miei ${this.mdt.parsers.contextualize(this.api.user, 'utenti')}`;
    }
    if (this.api.hasRole('LIST_ANY_USER_UI_NOTIFICATION')) {
      this.privileges.isOperator = this.privileges.tabs.elencoNotificheUtenti = true;
      this.tabs.find(tab => tab.id === 'elencoNotificheUtenti').label = `Le notifiche degli utenti`;      
    }
  }
}
