import { Aurelia, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import mdt from 'services/md-tools';

@inject(Aurelia, Router, Api, KaDialog, KaToast)
export class vmLandingRegistrazione {
  mdt = mdt;
  clients;
  legalForms;
  legalFormTypes;
  legalFormSelected;
  editableUsername = false;
  oldSelectedCountryCode;
  pendingAttached = true;
  priceList = {
    schema: {
      definitive: {
        label: 'Listino definitivo applicato',
        control: 'text',
        readonly: true,
        required: true,
      },
      temporary: {
        label: 'Listino temporaneo applicato',
        control: 'text',
        readonly: true,
        required: this.prospectUuid,
      }
    },   
    data: {}
  };
  resource = {
    controls: {},
    schema: {
      passwordConfirm: {
        control: 'password', 
          label: 'Conferma password',
          validationRule: {
            satisfies: () => { return this.resource.data.password === this.resource.data.passwordConfirm; },
            withMessage: 'Le password non coincidono',
          },
          required: true
      },
      password: {
        control: 'password', 
        label: 'Password',
        min: 8,
        required: true
      },
      clientUuid: {
        label: 'Gestionale utilizzato',
        control: 'combo',
        datatext: 'name',
        datavalue: 'id', 
        datamultiple: false,
        datapreload: true,
        datasource: [],
        required: true
      },
      profile: {
        username: {
          control: 'text',
          label: 'Username',
          required: true
        },
        email: {
          control: 'text', 
          label: 'Email',
          required: true
        },
        businessType: {
          label: 'Attività',
          control: 'combo',
          datatext: 'label',
          datavalue: 'code', 
          datamultiple: false,
          datapreload: true,
          datasource: [
            { label: 'Amministratore di condominio', code: 'BUILDING-ADMINISTRATOR' },
            { label: 'Altro', code: 'OTHER' },
          ],
          required: true
        },
        companyLegalFormCode: {
          label: 'Forma giuridica',
          control: 'combo',
          datatext: 'label',
          datavalue: 'code', 
          datamultiple: false,
          datapreload: false,
          datasource: [],
          required: true
        },
        fiscalCode: {
          control: 'text', 
          label: 'Codice fiscale',
          required: false
        },
        vatCode: {
          control: 'text', 
          label: 'Partita iva',
          required: true
        },
        phoneNumber: {
          control: 'text',
          label: 'Telefono fisso',
          required: false
        },
        mobilePhoneNumber: {
          control: 'text',
          label: 'Cellulare',
          required: false
        },
        faxNumber: {
          control: 'text',
          label: 'Fax',
          required: false
        },
        holderName: {
          firstname: {
            control: 'text', 
            label: 'Nome',
            required: false
          },
          lastname: {
            control: 'text', 
            label: 'Cognome',
            required: false
          },
          companyName: { 
            control: 'text',
            label: 'Denominazione',
            required: false
          },
        },
        location: {
          streetAddress: {
            control: 'text', 
            label: 'Indirizzo',
            required: true
          },
          countryCode: { 
            label: 'Nazione', 
            control: 'combo',  
            datatext: 'name',
            datavalue: 'code', 
            datamultiple: false,
            datapreload: false,
            datasource: { table: `geo/countries`, query: { search: 'text-search', item: 'code' }},
            required: true
          },
          admLvl1Uuid: {
            label: 'Regione',
            control: 'combo',
            datatext: 'endonym',
            datavalue: 'uuid',
            datamultiple: false,
            datapreload: false,
            datasource: { table: null, query: { search: 'text-search', item: 'uuid' }}
          },
          admLvl2Uuid: {
            label: 'Provincia',
            control: 'combo',
            datatext: 'endonym',
            datavalue: 'uuid',
            datamultiple: false,
            datapreload: false,
            datasource: { table: null, query: { search: 'text-search', item: 'uuid' }}
          },
          admLvl3Uuid: {
            label: 'Comune',
            control: 'combo',
            datatext: 'endonym',
            datavalue: 'uuid',
            datamultiple: false,
            datapreload: false,
            datasource: { table: null, query: { search: 'text-search', item: 'uuid' }}
          },
          zipCode: {
            label: 'CAP',
            control: 'text',
            required: true
          }
        }
      }
    }, 
    data: {
      group: "MASTER_USER",
      profile: {
        businessType: 'BUILDING-ADMINISTRATOR',
        location: {
          countryCode: 'IT'
        },
      },
      privacyConsent: {
        commercialContact: null,
        thirdPartyDisclosure: null
      }
    },
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      this.api.dialogError(error, this.resource.controls);
    }
  }
  
  constructor(aurelia, router, api, dialog, toast) {
    this.aurelia = aurelia;
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    this.prospectUuid = this.params.prospectUuid || null;
    this.api.logout(true); // API calls need not to be authenticated
  }

  attached() {
    this.oldSelectedCountryCode = this.resource.data.profile.location.countryCode;
    this.init();
    this.getPriceLists();
  }

  async init() {
    let promises = [
      this.setClientUuidDatasource(),
      this.setBusinessTypeDatasource(),
      this.setCompanyLegalFormCodeDatasource(),
    ];
    if (this.prospectUuid) {
      promises.push(this.load())
    };
    return Promise.all(promises).catch((error) => {
      console.error(`Init`, error);
      this.toast.show('Errore durante il caricamento dei dati!', 'error');
    }).finally(() => {
      this.pendingAttached = false;
      this.changeEmail();
    });
  }

  async getPriceLists() {
    const url = this.prospectUuid ? `price-lists/default?prospect-uuid=${this.prospectUuid}` : 'price-lists/default/';
    return this.api.get(url).then((xhr) => {
      let priceListResponse = xhr.response;
      this.priceList.data.definitive = priceListResponse.definitivePriceList;
      this.priceList.data.temporary = priceListResponse?.temporaryPriceList;
    }).catch((error) => {
      console.error('Error in getPriceLists(): ', url, error);
    });
  }

  async setClientUuidDatasource() {
    return this.api.get('clients?declarable=YES').then((xhr) => { 
      this.clients = xhr.response;
      this.resource.schema.clientUuid.datasource = this.clients;
    });
  }

  async setCompanyLegalFormCodeDatasource() {
    const countryCode = this.resource.data.profile.location.countryCode;
    const promises = [
      this.api.get('legal-form-types'),
      this.api.get(`legal-forms?country-code=${countryCode}&self-sufficient-account=YES&available-for-registration=YES`)
    ];
    return Promise.all(promises).then((xhrs) => {
      this.legalFormTypes = xhrs[0]?.response || null;
      this.legalForms = xhrs[1]?.response || null;

      if (this.legalFormTypes?.length) {
        let optgroup = {};
        let companyLegalFormCodeDatasource = [];
        this.legalFormTypes.forEach(type => {
          let legalFormsForThisType = this.legalForms?.filter(x => x.type === type.code) || null;

          if (legalFormsForThisType?.length) {
            optgroup = { code: type.code, label: type.label, optgroup: true };
            companyLegalFormCodeDatasource.push(optgroup);
            legalFormsForThisType.forEach(form => {
              form = { code: form.code, label: form.label };
              companyLegalFormCodeDatasource.push(form);
            });
          }
        });
        this.resource.schema.profile.companyLegalFormCode = Object.assign({}, this.resource.schema.profile.companyLegalFormCode, { datasource: companyLegalFormCodeDatasource });
      }
    });
  }

  async setBusinessTypeDatasource() {
    return this.api.get(`user-business-types`).then((xhr) => {
      let businessTypes = xhr.response;
      this.resource.schema.profile.businessType = Object.assign({}, this.resource.schema.profile.businessType, { datasource: businessTypes });
    });
  }

  async load() {
    return this.api.get(`user-prospects/${this.prospectUuid}`).then((xhr) => {
      // Parse prospect data
      const response = xhr.response;
      this.resource.data = {
        ...this.resource.data,
        ...(response.clientUuid && { clientUuid: response.clientUuid }),
      }
      this.resource.data.privacyConsent = {
        commercialContact: response.privacyConsent.commercialContact,
        thirdPartyDisclosure: response.privacyConsent.thirdPartyDisclosure
      }
      this.assignToProfile(response);
      this.assignToHolderName(response);
      this.parsePhoneNumber(response);
    }).catch((error) => {
      console.error('GET user-prospects', error);
      if (error.response?.source?.intents && error.response.source.intents[0].subject?.referral) {
        this.router.navigateToRoute('login', { 
          email: error.response.source.intents[0].subject?.email, 
          source: 'prospect-registration' 
        });
      }
    });
  }

  assignToProfile(response) {
    const email = response.email;
    const zipCode = response.zipCode;
    const vatCode = response.vatCode;
    const prospectUuid = this.prospectUuid;
    const fiscalCode = response.fiscalCode;
    const countryCode = response.countryCode;
    const admLvl1Uuid = response.admLvl1Uuid;
    const admLvl2Uuid = response.admLvl2Uuid;
    const admLvl3Uuid = response.admLvl3Uuid;
    const streetAddress = response.streetAddress;
    const legalFormCode = response.legalFormCode;
    this.resource.data.profile = {
      ...this.resource.data.profile,
      email,
      prospectUuid,
      username: response.email,
      ...(zipCode && { zipCode }),
      ...(vatCode && { vatCode }),
      ...(fiscalCode && { fiscalCode }),     
      ...(countryCode && { countryCode }),
      ...(admLvl1Uuid && { admLvl1Uuid }),
      ...(admLvl2Uuid && { admLvl2Uuid }),
      ...(admLvl3Uuid && { admLvl3Uuid }),
      ...(streetAddress && { streetAddress }),
      ...(legalFormCode && { companyLegalFormCode }),
    }
  }

  assignToLocation(response) {
    const zipCode = response.zipCode;
    const countryCode = response.countryCode;
    const admLvl1Uuid = response.admLvl1Uuid;
    const admLvl2Uuid = response.admLvl2Uuid;
    const admLvl3Uuid = response.admLvl3Uuid;
    const streetAddress = response.streetAddress;
    if (zipCode || countryCode || admLvl1Uuid || streetAddress) {
      this.resource.data.profile.location = {
        ...(zipCode && { zipCode }),  
        ...(countryCode && { countryCode }),
        ...(admLvl1Uuid && { admLvl1Uuid }),
        ...(admLvl2Uuid && { admLvl2Uuid }),
        ...(admLvl3Uuid && { admLvl3Uuid }),
        ...(streetAddress && { streetAddress }),
      }
    }
  }

  assignToHolderName(response) {
    const lastname = response.lastname;
    const firstname = response.firstname;
    const companyName = response.companyName;
    if (companyName || firstname || lastname) {
      this.resource.data.profile.holderName = {
        ...(firstname && { firstname }),
        ...(lastname && { lastname }),
        ...(companyName && { companyName })
      }
    }
  }

  parsePhoneNumber(response) {
    const phoneId = response.phoneId;
    const mobilePhoneId = response.mobilePhoneId;
    const number = response.phoneId || response.mobilePhoneId;
    if (number) {
      const phoneCountryPrefix = response.phoneCountryPrefix || '';
      const mobilePhoneCountryPrefix = response.mobilePhoneCountryPrefix || '';
      this.resource.data.profile = {
        ...this.resource.data.profile,
        ...(phoneId && { phoneNumber: `${phoneCountryPrefix}${number}` }),
        ...(mobilePhoneId && { mobilePhoneNumber: `${mobilePhoneCountryPrefix}${number}` }),
      }
    }
  }

  changeEmail() {
    if (!this.editableUsername) this.resource.data.profile.username = this.resource.data.profile.email;
  }

  changeUsername() {
    this.editableUsername = true;
  }

  changeLegalForm() {
    this.legalFormSelected = this.legalForms.find(legalForm => legalForm.code === this.resource.data.profile.companyLegalFormCode);
    this.resource.controls['profile.vatCode'].required = this.legalFormSelected.vatCodeNeeded === 'REQUIRED';
    this.resource.controls['profile.fiscalCode'].required = this.legalFormSelected.fiscalCodeNeeded === 'REQUIRED';
    this.resource.controls['profile.holderName.companyName'].required = this.legalFormSelected.companyNameNeeded === 'YES';
    this.resource.controls['profile.holderName.firstname'].required = this.resource.controls['profile.holderName.lastname'].required = this.legalFormSelected.personNameNeeded === 'YES';
  }

  setGeo(field) {
    let childField, lvl, countryCode, countryCodeValueModel;
    if (field === 'countryCode') {
      childField = 'admLvl1Uuid';
      lvl = 1;
    } else if (field === 'admLvl1Uuid') {
      childField = 'admLvl2Uuid';
      lvl = 2;
    } else if (field === 'admLvl2Uuid') {
      childField = 'admLvl3Uuid';
      lvl = 3;
    } else return; 
    countryCode = this.resource.data.profile.location.countryCode || null;
    countryCodeValueModel = this.resource.controls['profile.location.countryCode']?.control?.valueModel;
    if (countryCodeValueModel === undefined) return setTimeout(() => { this.setGeo(field) }, 500);
    let readonly = true, required = false, url = null;
    if (countryCode) {
      url = `geo/countries/${countryCode}/levels/${lvl}/divisions` + (lvl > 1 ? `?parent-uuid=${this.resource.data.profile.location[field]}` : '');
    }
    if (this.resource.data.profile.location[field]) {
      if (countryCodeValueModel.administrativeLevels && countryCodeValueModel.administrativeLevels.find(x => x.level === lvl)) {
        readonly = false;
        required = true;
      } else {
        this.resource.data.profile.location[childField] = null;
        this.resource.controls[`profile.location.${childField}`].element.dispatchEvent(new Event('change', { bubbles: true })); // legacy ka-control workaround: trigger valueChange
      }
    } else {
      this.resource.data.profile.location[childField] = null;
      this.resource.controls[`profile.location.${childField}`].element.dispatchEvent(new Event('change', { bubbles: true })); // legacy ka-control workaround: trigger valueChange
    }
    
    this.resource.schema.profile.location[childField] = Object.assign({}, this.resource.schema.profile.location[childField], { readonly: readonly, datasource: { table: url, query: { search: 'text-search', item: 'uuid'} }});
    // legacy ka-control workaround: trigger readonlyChanged
    this.resource.controls[`profile.location.${childField}`].readonly = readonly;
    this.resource.controls[`profile.location.${childField}`].required = required;

    // Avoid redoing the call for the legal forms if not necessary
    if (field === 'countryCode' && (this.oldSelectedCountryCode !== countryCode)) {
      this.setCompanyLegalFormCodeDatasource();
      this.oldSelectedCountryCode = countryCode;
    }
  }

  dialogPrivacyConsent($event) {
    return this.dialog.open({ 
      title: 'Attenzione!', 
      class: 'medium', 
      type: 'alert', 
      body: 'Necessario indicare una preferenza riguardo l\'informativa sulla <a target="_blank" href="https://www.multidialogo.it/sicurezza/informativa" style="color:#ed5a23">privacy.</a>' 
    }, true).whenClosed(() => { $event.model.busy = false; });
  }

  async registration($event) {
    $event.model.busy = true;
    let data = Object.assign({}, this.resource.data);
    data = mdt.helpers.removeEmptyAttributes(data);
    delete data.passwordConfirm;

    return this.resource.validate().then(() => {
      if (!this.resource.data.privacyConsent.commercialContact || !this.resource.data.privacyConsent.thirdPartyDisclosure) {
        return this.dialogPrivacyConsent($event);
      } else {
        return this.postAnonymousUsers($event);
      }
    }).catch(() => {
      $event.model.busy = false;
    });
  }

  async postAnonymousUsers($event) {
    let data = Object.assign({}, this.resource.data);
    data = mdt.helpers.removeEmptyAttributes(data);
    delete data.passwordConfirm;

    return this.api.post('anonymous-users', data).then(() => {
      this.toast.show('Utente registrato con successo!', 'success');
      this.api.login(data.profile.username, data.password).then(() => {
      }).catch((error) => {
        console.warn('Login failed', error);
        this.toast.show('Autenticazione utente fallita!', 'error');
      });
    }).catch((error) => {
      console.error('POST anonymous-users', error);
      this.resource.error(error);
    }).finally(() => {
      $event.model.busy = false;
    });
  }
}
