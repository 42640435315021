import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import mdt from 'services/md-tools';

@inject(Router, Api, KaDialog, KaToast)
export class DialogIntentsLogin {
  mdt = mdt;

  constructor(router, api, dialog, toast) {
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    sessionStorage.setItem(btoa(`intents-reload`), JSON.stringify({ reload: false }));
  }

  login($event) {
    // add reroute query to url
    if ('URLSearchParams' in window) {
      var searchParams = new URLSearchParams(window.location.search)
      searchParams.set("reroute", encodeURIComponent(this.router.currentInstruction.fragment));
      var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
      history.pushState(null, '', newRelativePathQuery);
    }
    // login and return to the offer page
    if ($event && $event.model) $event.model.busy = true;
    this.api.login(this.username, this.password).then(() => {
      sessionStorage.setItem(btoa(`intents-reload`), JSON.stringify({ reload: true }));
    }).catch(error => {
      console.warn('Login failed', error);
      this.toast.show('Autenticazione utente fallita!', 'error');
    }).finally(() => {
      if ($event && $event.model) $event.model.busy = false;
    });
  }
}