import {inject, InlineViewStrategy} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaToast, KaDialog} from 'ka-components';

@inject(Router, Api, KaToast, KaDialog)
export class viewRubricaImportazione {
  records = false;
  loading = false;
  constructor(router, api, toast, dialog) {
    this.api = api;
    this.router = router;
    this.toast = toast;
    this.dialog = dialog;
  }

  attached() {}

  carica($event) {
    if (!this.file || this.file.length === 0) return this.toast.show('Carica il file csv!', 'error');
    $event.model.busy = true;
    this.loading = true;
    let reader = new FileReader();
    reader.readAsDataURL(this.file[0]);
    reader.onload = () => {
      let data = {
        filename: this.file[0].name,
        fileData: reader.result
      };
      this.api.post('users/me/contacts/bulk-import', data).then(x => {
        this.toast.show('File importato con successo!', 'success');
        this.file = null;
        this.loading = true;
      }).catch(x => {
        let response = JSON.parse(x.response);
        let errorMessages = [];
        if (response.status === 400 && response.source) {
          if (response.source.parameters) {
            console.log('400', response)
            for (let parameter of response.source.parameters) {
              if (/^data\.attributes\.fileData/.test(parameter.parameter)) {
                errorMessages.push('Formato file non valido');
              }
              else if (parameter.messages && parameter.messages.length) {
                for (let message of parameter.messages) {
                  errorMessages.push(message);
                }
              }
            }
          }
        } else errorMessages.push('Errore nel caricamento del file!');
        this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'alert', body: errorMessages.join('<br>')});
        this.data.file = null;
      }).finally(() => {
        this.loading = false;
        $event.model.busy = false;
      });
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
      this.toast.show('Errore nel caricamento del file!', 'error');
      this.loading = false;
      $event.model.busy = false;
    };
  }

  annulla() {
    if (!this.records) return this.router.navigateToRoute('anagrafiche/rubrica');
    this.records = false;
  }
}

