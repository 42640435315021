import { Api } from 'services/api';
import { Router } from 'aurelia-router';
import { inject } from 'aurelia-framework';
import { KaDialog, KaToast } from 'ka-components';
import { TableInterface, SearchInterface}  from 'classes/md-table';

@inject(Api, Router, KaDialog, KaToast)
export class viewCurrentAdvices {
  
  constructor(api, router, kaDialog, kaToast) {
    this.api = api;
    this.toast = kaToast;
    this.router = router;
    this.dialog = kaDialog;
  }

  activate(model) {
    this.tools = model.tools;
  }

  attached() {
    this.init();
    window[`active-advices-update-handler`] = () => { this.search(); };
    window.addEventListener("updateActiveList", window[`active-advices-update-handler`]);
  }

  detached() {
    window.removeEventListener('updateActiveList', window[`active-advices-update-handler`]);
    delete(window[`active-advices-update-handler`]);
  }

  init() {
    this.initTableInterface();
    this.initSearchInterface().then(() => {
      this.search();
    });
  }

  initTableInterface() {
    this.tableInterface = new TableInterface({
      client: this.api,
      name: 'table-wall-advices',
      endpoint: "bo/wall-advices",
      query: "status-in=ACTIVE,SCHEDULED"
    });
    return this.tableInterface.initialize();
  }

  initSearchInterface() {
    this.searchInterface = new SearchInterface({
      name: 'table-activeAdvices-search',
      table: this.tableInterface,
      schema: {
        title: {
          control: 'text',
          label: 'Titolo',
          query: 'title-like'
        },
        tag: {
          label: 'Tag',
          control: 'text',
          query: "tag"
        },
        startAt: {
          control: 'date',
          label: 'Programmato dal',
          query: 'active-at-gte'
        },
        endAt: {
          label: 'al',
          control: 'date',
          query: "active-at-lte"
        },
        public: {
          control: "check",
          description: "escludi privati",
          label: "Visibilità",
          query: "exclude-private"
        }
      },
      data: {}
    });
    return this.searchInterface.initialize();
  }

  async search() {
    const data = structuredClone(this.searchInterface.data);
    data.public = (data.public) ? "YES" : "NO";
    if (data.startAt) {
      data.startAt = this.tools.normalizeStartAtDateTime(this.searchInterface.data.startAt);
    }
    if (data.endAt) {
      data.endAt = this.tools.normalizeEndAtDateTime(this.searchInterface.data.endAt);
    }
    return await this.searchInterface.search(data);
  }

  reset() {
    this.searchInterface.reset();
  }

  openEditorWallAdvice(record) {
    this.tools.dialogEditorWallAdvice((record) ? record : null).whenClosed((response) => {
      if (!response.wasCancelled) {
        this.search();
      }
    });
  }

}