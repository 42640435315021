import { Aurelia, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { TableInterface, SearchInterface } from 'classes/md-table';
import { MavRequest } from 'classes/mavRequest';
import mdt from 'services/md-tools';
import environment from '../../../../environment';

@inject(Aurelia, Router, Api, KaDialog, KaToast)
export class vmMav {
  mdt = mdt;
  environment = environment;

  constructor(aurelia, router, api, dialog, toast) {
    this.aurelia = aurelia;
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(model) {
    this.params = model.params;
    this.privileges = model.privileges;
    this.isBankOperator = this.api.hasRole('MODIFY_ANY_MAV_ORDER_SELF_PRINTING_AUTHORIZATION');
    this.isOperator = this.api.hasRole('LIST_ANY_USER');
    this.mavRequestModel = new MavRequest(this.isOperator);
  }

  attached() {
    this.init();
  }

  init() {
    let bankOperatorStatusDatasource = [{ value: 'null', text: 'Tutti' },{ value: 'ACTIVE', text: 'Confermata' },{ value: 'PENDING-APPROVAL,PENDING-DOCUMENT-DOWNLOAD', text: 'In attesa di approvazione' },{ value: 'PENDING-ACTIVATION', text: 'In attesa di conferma' },{ value: 'REFUSED', text: 'Rifiutata' }];
    let backofficeOperatorStatusDatasource = [{ value: 'null', text: 'Tutti' },{ value: 'ACTIVE', text: 'Attiva' },{ value: 'PENDING-APPROVAL,PENDING-DOCUMENT-DOWNLOAD', text: 'In attesa di approvazione' },{ value: 'PENDING-DOCUMENT-INTERNAL-APPROVAL', text: 'In attesa di approvazione interna' },{ value: 'PENDING-ACTIVATION', text: 'In attesa di attivazione' },{ value: 'REFUSED', text: 'Rifiutata' }];
    
    // INIT TableInterface
    this.tableInterface = new TableInterface({
      name: 'tab-mav-autorizzazioni-table',
      client: this.api,
      endpoint: this.privileges.isOperator ? 'bo/users/billing-profiles/mav-order-self-printing-authorizations' : 'users/billing-profiles/mav-order-self-printing-authorizations/mine'
    });
    this.tableInterface.initialize().catch(error => { console.log('tableInterface initialization failed', error); });

    // INIT SearchInterface
    this.searchInterface = new SearchInterface({
      name: 'tab-mav-richieste-search',
      table: this.tableInterface,
      schema: {
        createdAtGte: {
          label: 'Richiesta inviata dal',
          control: 'date',
          query: 'created-at-gte'
        },
        createdAtLte: {
          label: 'al',
          control: 'date',
          query: 'created-at-lte'
        },
        trusteeDisplayName: {
          label: 'Richiedente',
          control: 'text',
          query: 'trustee-text-search'
        },
        trusteeServiceCode: {
          label: 'Codice servizio',
          control: 'text',
          query: 'service-code-in'
        },
        trusteeSubServiceCode: {
          label: 'Codice sottoservizio',
          control: 'text',
          query: 'sub-service-code-in'
        },
        ownerDisplayName: {
          label: 'Proprietario del conto',
          control: 'text',
          query: 'owner-text-search'
        },
        status: {
          label: 'Stato richiesta',
          control: 'combo',
          datasource: this.isBankOperator ? bankOperatorStatusDatasource : backofficeOperatorStatusDatasource,
          datavalue: 'value',
          datatext: 'text',
          query: 'status-in'
        }
      },
      data: {
        createdAtGte: null,
        createdAtLte: null,
        status: this.isBankOperator ? 'PENDING-APPROVAL,PENDING-DOCUMENT-DOWNLOAD' : 'null'
      }
    });
    this.searchInterface.initialize().then(() => {
      this.searchInterface.search().then(() => {
        this.parentServiceCode = !this.privileges.isOperator && this.tableInterface.data.some(x => x.trustee.serviceCode) ? this.tableInterface.data.find(x => x.trustee.serviceCode).trustee.serviceCode : null;
      });
    }).catch(error => { 
      console.log('searchInterface initialization failed', error);
    });
  }

  mavCreateAuthorizationRequest() {
    this.dialog.open({
      title: `Richiedi autorizzazione stampa MAV`,
      class: 'small mav-create-request',
      viewModel: PLATFORM.moduleName('views/riscossioni/autorizzazioni/dialogs/mav-create-request'),
    }, true).whenClosed(response => {
      if (!response.wasCancelled) {
        this.dialog.open({ 
          title: 'Attenzione!', 
          class: 'small', 
          type: 'alert', 
          body: '<p class="m-0"><strong>La richiesta è stata inoltrata correttamente!</strong><span class="d-block">L\'approvazione di una richiesta da parte dell\'ente bancario potrà richiedere dai 3 ai 5 giorni lavorativi.</span></p>'
        }).whenClosed(() => {
          this.searchInterface.search();
        });
      }
    });
  }

  processing(request) {
    if (request.status === 'PENDING-DOCUMENT-DOWNLOAD' || request.status === 'REFUSED') return;
    this.dialog.open({
      title: `Gestione richiesta di autorizzazione`,
      class: 'small documenti-approval',
      viewModel: PLATFORM.moduleName('views/riscossioni/autorizzazioni/dialogs/mav-approval'),
      viewModelParams: { request }
    }, true).whenClosed(response => {
      if (!response.wasCancelled) {
        this.searchInterface.search();
        let requestStatus = response.output === 'PENDING-ACTIVATION' ? 'approvata' : (response.output === 'ACTIVE' ? 'attivata' : 'rifiutata');
        this.toast.show(`Richiesta ${requestStatus} con successo!`, 'success');
      }
    });
  }

  download(request) {
    if (request.status === 'ACTIVE' || request.status === 'REFUSED') return;
    this.toast.show('Caricamento in corso', 'loading', true);

    // Uri build
    let uri = `bo/users/${request.owner.id}/billing-profiles/${request.billingProfileUuid}/mav-order-self-printing-authorizations/${request.uuid}/document-downloads`;
    this.api.client.createRequest(uri)
    .asGet()
    .withHeader('Accept', 'application/zip')
    .withResponseType('blob')
    .send()
    .then((xhr) => {
      // Filename
      let regexp = new RegExp('filename=(.+\.zip$)');
      let matches = xhr.headers.headers['content-disposition'].value.match(regexp);
      
      let anchor = document.createElement('a');
      let blob = new Blob([xhr.response], { type: "application/zip" });
      let url = URL.createObjectURL(blob);
      anchor.href = url;
      anchor.download = matches[1];
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      this.searchInterface.search();
    }).catch((error) => {
      console.log('ZIP mav-order-self-printing-authorizations', error);
      this.dialog.open({ 
        title: 'Attenzione!', 
        class: 'small', 
        type: 'alert', 
        body: '<strong>Errore nel download del file!</strong><br><p class="m-0 mt-3">Il download non è possibile se tutti i documenti necessari alla richiesta stampa in proprio MAV non sono stati approvati internamente.</p>'
      });
    }).finally(() => {
      this.toast.consume();
    });
  }

  csvDownload() {
    this.toast.show('Caricamento in corso', 'loading', true);

    // Uri build
    let query = null;
    let limit = this.tableInterface.limit;
    let offset = this.tableInterface.offset !== 0 ? `&offset=${this.tableInterface.offset}` : '';
    if (this.searchInterface?.table?._query) query = `${decodeURIComponent(decodeURIComponent(this.searchInterface.table._query))}`;
    let path = this.privileges.isOperator ? 'bo/users/billing-profiles/mav-order-self-printing-authorizations.csv' : 'users/billing-profiles/mav-order-self-printing-authorizations/mine.csv'
    let uri = `${path}${query ? '?'+query+'&limit='+limit : '?limit='+limit}${offset}`;

    this.api.client.createRequest(uri)
    .asGet()
    .withHeader('Accept', 'application/csv')
    .withResponseType('blob')
    .send()
    .then((xhr) => {
      // Filename
      let regexp = new RegExp('filename=(.+\.csv$)');
      let matches = xhr.headers.headers['content-disposition'].value.match(regexp);
      
      let anchor = document.createElement('a');
      let blob = new Blob([xhr.response], { type: 'text/csv' });
      let url = URL.createObjectURL(blob);
      anchor.href = url;
      anchor.download = matches[1];
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }).catch((error) => {
      console.log('CSV mav-order-self-printing-authorizations', error);
    }).finally(() => {
      this.toast.consume();
    });
  }

  delete($event, request) {
    $event.model.busy = true;
    this.api.delete(`users/${request.owner.id}/mav-order-self-printing-authorizations/${request.uuid}`).then(() => {
      this.searchInterface.search();
      this.toast.show('Cancellazione richiesta avvenuta con successo!', 'success');
    }).catch(error => {
      console.log('DELETE mav-order-self-printing-authorizations', error);
      this.api.dialogError(error);
    }).finally(() => {
      $event.model.busy = false;
    });
  }
}
