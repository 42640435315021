import { inject } from 'aurelia-framework';
import { Api } from 'services/api';
import { KaDialog, KaToast, DateTime } from 'ka-components';
import { DialogController } from 'aurelia-dialog';
import mdt from 'services/md-tools';
import moment from 'moment';

@inject(Api, KaDialog, KaToast, DialogController)
export class ServiceDialogOfferteEditor {
  resource = {
    controls: {},
    schema: {
      title: {
        label: 'Titolo',
        control: 'text',
        required: true
      },
      description: {
        label: 'Descrizione',
        control: 'textarea',
        required: true
      },
      startAt: {
        label: 'Data inizio validità',
        control: 'datetime',
        readonly: false,
        required: true
      },
      endAt: {
        label: 'Data fine validità',
        control: 'datetime',
        required: true
      },
      code: {  
        label: 'Codice offerta',
        control: 'text',
        readonly: false,
        required: true
      },
      targetUserType: {
        label: 'Applicabile a',
        control: 'combo',
        datasource: [{ value: 'PARENT', text: 'Utenti' }, { value: 'SUBUSER', text: 'Sottoutenti' }],
        datavalue: 'value',
        datatext: 'text',
        readonly: false,
        required: true
      },
      contextBusinessType: {
        label: 'Contesto attività',
        control: 'combo',
        datasource: [{ value: 'BUILDING-ADMINISTRATOR', text: 'Amministratore' }, { value: '', text: 'N/D' }],
        readonly: false
      },
      promotionCode: {
        control: 'combo', 
        label: 'Codice promozione', 
        datasource: { table: 'bo/promotions', query: { search: 'code-like', item: 'code-in' }}, 
        datavalue: 'code', 
        datatext: 'code', 
        datapreload: false,
        readonly: false,
        required: true
      },
      deadlineType: {
        label: 'Seleziona la tipologia di scadenza',
        control: 'combo',
        datasource: [
          { value: 'USER-CREATION', text: 'Per iscrizione utente' }, 
          { value: 'FLAT-SERVICE-SUBSCRIPTION', text: 'Per sottoscrizione servizio' }
        ],
        datavalue: 'value',
        datatext: 'text',
        readonly: false,
        required: true
      },
      deadlineAt: {
        label: null,
        control: 'datetime',
        required: true
      },
      public: {
        label: 'Disponibilità',
        control: 'check',
        description: 'Offerta privata'
      }
    },
    data: {
      deadlineType: 'USER-CREATION'
    },
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        control.validator.errors = [];
        control.element.classList.remove('error');
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      this.api.dialogError(error, this.resource.controls);
    }
  }

  constructor(api, kaDialog, kaToast, dialogController) {
    this.api = api;
    this.dialog = kaDialog;
    this.toast = kaToast;
    this.controller = dialogController;
  }

  activate(params) {
    this.params = params;
    if (this.params) {
      this.resource.data = this.params.offer;
      this.resource.schema.startAt.readonly = this.resource.schema.code.readonly = this.resource.schema.promotionCode.readonly = this.resource.schema.targetUserType.readonly = this.resource.schema.contextBusinessType.readonly = this.resource.schema.public.readonly = true;
    }
  }

  deadlineTypeChange() {
    return this.resource.controls.deadlineAt.label = this.resource.data?.deadlineType === 'USER-CREATION' ? 'Scadenza per iscrizione utente' : 'Scadenza per sottoscrizione servizio';
  }

  save($event) {
    $event.model.busy = true
    this.resource.validate().then(() => {
      let data = null;
      if (this.params) {
        data = {
          title: this.resource.data.title,
          description: this.resource.data.description,
          endAt: this.resource.data.endAt,
          deadlineType: this.resource.data.deadlineType,
          deadlineAt: this.resource.data.deadlineAt
        }
        this.api.patch(`bo/promotion-offers/${this.params.offer.code}`, data).then(() => {
          this.toast.show(`Modifiche effettuate con successo!`, 'success');
          this.controller.ok();
        }).catch(error => {
          console.log('ERROR promotion-offers - PATCH', error);
          this.resource.error(error);
        }).finally(() => {
          $event.model.busy = false;
        });
      } else {
        data = structuredClone(this.resource.data);
        data.public = (this.resource.data.public === true ? 'NO' : 'YES');
        this.api.post(`bo/promotion-offers`, data).then(() => {
          this.toast.show(`Offerta creata con successo!`, 'success');
          this.controller.ok();
        }).catch(error => {
          console.log('ERROR promotion-offers - POST', error);
          this.resource.error(error);
        }).finally(() => {
          $event.model.busy = false;
        });
      }
    }).catch(() => {
      $event.model.busy = false;
    });
  }
}
