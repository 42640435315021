import { inject } from 'aurelia-framework';
import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { DialogController } from 'aurelia-dialog';

@inject(Api, KaDialog, KaToast, DialogController)
export class PreviewWallAdvice {
  wallAdvice;
  
  constructor(api, dialog, toast, dialogController) {
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
    this.controller = dialogController;
  }

  activate(params) {
    this.params = params;
    this.wallAdvice = params.wallAdvice;
  }

  attached() {
    const elementHeader = document.querySelector('ux-dialog.wall-advice ux-dialog-header h2');
    const title = elementHeader.appendChild(document.createElement('span'));
    title.classList.add('title');
    title.innerHTML = 'Anteprima avviso';
  }
}