import {inject} from 'aurelia-framework';
import {Api} from 'services/api';
import datasets from 'md-datasets.json';

@inject(Api)
export class MdPrefselDialog {
  value = null;
  users = [];

  constructor(api) {
    this.api = api;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    if (this.tableUsers) {
      this.tableUsers.parse_query = (query) => {
        // Ok, concordo che quanto segue sia indubbiamente penosa come soluzione, ma funziona
        // D'altronde è penoso il funzionamento rest per risolvere la #FEATURE/guxg84byh
        let original = query;
        query = query.replace(/statusCode~~__NONE__&/, '&');
        query = query.replace(/(%2C)?__NONE__(%2C)?/, '');
        if (original !== query) {
          query = query + '&hasNoPostalOrder=true';
        }
        return query;
      }
    }
  }

  select(record) {
    this.params.dialog.ok(record);
  }
}
