import {inject, InlineViewStrategy} from 'aurelia-framework';
import {Api} from 'services/api';
import {Router} from 'aurelia-router';
import {activationStrategy} from 'aurelia-router';
import {DialogController} from 'aurelia-dialog';

@inject(Api, Router, DialogController)
export class MulticafDialogListino {
  constructor(api, router, controller) {
    this.api = api;
    this.router = router;
    this.controller = controller;
  }
  determineActivationStrategy() {
    return activationStrategy.replace;
  }
  toRoute() {
    location.href = '/servizi/abbonamenti?ka-tab-services=activable';
    this.controller.ok();
  }
}
