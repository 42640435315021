import { bindable, inject, customAttribute } from "aurelia-framework";
import { computePosition, flip, shift, offset, arrow } from "@floating-ui/dom";

@customAttribute("static-tooltip")
@inject(Element)
export class StaticTooltipCustomAttribute {
  @bindable({ primaryProperty: true }) value;
  @bindable() style = 'static-tooltip';
  @bindable() placement = "top";
  @bindable() delay = 1000;

  constructor(element) {
    this.element = element;
    this.body = document.body;
    this.mouseEnterHandler = this.handleMouseEnter.bind(this);
    this.mouseLeaveHandler = this.handleMouseLeave.bind(this);
  }

  attached() {
    this.element.addEventListener("mouseenter", this.mouseEnterHandler);
    this.element.addEventListener("mouseleave", this.mouseLeaveHandler);
  }

  detached() {
    this.removeTooltip();
    this.element.removeEventListener("mouseenter", this.mouseEnterHandler);
    this.element.removeEventListener("mouseleave", this.mouseLeaveHandler);
  }

  handleMouseEnter(e) {
    if (this.showTimeout || this.tooltip || !this.value || !this.value.text || !this.value.href || !this.value.text.trim().length) {
      return;
    }
    this.showTimeout = setTimeout(() => {
      this.createTooltip(e);
      this.showTimeout = null;
    }, this.delay);
  }

  handleMouseLeave() {
    if (this.showTimeout) {
      clearTimeout(this.showTimeout);
      this.showTimeout = null;
    }
  }

  createTooltip(event) {
    this.tooltip = document.createElement("div");
    this.tooltip.role = "tooltip";
    this.tooltip.classList.add("ka-tooltip", "static-tooltip");
    if (this.style) {
      this.tooltip.classList.add(this.style);
    }
    this.tooltip.innerHTML = `
      <div class="header">
        <h3>Assistenza</h3>
        <div class="closure"></div>
      </div>
      <div class="value">
        <p>
          <strong>${this.value.text}</strong><br>
          <a href="${this.value.href}" target="_blank">Se ha dei dubbi visita il nostro portale di assitenza!</a>
        </p>
      </div>
      <div class="arrow"></div>
    `;

    this.body.insertBefore(this.tooltip, this.body.firstChild);

    setTimeout(() => {
      if (!this.tooltip) return;
      this.tooltip.classList.add("visible");
      const closure = this.tooltip.querySelector(".closure");
      const arrowElement = this.tooltip.querySelector(".arrow");

      computePosition(event.target, this.tooltip, {
        placement: this.placement,
        middleware: [
          offset(5),
          flip(),
          shift({ padding: 5 }),
          arrow({ element: arrowElement, padding: 10 })
        ],
      }).then(({ x, y, placement, middlewareData }) => {
        Object.assign(this.tooltip.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
        const { x: arrowX, y: arrowY } = middlewareData.arrow;
        const staticSide = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right',
        }[placement.split('-')[0]];
        Object.assign(arrowElement.style, {
          left: arrowX != null ? `${arrowX}px` : '',
          top: arrowY != null ? `${arrowY}px` : '',
          right: '',
          bottom: '',
          [staticSide]: '-4px',
        });
      });

      if (closure) {
        closure.addEventListener("click", this.removeTooltip.bind(this));
      }
    }, 0);
  }

  removeTooltip() {
    if (this.tooltip && this.body.contains(this.tooltip)) {
      this.body.removeChild(this.tooltip);
      this.tooltip = null;
    }
  }
}
