import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {KaToast} from 'ka-components';
import mdt from 'services/md-tools';
import { DateTime } from 'luxon';

@inject(Api, KaToast)
export class ServiceDialogAbbonamentiStatoServizio {
  resource = {
    controls: {},
    schema: {
      statusReason: {
        label: 'Messaggio per il cliente',
        control: 'textarea',
        required: true
      },
      applyStatusAt: {
        label: 'A partire da quando?',
        control: 'datetime',
        required: true
      },
      confirm: {
        label: 'Accetta e prosegui',
        description: 'Confermi di aver compreso i termini dell\'operazione?',
        control: 'check',
        required: true
      }
    },
    data: {
      applyStatusAt: DateTime.now().toUTC().set({seconds: 0, milliseconds: 0}).toISO({ suppressMilliseconds: true })
    },
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        console.log(control);
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      let errorPayload = mdt.parsers.apiError(error, this.resource.controls);
      if (errorPayload) this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>'+errorPayload.title+':</strong><div style="margin-top:1rem;border:1px solid #e0e0e0;padding:1rem;max-height:400px;overflow:auto;background-color:#ffffff">' + errorPayload.body + '</div>'});
    }
  }

  /* Messages */
  messages =  {
    ACTIVE: 'Riattiva una sottoscrizione a un servizio in stato di temporanea sospensione.',
    TERMINATED: 'Chiude definitivamente una sottoscrizione ad un servizio, per riattivare il servizio sarà necessario avviare una nuova sottoscrizione.',
    SUSPENDED: 'Sospende temporaneamente una sottoscrizione a un servizio. Non sarà comunque possible attivarne un\'altro della stessa tipologia fino alla scandenza.'
  }

  constructor(api, kaToast) {
    this.api = api;
    this.toast = kaToast;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
    this.message = this.messages[this.params.status];
  }

  save() {
    this.resource.validate().then(() => {
      let data = {
        status: this.params.status,
        statusReason: this.resource.data.statusReason,
        applyStatusAt: this.resource.data.applyStatusAt
      };
      if (this.params.status !== 'SUSPENDED') delete data.applyStatusAt;
      this.api.patch(`users/${ this.params.service.ownerId || 'me' }/flat-services-activations/${this.params.service.id}`, data).then(() => {
        this.toast.show(`Servizio modificato con successo!`, 'success');
        this.params.dialog.ok();
      }).catch(error => {
        console.error();
        this.resource.error(error);
      });
    });
  }
}
