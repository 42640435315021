var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _RestClient_instances, _RestClient_baseUrl, _RestClient_makeUrl, _RestClient_makeRequest, _APIResponse_jsonMimeTypes;
// @ts-ignore
import ENVIRONMENT from '../environment';
export class RestClient {
    constructor() {
        _RestClient_instances.add(this);
        _RestClient_baseUrl.set(this, void 0);
        Object.defineProperty(this, "auth", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        __classPrivateFieldSet(this, _RestClient_baseUrl, new URL(ENVIRONMENT.APP_API_BASE_URL), "f");
        this.auth = new RestAuth();
    }
    // Retrieve the device fingerprint from local storage
    get xhrFingerprint() {
        return localStorage.getItem('xhr-fingerprint');
    }
    // Execute the fetch and handle API response
    async fetch(request) {
        try {
            const response = await fetch(request);
            const apiResponse = await APIResponse.from(response);
            if (!apiResponse.ok) {
                const errorMessages = apiResponse.data?.errors?.map((err) => err.msg).join(", ") || "Server error";
                console.warn(`[rest-client] ${apiResponse.status} error: ${errorMessages}`);
            }
            return apiResponse;
        }
        catch (error) {
            console.error("[rest-client] Fetch error:", error);
            throw error;
        }
    }
    // GET request with optional query parameters
    get(endpoint, data) {
        const url = __classPrivateFieldGet(this, _RestClient_instances, "m", _RestClient_makeUrl).call(this, endpoint);
        if (data) {
            url.search = new URLSearchParams([...url.search, ...data]).toString();
        }
        const request = __classPrivateFieldGet(this, _RestClient_instances, "m", _RestClient_makeRequest).call(this, url, "GET");
        return this.fetch(request);
    }
}
_RestClient_baseUrl = new WeakMap(), _RestClient_instances = new WeakSet(), _RestClient_makeUrl = function _RestClient_makeUrl(endpoint) {
    return new URL(`${__classPrivateFieldGet(this, _RestClient_baseUrl, "f")}${endpoint}`);
}, _RestClient_makeRequest = function _RestClient_makeRequest(url, method, data) {
    const headers = new Headers({
        "Accept-Language": "it",
        "Content-Type": "application/vnd.api+json",
        "X-api-client-name": ENVIRONMENT.APP_API_CLIENT_NAME,
        "X-api-key": ENVIRONMENT.APP_API_KEY,
        "X-api-client-version": ENVIRONMENT.APP_API_CLIENT_VERSION,
    });
    if (ENVIRONMENT.APP_DEBUG === "true") {
        headers.append("X-ignore-test-agreements", "ignore");
    }
    if (this.xhrFingerprint) {
        headers.append("X-Device-Fingerprint", this.xhrFingerprint);
    }
    if (this.auth.accessToken) {
        headers.append("Authorization", `Bearer ${this.auth.accessToken}`);
    }
    const options = {
        method,
        headers,
        body: data ? JSON.stringify(data) : null,
    };
    return new Request(url.toString(), options);
};
class APIResponse {
    constructor(config = {}) {
        Object.defineProperty(this, "data", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "meta", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "headers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Headers()
        });
        Object.defineProperty(this, "ok", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 200
        });
        _APIResponse_jsonMimeTypes.set(this, [
            "application/json",
            "application/ld+json",
            "application/vnd.api+json",
            "application/x-javascript",
            "text/javascript",
            "text/x-javascript",
            "text/x-json",
        ]);
        Object.assign(this, config);
    }
    // Check if APIResponse's content type is JSON
    isJson() {
        const contentType = (this.headers.get("Content-Type") || "").replace(/\s*[,;].*$/, "");
        return __classPrivateFieldGet(this, _APIResponse_jsonMimeTypes, "f").includes(contentType);
    }
    // Factory method to create APIResponse from a fetch response
    static async from(response) {
        const apiResponse = new this({
            headers: response.headers,
            ok: response.ok,
            status: response.status,
        });
        apiResponse.data = apiResponse.isJson() ? await response.json() : await response.text();
        return apiResponse;
    }
}
_APIResponse_jsonMimeTypes = new WeakMap();
export class RestAuth {
    get accessToken() {
        return localStorage.getItem("access_token");
    }
    set accessToken(accessToken) {
        if (accessToken) {
            localStorage.setItem("access_token", accessToken);
        }
        else {
            localStorage.removeItem("access_token");
        }
    }
    get refreshToken() {
        return localStorage.getItem("refresh_token");
    }
    set refreshToken(refreshToken) {
        if (refreshToken) {
            localStorage.setItem("refresh_token", refreshToken);
        }
        else {
            localStorage.removeItem("refresh_token");
        }
    }
    // Check if the user is authenticated
    get isAuthenticated() {
        return !!this.accessToken && !!this.refreshToken;
    }
}
