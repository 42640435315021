import { Api } from 'services/api';
import mdt from 'services/md-tools';
import { Router } from 'aurelia-router';
import { inject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { KaToast, KaDialog } from 'ka-components';
import { QueueProperties } from 'classes/queueProperties';
import { TableInterface, SearchInterface } from 'classes/md-table';

@inject(Router, Api, KaToast, KaDialog, DialogService)
export class ViewArchived {
  mdt = mdt;
  statuses;
  userData;
  channels;
  statusMessages;
  constructor(router, api, toast, dialog, dialogService) {
    this.router = router;
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
    this.dialogService = dialogService;
  }

  activate(model) {
    this.tabs = model.tabs;
    this.params = model.params;
    this.tools = model.tools;
    this.privileges = model.privileges;
    this.statusMessages = this.params.queueProperties.statusMessages;
    this.statuses = this.params.queueProperties.statuses;
    this.channels = this.params.queueProperties.channels;
  }

  attached() {
    // Queues table interface
    this.tableInterface = new TableInterface({
      name: 'table-archived-queues',
      client: this.api,
      endpoint: null,
      query: 'directory=ARCHIVE'
    });

    this.tableInterface.initialize();

    this.tableInterface.parseResponse = (response) => {
      response.forEach((element) => {
        const _messagesStatusCount = element.messagesStatusCount;
        const messagesStatusCountArray = Object.keys(_messagesStatusCount).map((key) => ({
          key: key,
          value: _messagesStatusCount[key],
        }));
        element.messagesStatusCount = messagesStatusCountArray;
      });
      return response;
    };


    // Queues Search interface
    this.searchInterface = new SearchInterface({
      name: 'search-archived-queues',
      table: this.tableInterface,
      schema: {
        context: {
          label: 'Quali spedizioni visualizzare?',
          control: 'combo',
          datavalue: 'value',
          datatext: 'text',
          datasource: this.tools.setContextDatasource(this.api.user),
          required: true,
        },
        owner: {
          control: 'combo',
          label: mdt.parsers.contextualize(this.api.user, 'Utente'),
          datavalue: 'id',
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false,
          datasource: {
            table: `${this.privileges.isOperator ? 'bo/users' : 'users/me/users'}?include=user-profiles`,
            query: { search: 'text-search', item: 'id-in' }
          }
        },
        clients: {
          control: 'combo',
          label: 'Gestionale utilizzato',
          datavalue: 'id',
          datatext: 'name',
          datamultiple: false,
          datapreload: true,
          datasource: this.params.clients,
          query: 'client-uuid'
        },
        channel: {
          control: 'combo',
          label: "Canale d'invio",
          datavalue: 'value',
          datatext: 'text',
          datamultiple: false,
          datapreload: true,
          datasource: this.tools.setChannelDatasource(),
          query: 'channel'
        },
        createdAtGte: {
          label: 'Spedizione creata dal',
          control: 'date',
          query: 'created-at-gte'
        },
        createdAtLte: {
          label: 'al',
          control: 'date',
          query: 'created-at-lte'
        },
        totalGrossGte: {
          label: 'Importo minimo',
          control: 'number',
          query: 'total-gross-gte'
        },
        totalGrossLte: {
          label: 'Importo massimo',
          control: 'number',
          query: 'total-gross-lte'
        },
        recipient: {
          label: 'Destinatario',
          control: 'text',
          query: 'recipient-text-search-like'
        },
        shipment: {
          label: 'Spedizione',
          control: 'text',
          query: 'text-search-like'
        },
        paymentMethod: {
          label: 'Metodo di pagamento',
          control: 'combo',
          datavalue: 'value',
          datatext: 'text',
          datamultiple: false,
          datapreload: false,
          datasource: [
            { value: 'null', text: 'Qualsiasi' },
            { value: 'SDD', text: 'SDD' },
            { value: 'BANK-TRANSFER', text: 'Bonifico bancario' },
          ],
          query: 'payment-method'
        },
        shortConfirmationCode: {
          label: 'Codice di conferma pagamento',
          control: 'text',
          query: 'short-confirmation-code'
        },
        status: {
          label: 'Stato',
          control: 'combo',
          datavalue: 'value',
          datatext: 'text',
          datamultiple: false,
          datapreload: false,
          datasource: this.tools.setStatusDatasource(['COMPLETED', 'Annullata'], 'group'),
          query: 'status-in'
        }
      },
      data: {
        totalGrossGte: 0,
        paymentMethod: 'null',
        status: 'null',
        context: this.privileges.isOperator ? 'null' : this.api.user.group === 'MASTER_USER' ? 'USER&SUBUSERS' : 'USER'
      }
    });

    this.tableInterface.parseResponse = (response) => {
      return this.tools.parseMessagesStatusCount(response);
    };

    this.searchInterface.initialize().then(() => { return this.search(); });
  }

  async search() {
    this.setEndpoint();
    return this.searchInterface.search();
  }

  setContextSuffix() {
    let contextSuffix = '';

    if (this.searchInterface?.data?.context === 'USER') {
      contextSuffix = '';
    } else if (this.searchInterface?.data?.context === 'SUBUSERS') {
      switch (true) {
        case this.privileges.isOperator:
          contextSuffix = '~subs';
          break;
        case !this.searchInterface.data.owner:
          contextSuffix = '~subs';
          break;
        case this.searchInterface.data.owner:
          contextSuffix = '';
          break;
      }
    } else {
      contextSuffix = '~all';
    }

    if (contextSuffix.length) {
      contextSuffix = `/${contextSuffix}`;
    }

    return contextSuffix;
  }


  setEndpoint() {
    const contextSuffix = this.setContextSuffix();

    this.searchInterface.data.owner = this.searchInterface?.data?.context !== 'SUBUSERS' && !this.privileges.isOperator ? null : this.searchInterface.data.owner;
    let endpoint = this.privileges.isOperator && !this.searchInterface.data.owner ? 'bo/queue-outlines' : `users/${this.searchInterface.data.owner || 'me'}/queue-outlines${contextSuffix}`;

    return this.tableInterface.endpoint = endpoint;
  }

  reset() {
    this.searchInterface.reset();
    this.tools.setDefault(this.searchInterface, [{ paymentMethod: 'null' }, { totalGrossGte: 0 }, { status: 'null'}]);
    this.search();
  }

  vmSentReload() {
    let vmSent = this.tabs.find(x => x.id === 'inviate').controller.currentViewModel;
    return vmSent.search();
  }

  vmCanceledReload() {
    let vmCanceled = this.tabs.find(x => x.id === 'annullate').controller.currentViewModel;
    return vmCanceled.search();
  }

  unarchive(queue) {
    let data = { directory: null };
    this.api.patch(`users/me/queues/${queue.uuid}`, data).then(() => {
      this.search();
      this.vmCanceledReload();
      this.vmSentReload();
      this.toast.show('Spedizione disarchiviata con successo', 'success');
    }).catch(error => {
      console.log('Error queues/ PATCH', error);
      this.api.dialogError(error);
    })
  }

  detail(queue) {
    this.router.navigate(`spedizioni/reportistica/${queue.owner.id}/${queue.uuid}`);
  }

  setContext() {
    if (this.privileges.isOperator) {
      this.searchInterface.data.context = null;
      if (this.searchInterface.data.owner) {
        this.tools.getUserData(this.searchInterface.data.owner).then((xhr) => {
          const response = xhr.response;
          response.profile = response.profile[0];
          this.userData = response;
          if (this.userData.parentId) {
            this.searchInterface.data.context = null;
          } else {
            this.searchInterface.schema.context.datasource = this.tools.setContextDatasource(this.userData);
          }
        });
      } else {
        this.searchInterface.data.context = null;
        this.userData = null;
      }
    }
  }
}

