import {Aurelia, inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';
import moment from 'moment';
@inject(Aurelia, Router, Api, KaDialog, KaToast)
export class vmListaNotifiche {
  userId = null;

  constructor(aurelia, router, api, dialog, toast) {
    this.aurelia = aurelia;
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(model) {
    this.params = model.params;
    this.privileges = model.privileges;
    this.tools = model.tools;
  }

  attached() {
    this.init();
  }

  init() {
    // INIT TableInterface
    this.tableInterface = new TableInterface({
      name: 'tab-notifiche-sistema',
      client: this.api,
      endpoint: null,
      query: null
    });

    // PARSE query
    this.tableInterface.parseQuery = (query) => {
      query = new URLSearchParams(query);
      if (query.has('schedules-email')) {
         query.set('schedules-email', query.get('schedules-email') === 'true' ? 'YES' : 'NO');
      }
      if (query.has('schedules-sms')) {
        query.set('schedules-sms', query.get('schedules-sms') === 'true' ? 'YES' : 'NO');
      }
      if (query.has('schedules-ui')) {
        query.set('schedules-ui', query.get('schedules-ui') === 'true' ? 'YES' : 'NO');
      }
      return query.toString();
    };
    this.tableInterface.initialize().catch(error => { console.log('tableInterface initialization failed', error) });

    // INIT SearchInterface
    this.searchInterface = new SearchInterface({
      name: 'tab-notifiche-sistema-search',
      table: this.tableInterface,
      schema: {
        user: { 
          control: 'combo', 
          label: 'Utente',
          datasource: { table: 'bo/users?include=user-profiles', query: { search: 'text-search', item: 'id-in'} },
          datavalue: 'id', 
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false,
          required: true
        },
        code: {
          control: 'combo', 
          label: 'Codice',
          datasource: { table: 'system-notification-templates' },
          datavalue: 'code', 
          datatext: 'code',
          datamultiple: false,
          datapreload: true,
          query: 'code'
        },
        scheduleEmail: {
          label: 'Notifiche Email',
          control: 'check',
          description: 'Canale Email',
          query: 'schedules-email'
        },
        statusEmail: {
          label: ' ',
          control: 'combo',
          datasource: [{ value: 'null', text: 'Nessuno stato' }, { value: 'DISPATCHED', text: 'Inviate' }, { value: 'DISPATCH-FAILED', text: 'Non Inviate' }, { value: 'DELIVERED', text: 'Consegnate' }, { value: 'BOUNCED', text: 'Non Consegnate' }],
          datavalue: 'value',
          datatext: 'text',
          query: 'status-email'
        },
        scheduleSms: {
          label: 'Notifiche SMS',
          control: 'check',
          description: 'Canale SMS',
          query: 'schedules-sms'
        },
        statusSms: {
          label: ' ',
          control: 'combo',
          datasource: [{ value: 'null', text: 'Nessuno stato' }, { value: 'DISPATCHED', text: 'Inviate' }, { value: 'DISPATCH-FAILED', text: 'Non Inviate' }, { value: 'DELIVERED', text: 'Consegnate' }, { value: 'BOUNCED', text: 'Non Consegnate' }],
          datavalue: 'value',
          datatext: 'text',
          query: 'status-sms'
        },
        scheduleUiNotice: {
          label: 'Notifiche UI',
          control: 'check',
          description: 'Canale UI',
          query: 'schedules-ui'
        },
        statusUI: {
          label: ' ',
          control: 'combo',
          datasource: [{value: 'null', text: 'Nessuno stato'}, {value: 'DISPATCHED', text: 'Inviate'}, {value: 'DISPATCH-FAILED', text: 'Non Inviate'}, {value: 'DELIVERED', text: 'Consegnate'}, {value: 'BOUNCED', text: 'Non Consegnate'}],
          datavalue: 'value',
          datatext: 'text',
          query: 'status-ui'
        }
      },
      data: {
        statusEmail: 'null',
        statusSms: 'null',
        statusUI: 'null'
      }
    });
    this.searchInterface.initialize().then(() => {
      this.selectedChannel();
      if (this.searchInterface.data.user) this.search();
    }).catch(error => { console.log('searchInterface initialization failed', error) });
  }

  search() {
    this.userId = this.searchInterface.data.user;
    this.tableInterface.endpoint = `bo/users/${this.userId}/system-notifications`;
    this.searchInterface.search().then(() => {
      // User profile
      this.api.get(`users/${this.userId}?include=user-profiles`).then(xhr => {
        let response = xhr.response;
        if (response.profile && Array.isArray(response.profile)) response.profile = response.profile[0];
        this.selectedUser = response;
      });
    });
  }

  reset() {
    this.tableInterface.reset();
    this.searchInterface.reset(true);
    this.selectedChannel();
  }

  selectedChannel() {
    if (!this.searchInterface.data.scheduleEmail) this.searchInterface.data.statusEmail = 'null';
    if (!this.searchInterface.data.scheduleSms) this.searchInterface.data.statusSms = 'null';
    if (!this.searchInterface.data.scheduleUiNotice) this.searchInterface.data.statusUI = 'null';
  }

  statusTooltip(step, channel, item) {
    let dispatchedAt = `${channel}DispatchedAt`;
    let dispatchError = `${channel}DispatchErrorAt`;
    let dispatchErrorMessage = `${channel}DispatchErrorMessage`;
    let deliveredAt = `${channel}DeliveredAt`;
    let bounced = `${channel}BouncedAt`;
    let bounceReason = `${channel}BounceReason`;
    let date = this.aurelia.resources.valueConverters.dateFormat.toView(item.createdAt);

    // Tooltip
    if (step == 1) {
      return `<div class="notifications-tooltip success"><strong>Creata</strong><br><em>${date}</em></div>`;
    } else if (step == 2) {
      date = this.aurelia.resources.valueConverters.dateFormat.toView(item[dispatchError] ? item[dispatchError] : item[dispatchedAt]);
      if (item[dispatchError]) return `<div class="notifications-tooltip error"><strong>Non inviata</strong><br>${item[dispatchErrorMessage]}<br><em>${date}</em></div>`;
      if (item[dispatchedAt] && !item[dispatchError]) return `<div class="notifications-tooltip success"><strong>Inviata</strong><br><em>${date}</em></div>`;
      if (item.createdAt && !item[dispatchedAt] && !item[dispatchError]) return `<div class="notifications-tooltip pending"><strong>In attesa di essere inviata</strong></div>`;
    } else if (step == 3) {
      if (channel === 'ui' && dispatchedAt) {
        date = this.aurelia.resources.valueConverters.dateFormat.toView(item[dispatchedAt]);
        return `<div class="notifications-tooltip success"><strong>Consegnata</strong><em>${date}</em></div>`
      } else {
        date = this.aurelia.resources.valueConverters.dateFormat.toView(item[bounced] ? item[bounced] : item[deliveredAt]);
        if (item[bounced]) return `<div class="notifications-tooltip error"><strong>Non consegnata</strong><br>${item[bounceReason]}<br><em>${date}</em></div>`;
        if (item[deliveredAt] && !item[bounced]) return `<div class="notifications-tooltip success"><strong>Consegnata</strong><br><em>${date}</em></div>`;
        if (item[dispatchedAt] && !item[deliveredAt] && !item[bounced]) return `<div class="notifications-tooltip pending"><strong>In attesa di essere consegnata</strong></div>`;
      }
    }
  }

  detail(item) {
    this.dialog.open({
      title: `Dettaglio notifica per ${this.selectedUser.profile.displayName}`,
      class: 'large',
      viewModel: PLATFORM.moduleName('views/backoffice/notifiche-sistema/dialogs/notifiche-dettaglio'),
      viewModelParams: { notificationUuid: item.uuid, userId: this.userId, userDisplayName: this.selectedUser.profile.displayName }
    }, true);
  }
}
