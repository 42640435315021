import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { KaToast, KaDialog } from 'ka-components';

@inject(Router, Api, KaToast, KaDialog)
export class Help {
  urls = {
    privacy: "https://termsofservice.multidialogo.it/multidialogo/privacy-policy/",
    mailTo: "mailto:assistenza@multidialogo.it",
    sla: "https://termsofservice.multidialogo.it/multidialogo/sla/",
    knowledge: "https://docs.multidialogo.it/s/"
  }

  constructor(router, api, toast, dialog) {
    this.router = router;
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
  }

  attached() {
    this.supportTickets.reload();
  }

  supportTickets = {
    data: null,
    reload: () => {
      this.api.get(`users/me/support-tickets`).then(xhr => {
        let response = xhr.response.filter(x => x.expired === false);
        this.supportTickets.data = response && response.length > 0 ? response[0] : null;
      }).catch(error => {
        console.log('Errore GET support-tickets', error);
      });
    },
    request: () => {
      this.api.post(`users/me/support-tickets`, {}).then(() => {
        this.toast.show('Codice assistenza generato!', 'success');
        this.supportTickets.reload();
      }).catch(error => {
        console.log('Errore POST support-tickets', error);
        this.toast.show('Impossibile generare codice assistenza!', 'error');
      });
    }
  };

  dialogHelpServicesAgreement() {
    return this.dialog.open({
      title: 'Condizioni e termini contrattuali',
      class: 'help-services-agreement small',
      viewModel: PLATFORM.moduleName('views/help/dialogs/help-services-agreement')
    });
  }

  openExternalLink(target) {
    window.open(target, "_blank");
  }
}
