import { inject, bindable } from 'aurelia-framework';
import { Api } from 'services/api';
import mdt from 'services/md-tools';
import { KaToast, KaDialog } from 'ka-components';

@inject(Api, KaToast, KaDialog)
export class ImpostazioniNotifiche {
  @bindable() prefsel = null;

  constructor(api, toast, dialog) {
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
  }

  activate(params) {
    this.params = params;
  }

  prefselChanged(value, old) {
    if (value === old) return;
    setTimeout(() => {
      this.pushPreferences.isOverride = null;
      this.uiPreferences.isOverride = null;
      this.uiPreferences.reload();
      this.emailPreferences.reload();
      this.pushPreferences.reload();
      this.privacyConsent.reload();
    }, 0);
  }

  uiPreferences = {
    endpoint: null,
    schema: {
      email: { 
        control: 'list', 
        datasource: { 
          model: { 
            view: 'address', 
            schema: [ { field: 'address', control: 'text', label: 'Indirizzo email' } ] 
          } 
        }, 
        label: 'Inserire gli indirizzi email sui quali ricevere le notifiche di sistema',
        max: 3
      }
    },
    data: {},
    reload: () => {
      this.uiPreferences.isCal = String(this.prefsel) !== String(this.api.user.id);
      this.api.get(`users/${this.prefsel}/preferences`).then(x => {
        if (this.uiPreferences.isOverride === null) {
          this.uiPreferences.isOverride = (this.uiPreferences.isCal && x.response.requested?.notification?.email?.length) || (!this.uiPreferences.isCal && x.response.current?.notification?.email?.length)
        };
        this.uiPreferences.data = mdt.preferences.mergeModelData(this.uiPreferences.schema, ['notification'], x.response);
        this.uiPreferences._data = JSON.parse(JSON.stringify(this.uiPreferences.data));
      });
    },
    override: ($event) => {
      $event.model.busy = true;
      if (this.uiPreferences.data.email) {
        this.api.patch(`users/${this.prefsel}/preferences/notification`, {email: []}).then(x => {
          this.uiPreferences.isOverride = !this.uiPreferences.isOverride;
        }).catch(e => {}).finally(() => {
          this.uiPreferences.reload();
          $event.model.busy = false;
        });
      }
    },
    save: ($event) => {
      $event.model.busy = true;
      let data = this.uiPreferences.data;
       data = mdt.helpers.diffObject(this.uiPreferences._data, this.uiPreferences.data);
      if (data && Object.entries(data).length) {
       this.uiPreferences.resource.save(data, `users/${this.prefsel}/preferences/notification`, 'patch').then(x => {
          this.toast.show('Modifiche salvate con successo!', 'success');
          this.uiPreferences.reload();
        }).catch(e => {
          this.toast.show('Errore nel salvataggio delle modifiche!', 'error');
        }).finally(() => {
          $event.model.busy = false;
        });
      }
      else $event.model.busy = false;
    }
  };

  emailPreferences = {
    endpoint: null,
    schema: {
      credit: {
        enabled: { control: 'check' },
        serviceThreshold: { control: 'number', label: 'Soglia minima servizio €' },
        postageThreshold: { control: 'number', label: 'Soglia minima affrancatura €' },
      },
      dailyDigest: {
        queue: null
      },
      directDebit: {
        sdd: {
          enabled: { control: 'check' },
          threshold: { control: 'number', label: 'Soglia minima deposito cauzionale €' },
        }
      },
      bouncedEmailsDigest: {
        enabled: { control: 'check' },
      }
    },
    data: {},
    checks: {
      dailyDigest: false
    },
    reload: () => {
      this.api.get(`users/me/preferences`).then(x => {
        this.emailPreferences.data = mdt.preferences.mergeModelData(this.emailPreferences.schema, ['notification'], x.response);
        this.emailPreferences._data = JSON.parse(JSON.stringify(this.emailPreferences.data));
        this.emailPreferences.checks.dailyDigest = this.emailPreferences.data.dailyDigest?.queue?.includes('BLOCKED');
      });
    },
    save: ($event) => {
      $event.model.busy = true;
      let data = this.emailPreferences.data;
      if (this.emailPreferences.checks.dailyDigest === true) {
        if (!data.dailyDigest) data.dailyDigest = { queue: []};
        if (data.dailyDigest.queue.indexOf('BLOCKED') < 0) data.dailyDigest.queue.push('BLOCKED');
      } else if (this.emailPreferences.checks.dailyDigest === false) {
        if (!data.dailyDigest) data.dailyDigest = { queue: []};
        if (data.dailyDigest.queue.indexOf('BLOCKED') > -1) data.dailyDigest.queue.splice(data.dailyDigest.queue.indexOf('BLOCKED'), 1);
      }
      data = mdt.helpers.diffObject(this.emailPreferences._data, this.emailPreferences.data);
      if (data && Object.entries(data).length) {
        if (data.credit?.postageThreshold) data.credit.postageThreshold = parseInt(data.credit.postageThreshold, 10);
        if (data.credit?.serviceThreshold) data.credit.serviceThreshold = parseInt(data.credit.serviceThreshold, 10);
        this.emailPreferences.resource.save(data, `users/me/preferences/notification`, 'patch').then(x => {
          this.toast.show('Modifiche salvate con successo!', 'success');
          this.emailPreferences.reload();
        }).catch(e => {
          this.toast.show('Errore nel salvataggio delle modifiche!', 'error');
        }).finally(() => {
          $event.model.busy = false;
        });
      }
      else $event.model.busy = false;
    }
  };

  pushPreferences = {
    endpoint: null,
    schema: {
      appPush: {
        blocked: { control: 'check' },
        refused: { control: 'check' },
        delivered: { control: 'check' }
      }
    },
    data: {},
    checks: {
      dailyDigest: false
    },
    reload: () => {
      this.pushPreferences.isCal = String(this.prefsel) !== String(this.api.user.id);
      this.api.get(`users/${this.prefsel}/preferences`).then(x => {
        if (this.pushPreferences.isOverride === null) this.pushPreferences.isOverride = (this.pushPreferences.isCal && x.response.requested?.notification?.appPush) || (!this.pushPreferences.isCal && x.response.current?.notification?.appPush);
        this.pushPreferences.data = mdt.preferences.mergeModelData(this.pushPreferences.schema, ['notification'], x.response);
        this.pushPreferences._data = JSON.parse(JSON.stringify(this.pushPreferences.data));
      });
    },
    override: ($event) => {
      $event.model.busy = true;
      this.api.patch(`users/${this.prefsel}/preferences/notification`, {appPush: {enableOverride: !this.pushPreferences.isOverride}}).then(x => {
        this.pushPreferences.isOverride = !this.pushPreferences.isOverride;
      }).catch(e => {}).finally(() => {
        this.pushPreferences.reload();
        $event.model.busy = false;
      });
    },
    save: ($event) => {
      $event.model.busy = true;
      let data = this.pushPreferences.data;
      data = mdt.helpers.diffObject(this.pushPreferences._data, this.pushPreferences.data);
      if (data && Object.entries(data).length) {
        this.pushPreferences.resource.save(data, `users/${this.prefsel}/preferences/notification`, 'patch').then(x => {
          this.toast.show('Modifiche salvate con successo!', 'success');
          this.pushPreferences.reload();
        }).catch(e => {
          this.toast.show('Errore nel salvataggio delle modifiche!', 'error');
        }).finally(() => {
          $event.model.busy = false;
        });
      }
      else $event.model.busy = false;
    }
  };

  privacyConsent = {
    data: null,
    reload: () => {
      this.api.get(`users/${this.prefsel}/preferences`).then(xhr => {
        const response = xhr.response;
        this.privacyConsent.data = response.current?.notification?.commercial;
      });
    },
    save: ($event) => {
      $event.model.busy = true;
      const data = { commercial: this.privacyConsent.data };
      this.api.patch(`users/${this.prefsel}/preferences/notification`, data).then(() => {
        this.toast.show('Modifiche salvate con successo!', 'success');
        this.privacyConsent.reload();
      }).catch(xhr => {
        console.log('PATCH preferences/notification', xhr);
        this.toast.show('Errore nel salvataggio delle modifiche!', 'error');
      }).finally(() => {
        $event.model.busy = false;
      });
    }
  };
}
