import {PLATFORM, inject} from 'aurelia-framework';
import {Router, activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';

@inject(Router, Api, KaDialog, KaToast)
export class viewAgreements {
  endpoint = 'bo/user-agreements';
  isActive = false;
  agreement = {
    route: PLATFORM.moduleName('views/_agreement/agreement'),
    view: null,
    back: true
  }
  
  /* Agreements */
  agreements = {
    schema: {
      startAt: {
        label: 'Data inizio',
        control: 'date',
        readonly: false,
        query: 'createdAt-lte'
      },
      label: {
        label: 'Titolo',
        control: 'text',
        readonly: false
      },
      content: {
        label: 'Descrizione',
        readonly: false,
        control: 'editor'
      },
      status: {
        label: 'Attivazione',
        control: 'combo',
        datasource: [{ text: 'Attivo', value: 'ACTIVE' }, { text: 'Non attivo', value: 'NOT-ACTIVE' }],
        datatext: 'text',
        datavalue: 'value',
        datapreload: true,
        datamultiple: false,
        readonly: true,
        query: 'statusCode-in'
      }
    }
  };

  /* Clauses */
  clauses = {
    schema: {
      label: {
        label: 'Titolo',
        control: 'text'
      },
      content: {
        label: 'Descrizione',
        control: 'textarea'
      }
    },
    data: []
  };

  constructor(router, api, kaDialog, kaToast) {
    this.router = router;
    this.api = api;
    this.dialog = kaDialog;
    this.toast = kaToast;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
  }
 
  attached() {
    /* Table interface */
    this.agreementsTableInterface = new TableInterface({
      name: 'agreements-table',
      client: this.api,
      endpoint: this.endpoint,
      query: null
    });

    /* Table Search */
    this.agreementsSearchInterface = new SearchInterface({
      name: 'agreements-table-search',
      table: this.agreementsTableInterface,
      schema: {
        startAtLte: {
          label: 'Data inizio dal',
          control: 'date',
          query: 'createdAt-gte'
        },
        startAtGte: {
          label: 'al',
          control: 'date',
          query: 'createdAt-lte'
        },
        status: this.agreements.schema.status
      },
      data: {}
    });

    /* Initialize & Search function */
    this.agreementsTableInterface.initialize().then(() => {
      this.agreementsSearchInterface.initialize().then(() => {
        this.init();
      }).catch(error => { console.error('TableSearchInterface-initialize agreements failed', error); });
    }).catch(error => { console.error('TableInterface-initialize agreements failed', error); });
  }

  init() {
    if (!this.params.id) {
      this.agreementsSearchInterface.search().catch(error => { console.error('TableSearchInterface-search agreements failed', error); });
    }
    this.clauses.data = [];
    if (this.agreements.resource) {
      if (this.params.id !== 'new') {
        this.agreements.resource.load(`bo/user-agreements/${this.params.id}`).then(() => {
          if (this.agreements.resource.data.status === 'ACTIVE') this.isActive = true;
          else this.isActive = false;

          /* Clauses */
          if (this.agreements.resource.data.clauses) {
            this.agreements.resource.data.clauses.forEach(clause => {
              this.clauses.data.push({ data: JSON.parse(JSON.stringify(clause)) });
            });
          }
        });
      } else if (this.params.id === 'new') {
        if (!this.agreements.resource.data.status) {
          this.agreements.resource.data.status = 'NOT-ACTIVE';
          this.isActive = false;
        }
      }
    };
  }

  new() {
    this.router.navigateToRoute('backoffice/agreements', { id: 'new' });
  }

  edit(record) {
    /* // Set readonly from schema js
    for (let property in this.agreements.schema) this.agreements.schema[property].readonly = record.status === 'ACTIVE' ? true : false;
    for (let property in this.clauses.schema) this.clauses.schema[property].readonly = record.status === 'ACTIVE' ? true : false;
    this.agreements.schema.status.readonly = true; */
    this.router.navigateToRoute('backoffice/agreements', { id: record.uuid });
  }

  activation(record, $event) {
    if (record.status === 'ACTIVE') return
    if ($event) $event.model.busy = true;
    this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'confirm', body: 'Se attivi questo agreement non potrai più modificarne il contenuto!'}).whenClosed(response => {
      if (!response.wasCancelled) {
        this.api.patch(`bo/user-agreements/${record.uuid}`, { status: 'ACTIVE' }).then(() => {
          this.init();
        }).catch(error => {
          this.toast.show('Errore in fase di attivazione!', 'error');
          console.log('Agreement activation', error);
        }).finally(() => {
          if ($event) $event.model.busy = false;
        })
      }
    });
  }

  view(record) {
    this.agreement.view = record;
  }

  cancel() {
    this.agreement.view = null;
    this.router.navigateToRoute('backoffice/agreements');
  }

  save(event) {
    event.model.busy = true;
    let method = 'post';
    let endpoint = this.endpoint;
    if (!Object.entries(this.agreements.resource.data).length) {
      event.model.busy = false;
      return;
    } else if (this.params.id !== 'new') {
      method = 'patch';
      endpoint = `bo/user-agreements/${this.params.id}`;
    }
    if (this.agreements.resource.data.status !== 'ACTIVE') {
      this.saveData(event, endpoint, method);
      event.model.busy = false;
    }
  }

  saveData(event, endpoint, method) {
    event.model.busy = true;

    // Clauses
    let promises = [];
    let newClause = false;
    if (this.clauses?.data) this.clauses.data.forEach(clause => { if (clause.data && clause.data?.id === undefined) return newClause = true; });
    if (this.params.id !== 'new' && newClause) {
      this.clauses.data.forEach(clause => {
        if (clause.data?.id === undefined) promises.push(clause.resource.save(clause.data, `bo/user-agreements/${this.params.id}/clauses`, 'post'));
      });
    }

    // Save
    this.agreements.resource.save(this.agreements.resource.data, endpoint, method).then(xhr => {
      Promise.all(promises).then(() => {
        if (this.params.id !== 'new' || (this.params.id === 'new' && this.agreements.resource.data.status === 'ACTIVE')) this.cancel();
        else if (this.params.id === 'new' && this.agreements.resource.data.status === 'NOT-ACTIVE') {
          this.params.id = xhr.response.uuid;
          this.init();
        }
        this.toast.show('Agreement creato con successo!', 'success');
      }).catch(error => {
        console.error('Save clauses', error);
        this.toast.show('Errore nel salvataggio!', 'error');
      });
    }).catch(error => {
      console.error('Save agreements', error);
      this.toast.show('Errore nel salvataggio!', 'error');
    }).finally(() => {
      event.model.busy = false;
    });
  }

  deleteClause(event, id = null, idx) {
    let newClause = false 
    if (this.clauses?.data) this.clauses.data.forEach(clause => { if (clause.data && clause.data?.id === undefined) return newClause = true; });
    if (id) {
      if (newClause) {
        this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'confirm', body: 'Tutte le clausole non salvate saranno cancellate'}).whenClosed(response => {
          if (!response.wasCancelled) this.deleteClauseData(event, id);
        });
      } else this.deleteClauseData(event, id);
    } else this.clauses.data.splice(idx, 1);
  }

  deleteClauseData(event, id) {
    event.model.busy = true;
    if (!id) return
    this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'confirm', body: 'Stai cancellando questa clausola definitivamente. Confermi?'}).whenClosed(response => {
      if (!response.wasCancelled) {
        this.api.delete(`bo/user-agreements/${this.params.id}/clauses/${id}`).then(() => {  
          this.toast.show('Clausola eliminata!', 'success');
          this.init();
        }).catch(() => {
          this.toast.show('Errore nella cancellazione!', 'error');
        }).finally(() => {
          event.model.busy = false;
        });
      }
    });
  }

  addClause() {
    this.clauses.data.unshift({
      data: { label: null, content: null }
    });
  }
}
