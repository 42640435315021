import {inject, bindable} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {KaToast, KaDialog} from 'ka-components';
import mdt from 'services/md-tools';
import environment from 'environment';

@inject(Api, KaToast, KaDialog)
export class ImpostazioniGenerali {
  @bindable() prefsel = null;
  environment = environment;
  mdt = mdt;

  constructor(api, toast, dialog) {
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
  }
 
  attached() {
    this.preferences.reload();
  }

  prefselChanged(value, old) {
    if (value === old) return;
    console.log('PREFSEL changed', value);
    setTimeout(() => {
      this.mobileNumbers.reload();
    }, 0);
  }

  preferences = {
    endpoint: null,
    schema: {
      masterUserCreditSharing: { control: 'check', datamultiple: false, description: 'Attivo' },
      sdi: {
        code: { control: 'text' },
        genericSubuserCode: { control: 'text' }
      },
      debtGuarantorDisplayName: { control: 'text', label: 'Garante deposito' },
      debtGuarantorUuid: {
        label: 'Garante deposito', 
        control: 'combo',  
        datatext: 'profile[0].displayName',
        datavalue: 'uuid', 
        datamultiple: false,
        datapreload: false,
        datasource: { table: `bo/users?include=user-profiles&user-group-in=MASTER_USER`, query: { search: 'text-search', item: 'uuid-in'} },
        query: 'parent-uuid-in'
      },
      enableCreditPartialUsage: { control: 'check', datamultiple: false, label: 'Usa il credito residuo per il pagamento delle spedizioni', description: 'Attivo' },
      enableToPayQueueDutiesForSubuserAtCheckout: { control: 'check', datamultiple: false, description: 'Attivo' },
      registerBillsAsManagementMovement: { control: 'check', datamultiple: false, label: 'Le fatture delle spedizioni verranno registrate come movimento di gestione, senza la suddivisione per costi personali' }
    },
    data: {},
    checks: {
      masterUserCreditSharing: false
    },
    writeEnabled: false,
    reload: () => {
      this.preferences.isCal = String(this.prefsel) !== String(this.api.user.id);
      this.api.get(`users/me/preferences`).then(x => {
        if (this.preferences.isOverride === null) this.preferences.isOverride = (this.preferences.isCal && x.response.requested?.sender?.email) || (!this.preferences.isCal && x.response.current?.sender?.email);
        this.preferences.data = mdt.preferences.mergeModelData(this.preferences.schema, ['payment'], x.response);
        this.preferences._data = JSON.parse(JSON.stringify(this.preferences.data));
        this.preferences.checks.masterUserCreditSharing = this.preferences.data.masterUserCreditSharing?.scopes?.includes('QUEUE-DUTIES');
      });
      this.writeEnabled = this.api.hasRole('MODIFY_USERS_PRIVILEDGED_PREFERENCES');
    },
    save: ($event) => {
      $event.model.busy = true;
      let data = this.preferences.data;
      if (this.preferences.checks.masterUserCreditSharing === true) {
        if (!data.masterUserCreditSharing) data.masterUserCreditSharing = { scopes: []};
        if (data.masterUserCreditSharing.scopes.indexOf('QUEUE-DUTIES') < 0) data.masterUserCreditSharing.scopes.push('QUEUE-DUTIES');
      } else if (this.preferences.checks.masterUserCreditSharing === false) {
        if (!data.masterUserCreditSharing) data.masterUserCreditSharing = { scopes: []};
        if (data.masterUserCreditSharing.scopes.indexOf('QUEUE-DUTIES') > -1) data.masterUserCreditSharing.scopes.splice(data.masterUserCreditSharing.scopes.indexOf('QUEUE-DUTIES'), 1);
      }
      data = mdt.helpers.diffObject(this.preferences._data, this.preferences.data);
      if (data.debtGuarantorDisplayName) delete data.debtGuarantorDisplayName;
      if (data && Object.entries(data).length) {
        this.preferences.resource.save(data, `users/me/preferences/payment`, 'patch').then(x => {
          this.toast.show('Modifiche salvate con successo!', 'success');
          this.preferences.reload();
        }).catch(e => {
          this.toast.show('Errore nel salvataggio delle modifiche!', 'error');
        }).finally(() => {
          $event.model.busy = false;
        });
      }
      else $event.model.busy = false;
    }
  };

  mobileNumbers = {
    endpoint: null,
    schema: {
      //internationalPrefix: { control: 'number', label: 'Prefisso'},
      internationalPrefix: { control: 'combo', label: 'Prefisso', datasource: [{value: '+39', text: '+39'}], datavalue: 'value', datatext: 'text', datapreload: true },
      number: { control: 'number', label: 'Numero di telefono'},
      confirmationCode: { control: 'text', label: 'Codice di sicurezza ricevuto tramite SMS'}
    },
    records: [],
    optedIn: null,  // List of all mobile numbers already registred and verified
    optedOut: null, // List of all mobile numbers already registred but not yet verified
    uuid: null,
    data: {
      internationalPrefix: '+39',
      number: null,
      confirmationCode: null
    },
    reset: () => {
      this.mobileNumbers.data.number = null;
      this.mobileNumbers.reload();
    },
    reload: () => {
      this.mobileNumbers.isCal = String(this.prefsel) !== String(this.api.user.id);
      this.api.get(`users/${this.prefsel}/preferences/mobile-numbers`).then(x => {
        this.mobileNumbers.records = x.response;
        this.mobileNumbers.optedIn = x.response.filter(x => x.status === 'opted-in');
        this.mobileNumbers.optedOut = x.response.filter(x => x.status === 'opted-out');
        this.mobileNumbers.uuid = (this.mobileNumbers.optedIn[0] || this.mobileNumbers.optedOut[0] || { uuid: null}).uuid;
      });
    },
    request: ($event) => {
      $event.model.busy = true;
      // Normalize data
      this.mobileNumbers.data.internationalPrefix = String(this.mobileNumbers.data.internationalPrefix);
      this.mobileNumbers.data.number = String(this.mobileNumbers.data.number);
      // Override data
      let overrideData = Object.assign({}, this.mobileNumbers.data);
      overrideData.number = overrideData.internationalPrefix + overrideData.number;
      delete overrideData.internationalPrefix;
      delete overrideData.confirmationCode;
      // Save data through md-resource
      this.mobileNumbers.resource.save(overrideData, `users/${this.prefsel}/preferences/mobile-numbers`).then(() => {
        this.toast.show('Richiesta inviata con successo!', 'success');
        this.mobileNumbers.reset();
      }).catch(() => {
        this.toast.show('Impossibile inviare richiesta!', 'error');
      }).finally(() => {
        $event.model.busy = false;
      });
    },
    resend: ($event, record) => {
      $event.model.busy = true;
      this.api.post(`users/${this.prefsel}/preferences/mobile-numbers`, {number: record.number}).then(() => {
        this.toast.show('Richiesta inviata con successo!', 'success');
        this.mobileNumbers.reset();
      }).catch((x) => {
        this.toast.show('Impossibile inviare richiesta!', 'error');
      }).finally(() => {
        $event.model.busy = false;
      });
    },
    confirm: ($event, record) => {
      $event.model.busy = true;
      this.api.post(`users/${this.prefsel}/preferences/mobile-numbers/${record.uuid}/opt-ins`, {confirmationCode: record.confirmationCode}).then(() => {
        this.toast.show('Conferma inviata con successo!', 'success');
        this.mobileNumbers.reset();
      }).catch((x) => {
        this.toast.show('Impossibile inviare conferma!', 'error');
      }).finally(() => {
        $event.model.busy = false;
      });
    },
    revoke: ($event, record) => {
      $event.model.busy = true;
      this.api.post(`users/${this.prefsel}/preferences/mobile-numbers/${record.uuid}/opt-outs`, {}).then(() => {
        this.toast.show('Revoca inviata con successo!', 'success');
        this.mobileNumbers.reset();
      }).catch((x) => {
        this.toast.show('Impossibile inviare revoca!', 'error');
      }).finally(() => {
        $event.model.busy = false;
      });
    },
    delete: ($event, record) => {
      $event.model.busy = true;
      this.api.delete(`users/${this.prefsel}/preferences/mobile-numbers/${record.uuid}`, {}).then(() => {
        this.toast.show('Numero eliminato con successo!', 'success');
        this.mobileNumbers.reset();
      }).catch((x) => {
        this.toast.show('Impossibile eliminare il numero!', 'error');
      }).finally(() => {
        $event.model.busy = false;
      });
    }
  };
}
