import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {KaToast} from 'ka-components';
import mdt from 'services/md-tools';

@inject(Api, KaToast)
export class ServiceDialogModifyServiceExpiration {
  resource = {
    controls: {},
    schema: {
      applyStatusAt: {
        label: 'Data scadenza',
        control: 'date',
        required: true
      }
    },
    data: {},
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        console.log(control);
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      let errorPayload = mdt.parsers.apiError(error, this.resource.controls);
      if (errorPayload) this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>'+errorPayload.title+':</strong><div style="margin-top:1rem;border:1px solid #e0e0e0;padding:1rem;max-height:400px;overflow:auto;background-color:#ffffff">' + errorPayload.body + '</div>'});
    }
  }

  constructor(api, kaToast) {
    this.api = api;
    this.toast = kaToast;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
    this.resource.data.applyStatusAt = params.service?.expireAt || null;
  }

  save() {
    this.resource.validate().then(() => {
      this.api.patch(`users/${this.params.service.ownerId || 'me'}/flat-services-activations/${this.params.service.id}`, { status: 'EXPIRED', applyStatusAt: this.resource.data.applyStatusAt, statusReason: 'Scadenza modificata da un operatore' }).then(() => {
        this.toast.show(`Servizio modificato con successo!`, 'success');
        this.params.dialog.ok();
      }).catch(error => {
        console.error();
        this.resource.error(error);
      });
    });
  }
}
