import { inject } from 'aurelia-framework';
import { activationStrategy } from 'aurelia-router';
import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { DialogController } from 'aurelia-dialog';
import mdt from 'services/md-tools';
@inject(Api, KaDialog, KaToast, DialogController)

export class ServiceDialogListiniPianificazione {
  constructor(api, kaDialog, kaToast, dialogController) {
    this.api = api;
    this.dialog = kaDialog;
    this.toast = kaToast;
    this.controller = dialogController;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.userId = params.userId;
  }

  attached() {
    this.api.get('bo/price-lists').then(xhr => {
      this.pricelists = xhr.response;
      this.pricelists[0].label = this.pricelists[0].label + ' - Predefinito';
      this.init();
    }).catch(error => {
      console.log('ERROR - bo/ pricelists - GET', error);
      this.api.dialogError(error);
    });
  }

  init() {
    this.resource = {
      controls: {},
      schema: {
        code: {
          label: 'Listino',
          control: 'combo',
          datasource: this.pricelists,
          datavalue: 'code',
          datatext: 'label',
          datapreload: true,
          required: true
        },
        startAt: {
          label: 'Data inizio intervallo',
          control: 'date'
        },
        endAt: {
          label: 'Data fine intervallo',
          control: 'date',
          placeholder: 'Indefinita',
        }
      }, 
      data: {
        code: this.pricelists[0].code,
      },
      validate: () => {
        let promises = [];
        Object.values(this.resource.controls).forEach(control => {
          console.log(control);
          promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
        });
        return Promise.all(promises);
      },
      error: error => {
        let errorPayload = mdt.parsers.apiError(error, this.resource.controls);
        if (errorPayload) this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>'+errorPayload.title+':</strong><div style="margin-top:1rem;border:1px solid #e0e0e0;padding:1rem;max-height:400px;overflow:auto;background-color:#ffffff">' + errorPayload.body + '</div>'});
      }
    }
  }

  save() {
    this.resource.validate().then(() => {
      this.api.post(`bo/users/${this.userId}/price-list-schedules`, this.resource.data).then(() => {
        this.toast.show(`Servizio modificato con successo!`, 'success');
        this.controller.ok();
      }).catch(error => {
        this.resource.error(error);
      });
    });
  }
}
