import {PLATFORM, inject} from 'aurelia-framework';
import {Api} from 'services/api';

@inject(Api)
export class view_routeViewName {

  tabs = [
    {
      id: 'tab1',
      label: 'tabName1',
      vm: PLATFORM.moduleName('views/_template/tabs/routeViewName-tabName1')
    }, {
      id: 'tab2',
      label: 'tabName2',
      vm: PLATFORM.moduleName('views/_template/tabs/routeViewName-tabName2')
    }
  ];

  constructor(api) {
    this.api = api;
  }

  activate(params) {
    this.params = params;
    this.privilege();
  }

  privilege() {
    this.privileges = { tabs: { tab1: true, tab2: true }, buttons: {} };
    this.privileges.isOperator = this.api.hasRole('OPERATOR_ROLE');
    return;
    // Tab 1
    if (this.privileges.isOperator && this.api.hasRole('OPERATOR_ROLE_FOR_TAB_1')) {
      this.privileges.tabs.tab1 = true;
    } else if (!this.privileges.isOperator && this.api.hasRole('ROLE_FOR_TAB_1')) {
      this.privileges.tabs.tab1 = true;
    }
    // Tab 2
    if (this.api.hasRole('ROLE_FOR_TAB_2')) {
      this.privileges.tabs.tab2 = true;
    }
  }
}