import moment from 'moment';

export async function checkAgreements(scope, fragment) {
  const storagePrefix = `u${scope.data.id}-eula`;
  const currentTimestamp = moment().utc();
  const expiryTimestamp = localStorage.getItem(`${storagePrefix}-exp`);
  
  if (expiryTimestamp&& expiryTimestamp > currentTimestamp.format()) return checkResult(true);
  if (['/impostazioni/agreements'].includes(fragment)) return checkResult(false);
  const response = await scope.rest.get('users/me/user-agreements/current');
  console.log(JSON.stringify(response));
  if (response.status === 401) return checkResult(true);
  if (response.status >= 404 && response.status <= 410) {
    localStorage.setItem(`${storagePrefix}-exp`, currentTimestamp.add(24, 'hours').format());
    return checkResult(true);
  }
  if (response.status === 200) {
    await new Promise((resolve) => {
      scope.dialog.open({
        title: 'Attenzione!',
        class: 'small',
        type: 'alert',
        body: 'È necessario accettare i termini e le clausole di utilizzo obbligatorie'
      }).whenClosed(() => {
        resolve();
      });
    });
    localStorage.removeItem(`${storagePrefix}-href`);
    if (!['/', '/login', '/logout'].includes(location.pathname)) {
      localStorage.setItem(`${storagePrefix}-href`, sanitizeUrl(location.href));
    }
    return checkResult(false, '/impostazioni/agreements');
  }
  console.warn('[checkAgreements] Unhandled response status code!');
  return checkResult(false);
}

export function checkResult(hasPassed, redirect) {
  const result = {};
  if (hasPassed !== undefined) result.hasPassed = hasPassed;
  if (redirect !== undefined) result.redirect = redirect;
  return result;
}

export function sanitizeUrl(url) {
  url = new URL(url);
  const params = new URLSearchParams(url.search);
  params.delete('st'); // Eventually remove service token passed from legacy frontend
  url.search = params.toString();
  return url.href;
}
