import { Api } from 'services/api';
import { Router } from 'aurelia-router';
import { KaDialog } from 'ka-components';
import { inject } from 'aurelia-framework';
import { TableInterface } from 'classes/md-table';

@inject(Api, Router, KaDialog)
export class ViewMultinvioContracts {
  constructor(api, router, dialog) {
    this.api = api;
    this.router = router;
    this.dialog = dialog;
  }

  activate(model) {
    this.tools = model.tools;
    this.params = model.params;
  }

  attached() {
    // Queues table interface
    this.tableInterface = new TableInterface({
      name: 'table-cashback-multinvio-contracts',
      client: this.api,
      endpoint: 'bo/cashback-contracts?type=MULTINVIO-VOLUMES'
    });
    
    this.tableInterface.initialize().then(() => { return this.tableInterface.load(); });
  }

  openDialogCreateContract() {
    return this.dialog.open({
      title: 'Crea nuovo contratto',
      class: 'create-contract',
      viewModelParams: { type: 'MULTINVIO-VOLUMES' },
      viewModel: PLATFORM.moduleName('views/cashback/contracts/dialogs/create-contract')
    }).whenClosed((response) => {
      if (!response.wasCancelled) {
        this.tableInterface.load();
      }
    });
  }
}
