import {inject} from 'aurelia-framework';
import {HttpClient} from 'aurelia-http-client';
import {MdAuth} from './md-auth';
import environment from '../environment';

@inject(MdAuth)
export class MdApi {
  constructor(MdAuth) {
    this.mdAuth = MdAuth;
  }
}
