import { Api } from 'services/api';
import { inject } from 'aurelia-framework';
import { KaDialog, KaToast } from 'ka-components';
import { TableInterface, SearchInterface}  from 'classes/md-table';

@inject(Api, KaDialog, KaToast)
export class tabRequests {
  status = {
    'PENDING' : 'In attesa',
    'REFUSED' : 'Rifiutata'
  }
  constructor(api, kaDialog, kaToast) {
    this.api = api;
    this.toast = kaToast;
    this.dialog = kaDialog;
  }

  activate(model) {
    this.params = model.params;
    this.privileges = model.privileges;
    model.tab.controller = this;
    this.tools = model.tools;
  }

  attached() {
    this.initTab();
  }

  setEndpoint() {
    const path = this.privileges.isOperator ? 'bo' : 'users/me';
    const endpoint = `${path}/cashback-withdrawal-requests`;
    return endpoint;
  }

  async initTableInterface() {
    this.tableInterface = new TableInterface({
      client: this.api,
      name: 'table-cashback-requests',
      endpoint: this.setEndpoint()
    });

    return this.tableInterface.initialize();
  }

  async initSearchInterface() {
    this.searchInterface = new SearchInterface({
      name: 'table-cashback-requests-search',
      table: this.tableInterface,
      schema: {
        user: {
          label: 'Utente',
          control: 'combo', 
          datavalue: 'uuid',
          query: 'user-uuid',
          datapreload: false,
          datamultiple: false,
          datatext: 'profile[0].displayName',
          datasource: { 
            table: 'bo/users?include=user-profiles', 
            query: { search: 'text-search', item: 'uuid-in' }
          }
        }
      },
      data: {}
    });

    return this.searchInterface.initialize();
  }

  async initTab() {
    await this.initTableInterface();
    await this.initSearchInterface();
    this.search();
  }

  async search() {
    return this.searchInterface.search();
  }

  async reset() {
    await this.searchInterface.reset(true);
    return this.search();
  }

  async deleteRequest($event, record) {
    return this.api.delete(`users/me/cashback-withdrawal-requests/${record.id}`).then(() => {
      this.search();
      this.toast.show('Richiesta eliminata con successo!', 'success');
    }).catch((xhr) => {
      console.log('Delete cashback-withdrawal-requests', xhr);
      return this.api.dialogError(xhr);
    }).finally(() => {
      $event.model.busy = false;
    });
  }

  dialogDeleteRequest($event, record) {
    $event.model.busy = true;
    return this.dialog.open({ 
      class: 'small', 
      type: 'confirm', 
      title: 'Attenzione!', 
      body: `Confermi di voler eliminare questa richiesta di prelievo?`}).whenClosed((response) => {
      if (!response.wasCancelled) return this.deleteRequest($event, record);
      $event.model.busy = false;
    });
  }

  dialogEditRequest($event, record, status) {
    $event.model.busy = true;
    const statusSchema = { 'REFUSED': 'Rifiuta', 'CONFIRMED': 'Conferma' };
    return this.dialog.open({
      class: 'small edit-request',
      title: `${statusSchema[status]} richiesta di prelievo`,
      viewModelParams: { record, status }, 
      viewModel: PLATFORM.moduleName('views/cashback/transactions/dialogs/edit-request')
    }).whenClosed((response) => {
      $event.model.busy = false;
      if (!response.wasCancelled) {
        this.search();
      }
    });
  }
}
