import { inject } from 'aurelia-framework';
import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { DateTime } from 'luxon';

@inject(Api, KaDialog, KaToast)
export class DialogEditPromotion {
  resource = {
    controls: {},
    schema: {
      expireAt: {
        control: 'date',
        label: 'Scadenza promozione',
        placeholder: 'Illimitata',
        validationRule: {
          satisfies: () => {
            if (!this.resource.data.expireAt) return true;
            if (DateTime.fromISO(this.resource.data.expireAt).isValid) {
              return DateTime.now() < DateTime.fromISO(this.resource.data.expireAt);
            } else return false;
          },
          withMessage: 'La scadenza non pùo essere inferiore ad oggi',
        },
      },
      notes: {
        control: 'text',
        label: 'Motivazione'
      }
    },
    data: {},
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      this.api.dialogError(error, this.resource.controls);
    }
  }

  constructor(api, kaDialog, kaToast) {
    this.api = api;
    this.dialog = kaDialog;
    this.toast = kaToast;
  }

  activate(params) { 
    this.params = params;
    this.resource.data.expireAt = this.params.promotion.expireAt || null;
  }

  save() {
    let data = {...this.resource.data};
    if (this.params.type === 'TERMINATE') data.expireAt = 'NOW';
    
    this.resource.validate().then(() => {
      this.api.patch(`bo/users/${this.params.userId}/promotion-activations/${this.params.promotion.uuid}`, data).then(() => {
        this.toast.show('Promozione creata con successo', 'success'); 
        this.params.dialog.ok(); 
      }).catch(xhr => { 
        console.log('Error - PATCH promotion-activations', xhr); 
        this.resource.error(xhr); 
      });
    });
  }
}
