import { Api } from 'services/api';
import { inject } from 'aurelia-framework';
import { KaDialog, KaToast } from 'ka-components';
import { MavRequest } from '../../../../classes/mavRequest';

@inject(Api, KaDialog, KaToast)
export class FatturazioneEditor {
  legalForms;
  mavRequests;
  taxPercentage;
  legalFormTypes;
  bankAccountInfo;
  legalFormSelected;
  billingProfileUuid;
  billingProfileIban;
  pendingInit = true;
  hasProfileOwnership;
  changeLegalFormPending;
  checkSDDPassed = false;
  pendingPopulateMissingData;
  anagrafica = { data: {} };
  
  resource = {
    schema: {
      label: {
        control: 'text',
        label: 'Etichetta'
      },
      legalFormCode: {
        label: 'Forma giuridica',
        control: 'combo',
        datatext: 'label',
        datavalue: 'code',
        datamultiple: false,
        datapreload: false,
        datasource: [],
        required: true
      },
      name: {
        control: 'text',
        label: 'Denominazione'
      },
      firstName: {
        control: 'text',
        label: 'Nome'
      },
      lastName: {
        control: 'text',
        label: 'Cognome'
      },
      fiscalCode: {
        control: 'text',
        label: 'Codice fiscale',
      },
      vatCode: {
        control: 'text',
        label: 'Partita IVA',
      },
      officeCode: {
        control: 'text',
        label: 'Codice Univoco Ufficio'
      },
      ipaCode: {
        control: 'text',
        label: 'Codice iPA'
      },
      contact: {
        emailAddress: {
          control: 'text',
          label: 'Email'
        },
        phoneNumber: {
          control: 'text',
          label: 'Cellulare'
        },
        faxNumber: {
          control: 'text',
          label: 'Fax'
        }
      },
      location: {
        streetAddress: {
          control: 'text',
          label: 'Indirizzo',
          required: true
        },
        locality: {
          control: 'text',
          label: 'Località'
        },
        countryCode: {
          label: 'Nazione',
          control: 'combo',
          datatext: 'name',
          datavalue: 'code',
          datamultiple: false,
          datapreload: false,
          datasource: { table: `geo/countries`, query: { search: 'text-search', item: 'code' } },
          required: true
        },
        admLvl1Uuid: {
          label: 'Regione',
          control: 'combo',
          datatext: 'endonym',
          datavalue: 'uuid',
          datamultiple: false,
          datapreload: false,
          datasource: { table: null, query: { search: 'text-search', item: 'uuid' } },
          required: true
        },
        admLvl2Uuid: {
          label: 'Provincia',
          control: 'combo',
          datatext: 'endonym',
          datavalue: 'uuid',
          datamultiple: false,
          datapreload: false,
          datasource: { table: null, query: { search: 'text-search', item: 'uuid' } },
          required: true
        },
        admLvl3Uuid: {
          label: 'Comune',
          control: 'combo',
          datatext: 'endonym',
          datavalue: 'uuid',
          datamultiple: false,
          datapreload: false,
          datasource: { table: null, query: { search: 'text-search', item: 'uuid' } },
          required: true
        },
        zipCode: {
          label: 'CAP',
          control: 'text',
          required: true
        }
      },
      payments: {
        splitPaymentsEnabled: {
          label: 'Pagamenti separati',
          control: 'check',
          description: 'Abilita pagamenti separati',
        },
        banking: {
          lastFourIbanDigits: {
            label: 'Codice verifica',
            control: 'text',
            min: 0,
            max: 4
          },
          iban: {
            label: 'IBAN',
            control: 'text'
          },
          sepaDirectDebitEnabled: {
            label: 'Domiciliazione bancaria (SDD)',
            description: 'Abilita domiciliazione bancaria',
            control: 'check'
          }
        }
      }
    },
    data: {}
  };

  constructor(api, dialog, toast) {
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
    this.isOperator = this.api.hasRole('LIST_ANY_USER');
    this.mavRequest = new MavRequest(this.isOperator);
  }

  activate(params) {
    this.userId = params.userId; // is always required
    this.billingProfileUuid = params.profileUuid;
    this.callback = params.callback || ((completed) => { });
    this.init();
  }

  async init() {
    return this.getBankAccountInfo().then(() => {
      return this.getUser().then(() => {
        return this.setCompanyLegalFormCodeDatasource().finally(() => {
          return this.load().then(() => {
            if (this.billingProfileUuid) {
              return this.mavAuthorizationRequests(this.userId, this.billingProfileUuid);
            }
          });
        });
      });
    });
  }

  async getBankAccountInfo() {
    return new Promise((resolve, reject) => {
      if (this.billingProfileUuid) {
        return this.api.get(`users/${this.userId}/billing-profiles/${this.billingProfileUuid}/bank-accounts/current`).then((xhr) => {
          let response = xhr.response;
          return resolve(this.bankAccountInfo = response);
        }).catch((error) => {
          // Replies 404 if there is no iban and 409 if the iban is invalid
          console.error('bank-accounts/current', error);
          return resolve(error);
        });
      } else resolve();
    });
  }

  async getUser() {
    return this.api.get(`users/${this.userId}?include=user-profiles`).then((xhr) => {
      let response = xhr.response;
      // User profile
      if (response.profile && Array.isArray(response.profile)) response.profile = response.profile[0];

      // If there is no companyName
      if (!response.profile?.holderName?.companyName && response.profile?.holderName?.lastname && response.profile?.holderName?.firstname) {
        response.profile.holderName.companyName = `${response.profile.holderName.lastname} ${response.profile.holderName.firstname}`;
      }

      return this.anagrafica.data = response;
    }).catch((xhr) => {
      console.log('ERROR user-profile GET', xhr);
      this.toast.show('Errore durante il caricamento dei dati', 'error');
      throw xhr;
    });
  }

  async setCompanyLegalFormCodeDatasource() {
    let promises = [
      this.api.get('legal-form-types'),
      this.api.get(`legal-forms?applicable-to-user-uuid=${this.anagrafica.data.uuid}`)
    ];

    return Promise.all(promises).then((xhrs) => {
      let companyLegalFormCodeDatasource;
      this.legalForms = xhrs[1]?.response || null;
      this.legalFormTypes = xhrs[0]?.response || null;

      if (this.legalFormTypes?.length) {
        let optgroup = {};
        companyLegalFormCodeDatasource = [];
        this.legalFormTypes.forEach(type => {
          let legalFormsForThisType = this.legalForms?.filter(x => x.type === type.code) || null;

          if (legalFormsForThisType?.length) {
            optgroup = { code: type.code, label: type.label, optgroup: true };
            companyLegalFormCodeDatasource.push(optgroup);
            legalFormsForThisType.forEach(form => {
              form = { code: form.code, label: form.label };
              companyLegalFormCodeDatasource.push(form);
            });
          }
        });

        this.resource.schema.legalFormCode = Object.assign({}, this.resource.schema.legalFormCode, { datasource: companyLegalFormCodeDatasource });
      }
    }).catch(error => {
      console.error(`GET - setCompanyLegalFormCodeDatasource`, error);
      this.toast.show('Errore durante il caricamento dei dati!', 'error');
      throw xhr;
    });
  }

  async load() {
    let promises = [];
    if (this.billingProfileUuid) {
      promises.push(this.api.get(`users/${this.userId}/billing-profiles/${this.billingProfileUuid}`).then((xhr) => {
        this.hasProfileOwnership = xhr.originalResponse.meta.isVisitor === 'NO' ? true : false;
        this.resource.data = xhr.response;
        if (this.anagrafica.data?.profile?.companyLegalFormCode && !this.resource.data.legalFormCode) {
          this.resource.data.legalFormCode = this.anagrafica.data.profile.companyLegalFormCode;
        }
        if (this.resource.data.payments?.banking?.iban) {
          this.resource.data.payments.banking.iban = this.billingProfileIban = this.resource.data.payments.banking.iban.replace(/[^\d\w]*/gi, '');
        }
      }));
    } else {
      promises.push(this.api.get(`users/${this.userId}/billing-profiles`).then((xhr) => {
        this.autoPopulate = !xhr.response.length;
        if (this.autoPopulate) this.populate();
        else this.resource.data = {
          payments: { banking: {} },
          legalFormCode: this.anagrafica.data.profile.companyLegalFormCode || null
        }
      }));
    }
    return Promise.all(promises).catch(xhr => {
      console.log(`ERROR /billing-profiles - GET`, xhr);
      this.toast.show('Errore durante il caricamento dei dati', xhr);
      throw xhr;
    }).finally(() => {
      this.pendingInit = false;
    });
  }

  mavAuthorizationRequests(userId, billingProfileUuid) {
    if (!this.api.hasRole('LIST_MAV_ORDER_SELF_PRINTING_AUTHORIZATION')) return;

    this.mavRequests = [];
    if (this.api.hasRole('LIST_ANY_MAV_ORDER_SELF_PRINTING_AUTHORIZATION')) {
      if (!this.hasProfileOwnership) {
        this.getMavAuthorizationRequest('privileged-user', userId, billingProfileUuid);
      } else {
        this.getMavAuthorizationRequest('trustee', userId, billingProfileUuid);
      }
    } else {
      this.getMavAuthorizationRequest('trustee', userId, billingProfileUuid);
    }
  }

  getMavAuthorizationRequest(context, userId, billingProfileUuid) {
    if (context === 'trustee') {
      return this.api.get(`users/${userId}/billing-profiles/${billingProfileUuid}/mav-order-self-printing-authorizations/mine`).then(xhr => {
        this.mavRequests.push(xhr.response);
      }).catch(error => {
        // If it is "Not found" it means that there are no requests in progress.
        console.log(`${error.statusCode === 404 ? 'ERROR 404 not-found' : 'ERROR'} mav-order-self-printing-authorizations/mine - GET`, error);
        if (error.statusCode === 404) this.mavRequests = [];
        else this.api.dialogError(error);
      }).finally(() => {
        this.isInitializing = false;
      });
    }
    if (context === 'privileged-user') {
      return this.api.get(`bo/users/${userId}/billing-profiles/${billingProfileUuid}/mav-order-self-printing-authorizations`).then(xhr => {
        this.mavRequests = xhr.response;
      }).catch(error => {
        console.log(`ERROR bo/ mav-order-self-printing-authorizations - GET`, error);
        this.api.dialogError(error);
      }).finally(() => {
        this.isInitializing = false;
      });
    }
  }

  mavCreateAuthorizationRequest($event, userId, billingProfileUuid) {
    $event.model.busy = true;
    if (this.resource.data.payments?.banking?.iban && this.billingProfileIban !== this.resource.data.payments.banking.iban) {
      let data = { payments: { banking: { iban: this.resource.data.payments.banking.iban } } }
      this.api.patch(`users/${userId}/billing-profiles/${billingProfileUuid}`, data).then(() => {
        this.isBuildingAdminCreatingMavRequest($event, userId, billingProfileUuid);
      }).catch(error => {
        console.log(`ERROR billing-profiles - PATCH`, error);
        this.api.dialogError(error);
      });
    } else {
      this.isBuildingAdminCreatingMavRequest($event, userId, billingProfileUuid);
    }
  }

  isBuildingAdminCreatingMavRequest($event, userId, billingProfileUuid) {
    if (this.api.user.profile.businessType === 'BUILDING-ADMINISTRATOR' && userId === 'me') {
      this.dialog.open({
        title: 'Attenzione!',
        class: 'small',
        type: 'confirm',
        body: `<span>Stai inviando una richiesta di autorizzazione stampa in proprio MAV su una posizione bancaria dichiarata come appartenente allo studio.<span class="d-block mt-3">Se invece intendi avviarla per pagamenti legati ad una posizione condominiale è necessario partire dalla posizione di fatturazione del condominio.</span><strong class="d-block mt-3">Continuando attiverai una richiesta di autorizzazione stampa in proprio MAV per pagamenti verso lo Studio.</strong></span>`
      }).whenClosed(response => {
        if (!response.wasCancelled) this.sendMavAuthorizationRequest($event, userId, billingProfileUuid);
        else $event.model.busy = false;
      });
    } else {
      this.sendMavAuthorizationRequest($event, userId, billingProfileUuid);
    }
  }

  sendMavAuthorizationRequest($event, userId, billingProfileUuid) {
    return this.api.post(`users/${userId}/billing-profiles/${billingProfileUuid}/mav-order-self-printing-authorizations`, {}).then(() => {
      this.dialog.open({
        title: 'Attenzione!',
        class: 'small',
        type: 'alert',
        body: '<p class="m-0"><strong>La richiesta è stata inoltrata correttamente!</strong><span class="d-block">L\'approvazione di una richiesta da parte dell\'ente bancario potrà richiedere dai 3 ai 5 giorni lavorativi.</span></p>'
      }).whenClosed(() => {
        this.mavAuthorizationRequests(userId, billingProfileUuid);
        this.toast.show('Richiesta MAV creata con successo!', 'success');
        this.resource.data = null;
        this.callback();
      });
    }).catch(error => {
      console.log('ERROR mav-order-self-printing-authorizations - POST', error);
      this.api.dialogError(error);
    }).finally(() => {
      $event.model.busy = false;
      this.callback({ ownerLegacyId: this.userId, uuid: this.billingProfileUuid });
    });
  }

  mavDeleteAuthorizationRequest($event, userId, billingProfileUuid, requestUuid) {
    $event.model.busy = true;
    this.api.delete(`users/${userId}/mav-order-self-printing-authorizations/${requestUuid}`).then(() => {
      this.mavAuthorizationRequests(userId, billingProfileUuid);
      this.toast.show('Richiesta di autorizzazione MAV in corso cancellata!', 'success');
      this.resource.data = null;
      this.callback();
    }).catch(error => {
      console.log('ERROR mav-order-self-printing-authorizations - DELETE', error);
      this.api.dialogError(error);
    }).finally(() => { $event.model.busy = false });
  }

  save($event) {
    $event.model.busy = true;
    if (this.resource.data?.location) {
      for (const key in this.resource.data.location) {
        if (key.match(/admLvl(\d)$/)) delete this.resource.data.location[key];
      }
    }
    if (this.resource.data.payments?.banking?.iban) {
      this.resource.data.payments.banking.iban = this.resource.data.payments.banking.iban.replace(/[^\d\w]*/gi, '');
    }
    if (this.resource.data.uuid) {
      this.isVisibleForTheSelectedLegalForm('vatCode');
      this.isVisibleForTheSelectedLegalForm('fiscalCode');
      this.resource.resource.save(this.resource.data, `users/${this.userId}/billing-profiles/${this.resource.data.uuid}`, 'put').then(() => {
        this.toast.show('Modifiche salvate con successo!', 'success');
        this.resource.data = null;
        this.callback();
      }).catch(error => {
        console.log('ERROR - billing-profiles - PUT', error);
        this.toast.show('Errore nel salvataggio delle modifiche!', 'error');
      }).finally(() => { $event.model.busy = false; });
    } else {
      this.resource.resource.save(this.resource.data, `users/${this.userId}/billing-profiles`, 'post').then(x => {
        this.toast.show('Profilo creato con successo!', 'success');
        this.resource.data = null;
        this.callback();
      }).catch(error => {
        console.log('ERROR - billing-profiles - POST', error);
        this.toast.show('Errore nella creazione del profilo!', 'error');
      }).finally(() => { $event.model.busy = false; });
    }
  }

  cancel() {
    this.callback();
  }

  checkSDD() {
    konsole.log('checkSDD', this.resource.data.payments.banking.sepaDirectDebitEnabled, this.api.user.roles?.filter(x => x.id === 'DISABLE_SDD_ON_BILLING_PROFILE_ASSOCIATED_TO_ACTIVE_ORDERS'));
    this.checkSDDPassed = !this.resource.data.payments.banking.sepaDirectDebitEnabled && this.api.user.roles?.filter(x => x.id === 'DISABLE_SDD_ON_BILLING_PROFILE_ASSOCIATED_TO_ACTIVE_ORDERS').length === 1;
    konsole.log('checkSDDPassed', this.checkSDDPassed);
  }

  changeLegalForm() {
    this.changeLegalFormPending = true;
    setTimeout(() => {
      let legalFormCodeFound = this.resource.schema.legalFormCode.datasource.find(x => x.code === this.resource.data.legalFormCode);
      this.taxPercentage = legalFormCodeFound ? legalFormCodeFound.taxPercentage : null;
      this.legalFormSelected = this.legalForms.find(legalForm => legalForm.code === this.resource.data.legalFormCode) || null;
      this.resource.data.legalFormType = this.legalFormSelected?.type || null;

      this.setFieldSchemaRequired(['name'], 'companyNameNeeded');
      this.resetUnrequiredFieldData(['name'], 'companyNameNeeded');
      this.setFieldSchemaRequired(['vatCode'], 'vatCodeNeeded');
      this.resetUnrequiredFieldData(['vatCode'], 'vatCodeNeeded');
      this.setFieldSchemaRequired(['fiscalCode'], 'fiscalCodeNeeded');
      this.resetUnrequiredFieldData(['fiscalCode'], 'fiscalCodeNeeded');
      this.setFieldSchemaRequired(['firstName', 'lastName'], 'personNameNeeded');
      this.resetUnrequiredFieldData(['firstName', 'lastName'], 'personNameNeeded');

      // Advice when creating a profile
      if (this.resource.data.legalFormType === 'BUILDING' && this.anagrafica.data?.profile?.businessType !== 'BUILDING') {
        this.dialog.open({
          title: 'Attenzione!',
          class: 'small',
          type: 'confirm',
          body: `Si sconsiglia di inserire profili di fatturazione di condomini su utenti che non sono di tipo condominio, si intende procedere lo stesso?`
        });
      }
      this.changeLegalFormPending = false;
    }, 0);
  }

  isVisibleForTheSelectedLegalForm(necessityStatement) {
    return ['OPTIONAL', 'REQUIRED', 'YES'].includes(this.legalFormSelected?.[`${necessityStatement}`]);
  }

  setFieldSchemaRequired(schemaProperties, necessityStatement) {
    schemaProperties.forEach((property) => {
      if (this.resource.schema[property] && this.legalFormSelected?.[`${necessityStatement}`]) {
        this.resource.schema[property].required = ['REQUIRED', 'YES'].includes(this.legalFormSelected[`${necessityStatement}`]);
      }
    });
  }

  resetUnrequiredFieldData(schemaProperties, necessityStatement) {
    schemaProperties.forEach((property) => {
      if (!this.isVisibleForTheSelectedLegalForm(necessityStatement)) {
        delete this.resource.data[property];
      }
    });
  }

  setGeo(field, init = false) {
    let childField, childControl, childSchema, childData, countryCode, admLvls, lvl;
    if (field === 'countryCode') {
      childField = 'admLvl1Uuid';
      lvl = 1;
    } else if (field === 'admLvl1Uuid') {
      childField = 'admLvl2Uuid';
      lvl = 2;
    } else if (field === 'admLvl2Uuid') {
      childField = 'admLvl3Uuid';
      lvl = 3;
    }

    childControl = this.resource.resource.controls[`location.${childField}`];
    childSchema = this.resource.schema.location[childField];
    childData = this.resource?.data?.location;
    countryCode = childData ? childData['countryCode'] : null;
    admLvls = this.resource.resource.controls['location.countryCode'].objectValue?.administrativeLevels;

    if (countryCode) {
      let url = `geo/countries/${countryCode}/levels/${lvl}/divisions` + (lvl > 1 ? `?parent-uuid=${childData[field]}` : '');
      if (admLvls && admLvls.find(x => x.level === lvl)) {
        childSchema.enabled = true;
      } else if (admLvls && !admLvls.find(x => x.level === lvl)) {
        childSchema.enabled = false;
      }
      if (init) {
        childSchema.datasource.table = url;
      } else if (childControl) {
        childControl.datasource.table = url;
        childData[childField] = null;
      } else {
        childSchema.datasource.table = url;
        childData[childField] = null;
      }
    } else if (!init) {
      childData[childField] = null;
    }
  }

  populate() {
    const profile = this.anagrafica?.data?.profile;
    if (profile) {
      const holderName = profile?.holderName;
      const location = profile?.location;

      if (!this.billingProfileUuid) {
        this.resource.data = {
          ...this.resource.data,
          ...((this.isVisibleForTheSelectedLegalForm('vatCodeNeeded') && profile?.vatCode) && { vatCode: profile.vatCode }),
          ...((this.isVisibleForTheSelectedLegalForm('personNameNeeded') && holderName.lastname) && { lastName: holderName.lastname }),
          ...((this.isVisibleForTheSelectedLegalForm('fiscalCodeNeeded') && profile?.fiscalCode) && { fiscalCode: profile.fiscalCode }),
          ...((this.isVisibleForTheSelectedLegalForm('companyNameNeeded') && holderName.companyName) && { name: holderName.companyName }),
          ...((this.isVisibleForTheSelectedLegalForm('personNameNeeded') && holderName.firstname) && { firstName: holderName.firstname }),
          contact: {
            ...(profile?.email && { emailAddress: profile.email }),
            ...(profile?.faxNumber && { faxNumber: profile.faxNumber }),
            ...(profile?.mobilePhoneNumber && { phoneNumber: profile.mobilePhoneNumber })
          },
          location: {
            ...(location?.zipCode && { zipCode: location.zipCode }),
            ...(location?.locality && { locality: location.locality }),
            ...(location?.countryCode && { countryCode: location.countryCode }),
            ...(location?.admLvl1Uuid && { admLvl1Uuid: location.admLvl1Uuid }),
            ...(location?.admLvl2Uuid && { admLvl2Uuid: location.admLvl2Uuid }),
            ...(location?.admLvl3Uuid && { admLvl3Uuid: location.admLvl3Uuid }),
            ...(location?.streetAddress && { streetAddress: location.streetAddress })
          }
        };
        return this.toast.show('Dati recuperati con successo!', 'success');
      } else {
        this.toast.show('Caricamento in corso', 'loading', true);
        return this.populateMissingData(profile);
      }
    } else {
      if (!profile) return this.toast.show('Errore. Impossibile recuperare i dati!', 'error');
    }
  }

  flattenResource(property) {
    property = structuredClone(property);
    if (property?.contact) {
      Object.assign(property, property.contact);
      delete property.contact;
    }
    if (property?.location) {
      Object.assign(property, property.location);
      delete property.location;
    }
    delete property.label;
    delete property.ipaCode;
    delete property.payments;
    delete property.officeCode;
    return property;
  }

  populateMissingData(profile, data = structuredClone(this.resource.data)) {
    let admLvl1Uuid;
    let admLvl2Uuid;
    let admLvl3Uuid;
    let promises = [];
    this.pendingPopulateMissingData = true;
    const contact = ['emailAddress', 'phoneNumber', 'faxNumber'];
    const schema = Object.keys(this.flattenResource(this.resource.schema));
    const location = ['streetAddress', 'locality', 'zipCode', 'countryCode', 'admLvl1Uuid', 'admLvl2Uuid', 'admLvl3Uuid'];
    schema.forEach((key) => {
      if (location.includes(key)) {
        if ([null, undefined].includes(data?.location?.[key])) {
          switch (key) {
            case 'zipCode':
              data.location = {
                ...data.location,
                ...(profile.location?.zipCode && { [key]: profile.location?.zipCode })
              }
              break;
            case 'locality':
              data.location = {
                ...data.location,
                ...(profile.location?.locality && { [key]: profile.location.locality })
              }
              break;
            case 'countryCode':
              data.location = {
                ...data.location,
                ...(profile.location?.countryCode && { [key]: profile.location?.countryCode })
              }
              break;
            case 'admLvl1Uuid':
              if (profile.location.admLvl1Uuid) admLvl1Uuid = profile.location?.admLvl1Uuid;
              break;
            case 'admLvl2Uuid':
              if (profile.location.admLvl2Uuid) admLvl2Uuid = profile.location?.admLvl2Uuid;
              break;
            case 'admLvl3Uuid':
              if (profile.location?.admLvl3Uuid) admLvl3Uuid = profile.location.admLvl3Uuid;
              break;
            case 'streetAddress':
              data.location = {
                ...data.location,
                ...(profile.location?.streetAddress && { [key]: profile.location.streetAddress })
              }
              break;
          }
        }
      } else if (contact.includes(key)) {
        if ([null, undefined].includes(data?.contact?.[key])) {
          switch (key) {
            case 'faxNumber':
              data.contact = {
                ...data.contact,
                ...(profile.faxNumber && { [key]: profile.faxNumber })
              }
              break;
            case 'emailAddress':
              data.contact = {
                ...data.contact,
                ...(profile.email && { [key]: profile.email })
              }
              break;
            case 'phoneNumber':
              data.contact = {
                ...data.contact,
                ...((profile.mobilePhoneNumber || profile.phoneNumber) && { [key]: profile.mobilePhoneNumber || profile.phoneNumber })
              }
              break;
          }
        }
      } else {
        if ([null, undefined].includes(data[key])) {
          if (key === 'vatCode' && this.isVisibleForTheSelectedLegalForm('vatCodeNeeded')) {
            if (profile.vatCode) data[key] = profile.vatCode;
          } else if (key === 'name' && this.isVisibleForTheSelectedLegalForm('companyNameNeeded')) {
            if (profile.holderName?.companyName) data[key] = profile.holderName.companyName;
          } else if (key === 'fiscalCode' && this.isVisibleForTheSelectedLegalForm('fiscalCodeNeeded')) {
            if (profile.fiscalCode) data[key] = profile.fiscalCode;
          } else if (this.isVisibleForTheSelectedLegalForm('personNameNeeded')) {
            switch (key) {
              case 'firstName':
                data = {
                  ...data,
                  ...(profile.holderName?.firstname && { firstName: profile.holderName.firstname })
                }
                break;
              case 'lastName':
                data = {
                  ...data,
                  ...(profile.holderName?.lastname && { lastName: profile.holderName.lastname })
                }
                break;
            }
          }
        }
      }
    });
    this.resource.data = data;
    if (admLvl1Uuid) promises.push(
      new Promise((resolve) => { setTimeout(() => { this.resource.data.location.admLvl1Uuid = admLvl1Uuid; resolve() }, 500) })
    );
    if (admLvl2Uuid) promises.push(
      new Promise((resolve) => { setTimeout(() => { this.resource.data.location.admLvl2Uuid = admLvl2Uuid; resolve() }, 1000) })
    );
    if (admLvl3Uuid) promises.push(
      new Promise((resolve) => { setTimeout(() => { this.resource.data.location.admLvl3Uuid = admLvl3Uuid; resolve() }, 1500) })
    );
    Promise.all(promises).finally(() => {
      setTimeout(() => {
        this.pendingPopulateMissingData = false;
        this.toast.show('Dati mancanti recuperati con successo!', 'success');
      }, 500);
    });
  }
}
