import {activationStrategy} from 'aurelia-router';
export class ServiceDialogUserProfile {
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.missingProperties = params;
  }
}
export class missingPropertiesValueConverter {
  toView(value) {
    if (!value) return;
    let missingProperties = {
      zipCode: 'CAP',
      streetAddress: 'Indirizzo',
      admLvl1: 'Regione',
      admLvl2: 'Provincia',
      admLvl3: 'Comune',
      countryCode: 'Nazione',
      businessType: 'Attività',
      companyLegalFormCode: 'Forma giuridica',
      displayName: 'Mittente'
    };
    return missingProperties[value] || value;
  }
}
