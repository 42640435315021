import { Aurelia, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { MdUser } from 'services/md-user';
import { KaDialog, KaToast } from 'ka-components';


@inject(Aurelia, Router, Api, MdUser, KaDialog, KaToast)
export class Anagrafica {
  endpoint;
  pendingLoad;
  pendingInit;
  parentUser;
  legalForms;
  legalFormTypes;
  legalFormSelected;
  oldSelectedCountryCode;
  resource = {
    controls: {},
    schema: {
      group: { 
        control: 'combo',  
        label: 'Tipo accesso', 
        datatext: 'label',
        datavalue: 'code', 
        datamultiple: false,
        datapreload: true,
        datasource: [],
        readonly: true
      },
      password: {
        control: 'password', 
        label: 'Imposta nuova password'
      },
      profile: {
        username: {
          control: 'text',
          label: 'Username'
        },
        displayName: { 
          control: 'text',
          label: 'Etichetta'
        },
        courtesyTitle: { 
          label: 'Titolo',
          control: 'combo',
          datatext: 'text',
          datavalue: 'value', 
          datamultiple: false,
          datapreload: true,
          datasource: [{ value: 'Sig.', text: 'Sig.' }, { value: 'Sig.ra', text: 'Sig.ra' }, { value: 'Arch.', text: 'Arch.' }, { value: 'Avv.', text: 'Avv.' }, { value: 'Dott.', text: 'Dott.' }, { value: 'Dott.ssa', text: 'Dott.ssa' }, { value: 'Geom.', text: 'Geom.' }, { value: 'Ing.', text: 'Ing.' }, { value: 'Prof.', text: 'Prof.' }, { value: 'Prof.ssa', text: 'Prof.ssa' }, { value: 'P.I.', text: 'P.I.' }, { value: 'Rag.', text: 'Rag.' }, { value: 'Cav.', text: 'Cav.' }, { value: 'Comm.', text: 'Comm.' }],
        },
        holderName: {
          companyName: {
            control: 'text', 
            label: 'Denominazione',
            required: false
          },
          firstname: {
            control: 'text', 
            label: 'Nome',
            required: false
          },
          lastname: {
            control: 'text', 
            label: 'Cognome',
            required: false
          }
        },
        businessType: { 
          label: 'Attività',
          control: 'combo',
          datatext: 'label',
          datavalue: 'code', 
          datamultiple: false,
          datapreload: false,
          datasource: [],
          required: true
        },
        companyLegalFormCode: {
          label: 'Forma giuridica',
          control: 'combo',
          datatext: 'label',
          datavalue: 'code', 
          datamultiple: false,
          datapreload: false,
          datasource: [],
          required: true
        },
        fiscalCode: {
          control: 'text', 
          label: 'Codice fiscale',
          required: false
        },
        vatCode: {
          control: 'text', 
          label: 'Partita iva',
          required: false
        },
        location: {
          streetAddress: {
            control: 'text', 
            label: 'Indirizzo',
            required: true,
          },
          locality: {
            control: 'text', 
            label: 'Località'
          },
          countryCode: { 
            label: 'Nazione', 
            control: 'combo',  
            datatext: 'name',
            datavalue: 'code', 
            datamultiple: false,
            datapreload: false,
            datasource: { table: `geo/countries`, query: { search: 'text-search', item: 'code'} },
            required: true
          },
          admLvl1Uuid: {
            label: 'Regione',
            control: 'combo',
            datatext: 'endonym',
            datavalue: 'uuid',
            datamultiple: false,
            datapreload: false,
            datasource: { table: null, query: { search: 'text-search', item: 'uuid'} }
          },
          admLvl2Uuid: {
            label: 'Provincia',
            control: 'combo',
            datatext: 'endonym',
            datavalue: 'uuid',
            datamultiple: false,
            datapreload: false,
            datasource: { table: null, query: { search: 'text-search', item: 'uuid'} }
          },
          admLvl3Uuid: {
            label: 'Comune',
            control: 'combo',
            datatext: 'endonym',
            datavalue: 'uuid',
            datamultiple: false,
            datapreload: false,
            datasource: { table: null, query: { search: 'text-search', item: 'uuid'} }
          },
          zipCode: {
            label: 'CAP',
            control: 'text',
            required: true,
          }
        },
        email: {
          control: 'text', 
          label: 'Email'
        },
        phoneNumber: {
          control: 'text', 
          label: 'Telefono'
        },
        mobilePhoneNumber: {
          control: 'text', 
          label: 'Cellulare'
        },
        faxNumber: {
          control: 'text', 
          label: 'Fax'
        }
      }
    },
    data: {
      group: 'BASIC_USER',
      profile: {
        location: {
          countryCode: 'IT'
        }
      }
    },
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        control.validator.errors = [];
        control.element.classList.remove('error');
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      this.api.dialogError(error, this.resource.controls);
    }
  }

  constructor(aurelia, router, api, mdUser, dialog, toast) {
    this.aurelia = aurelia;
    this.api = api;
    this.mdUser = mdUser;
    this.router = router;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    this.isOperator = this.api.hasRole('LIST_ANY_USER');
    this.endpoint = this.isOperator ? 'bo/users' : 'users/me/users';
  }

  attached() {
    this.oldSelectedCountryCode = this.resource.data.profile.location.countryCode;
    this.init();
  }

  init() {
    this.pendingInit = true;
    let promises = [];
    if (this.isOperator) promises.push(this.getUserGroups());
    if (this.params.id && this.params.id !== 'new') promises.push(this.load());
    return Promise.all(promises).catch(error => {
      console.error(`GET - init`, error);
      this.toast.show('Errore durante il caricamento dei dati!', 'error');
    }).finally(() => {
      this.getBusinessType();
      this.setCompanyLegalFormCodeDatasource();
    });
  }

  load() {
    return this.getUserProfile();
  }

  changeLegalForm() {
    if (this.legalForms) {
      this.legalFormSelected = this.legalForms.find(legalForm => legalForm.code === this.resource.data.profile.companyLegalFormCode);
      this.resource.controls['profile.vatCode'].required = this.legalFormSelected?.vatCodeNeeded === 'REQUIRED';
      this.resource.controls['profile.fiscalCode'].required = this.legalFormSelected?.fiscalCodeNeeded === 'REQUIRED';
      this.resource.controls['profile.holderName.companyName'].required = this.legalFormSelected?.companyNameNeeded === 'YES';
      this.resource.controls['profile.holderName.firstname'].required = this.resource.controls['profile.holderName.lastname'].required = this.legalFormSelected?.personNameNeeded === 'YES';
    }
  }

  getUserProfile() {
    this.pendingLoad = true;
    return this.api.get(`users/${this.params.id || 'me'}?include=user-profiles,user-roles`).then(xhr => {
      let response = xhr.response;
      if (response.profile && Array.isArray(response.profile)) response.profile = response.profile[0];
      if (response.profile.holderName && !response.profile.holderName.companyName) response.profile.holderName.companyName = response.profile.holderName.lastname + ' ' + response.profile.holderName.firstname;
      this.resource.data = response;
      this.pendingLoad = false;
    }).catch(error => {
      console.error(`GET - users`, error);
      this.pendingLoad = false;
      this.toast.show('Errore durante il caricamento dei dati!', 'error');
    });
  }

  getUserGroups() {
    return this.api.get('user-groups').then(xhr => {
      let response = xhr.response;
      this.resource.schema.group.datasource = response; 
    });
  }

  setBusinessTypeEndpoint() {
    let endpoint = `user-business-types`;
    if (this.params.id === 'new') return `user-business-types`;
    else return `${endpoint}?applicable-to-user-uuid=${this.resource.data.uuid}`;
  }

  getBusinessType() {
    const endpoint = this.setBusinessTypeEndpoint();
    return this.api.get(endpoint).then(xhr => {
      let response = xhr.response;
      this.resource.schema.profile.businessType = Object.assign({}, this.resource.schema.profile.businessType, { datasource: response });
    });
  }

  setLegalFormsEndpoint() {
    let endpoint = `legal-forms`;
    let filters = [`country-code=${this.resource.data.profile.location.countryCode}`];

    // Master user creating a subuser
    if (this.params.id === 'new' && this.api.user.profile.businessType === 'BUILDING-ADMINISTRATOR') {
      filters.push(`legal-form-type=BUILDING`);
    } else if (this.params.id !== 'new') {
      filters.push(`applicable-to-user-uuid=${this.resource.data.uuid}`);
    }

    return `${endpoint}?${filters.join('&')}`;
  }

  setCompanyLegalFormCodeDatasource() {
    const legalFormsEndpoint = this.setLegalFormsEndpoint();
    let promises = [
      this.api.get('legal-form-types'),
      this.api.get(legalFormsEndpoint)
    ];

    return Promise.all(promises).then((xhrs) => {
      let companyLegalFormCodeDatasource;
      this.legalFormTypes = xhrs[0]?.response || null;
      this.legalForms = xhrs[1]?.response || null;

      if (this.legalFormTypes?.length) {
        let optgroup = {};
        companyLegalFormCodeDatasource = [];
        this.legalFormTypes.forEach(type => {
          let legalFormsForThisType = this.legalForms?.filter(x => x.type === type.code) || null;

          if (legalFormsForThisType?.length) {
            optgroup = { code: type.code, label: type.label, optgroup: true };
            companyLegalFormCodeDatasource.push(optgroup);
            legalFormsForThisType.forEach(form => {
              form = { code: form.code, label: form.label };
              companyLegalFormCodeDatasource.push(form);
            });
          }
        });

        this.resource.schema.profile.companyLegalFormCode = Object.assign({}, this.resource.schema.profile.companyLegalFormCode, { datasource: companyLegalFormCodeDatasource });
      }
    }).catch(error => {
      console.error(`GET - setCompanyLegalFormCodeDatasource`, error);
      this.toast.show('Errore durante il caricamento dei dati!', 'error');
    }).finally(() => {
      this.pendingInit = false;
    });
  }
  
  setGeo(field) {
    let childField, lvl, countryCode, countryCodeValueModel;
    if (field === 'countryCode') {
      childField = 'admLvl1Uuid';
      lvl = 1;
    } else if (field === 'admLvl1Uuid') {
      childField = 'admLvl2Uuid';
      lvl = 2;
    } else if (field === 'admLvl2Uuid') {
      childField = 'admLvl3Uuid';
      lvl = 3;
    } else return; 
    countryCode = this.resource.data.profile.location.countryCode || null;
    countryCodeValueModel = this.resource.controls['profile.location.countryCode'].control?.valueModel;
    if (countryCodeValueModel === undefined) return setTimeout(() => { this.setGeo(field) }, 500);

    let readonly = true, required = false, url = null;
    if (countryCode) {
      url = `geo/countries/${countryCode}/levels/${lvl}/divisions` + (lvl > 1 ? `?parent-uuid=${this.resource.data.profile.location[field]}` : '');
    }
    if (this.resource.data.profile.location[field]) {
      if (countryCodeValueModel.administrativeLevels && countryCodeValueModel.administrativeLevels.find(x => x.level === lvl)) {
        readonly = false;
        required = true;
      } else {
        this.resource.data.profile.location[childField] = null;
        this.resource.controls[`profile.location.${childField}`].element.dispatchEvent(new Event('change', { bubbles: true })); // legacy ka-control workaround: trigger valueChange
      }
    } else {
      this.resource.data.profile.location[childField] = null;
      this.resource.controls[`profile.location.${childField}`].element.dispatchEvent(new Event('change', { bubbles: true })); // legacy ka-control workaround: trigger valueChange
    } 
    this.resource.schema.profile.location[childField] = Object.assign({}, this.resource.schema.profile.location[childField], { readonly: readonly, datasource: { table: url, query: { search: 'text-search', item: 'uuid'} }});
    this.resource.controls[`profile.location.${childField}`].readonly = readonly; // legacy ka-control workaround: trigger readonlyChanged
    this.resource.controls[`profile.location.${childField}`].required = required;

    // Avoid redoing the call for the legal forms if not necessary
    if (field === 'countryCode' && (this.oldSelectedCountryCode !== countryCode)) {
      this.oldSelectedCountryCode = countryCode;
      this.resource.data.profile.companyLegalFormCode = null;
      this.setCompanyLegalFormCodeDatasource();
    }
  }

  copyServiceToken() {
    this.api.get('users/me/cross-login-tokens/current').then(xhr => {
      let token = xhr.response.token;
      konsole.debug(token);
      this.toast.show('Service token copiato in Console!', 'success');
      if (navigator.clipboard) navigator.clipboard.writeText(token).then(() => {
        this.toast.show('Service token copiato in Clipboard!', 'success');
      }, error => { throw error; });
    }).catch(error => {
      console.error('ERROR - cross-login-tokens/current - GET', error);
      this.toast.show('Impossibile copiare il service token in Clipboard!', 'error');
    });
  }

  parseResourceData() {
    if (this.resource.data?.profile?.location) {
      if (this.resource.data.profile.location.admLvl1) delete this.resource.data.profile.location.admLvl1;
      if (this.resource.data.profile.location.admLvl2) delete this.resource.data.profile.location.admLvl2;
      if (this.resource.data.profile.location.admLvl3) delete this.resource.data.profile.location.admLvl3;
    }

    let data = { ...this.resource.data };
    // A bulding administrator can only have bulding as sub-users
    if ((this.params.id === 'new' && this.api.user.profile.businessType === 'BUILDING-ADMINISTRATOR') || (this.params.id !== 'new' && this.resource.schema.profile.businessType.datasource.length === 1)) {
      data.profile.businessType = 'BUILDING';
    }
    if (data.profile?.businessType === 'BUILDING') {
      // The building tax code is assigned by the server as a username
      data.profile.username = null;
      // Password can be assigned when edit
      if (!data.uuid) data.password = null;
    }
    return data;
  }

  save($event) {
    const data = this.parseResourceData();

    // if data is an empty array
    if (!Object.entries(data).length) return $event.model.busy = false;

    return this.resource.validate().then(() => {
      $event.model.busy = true;

      // Edit resource
      if (data.uuid) {
        let endpoint = this.api.user.uuid === data.uuid ? `users/me` : `${this.endpoint}/${data.uuid}`;
        return this.api.put(endpoint, data).then(async () => {
          this.load();
          if (this.api.user.uuid === data.uuid) {
            await this.mdUser.initData();
            this.api.user = this.mdUser.data;
          }
          this.toast.show('Modifiche salvate con successo!', 'success');
          this.load();
        }).catch((error) => {
          console.error(`PUT ${endpoint}`, error);
          this.resource.error(error);
        }).finally(() => {
          $event.model.busy = false;
        });
      } 
      
      // Create resource
      else {
        return this.api.post(this.endpoint, data).then((xhr) => {
          let response = xhr.response;
          this.toast.show('Profilo creato con successo!', 'success');
          this.router.navigateToRoute('anagrafiche/utenti', { id: response.id, view: 'anagrafica' });
        }).catch(error => {
          console.error(`ERROR - ${this.endpoint} - PUT`, error);
          this.resource.error(error);
        }).finally(() => {
          $event.model.busy = false;
        });
      }
    });
  }

  annulla() {
    this.params = null;
    this.router.navigate('anagrafiche/utenti');
  }
}
