import {inject, InlineViewStrategy} from 'aurelia-framework';
import {Api} from 'services/api';
import {activationStrategy} from 'aurelia-router';
import {KaToast, KaDialog} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';
import moment from 'moment';

@inject(Api, KaToast, KaDialog)
export class MulticafDialogDettaglio {
  endpoint = null;
  schema = null;
  data = {};
  controls = {};
  columns = [];
  identifier = null;
  pendingRecords = null;
  account = null;

  constructor(api, toast, dialog) {
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
    this.schema = MulticafDettaglioSchema;
    this.endpoint = `${params.endpoint}/${params.session.uuid}`;
    this.transmissionType = params.transmissionType || 'CU';
    this.data.operatorConfirmIdentifier = 'YES';
    
    if (['770', 'F24', 'CND'].includes(this.transmissionType)) {
      this.schema.agentName.label = 'Contribuente';
      this.schema.agentTaxId.label = 'CF/IVA Contribuente';
    } else {
      this.schema.agentName.label = 'Sostituto d\'imposta';
      this.schema.agentTaxId.label = 'CF/IVA Sostituto d\'imposta';
    }
    for (let [key, value] of Object.entries(this.schema)) {
      let column = { name: key, label: value.label }
      if (['F24'].includes(this.transmissionType) && ['agentName', 'statusCode', 'registryCode', 'agentTaxId',].includes(column.name)) this.columns.push(column);
      if (['CU'].includes(this.transmissionType) && ['agentName', 'vendorName', 'statusCode', 'registryCode', 'agentTaxId', 'vendorTaxId'].includes(column.name)) this.columns.push(column);
      if (['770', 'CND'].includes(this.transmissionType) && ['agentName', 'agentTaxId'].includes(column.name)) this.columns.push(column);
    }

    // Account data
    this.api.get(`users/${this.params.session.userId}?include=user-profiles`).then(xhr => { this.account = xhr.response.profile[0]; console.log('PARAMS', this.account); });
  }

  attached() {
    this.checkPending();

    /* Table interface */
    this.multicafDettaglioTableInterface = new TableInterface({
      name: `dettaglio-${this.transmissionType}-table`,
      client: this.api,
      endpoint: `${this.endpoint}/records`,
      query: null
    });
    if (this.multicafDettaglioTableInterface) this.limit = [{ value:'10', text:'10' }, { value:'25', text:'25' }, { value:'50', text:'50' }];

    /* Table Search */
    this.multicafDettaglioSearchInterface = new SearchInterface({
      name: `dettaglio-${this.transmissionType}-table-search`,
      table: this.multicafDettaglioTableInterface,
      schema: { statusCode: this.schema.statusCode, agentTaxId: this.schema.agentTaxId, vendorTaxId: this.schema.vendorTaxId },
      data: {}
    });

    /* Initialize & Search function */
    this.multicafDettaglioTableInterface.initialize().then(() => {
      this.multicafDettaglioSearchInterface.initialize().then(() => { this.load(); }).catch(error => { console.error('TableSearchInterface-initialize multicafDettaglio failed', error); });
    }).catch(error => { console.error('TableInterface-initialize multicafDettaglio failed', error); });
  }

  load() {
    this.api.get(this.endpoint).then(xhr => {
      this.session = xhr.response;
      this.data.paymentOutcome = this.session.paymentOutcome;
      this.data.statusCode = this.session.statusCode;
      this.data.flag = this.session.flag === 'WORK_IN_PROGRESS_BY_OPERATOR';
      if (this.session.transmissionReceipt && this.session.transmissionReceipt.identifier) this.data.identifier = this.session.transmissionReceipt.identifier;
      if (['CND'].includes(this.transmissionType)) this.data.notes = this.session.notes;
      this.multicafDettaglioSearchInterface.search().catch(error => { console.error('TableSearchInterface-search multicafDettaglio failed', error); });
    }).catch(error => { console.error('Session load failed', error); });
  }

  rifiutaSessione($event) {
    this.data.statusCode = 'REFUSED';
    return this.update($event);
  }

  completeSession($event) {
    $event.model.busy = true;
    this.api.get(`${this.endpoint}/records`, {}).then(xhr => {
      let records = xhr.response;
      let pending = records.filter(record => record.statusCode === 'PENDING');
      if (pending.length) {
        $event.model.busy = false;
        return this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'alert', body: 'Alcuni record risultano ancora in lavorazione.'});
      } else {
        this.data.statusCode = 'COMPLETED';
        this.update($event);
      }
    });
  }

  updateSession($event) {
    $event.model.busy = true;
    if (this.data.operatorConfirmIdentifier === 'NO') {
      return this.dialog.open({
        title: 'Attenzione!',
        class: 'small',
        type: 'confirm',
        body: `<p class="m-0 mb-2">Sei sicuro/a di voler definitivamente registrare l'esito senza un numero di protocollo?</p><p class="m-0"><strong>L'operazione non sarà reversibile.</strong></p>`
      }).whenClosed(response => {
        if (!response.wasCancelled) {
          this.update($event);
        } else {
          $event.model.busy = false;
        }
      });
    } else {
      this.update($event);
    }
  }

  update($event) {
    let promises = [];
    let patchData = {};
    
    patchData.transmissionReceipt = { operatorConfirmIdentifier: this.data.operatorConfirmIdentifier };
    patchData.paymentOutcome = this.data.paymentOutcome || 'NONE';

    if (this.data.paymentReceiptFile) {
      patchData.paymentReceipt = {};
    }  
    if (this.data.identifier && this.data.identifier.length > 0) {
      patchData.transmissionReceipt.identifier = this.data.identifier;
    }

    if (this.data.statusCode === 'REFUSED') {
      patchData = { 
        statusCode: this.data.statusCode, 
        statusMessage: this.data.statusMessage 
      };
    } else if (this.data.statusCode === 'COMPLETED') {
      patchData = { 
        statusCode: this.data.statusCode, 
        flag: 'WORK_IN_PROGRESS_BY_OPERATOR' 
      }
    } else if (this.data.transmissionReceiptFile) {
      promises.push(new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(this.data.transmissionReceiptFile[0]);
        reader.onload = () => {
          console.log('patchData.transmissionReceipt', patchData);
          patchData.transmissionReceipt.fileData = reader.result;
          resolve(true);
        };
        reader.onerror = (error) => {
          console.log('Error: ', error);
          this.toast.show('Errore nel caricamento del file!', 'error');
          reject(error);
        };
      }));
    } else if (this.data.paymentReceiptFile) {
      promises.push(new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(this.data.paymentReceiptFile[0]);
        reader.onload = () => {
          patchData.paymentReceipt.fileData = reader.result;
          resolve(true);
        };
        reader.onerror = (error) => {
          console.log('Error: ', error);
          this.toast.show('Errore nel caricamento del file!', 'error');
          reject(error);
        };
      }));
    }

    if (['CND'].includes(this.transmissionType)) {
      patchData.notes = this.data.notes;
    }

    Promise.all(promises).then(() => {
      return this.api.patch(this.endpoint, { data: { attributes: patchData }}).then(() => {
        this.toast.show('Sessione aggiornata con successo!', 'success');
      }).catch(error => {
        this.api.dialogError(error, this.controls);
      }).finally(() => {
        this.load();
        $event.model.busy = false;
      });
    }).catch(() => {
      $event.model.busy = false;
      this.toast.show('Errore durante la\'aggiornamento della sessione!', 'error');
    });
  }

  recordAccepted(record) {
    let patchData = { statusCode: 'ACCEPTED' };
    this.api.patch(`${this.endpoint}/records/${record.uuid}`, { data: { attributes: patchData } }).then(() => {
      this.toast.show('Record aggiornato con successo!', 'success');
      this.multicafDettaglioSearchInterface.search().catch(error => { console.error('TableSearchInterface-search multicafDettaglio failed', error); });
      this.checkPending();
    }).catch(error => {
      this.api.dialogError(error);
      this.toast.show('Errore nel salvataggio dei dati!', 'error');
    });
  }

  recordRefused(record) {
    let viewStrategyParams = { codice: null, motivo: null };
    this.dialog.open({
      title: 'Attenzione!',
      class: 'medium',
      type: 'alert',
      viewStrategy: new InlineViewStrategy(`<template><strong>Confermi il rifiuto del record selezionato?</strong><br>Inserisci i seguenti dati per conferma:<br><br><ka-input config.bind="{control: 'text', label: 'Codice protocollo'}" value.bind="params.viewStrategyParams.codice"></ka-input><ka-input config.bind="{control: 'text', label: 'Motivo del rifiuto', required: true}" value.bind="params.viewStrategyParams.motivo"></ka-input></template>`),
      viewStrategyParams: viewStrategyParams
    }).whenClosed(response => {
      if (!response.wasCancelled) {
        if (!viewStrategyParams.motivo) {
          this.toast.show('Specificare il motivo del riufiuto', 'error');
          return;
        }
        let patchData = { statusCode: 'REFUSED', statusMessage: viewStrategyParams.motivo };
        if (viewStrategyParams.codice) patchData.receiptIdentifier = viewStrategyParams.codice;
        this.api.patch(`${this.endpoint}/records/${record.uuid}`, { data: { attributes: patchData } }).then(() => {
          this.toast.show('Record aggiornato con successo!', 'success');
          this.multicafDettaglioSearchInterface.search().catch(error => { console.error('TableSearchInterface-search multicafDettaglio failed', error); });
          this.checkPending();
        }).catch(error => {
          this.api.dialogError(error);
          this.toast.show('Errore nel salvataggio dei dati!', 'error');
        });
      }
    });
  }

  checkPending() {
    return this.api.get(`${this.endpoint}/records`, {}).then(xhr => {
      this.pendingRecords = xhr.response.filter(record => record.statusCode === 'PENDING').length || null;
      konsole.debug('this.pendingRecords', this.pendingRecords);
    }); 
  }

  acceptAll($event) {
    $event.model.busy = true;
    let patchData = { statusCode: 'ACCEPTED', type: 'PENDING' };
    this.api.patch(`${this.endpoint}/records/bulk`, { data: { attributes: patchData } }).then(x => {
      this.toast.show('Record aggiornato con successo!', 'success');
      this.multicafDettaglioSearchInterface.search().catch(error => { console.error('TableSearchInterface-search multicafDettaglio failed', error); });
      this.pendingRecords = null;
    }).catch(() => {
      this.toast.show('Errore nel salvataggio dei dati!', 'error');
    }).finally(() => {
      $event.model.busy = false;
    });
  }

  flag() {
    this.dialog.open({
      title: 'Attenzione!',
      class: 'small',
      viewModel: PLATFORM.moduleName('views/multicaf/multicaf-dialog-status-confirm'),
    }, true).whenClosed(response => {
      if (!response.wasCancelled) {
        this.api.patch(this.endpoint, { data: { attributes: { flag: this.data.flag === true ? 'WORK_IN_PROGRESS_BY_OPERATOR' : null } } }).then(() => {
          this.toast.show('Stato modificato con successo!', 'success');
        }).catch(x => {
          this.api.dialogError(x);
        });
      } else if (response.wasCancelled) this.data.flag = !this.data.flag;
    });
  }

  csvDownload() {
    console.log('CSV', this.multicafDettaglioTableInterface.data);

    if (!this.multicafDettaglioTableInterface.data.length) return;
    this.toast.show('Caricamento in corso', 'loading', true);
    let csv = ['ID ADE;Ricevuto il;Stato;Dettaglio;Sostituto imposta;CF Sostituto imposta;Percipiente;CF\P.IVA Percipiente;Codice MultiDialogo'];

    for (let record of this.multicafDettaglioTableInterface.data) {
      record.createdAt = moment.utc(record.createdAt, 'YYYY-MM-DD HH:mm:ss a').local().format('DD/MM/YYYY HH:mm');
      record.statusCode = record.statusCode === 'PENDING' ? 'In lavorazione' : record.statusCode === 'ACCEPTED' ? 'Accettato' : record.statusCode === 'COMPLETED' ? 'Completato' : record.statusCode === 'REFUSED' ? 'Rifiutato' : record.statusCode;
      console.log()
      csv.push([
        record.registryCode,
        record.createdAt,
        record.statusCode,
        record.statusMessage,
        record.agentName,
        record.agentTaxId,
        record.vendorName,
        record.venderTaxId,
        record.uuid,
      ].join(';'));
    }

    csv = csv.join('\n');
    let blob = new Blob([csv], {type: 'text/csv'});
    let anchor = document.createElement('a');
    anchor.download = 'rubrica-invio-tradizionale.csv';
    anchor.href = window.URL.createObjectURL(blob);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    this.toast.consume();
  }

  copyCode(code) {
    navigator.clipboard.writeText(code);
    this.toast.show('Codice copiato con successo!', 'success');
  }

  downloadTracciatoFiscale(session) {
    if (session.transmissionReceipt.identifier) {
      this.toast.show('Caricamento in corso', 'loading', true);
      let path = `users/${session.userId}/tax-withholding-transmission-sessions-receipt-downloads/${session.transmissionReceipt.identifier}`;

      this.api.client.createRequest(path)
      .asGet()
      .withHeader('Accept', 'application/vnd.api+json')
      .withResponseType('blob')
      .send()
      .then(xhr => {
        let regexp = new RegExp('filename="(.+\.txt)');
        let matches = xhr.headers.headers['content-disposition'].value.match(regexp);
        let anchor = document.createElement('a');
        let blob = new Blob([xhr.response], { type: 'text/plain' });
        let url = URL.createObjectURL(blob);
        anchor.href = url;
        anchor.download = matches && matches[1] ? matches[1] : `tracciato-fiscale-${session.transmissionReceipt.identifier}`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }).catch(xhr => {
        console.log('ERROR - export-users GET', xhr);
      }).finally(() => {
        this.toast.consume();
      });
    }
  }
}

export const MulticafDettaglioSchema = {
    uuid: { 
      control: 'text', 
      label: 'UUID',
    },
    createdAt: {
      label: 'Invio al', 
      control: 'date',
    },
    agentName: {
      control: 'text',
      label:   'Sostituto d\'imposta',
    },
    vendorName: {
      label:   "Percipiente",
      control: "text"
    },
    statusCode: { 
      control: 'combo',
      label: 'Stato',
      datatext: 'text',
      datavalue: 'value',
      datapreload: true,
      datasource: [{ text: 'In lavorazione', value: 'PENDING' }, { text: 'Accettato', value: 'ACCEPTED' }, { text: 'Completato', value: 'COMPLETED' }, { text: 'Rifiutato', value: 'REFUSED' }],
      query: 'status-code-in'
    },
    registryCode: { 
      control: 'text', 
      label: 'Progressivo',
    },
    agentTaxId: {
      control: 'text',
      label:   'CF/IVA Sostituto d\'imposta',
      query: 'agent-tax-id-in'
    },
    vendorTaxId: {
      label:   "CF/IVA Percipiente",
      control: "text",
      query: 'vendor-tax-id-in'
    },
    statusMessage: { 
      control: 'text', 
      label: 'Dettaglio stato',
    }
  }

