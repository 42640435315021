import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { TableInterface, SearchInterface, TableSelectionInterface } from 'classes/md-table';
import { KaDialog, KaToast } from 'ka-components';
import mdt from 'services/md-tools';

@inject(Router, Api, KaDialog, KaToast)
export class vmVolture {
  mdt = mdt;
  isInitializing = true;
  isLoading = false;
  context = null;
  contextUser = {};

  constructor(router, api, dialog, toast) {
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(model) {
    model.tab.controller = this;
    this.params = model.params;
    this.privileges = model.privileges;
    this.requestedUserData = model.requestedUserData;
  }

  attached() {
    this.init();
  }

  init() {
    this.initTableInterface();
    this.initTableSelectionInterface();
    this.initSearchInterface();
    this.productCodeChanged(this.searchInterface.data.productCode);
  }

  initTableInterface() {
    this.tableInterface = new TableInterface({
      name: 'volture-table',
      client: this.api,
      endpoint: this.endpoint,
      query: (this.privileges.isMaster ? 'acknowledged=NO' : null)
    });

    return this.tableInterface.initialize();
  }

  initTableSelectionInterface() {
    this.tableSelectionInterface = new TableSelectionInterface({
      table: this.tableInterface
    });
  }

  initSearchInterface() {
    this.searchInterface = new SearchInterface({
      name: 'volture-search',
      table: this.tableInterface,
      schema: {
        context: {
          control: 'combo',
          label: 'Quali volture visualizzare?',
          datasource: [
            { value: 'USER', text: (this.privileges.isOperator ? 'Le volture dell\'utente' : 'Le mie volture')},
            { value: 'SUB-USER', text: (this.privileges.isOperator ? 'Le volture dei sottoutenti' : 'Le volture dei miei ' + this.mdt.parsers.contextualize(this.api.user, 'utenti'))}
          ],
          datavalue: 'value',
          datatext: 'text',
          datamultiple: false,
          datapreload: true,
          required: true
        },
        user: { 
          control: 'combo', 
          label: this.mdt.parsers.contextualize(this.api.user, 'Utente'),
          datasource: { table: `${ this.privileges.isOperator ? 'bo/users' : 'users/me/users'}?include=user-profiles`, query: { search: 'text-search', item: 'id-in'} },
          datavalue: 'id', 
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false,
          required: this.privileges.isOperator
        },
        flatServiceCode: {
          control: 'text',
          label: 'Codice servizio',
          query: 'flat-service-code-in'
        },
        productCode: {
          control: 'combo',  
          label: 'Prodotto', 
          datasource: { table: 'products' }, 
          datavalue: 'code', 
          datatext: 'label',
          datamultiple: false,
          datapreload: true,
          query: 'product-code-in'
        },
        type: {
          control: 'combo', 
          label: 'Piano',
          datasource: { table: 'flat-service-types' },
          datavalue: 'code',
          datatext: 'description',
          datamultiple: false,
          datapreload: true,
          query: 'type-in'
        }
      },
      data: {
        context: !this.privileges.isOperator ? 'SUB-USER' : null,
        sdd: 'null'
      }
    });
    
    return this.searchInterface.initialize().then(() => {
      if (!this.privileges.isOperator) this.search();
      else if (this.privileges.isOperator && this.searchInterface.data.user && this.searchInterface.data.context) this.search();
    });
  }

  productCodeChanged(value) {
    // Control "type" related to "productCode"
    if (this.searchInterface.controls?.type) {
      this.searchInterface.data.type = null;
      this.searchInterface.controls.type.datasource.table = value ? `flat-service-types?product-code-in=${value}` : 'flat-service-types';
      this.searchInterface.controls.type.comboItemsSource = null;
      this.searchInterface.controls.type.comboItems = null;
    } else {
      this.searchInterface.schema.type.datasource.table = value ? `flat-service-types?product-code-in=${value}` : 'flat-service-types';
    }
  }

  search() {
    this.tableInterface.endpoint = this.buildEndpoint();
    this.searchInterface.search();
  }

  buildEndpoint() {
    // Endpoint
    this.endpoint = 'users';
    // users/(me|{id})
    if (this.requestedUserData) this.endpoint += `/${this.requestedUserData.id}`;
    else if (this.searchInterface.data.context === 'USER' && !this.privileges.isOperator) this.endpoint += '/me';
    else if (this.searchInterface.data.context === 'USER' && this.privileges.isOperator) this.endpoint += `/${this.searchInterface.data.user}`;
    else if (this.searchInterface.data.context === 'SUB-USER' && this.searchInterface.data.user) this.endpoint += `/${this.searchInterface.data.user}`;
    else if (this.searchInterface.data.context === 'SUB-USER' && !this.privileges.isOperator && !this.searchInterface.data.user) this.endpoint += '/me';
    // users/(me|{id})/(subusers?)
    if (this.requestedUserData && this.searchInterface.data.context === 'SUB-USER' && this.privileges.isOperator) this.endpoint += '/subusers';
    else if (!this.requestedUserData && this.searchInterface.data.context === 'SUB-USER' && !this.privileges.isOperator && !this.searchInterface.data.user) this.endpoint += '/subusers';
    else if (!this.requestedUserData && this.searchInterface.data.context === 'SUB-USER' && this.privileges.isOperator) this.endpoint += '/subusers';
    // users/(me|{id})/(subusers?)/flat-services-activations
    this.endpoint += '/flat-services-activations';
    return this.endpoint;
  }

  reset() {
    this.tableInterface.reset();
    this.searchInterface.reset(true);
    this.searchInterface.data.status = 'null';
    if (!this.privileges.isOperator && !this.requestedUserData) this.searchInterface.data.context = 'SUB-USER';
    if (!this.privileges.isOperator) this.search();
  }

  confirmSelectedServices($event) {
    $event.model.busy = true;
    
    this.dialog.open({
      title: 'Attenzione!',
      class: 'small',
      type: 'confirm',
      body: `Alla data di scadenza, i servizi volturati cesseranno istantaneamente. Confermata la voltura, potrai gestire in seguito l'autorinnovo dei servizi appena volturati nella sezione <em>Servizi attivi</em>.`
    }).whenClosed(response => {
      if (!response.wasCancelled) {
        let promises = [];
        this.tableSelectionInterface.reset();
        this.tableSelectionInterface.selectedRecords.forEach((record) => {
          record._selection.isSelected = false;
          record._selection.feedback = { status: 'In corso' };
          let data = { acknowledged: "YES" };

          promises.push(
            // the patch wants the flat service activation UUID but its inside the id, here's the reason for the record.id
            this.api.patch(`users/${record.ownerId}/flat-services-activations/${record.id}`, data).then(() => {
              record._selection.feedback = { status: 'Completato' };
            }).catch((error) => {
              console.error(error);
              let parsedError = JSON.parse(error.response);
              record._selection.feedback = { status: 'Errore', message: parsedError.source?.permissions[0]?.messages[0] || parsedError.detail };
            })
          );
        });
        Promise.all(promises).finally(() => {
          this.search();
        });
      }
    });

    return $event.model.busy = false;
  }
}
