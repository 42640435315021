import {Aurelia, inject, observable} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';
import mdt from 'services/md-tools';

@inject(Aurelia, Router, Api, KaDialog, KaToast)
export class vmElencoNotificheMie {
  mdt = mdt;
  pending = true;

  constructor(aurelia, router, api, dialog, toast) {
    this.aurelia = aurelia;
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(model) {
    this.params = model.params;
    this.privileges = model.privileges;
    this.tools = model.tools;
    
    // INIT notifications TableInterface
    this.myNotificationsTableInterface = new TableInterface({
      name: 'myNotificationsTableInterface',
      client: this.api,
      endpoint: `users/me/ui-notifications`
    });
    this.myNotificationsTableInterface.initialize().catch(error => { console.log('myNotificationsTableInterface initialization failed', error) });

    // INIT SearchInterface
    this.myNotificationsSearchInterface = new SearchInterface({
      name: 'myNotificationsSearchInterface',
      table: this.myNotificationsTableInterface,
      schema: {
        readBy: {
          control: 'combo',  
          label: 'Stato di lettura della notifica', 
          datasource: [{ value: 'null', text: 'Tutte le notifiche' }, { value: 'NOT-ME', text: 'Da leggere' }],
          datavalue: 'value', 
          datatext: 'text', 
          datapreload: true,
          datamultiple: false,
          query: 'read-by'
        },
        templateCode: {
          control: 'combo',  
          label: 'Addebitabile a', 
          datasource: null,
          datavalue: 'code', 
          datatext: 'label', 
          datapreload: false,
          datamultiple: false,
          query: 'template-code'
        }
      },
      data: {
        readBy: 'NOT-ME',
        templateCode: 'null'
      }
    });
    this.myNotificationsSearchInterface.initialize().then(() => {
      this.getTemplates().then(() => { this.setTemplate(); });
    }).catch(error => { 
      console.log('ERROR searchInterface initialization failed', error);
    });
  }

  readByChanged() {
    this.pending = true;
    this.getNotifications();
  }

  getTemplates() {
    return this.api.get('system-notification-templates').then(xhr => {
      this.templates = xhr.response;
      this.myNotificationsSearchInterface.schema.templateCode.datasource = this.templates;
    }).catch(error => {
      console.log('ERROR system-notification-templates get', error);
      this.api.dialogError(error);
    }).finally(() => {
      this.pending = false;
    });
  }

  setTemplate(templateCode) {
    this.pending = true;
    if (templateCode) {
      this.myNotificationsSearchInterface.data.templateCode = templateCode === 'all-templates' ? null : templateCode;
    } else {
      templateCode = this.myNotificationsSearchInterface.data.templateCode;
    }

    // Select templates
    setTimeout(() => {
      let oldSelectedTemplate = document.querySelector('.templates > .selected');
      oldSelectedTemplate?.classList.remove('selected');
      let newSelectedTemplate = document.getElementById(templateCode || 'all-templates');
      newSelectedTemplate?.classList.add("selected");
    }, 0);

    this.getNotifications();
  }

  getNotifications() {
    return this.myNotificationsSearchInterface.search().catch(error => {
      console.log('ERROR searchInterface search', error);
      this.toast.show('Errore durante il caricamento delle notifiche!', 'error');
    }).finally(() => {
      this.pending = false;
    });
  }

  preview(notification, $event) {
    if (!notification.readByMeAt) $event.model.busy = true;
    this.tools.preview(notification).then(load => {
      this.pending = true;
      $event.model.busy = false;
      if (load) {
        this.tools.getAlerts().then(() => {
          this.getNotifications();
        }).catch(() => { 
          this.pending = false;
         });
      } else {
        this.pending = false;
      }
    }).catch(() => { 
      $event.model.busy = false;
    });
  }

  notificationsReadStatus(notification) {
    this.pending = true;
    this.tools.notificationsReadStatus(notification).then(() => {
      this.tools.getAlerts().then(() => {
        this.getNotifications();
      }).catch(() => { this.pending = false; });
    }).catch(() => { this.pending = false; });
  }

  setAllAsRead(myNotificationsTableInterface) {
    this.pending = true;
    this.tools.setAllAsRead(myNotificationsTableInterface).finally(() => {
      this.tools.getAlerts().then(() => { 
        this.getNotifications();
      }).catch(() => { this.pending = false; });
    }).catch(() => { this.pending = false; });
  }

  takeOver(notification, $event) {
    this.pending = true;
    $event.model.busy = true;
    this.tools.takeOver(notification).then(() => {
      this.tools.getAlerts().then(() => { 
        this.getNotifications().then(() => {
          $event.model.busy = false;
        }).catch(() => { 
          this.pending = false;
          $event.model.busy = false;
        });
      }).catch(() => { 
        this.pending = false;
        $event.model.busy = false;
      });
    }).catch(() => { 
      this.pending = false;
      $event.model.busy = false;
    });
  }
}
