import {inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import mdt from 'services/md-tools';

@inject(Api, KaDialog, KaToast)
export class DialogIntentsConfirmTransmissionDelay {
  mdt = mdt;

  constructor(api, dialog, toast) {
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  created(view) {
    this.parent = view.bindingContext;
  }

  activate(params) {
    this.params = params;
  }

  send() {
    const data = { attributes: { statusCode: 'TRANSMITTED', nonWorkingDaysDisclaimerAccepted: 'YES' } };
    this.api.patch(`users/me/tax-withholding-transmission-sessions/${this.params.sessionUuid}`, { data }).then(x => {
      this.parent.controller.cancel();
      this.toast.show('Invio effettuato con successo!', 'success');
    }).catch(error => {
      console.log('Error Patch', error);
      this.toast.show('Errore durante l\'invio!', 'error');
    });
  }
}
