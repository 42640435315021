import {inject} from 'aurelia-framework';
import {Router, activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {TableInterface, SearchInterface} from 'classes/md-table';

@inject(Router, Api)
export class viewAlias {
  endpoint = 'bo/sms-aliases';

  constructor(router, api) {
    this.router = router;
    this.api = api;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    if (!this.params.id) {
      /* Table interface */
      this.aliasTableInterface = new TableInterface({
        name: 'table-alias',
        client: this.api,
        endpoint: 'bo/sms-aliases',
        query: null
      });

      /* Table Search */
      let schema = AliasSchema;
      schema.requestedAt.label = 'Data richiesta dal';
      this.aliasSearchInterface = new SearchInterface({
        name: 'alias-table-search',
        table: this.aliasTableInterface,
        schema: {
          requestedAtGte: schema.requestedAt,
          requestedAtLte: {
            label: 'al',
            control: 'date',
            datatype: 'datetime',
            utc: true,
            query: 'requestedAt-lte'
          },
          displayName: schema.displayName,
          companyName: schema.companyName,
          status: schema.status
        },
        data: {}
      });

      /* Initialize & Search function */
      this.aliasTableInterface.initialize().then(() => {
        this.aliasSearchInterface.initialize().then(() => {
          this.aliasSearchInterface.search().catch(error => { console.error('search alias failed', error); });
        }).catch(error => { console.error('table-search-initialize alias failed', error); });
      }).catch(error => { console.error('table-interface-initialize alias failed', error); });
    } else {
      this.resource.schema = AliasSchema;
      this.resource.endpoint = 'bo/sms-aliases' + (this.params.id !== 'new' ? `/${this.params.id}` : '');
      if (this.params.id !== 'new') { this.resource.load(); }
    }
  }

  edit(record) {
    this.router.navigateToRoute('backoffice/alias', { id: record.id });
  }

  cancel() {
    this.router.navigateToRoute('backoffice/alias');
  }

  /* save() {
    if (this.params.id === 'new') {
      this.resource.save().then();
    } else {
      this.resource.save(undefined, undefined, 'patch').then();
    }
  } */
}


export const AliasSchema = {
  displayName: {
    label: 'Mittente SMS',
    control: 'text',
    query: 'displayName-like'
  },
  companyName: {
    label: 'Azienda',
    control: 'text',
    query: 'companyName-like'
  },
  vatCode: {
    label: 'Partita IVA',
    control: 'text'
  },
  fiscalCode: {
    label: 'Codice fiscale',
    control: 'text'
  },
  streetAddress: {
    label: 'Indirizzo',
    control: 'text'
  },
  zipCode: {
    label: 'CAP',
    control: 'text'
  },
  country: {
    label: 'Nazione',
    control: 'text'
  },
  email: {
    label: 'Email',
    control: 'text'
  },
  phoneNumber: {
    label: 'Telefono',
    control: 'text'
  },
  requestedAt: {
    label: 'Data richiesta',
    control: 'date',
    datatype: 'datetime',
    utc: true,
    query: 'requestedAt-gte'
  },
  status: {
    label: 'Stato attivazione',
    control: 'combo',
    datatext: 'text',
    datavalue: 'value',
    datapreload: true,
    datasource: [{ text: 'In attesa di registrazione', value: 'PENDING'}, { text: 'Richiesta registrata', value: 'REGISTERED' }, { text: 'Attivo', value: 'COMPLETED' }, { text: 'Errore', value: 'ERROR' }, { text: 'Richiesta rifiutata', value: 'REFUSED' }],
    query: 'statusCode-in'
  }
}

