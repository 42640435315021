import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';
import mdt from 'services/md-tools';

@inject(Router, Api, KaDialog, KaToast)
export class vmOffertePrenotate {
  mdt = mdt;

  constructor(router, api, dialog, toast) {
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(model) {
    this.params = model.params;
    this.privileges = model.privileges;
    this.tools = model.tools;
  }

  attached() {
    this.init();
  }

  init() {
    // INIT TableInterface
    this.tableInterface = new TableInterface({
      name: 'tab-offerte-prenotate-table',
      client: this.api,
      endpoint: 'bo/promotion-offer-activations',
      query: 'status=PENDING'
    });
    this.tableInterface.initialize().catch(error => { console.log('tableInterface initialization failed', error) });

    // INIT SearchInterface
    this.searchInterface = new SearchInterface({
      name: 'tab-offerte-prenotate-search',
      table: this.tableInterface,
      schema: {
        code: {  
          label: 'Codice offerta',
          control: 'text',
          query: 'redeem-code'
        },
        requestedAtGte: {
          label: 'Data prenotazione dal',
          control: 'date',
          query: 'requested-at-gte'
        },
        requestedAtLte: {
          label: 'al',
          control: 'date',
          query: 'requested-at-lte'
        },
        email: {
          label: 'Email',
          control: 'text',
          query: 'request-email-like'
        }
      },
      data: {}
    });
    this.searchInterface.initialize().then(() => {
      this.searchInterface.data.requestedAtGte = this.searchInterface.data.requestedAtLte = null;
      this.searchInterface.search().catch(error => { 
        console.log('searchInterface search failed', error);
      });
    }).catch(error => { 
      console.log('searchInterface initialization failed', error);
    });
  }

  offerLink(offer) {
    this.tools.copyText(`${window.location.origin}/landing/offerta/${offer.redeemCode}`, 'Link offerta');
  }
}
