import {PLATFORM, inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaToast} from 'ka-components';

@inject(Api, KaToast)
export class vmNotifiche {
  tabs = [
    {
      id: 'notifiche',
      label: 'Notifiche di sistema',
      vm: PLATFORM.moduleName('views/backoffice/notifiche-sistema/tabs/lista-notifiche')
    }
  ];

  tools = {
    copyText: (text, context = 'Testo') => {
      if (!text) return;
      navigator.clipboard.writeText(text);
      this.toast.show(`${context} copiato con successo!`, 'success');
    }
  }

  constructor(api, toast) {
    this.api = api;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    this.privilege();
  }

  privilege() {
    this.privileges = { tabs: { notifiche: false }, buttons: {} };
    if (this.api.hasRole('LIST_USER_SYSTEM_NOTIFICATIONS')) {
      this.privileges.tabs.notifiche = true;
    }
  }
}
