import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { TableInterface, SearchInterface } from 'classes/md-table';
import mdt from 'services/md-tools';

@inject(Router, Api, KaDialog, KaToast)
export class vmAbbonamentiAttivabili {
  mdt = mdt;
  searchedData;
  endpoint = null;
  
  constructor(router, api, dialog, toast) {
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(model) {
    model.tab.controller = this;
    this.parent = model.parent;
    this.params = model.params;
    this.privileges = model.privileges;
    this.tools = model.tools;
    this.requestedUserData = model.requestedUserData;
    this.lastAcceptedServiceContract = this.privileges.lastAcceptedServiceContract;
  }

  attached() {
    this.init();
  }

  async init() {
    // INIT TableInterface
    this.tableInterface = new TableInterface({
      name: 'tab-abbonamenti-attivabili-table',
      client: this.api,
      endpoint: this.endpoint,
      query: null
    });
    this.tableInterface.initialize().catch(error => { console.log('tableInterface initialization failed', error) });

    // INIT SearchInterface
    this.searchInterface = new SearchInterface({
      name: 'tab-abbonamenti-attivabili-search',
      table: this.tableInterface,
      schema: {
        activable: {
          control: 'combo',
          label: 'Quali servizi attivabili visualizzare?',
          datasource: [
            { value: 'MASTER', text: 'I miei servizi attivabili'},
            { value: 'SUBUSER', text: 'I servizi attivabili dei miei ' + this.mdt.parsers.contextualize(this.api.user, 'utenti')}
          ],
          datavalue: 'value',
          datatext: 'text',
          datapreload: true,
          required: true
        },
        payable: {
          control: 'combo', 
          label: 'Fatturazione',
          datasource: [
            { value: 'MASTER', text: 'Fatturazione ' + this.mdt.parsers.contextualize(this.api.user, 'all\'account')},
            { value: 'SUBUSER', text: 'Fatturazione ' + this.mdt.parsers.contextualize(this.api.user, 'all\'utente')}
          ],
          datavalue: 'value',
          datatext: 'text',
          datapreload: true,
          required: true,
          query: 'payable-by'
        },
        productCode: {
          control: 'combo',  
          label: 'Prodotto',
          datasource: { table: 'products' },
          datavalue: 'code', 
          datatext: 'label',
          datamultiple: false,
          datapreload: true,
          query: 'product-code-in'
        },
        type: {
          control: 'combo',
          label: 'Piano',
          datasource: { table: 'flat-service-types' },
          datavalue: 'code',
          datatext: 'description',
          datamultiple: false,
          datapreload: true,
          query: 'type-in'
        },
        code: { 
          control: 'text', 
          label: 'Codice servizio',
          query: 'code-in'
        },
        title: {
          control: 'text',
          label: 'Titolo',
          query: 'title-like'
        },
        cashbackAmount: {
          label: 'Cashback',
          control: 'combo',
          datasource: [
            { value: 'null', text: 'Qualsiasi' },
            { value: 'YES', text: 'Servizi con cashback' },
            { value: 'NO', text: 'Servizi senza cashback' }
          ],
          query: 'has-cashback'
        }
      },
      data: {
        payable: 'SUBUSER',
        activable: 'SUBUSER',
        cashbackAmount: 'null'
      }
    });
    
    this.productCodeChanged();
    return this.searchInterface.initialize().then(() => { 
      return this.search();
    });
  }

  productCodeChanged(value = null) {
    // Control type related to productCode
    if (this.searchInterface.controls?.type) {
      this.searchInterface.data.type = null;
      this.searchInterface.controls.type.datasource.table = value ? `flat-service-types?product-code-in=${value}` : 'flat-service-types';
      this.searchInterface.controls.type.comboItemsSource = null;
      this.searchInterface.controls.type.comboItems = null;
    } else {
      this.searchInterface.schema.type.datasource.table = value ? `flat-service-types?product-code-in=${value}` : 'flat-service-types';
    }
  }

  setEndpoint() {
    let endpoint;
    const baseUrl = `users/${this.requestedUserData?.id || 'me'}`;
    const suffixUrl = `${this.searchInterface?.data?.activable === 'SUBUSER' ? 'subusers-activable-flat-services' : 'activable-flat-services'}`;

    if (this.requestedUserData) {
      endpoint = `${baseUrl}/activable-flat-services`;
    } else {
      endpoint = `${baseUrl}/${suffixUrl}`;
    }
    if (this.lastAcceptedServiceContract?.rescindedAt) this.tableInterface.query = 'has-cashback=NO';
    return endpoint;
  }

  async search() {
    this.endpoint = this.tableInterface.endpoint = this.setEndpoint();
    if (this.requestedUserData && !this.searchInterface.data.payable) this.searchInterface.data.payable = 'MASTER';
    if (!this.requestedUserData && this.searchInterface.data.activable === 'MASTER') this.searchInterface.data.payable = 'MASTER';
    
    return this.searchInterface.search().then(() => { 
      this.searchedData = { ...this.searchInterface.data }; 
    });
  }

  reset() {
    this.tableInterface.reset();
    this.searchInterface.reset(true);
    if (this.requestedUserData) this.searchInterface.data.payable = 'MASTER';
    else this.searchInterface.data.activable = this.searchInterface.data.payable = 'SUBUSER';
    this.search();
  }

  activateService($event, service) {
    if ($event.model) $event.model.busy = true;
    let activateOnUserId, payableByUserId, payableByUserUuid;
    const isServiceCashbackGracePeriod = this.lastAcceptedServiceContract?.gracePeriodEndAt ? true : false;
    const isCashbackContractNeverBeenSigned = this.lastAcceptedServiceContract?.isCashbackContractNeverBeenSigned;

    if (this.requestedUserData) {
      activateOnUserId = this.requestedUserData.id;
      payableByUserId = this.searchedData.payable === 'MASTER' ? this.requestedUserData.parentId : this.requestedUserData.id;
      payableByUserUuid = this.searchedData.payable === 'MASTER' ? this.requestedUserData.parentUuid : this.requestedUserData.uuid;
    } else if (this.searchedData.activable === 'MASTER') {
      activateOnUserId = payableByUserId = this.api.user.id;
      payableByUserUuid = this.api.user.uuid;
    } else if (this.searchedData.activable === 'SUBUSER') {
      if ((isCashbackContractNeverBeenSigned || isServiceCashbackGracePeriod) && service.cashbackAmount) {
        return this.tools.dialogCashbackSubscriptionRequired($event);
      } else {
        return this.openDialogSubuserServiceActivation($event, service);
      }
    }
    
    if (activateOnUserId && payableByUserId && payableByUserUuid) {
      if ((isCashbackContractNeverBeenSigned || isServiceCashbackGracePeriod) && service.cashbackAmount) {
        return this.tools.dialogCashbackSubscriptionRequired($event);
      } else {
        return this.openDialogUserServiceActivation($event, service, payableByUserId, payableByUserUuid, activateOnUserId);
      }
    }
  }

  openDialogSubuserServiceActivation($event, service) {
    return this.dialog.open({
      title: 'Attivazione servizio in abbonamento',
      class: 'abbonamenti-attivazione-sottoutenti',
      viewModel: PLATFORM.moduleName('views/servizi/abbonamenti/dialogs/abbonamenti-attivazione-sottoutenti'),
      viewModelParams: { service, payable: this.searchedData.payable }
    }, true).whenClosed((response) => {
      if (!response.wasCancelled) {
        this.parent.tabs.find((tab) => tab.id === 'attivi').controller.search();
        this.tableInterface.load();
      }
      if ($event.model) $event.model.busy = false; 
    });
  }

  openDialogUserServiceActivation($event, service, payableByUserId, payableByUserUuid, activateOnUserId) {
    return this.dialog.open({
      title: 'Attivazione servizio in abbonamento',
      class: 'small abbonamenti-attivazione',
      viewModel: PLATFORM.moduleName('views/servizi/abbonamenti/dialogs/abbonamenti-attivazione'),
      viewModelParams: { service, payableByUserId }
    }, true).whenClosed((response) => {
      if (!response.wasCancelled && response.output) {
        let data = {
          flatServiceUuid: service.id,
          payeeUserUuid: payableByUserUuid,
          payeeBillingProfileUuid: response.output.billing,
          paymentMethod: response.output.payment
        }

        if (service.autoRenewable === true && data.paymentMethod === 'SDD') data.autoRenew = 'ENABLED';
        this.api.post(`users/${activateOnUserId}/flat-services-activations`, data).then(() => {
          this.toast.show('Servizio attivato con successo!', 'success');
          this.parent.tabs.find(tab => tab.id === 'attivi').controller.search();
          this.tableInterface.load();
        }).catch((error) => {
          this.api.dialogError(error);
        }).finally(() => {
          if ($event.model) $event.model.busy = false;
        });
      } else if ($event.model) $event.model.busy = false;
    });
  }

  serviceDescription(description) {
    this.dialog.open({ title: 'Descrizione servizio', class: 'medium', type: 'alert', body: `${description}` });
  }
}
