import {inject, customElement, containerless, bindable, bindingMode, InlineViewStrategy} from 'aurelia-framework';

@customElement('md-control')
@containerless()
@inject(Element)
export class MdControl {
  // Basic input control properties
  @bindable() schema = null;
  @bindable({defaultBindingMode: bindingMode.twoWay}) value = null;
  // Other input control bindable properties
  @bindable() name = null;
  @bindable() alias = null; // Utilizzato per impostare un alias da aggiungere in resource.controls per la gestione errori
  @bindable() readonly = null;
  @bindable() required = null;
  @bindable() label = null;
  @bindable() description = null;
  @bindable() autocomplete = null;
  @bindable() placeholder = '';
  @bindable() client = null;
  @bindable() error = null;

  // Input control specific params property
  @bindable() params = null;
  // Other control input properties
  errors = null;
  // Bindable callbacks
  @bindable() change = (value) => {};
  @bindable() submit = (value) => {};

  constructor(element) {
    this.element = element;
  }

  bind(bindingContext) {
    this.parent = bindingContext;
  }

  attached() {
    let bindValueToParentData = !(this.name && (this.element.hasAttribute('value') || this.element.hasAttribute('value.bind')));
    let mdTooltip = this.element.hasAttribute('md-tooltip') ? `md-tooltip="${this.element.getAttribute('md-tooltip')}"` : ( this.element.hasAttribute('md-tooltip.bind') ? `md-tooltip.bind="${this.element.getAttribute('md-tooltip.bind')}"` : "" );
    this.viewStrategy = new InlineViewStrategy(`<template><ka-control view-model.ref="parent.controls['${this.name}']" schema.bind="parent.schema.${this.name}" value.bind="${bindValueToParentData ? `parent.data.${this.name}` : 'value'}" readonly.bind="readonly||parent.schema.${this.name}.readonly||parent.readonly" placeholder.bind="placeholder" ${mdTooltip} autocomplete.bind="autocomplete" client.bind="client||parent.client" ${ this.element.hasAttribute('buttons') ? `buttons="${this.element.getAttribute('buttons')}"` : ''} params.bind="params" ka-enter.call="enterPressed()" change.trigger="change()"></ka-control></template>`);
    setTimeout(() => {
      if (this.parent && this.parent.controls && this.name && this.alias) {
        this.parent.controls[this.alias] = this.parent.controls[this.name];
      }
    }, 500);
  }

  enterPressed() {
    if (this.submit && typeof this.submit === 'function') this.submit();
  }
}

