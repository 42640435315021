import {inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaToast} from 'ka-components';

@inject(Api, KaToast)
export class viewRecupero {
  uniqueIdentifier = null;
  success = false;

  constructor(api, toast) {
    this.api = api;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
  }

  start($event) {
    if ($event && $event.model) $event.model.busy = true;
    
    this.uid.input.validator.errors = [];

    this.uid.input.validate().then(x => {
      this.api.post('users/login-recovery-requests', { uniqueIdentifier: this.uniqueIdentifier }).then(x => {
        this.email = x.response.emailAddress;
      }).catch(x => {
        this.uid.input.validator.errors.push('Identificativo non riconosciuto');
        this.uid.input.element.classList.add('error');
        this.toast.show('Identificazione utente fallita!', 'error');
      }).finally(() => {
        if ($event && $event.model) $event.model.busy = false;
      });
    }).catch(e => {
      if ($event && $event.model) $event.model.busy = false;
    }).finally(x => {
    });
  }
  end($event) {
    if ($event && $event.model) $event.model.busy = true;

    this.error = false;
    this.pass1.input.validator.errors = [];
    this.pass2.input.validator.errors = [];

    let promises = [];
    promises.push(this.pass1.input.validate());
    promises.push(this.pass2.input.validate());

    Promise.all(promises).then(x => {
      if (this.password1 !== this.password2) {
        this.pass2.input.validator.errors.push('Le password non combaciano');
        this.pass2.input.element.classList.add('error');
        if ($event && $event.model) $event.model.busy = false;
      } else {
        this.api.post(`users/login-recovery-requests/${this.params.token}/password-resets`, { password: this.password1 }).then(x => {
          this.success = true;
        }).catch(x => {
          let response = JSON.parse(x.response);
          if (response.source?.parameters?.length && response.source?.parameters[0].messages?.length) {
            this.error = response.source.parameters[0].messages[0];
          } else if (response.detail) {
            this.error = response.detail;
          }
          this.toast.show('Cambio password utente fallito!', 'error');
        }).finally(() => {
          if ($event && $event.model) $event.model.busy = false;
        });
      }
    }).catch(e => {
      if ($event && $event.model) $event.model.busy = false;
    }).finally(x => {
    });
    
  }
}
