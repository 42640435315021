import {inject} from 'aurelia-framework';
import {HttpClient, HttpResponseMessage} from 'aurelia-http-client';
import {Router} from 'aurelia-router';
import {KaDialog, KaToast} from 'ka-components';
import datasets from '../md-datasets.json';
import mdt from './md-tools';

@inject(Router, KaDialog, KaToast)
export class Api {
  _accessToken = '';
  _refreshToken = '';
  _refreshPromise = null;
  _autologinPromise = null;
  _user = null;
  _baseUrl = ENVIRONMENT.APP_API_BASE_URL;

  constructor(router, dialog, toast) {
    this._accessToken = localStorage.getItem('access_token') || '';
    this._refreshToken = localStorage.getItem('refresh_token') || '';
    this._boAccessToken = localStorage.getItem('bo_access_token') || '';
    this._boRefreshToken = localStorage.getItem('bo_refresh_token') || '';
    this._user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    this._refreshUrl = 'users/login/refresh';
    this.client = new HttpClient();
    this.client._baseUrl = this._baseUrl; // Keep reference of baseUrl into HttpClient object
    this.router = router;
    this.dialog = dialog;
    this.toast = toast;

    if (ENVIRONMENT.APP_DEBUG === 'true') {
      window['destorageTokens'] = () => {
        this._accessToken = localStorage.getItem('access_token') || '';
        this._refreshToken = localStorage.getItem('refresh_token') || '';
      };
    }

    this.client.configure(x => {
      x.withBaseUrl(this.baseUrl);
      x.withHeader('Accept-Language', 'it');
      x.withHeader('Accept', 'application/vnd.api+json');
      x.withHeader('X-api-client-name', ENVIRONMENT.APP_API_CLIENT_NAME);
      x.withHeader('X-api-key', ENVIRONMENT.APP_API_KEY);
      x.withHeader('X-api-client-version', ENVIRONMENT.APP_API_CLIENT_VERSION);
      if (ENVIRONMENT.APP_DEBUG === 'true') x.withHeader('X-ignore-test-agreements', 'ignore');
      x.withInterceptor({
        request: msg => {
          if (this.accessToken.length && (!this._refreshUrl.length || (msg.url !== this._refreshUrl))) {
            msg.headers.add('Authorization', 'Bearer ' + this.accessToken);
          }
          let fingerprint = window.localStorage.getItem('xhr-fingerprint');
          if (fingerprint) {
            msg.headers.add('X-Device-Fingerprint', fingerprint);
          }
          if ((msg.method === 'POST' || msg.method === 'PUT' || msg.method === 'PATCH') && msg.content) {
            console.log('POST da normalizzare', msg.content);
            if (Array.isArray(msg.content) && msg.content.length) {
              let content = [];
              msg.content.forEach(item => {
                content.push({ attributes: item });
              });
              msg.content = JSON.stringify({ data: content });
            } else if (typeof msg.content === 'string' && msg.content.startsWith('{"data":{"attributes"')) { // Già serializzato
              msg.content = msg.content;
            } else if (typeof msg.content === 'object' && (!msg.content.data || (msg.content.data && !msg.content.data.attributes))) {
              msg.content = JSON.stringify({data: { attributes: msg.content }});
            }
          }
          if (/^datasets\//.test(msg.url)) {
            // Intercetto chiamata kamaji /datasets e simulo risposta
            console.group('DATASETS CALL INTERCEPTED');
            console.log('Call:', msg.url);
            throw new Error(msg.url);
          }
          // // Sanitize della query K2->MD
          // msg.url = this.sanitizeUrl(msg.url);
          // Convert url K2->MD
          let queryMap = {
            meta: null,
            depth: null,
            fields: null,
            filters: (expr) => {
              let filters = [];
              expr = expr.split(/\&|\|/);
              expr.forEach((element, index) => {
                let operators = {
                  gte : '>=',
                  lte : '<=',
                  gt  : '>',
                  lt  : '<',
                  eq  : '=',
                  in  : '~~',
                  like: '~'
                };
                element = element.replace(/^\(|\)$/g, ''); // Remove k2 brackets from filters
                console.log('regex on',element);
                let regexp = new RegExp('([^\^!~><=]*)(' + Object.values(operators).join('|') + ')([^\^!~><=]*)');
                let matches = element.match(regexp);
                if (matches && matches.length) {
                  for (let [key, operator] of Object.entries(operators)) {
                    if (matches[1] === 'id') {
                      filters['id-in'] = matches[3];
                      break;
                    } else if (msg.url.startsWith('users/me/users') && matches[1] === 'displayName') {
                      filters['text-search'] = matches[3];
                      break;
                    } else if (matches[2] === operator) {
                      key = matches[1] + ((key === 'eq') ? '' : '-' + key);
                      filters[key] = matches[3];
                      break;
                    }
                  }
                }
              });
              return filters;
            },
            sort: (expr) => {
              let sorts = [];
              expr = expr.split(',');
              expr.forEach((element, index) => {
                sorts.push(element.replace('+',''));
              });
              return {sort: sorts.join(',')};
            }
          };
          // console.log('URL', this.convertHref(msg.url, queryMap));
          msg.url = this.convertHref(msg.url, queryMap);

          return msg;
        },
        requestError(error) {
          throw error;
        },
        response: msg => {
          if (typeof msg.response === 'string' && msg.response !== '') {
            msg.response = msg.originalResponse = JSON.parse(msg.response);

            let response = {};
            let included = {};
            let isResponseArray = false;

            // Parse jsonapi meta
            if (msg.response.meta && msg.response.meta.total) {
              msg.headers.headers['x-total-count'] = { key: 'x-total-count', value: msg.response.meta.total };
            }
            if (msg.response.meta && msg.response.meta.warnings) {
              let warnings = msg.response.meta.warnings;
              if (!Array.isArray(warnings)) warnings = [warnings];
              warnings.forEach(warning => {
                this.toast.show(warning, 'warning');
              });
            }
            // Parse jsonapi includes
            if (msg.response.included) {
              for (let record of msg.response.included) {
                if (!included[record.type]) included[record.type] = {};
                included[record.type][record.id || record.uuid] = Object.assign({id: record.id, uuid: record.uuid}, record.attributes);
              }
            }
            // Parse jsonapi data
            if (msg.response.data) {
              isResponseArray = Array.isArray(msg.response.data);
              response = isResponseArray ? msg.response.data : [msg.response.data];
              for (let record of response) {
                
                if (record.data && Object.keys(record).length === 1) {
                  //decapsulate data
                  record = Object.assign(record, record.data);
                  delete record.data;
                }
                if (record.attributes) {
                  record = Object.assign(record, record.attributes);
                  delete(record.attributes);
                }
                if (record.relationships) {
                  for (let keys of Object.keys(record.relationships)) {
                    let includes = Array.isArray(record.relationships[keys]) ? record.relationships[keys] : [record.relationships[keys]];
                    for (let include of includes) {
                      if (!record[keys]) record[keys] = [];
                      record[keys].push(included[include.data.type][include.data.id || include.data.uuid]);
                    }
                    //if (record[keys].length === 1) record[keys] = record[keys][0];
                  }
                  delete(record.relationships);
                }
              }
            }
            msg.response = (response.length === 1 && !isResponseArray) ? response[0] : response;
          }
          if (ENVIRONMENT.APP_DEBUG === 'true') console.log('Parsed response', msg.response);

          return msg;
        },
        responseError: msg => {
          if (msg && msg.message && /datasets\//.test(msg.message)) {
            console.log('ResponseError interceptor');
            msg.message = msg.message.replace(/\|/, '/');
            let endpoint = msg.message.match(/^datasets\/(.*)$/);
            
            if (endpoint[1]) {
              let response = {
                mimeType: 'application/json',
                response: endpoint[1],
                responseType: 'json',
                statusCode: 200,
                statusText: 'OK'
              };
              if (datasets[endpoint[1]]) {
                response.response = datasets[endpoint[1]];
                console.log('Endpoint found:', endpoint[1]);
                console.groupEnd();
                return Promise.resolve(response);
              }
            }
            console.warn('No endpoint found!', endpoint);
            console.groupEnd();
          }
          if (!msg.statusCode && !/healthcheck/.test(msg.requestMessage?.url)) {
            const httpclient = new HttpClient();
            httpclient.get(`${ENVIRONMENT.APP_API_BASE_URL}../../healthcheck`).then((xhr) => {
              try {
                const response = JSON.parse(xhr.response);
                if (response.status === 'MAINTENANCE') {
                  location.href = '/maintenance' + (response.reason ? `/${window.btoa(response.reason)}`: '');
                }
              } catch (error) {}
            });
            return Promise.reject(msg);
          }
          let smsReportUrl = new RegExp('users\/(me|\d*)\/sms-queues\/.*');
          if (msg.statusCode === 500 && !smsReportUrl.test(msg.requestMessage.url)) {
            this.dialog.open({ title: 'Errore', class: 'small', type: 'alert', body: '<strong>Si è verificato un errore lato server</strong><br>Se il problema persiste, si prega di inviare una email ad<br><a href="mailto:assistenza@multidialogo.it" class="primary-text">assistenza@multidialogo.it</a>'});
          }
          // Maintenance mode
          else if (msg.statusCode === 503) {
            location.href = '/maintenance';
            return Promise.reject(msg);
          }
          // Intents dialog
          else if (msg.statusCode === 423) {
            console.log('423 - check if response is blob', typeof msg.response, msg.response.constructor === Blob);
            if (typeof msg.response === 'object' && msg.response.constructor === Blob) {
              msg.response.text().then((response) => {
                if (this.isJSON(response)) {
                  msg.response = JSON.parse(response);
                  return this.dialogIntents(msg);
                }
              });
            }
            if (this.isJSON(msg.response) && JSON.parse(msg.response).source) {
              msg.response = JSON.parse(msg.response);
              if (['/user-prospects/'].find(key => msg.requestMessage?.url.includes(key))) {
                return Promise.reject(msg);
              } else {
                return this.dialogIntents(msg);
              }
            }
          }
          // Refresh token
          else if (msg.statusCode === 401 && this.refreshToken.length && msg.requestMessage.url !== this._refreshUrl) {
            return this.refresh(this.refreshToken).then(() => {
              let xhr = msg.requestMessage;
              xhr.headers.add('Authorization', 'Bearer ' + this.accessToken);
              console.log('Replay xhr data', xhr);
              if (xhr.method === 'POST' && xhr.content && (typeof xhr.content === 'string') && xhr.content.indexOf('{') === 0) {
                xhr.content = JSON.parse(xhr.content);
              }
              return this.client.send(xhr);
            }, error => {
              return error;
            });
          }
          return Promise.reject(msg);
        }
      });
    });

    this.events = document.createTextNode(null);
    this.events.userDataChanged = new CustomEvent('userDataChanged', { detail: this._user });
  }

  get baseUrl() {
    return this._baseUrl;
  }

  get accessToken() {
    return this._accessToken;
  }
  set accessToken(value) {
    this._accessToken = value || '';
    if (value) localStorage.setItem('access_token', value);
    else localStorage.removeItem('access_token');
  }

  get refreshToken() {
    return this._refreshToken;
  }
  set refreshToken(value) {
    this._refreshToken = value || '';
    if (value) localStorage.setItem('refresh_token', value);
    else localStorage.removeItem('refresh_token');
  }

  get boAccessToken() {
    return this._boAccessToken;
  }
  set boAccessToken(value) {
    this._boAccessToken = value || '';
    if (value) localStorage.setItem('bo_access_token', value);
    else localStorage.removeItem('bo_access_token');
  }

  get boRefreshToken() {
    return this._boRefreshToken;
  }
  set boRefreshToken(value) {
    this._boRefreshToken = value || '';
    if (value) localStorage.setItem('bo_refresh_token', value);
    else localStorage.removeItem('bo_refresh_token');
  }

  get user() {
    return this._user;
  }
  set user(value) {
    this._user = value || '';
    if (value) localStorage.setItem('user', JSON.stringify(value));
    else localStorage.removeItem('user');
    this.events.dispatchEvent(this.events.userDataChanged);
  }

  get isAuthenticated() {
    return !!this.accessToken.length && !!this.user?.roles;
  }
  get isImpersonated() {
    return !!this.boAccessToken.length && this.isAuthenticated;
  }

  login(username, password, code = null) {
    let data = {};
    if (!code) data = { username: username, password: password, grantType: 'password' };
    else data = { token: code, grantType: 'serviceToken' };

    this.accessToken = '';
    this.refreshToken = '';
    this.user = null;
    localStorage.clear();
    sessionStorage.clear();

    return this.client.post('users/login', data).then(msg => {
      console.log('Login response', msg.response);
      this.accessToken = msg.response.token;
      this.refreshToken = msg.response.refreshToken;
      return this.startUser(code);
    }, x => {
      this.accessToken = '';
      this.refreshToken = '';
      this.user = null;
      return Promise.reject(x);
    });
  }
  startUser(code = null) {
    return new Promise((resolve, reject) => {
      this.client.get('users/me?include=user-profiles,user-roles,user-modules').then(xhr => {
        let user = JSON.parse(JSON.stringify(xhr.response));
        user.profile = user.profile[0];
        user.username = user.profile.username;
        user.displayName = user.profile.displayName;
        this.user = user;
        console.log('%cLOGGED IN USER DATA', 'color: purple; font-weight: bold;', user);

        // I add the BETA-feature codes to the user roles
        if (this.hasRole('LIST_USER_BETA_FEATURE_SUBSCRIPTIONS')) {
          this.client.get('users/me/beta-feature-subscriptions').then(xhr => {
            const response = xhr.response;
            const user = this.user;
            if (response.length) xhr.response.forEach(feat => { user.roles.push({ id: `BETA_HAS_${feat.featureCode}` }); });
            this.user = user;
          }).catch(() => {
            console.log('GET beta-feature-subscriptions', error);
          }).finally(() => {
            resolve(this.reroute(code));
          })
        } else {
          resolve(this.reroute(code));
        }
      }).catch(error => {
        this.user = null;
        reject(error);
      });
    });
  }
  reroute(code) {
    let reroute = decodeURIComponent(new URL(document.location).searchParams.get('reroute'));
    if (!code && reroute !== 'null') return location.href = reroute;
    else if (!code) return this.router.navigateToRoute('dashboard');
  }
  impersonate(id = null, code = null) {
    if (!id && !code) return Promise.reject(new Error('Either user id or support ticket code must be provided!'));
    this.toast.show('Caricamento in corso', 'loading', true);
    return this.client.post(id ? `bo/users/${id}/login` : `bo/users/me/support-ticket-login/${code}`, {}).then(xhr => {
      let boAccessToken = this.accessToken;
      let boRefreshToken = this.refreshToken;
      this.user = null;
      localStorage.clear();
      sessionStorage.clear();
      this.boAccessToken = boAccessToken;
      this.boRefreshToken = boRefreshToken;
      this.accessToken = xhr.response.token;
      this.refreshToken = xhr.response.refreshToken;
      return this.startUser().then(() => { location.reload() });
    }, error => {
      this.toast.consume();
      return Promise.reject(error);
    });
  }
  disimpersonate() {
    this.toast.show('Caricamento in corso', 'loading', true);
    let boAccessToken = this.boAccessToken;
    let boRefreshToken = this.boRefreshToken;
    this.user = null;
    this.boAccessToken = null;
    this.boRefreshToken = null;
    localStorage.clear();
    sessionStorage.clear();
    this.accessToken = boAccessToken;
    this.refreshToken = boRefreshToken;
    return this.startUser().then(() => { location.reload() });
  }

  logout(soft = false) {
    let href = '/login';
    const reroute = JSON.parse(sessionStorage.getItem(btoa(`reroute`)));

    this.accessToken = '';
    this.refreshToken = '';
    this.boAccessToken = '';
    this.boRefreshToken = '';
    this.user = null;
    localStorage.clear();
    sessionStorage.clear();
    if (!soft) location.href = reroute ? `${href}?reroute=${reroute.href}`: href;
  }

  refresh(refreshToken) {
    if (this._refreshPromise) return this._refreshPromise;
    if (!this.user || !this.user.username) {
      this.logout();
      return Promise.reject(x);
    }
    this._refreshPromise = this.client.post(this._refreshUrl, { username: this.user.username, refreshToken, grantType: 'refresh-token'}).then(msg => {
      this.accessToken = msg.response.token;
      this.refreshToken = msg.response.refreshToken;
      return Promise.resolve(msg.response);
    }, x => {
      this.logout();
      return Promise.reject(x);
    });
    this._refreshPromise.finally(() => {
      this._refreshPromise = null;
    });
    return this._refreshPromise;
  }

  get(endpoint, params) {
    let queryString = (typeof params === 'object') ? '?' + this.buildQueryString(params) : '';
    return this.client.get(this.baseUrl + endpoint + queryString);
  }

  post(endpoint, data) {
    return this.client.post(this.baseUrl + endpoint, data);
  }

  put(endpoint, data) {
    return this.client.put(this.baseUrl + endpoint, data);
  }

  patch(endpoint, data) {
    return this.client.patch(this.baseUrl + endpoint, data);
  }

  delete(endpoint) {
    return this.client.delete(this.baseUrl + endpoint);
  }

  buildQueryString(params) {
    let query = [];
    for (let [key, value] of Object.entries(params)) query.push(`${key}=` + encodeURIComponent(value));
    return query.join('&');
  }

  parseUrl(url) {
    let chunks = url.split('?');
    let hashes = (chunks[1] || '').split('&');
    let query = {};
    hashes.map(hash => {
      if (hash.length) {
        let [key, val] = hash.split('=');
        query[key] = decodeURIComponent(val);
      }
    });
    return { url: chunks[0], query };
  }

  sanitizeUrl(url) {
    let blacklist = [ 'meta', 'depth', 'fields', 'filters' ];
    let chunks = url.split('?');
    let query = chunks[1] ? chunks[1].split('&') : [];
    let regexp = new RegExp('^' + blacklist.join('|'));
    for (let i = 0; i < query.length; i++) {
      if (query[i].match(regexp)) { query.splice(i, 1); i--; }
    }
    return chunks[0] + (query.length ? '?' + query.join('&') : '');
  }

  convertHref(href, queryMap = {}) {
    let { url, query } = this.parseUrl(href);
    for (let [key, item] of Object.entries(queryMap)) {
      if (typeof item === 'function' && query[key]) {
        let result = item(query[key]);
        delete(query[key]);
        query = Object.assign({}, query, result);
      } else if (item !== null) {
        delete(query[key]);
        query = Object.assign({}, query, item);
      } else {
        delete(query[key]);
      }
    }
    let queryString = Object.keys(Object.assign({}, query)).map(key => key + '=' + query[key]).join('&');
    return url + (queryString.length ? '?' + queryString : '');
  }

  dialogError(xhr, controls = {}, unlabelParameters = []) {
    if (xhr instanceof Object && (xhr.constructor === Object || xhr.constructor === HttpResponseMessage)) {
      if (xhr?.statusCode !== 500) {
        if (xhr.statusCode === 423 && xhr.response?.source) return;
        if (xhr.statusCode === 0 && xhr.response.type === 'abort') return;
        let errorPayload = mdt.parsers.apiError(xhr, controls, unlabelParameters);
        if (errorPayload) this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>'+errorPayload.title+':</strong><div style="margin-top:1rem;border:1px solid #e0e0e0;padding:1rem;max-height:400px;overflow:auto;background-color:#ffffff">' + errorPayload.body + '</div>'});
      }
    } else return;
  }

  dialogIntents(xhr) {
    return new Promise((resolve, reject) => {
      this.dialog.dialog.open({ 
        viewModel: PLATFORM.moduleName('resources/dialogs/intents/intents'),
        model: xhr,
        lock: true
      }).whenClosed((response) => {
        if (sessionStorage.getItem(btoa(`intents-reload`)) === JSON.stringify({ reload: true })) return location.reload();
        if (!response.wasCancelled) {
          this.client.send(xhr.requestMessage).then(xhr => { resolve(xhr) }).catch(xhr => { reject(xhr) })
        } else {
          return reject(xhr);
        }
      });
    });
    
  }

  hasRole(roles) {
    if (!this.user?.roles) return null;
    if (!Array.isArray(roles)) roles = [roles];
    return this.user.roles?.some(e => roles.includes(e.id));
  }

  isJSON(string) {
    try {
      if (JSON.parse(string)) return true;
    } catch(error) {
      return false;
    }
  }
}

