import { Router } from 'aurelia-router';
import { inject } from 'aurelia-framework';

@inject(Router)
export class DialogIntentsAcceptCashbackContract {
  constructor(router) {
    this.router = router;
  }

  activate(params) {
    this.params = params;
    this.controller = this.params.controller;
  }

  goToCashbackContracts() {
    this.router.navigateToRoute('cashback/contracts');
    this.controller.cancel();
  }
}
