export async function checkUserBillingProfile(scope, fragment) {
  if (/\/(anagrafiche|gestione)\/(utenti|profilo)\/me\/.+/.test(fragment)) return checkResult(false, fragment);
  const response = await scope.rest.get('users/me/billing-profiles');
  if (response.status !== 200) return checkResult();
  if (response.data?.data?.length) return checkResult(true);
  await dialogMissingBillingProfile(scope);
  return checkResult(false, '/anagrafiche/utenti/me/fatturazione');
}

export function dialogMissingBillingProfile(scope) {
  return new Promise((resolve) => {
    scope.dialog.open({
      type: 'alert',
      class: 'small',
      title: 'Attenzione!',
      body: 'È necessario impostare un profilo di fatturazione'
    }).whenClosed(() => {
      resolve();
    });
  });
}

export function checkResult(hasPassed, redirect) {
  const result = {};
  if (hasPassed !== undefined) result.hasPassed = hasPassed;
  if (redirect !== undefined) result.redirect = redirect;
  return result;
}
