import {activationStrategy} from 'aurelia-router';

export class RubricaDialogEliminaGruppo {
  deleteContacts = false;
  constructor() {
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
  }
}
