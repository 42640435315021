import {Aurelia, inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import environment from '../../../../environment';
import {TableInterface, SearchInterface} from 'classes/md-table';

@inject(Aurelia, Router, Api, KaDialog, KaToast)
export class vmDocumentiGestione {
  environment = environment;
  documentTypes = null;

  constructor(aurelia, router, api, dialog, toast) {
    this.aurelia = aurelia;
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(model) {
    this.params = model.params;
    this.privileges = model.privileges;
    this.tools = model.tools;
  }

  attached() {
    this.documentTypes = JSON.parse(sessionStorage.getItem(btoa('certifiable-document-types'))) || null;
    if (this.documentTypes) this.init();
    else this.getDocumentTypes();
  }

  getDocumentTypes() {
    this.api.get(`certifiable-document-types`).then(xhr => {
      this.documentTypes = xhr.response;
      sessionStorage.setItem(btoa('certifiable-document-types'), JSON.stringify(this.documentTypes));
      this.init();
    });
  }

  init() {
    // INIT TableInterface
    this.tableInterface = new TableInterface({
      name: 'tab-abbonamenti-gestione-table',
      client: this.api,
      endpoint: 'bo/certifiable-document-buckets/certifiable-documents',
      query: 'sort=-uploadedAt'
    });
    this.tableInterface.initialize().catch(error => { console.log('tableInterface initialization failed', error) });

    // INIT SearchInterface
    this.searchInterface = new SearchInterface({
      name: 'tab-abbonamenti-gestione-search',
      table: this.tableInterface,
      schema: {
        uuid: {
          control: 'combo',  
          label: 'Utente', 
          datasource: { table: `bo/users?include=user-profiles`, query: { search: 'text-search', item: 'uuid-in'} }, 
          datavalue: 'uuid', 
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false,
          query: 'uuid-in'
        },
        status: {
          control: 'combo', 
          label: 'Stato',
          datasource: [{ value: 'null', text: 'Tutti' }, { value: 'PENDING', text: 'In attesa di approvazione' }, { value: 'APPROVED', text: 'Approvato' }, { value: 'REFUSED', text: 'Rifiutato' }],
          datavalue: 'value',
          datatext: 'text',
          datamultiple: false,
          datapreload: true,
          query: 'status'
        }
      },
      data: {
        status: 'PENDING'
      }
    });
    this.searchInterface.initialize().then(() => {
      this.searchInterface.data.status = this.params.filterStatus || this.searchInterface.data.status;
      this._search();
    }).catch(error => { 
      console.log('ERROR - searchInterface initialization failed', error);
    });
  }

  setPlaceholderType() {
    this.tableInterface.data.forEach(document => {
      document.placeholderType = this.documentTypes.find(documentType => documentType.code === document.code)?.placeholderType || null;
    });
  }

  _search() {
    this.searchInterface.search().then(() => {
      this.setPlaceholderType();
    }).catch(error => {
      console.log('ERROR - searchInterface search failed', error);
    });
  }

  approval(item) {
    if (!item) return

    this.dialog.open({
      title: `Stato documento`,
      class: 'small documenti-approval',
      viewModel: PLATFORM.moduleName('views/backoffice/documenti/dialogs/documenti-approval'),
      viewModelParams: { document: item }
    }, true).whenClosed(response => {
      let certification = response.output === 'APPROVED' ? 'approvato' : 'rifiutato';
      if (!response.wasCancelled) {
        this.searchInterface.search();
        this.toast.show(`Documento ${certification} con successo!`, 'success');
      }
    });
  }

  download(file) {
    if (!file.downloadUri) return this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>Impossibile scaricare il file!</strong><br>Errore server: percorso Uri non disponibile' });

    // Uri build
    let uri = file.downloadUri;
    uri = uri.replace(this.environment.APP_API_BASE_URL, '');
    uri = uri.replace('/api/v0.0.1/', '');

    this.api.get(uri).then(xhr => {
      let anchor = document.createElement('a');
      anchor.download = `${xhr.response.filename}`;
      anchor.href = xhr.response.fileData;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }, xhr => {
      let error = JSON.parse(xhr.response);
      this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>Impossibile scaricare il file!</strong><br>Errore server' + (error.detail ? `: ${error.detail}` : '') });
    });
  }
}
