import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';
import mdt from 'services/md-tools';

@inject(Router, Api, KaDialog, KaToast)
export class viewUtenti {
  mdt = mdt;
  migratePending;

  constructor(router, api, dialog, toast) {
    this.api = api;
    this.router = router;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    this.isAdvancedUser = this.api.hasRole('LIST_ANY_USER');
    this.isMasterUser = this.api.user.group.includes('MASTER_USER');
    this.isImpersonateUser = this.api.hasRole('IMPERSONATE_ANY_USER');
    this.endpoint = this.isAdvancedUser ? 'bo/users' : 'users/me/users';
  }

  attached() {
    /* Table interface */
    this.usersTableInterface = new TableInterface({
      name: 'users-table',
      client: this.api,
      endpoint: this.isAdvancedUser ? 'bo/users' : 'users/me/users',
      query: this.isAdvancedUser ? 'include=user-profiles,user-sponsors' : 'include=user-profiles'
    });
    this.usersTableInterface.initialize();

    /* Table Search */
    this.usersSearchInterface = new SearchInterface({
      name: 'users-table-search',
      table: this.usersTableInterface,
      schema: {
        textSearch: { 
          control: 'text', 
          query: 'text-search',
          label: 'Ricerca libera',
          placeholder: 'Inserire almeno 3 caratteri'
        },
        group: { 
          control: 'combo',
          label: 'Tipo accesso',
          datatext: 'label',
          datavalue: 'code',
          datamultiple: false,
          datapreload: true,
          datasource: [],
          query: 'user-group-in'
        },
        countryCode: {
          label: 'Nazione',
          control: 'combo',
          datatext: 'name',
          datavalue: 'code',
          datamultiple: false,
          datapreload: false,
          datasource: { table: `geo/countries`, query: { search: 'text-search', item: 'code'} },
          query: 'country-code-in'
        },
        admLvl1: {
          label: 'Regione',
          control: 'combo',
          datatext: 'endonym',
          datavalue: 'uuid',
          datamultiple: false,
          datapreload: false,
          datasource: { table: null, query: { search: 'text-search', item: 'uuid'} },
          query: 'adm-level-1-uuid-in'
        },
        admLvl2: {
          label: 'Provincia',
          control: 'combo',
          datatext: 'endonym',
          datavalue: 'uuid',
          datamultiple: false,
          datapreload: false,
          datasource: { table: null, query: { search: 'text-search', item: 'uuid'} },
          query: 'adm-level-2-uuid-in'
        },
        admLvl3: {
          label: 'Comune',
          control: 'combo',
          datatext: 'endonym',
          datavalue: 'uuid',
          datamultiple: false,
          datapreload: false,
          datasource: { table: null, query: { search: 'text-search', item: 'uuid'} },
          query: 'adm-level-3-uuid-in'
        },
        zipCode: {
          label: 'CAP',
          control: 'text',
          query: 'zip-code-in'
        },
        businessType: {
          label: 'Attività',
          control: 'combo',
          datatext: 'label',
          datavalue: 'code',
          datamultiple: false,
          datapreload: true,
          datasource: [],
          query: 'business-type-in'
        },
        priceList: {
          label: 'Listino', 
          control: 'combo',  
          datatext: 'label',
          datavalue: 'code', 
          datamultiple: false,
          datapreload: true,
          datasource: [],
          query: 'price-list-code-in'
        },
        client: {
          label: 'Gestionale', 
          control: 'combo',  
          datatext: 'name',
          datavalue: 'id', 
          datamultiple: false,
          datapreload: true,
          datasource: [],
          query: 'client-uuid-in'
        },
        registeredAtStart: {
          label: 'Dal', 
          control: 'date',  
          query: 'registered-at-gte'
        },
        registeredAtEnd: {
          label: 'al', 
          control: 'date',  
          query: 'registered-at-lte'
        },
        parent: {
          label: 'Utente padre', 
          control: 'combo',  
          datatext: 'profile[0].displayName',
          datavalue: 'uuid', 
          datamultiple: false,
          datapreload: false,
          datasource: { table: `bo/users?include=user-profiles&user-group-in=MASTER_USER`, query: { search: 'text-search', item: 'uuid-in'} },
          query: 'parent-uuid-in'
        },
        reseller: {
          label: 'Segnalatore', 
          control: 'combo',  
          datatext: 'profile[0].displayName',
          datavalue: 'uuid', 
          datamultiple: false,
          datapreload: false,
          datasource: { table: `bo/users?include=user-profiles&business-type-in=SYSTEM-BUSINESS-SCOUT`, query: { search: 'text-search', item: 'uuid-in'} },
          query: 'reseller-uuid-in'
        },
        status: {
          label: 'Stato', 
          control: 'combo',  
          datatext: 'text',
          datavalue: 'value', 
          datamultiple: false,
          datapreload: true,
          datasource: [{ value: 'ACTIVE', text: 'Attivo'}, { value: 'NOT-ACTIVE', text: 'Non attivo'}, { value: 'ARCHIVED', text: 'Archiviato'}],
          query: 'status'
        },
        sepaDirectDebit: {
          label: 'SDD',
          control: 'combo',
          datatext: 'text',
          datavalue: 'value', 
          datamultiple: false,
          datapreload: true,
          datasource: [{ value: 'null', text: 'Tutti'}, { value: 'TRUE', text: 'Attivo'}, { value: 'FALSE', text: 'Non attivo'}],
          query: 'sepa-direct-debit'
        }
      },
      data: {
        countryCode: 'IT',
        registeredAtStart: null,
        registeredAtEnd: null,
        status: 'ACTIVE',
        sepaDirectDebit: 'null'
      }
    });
    
    let promises = this.isAdvancedUser ? [
      this.api.get('user-business-types').then(xhr => {
        this.usersSearchInterface.schema.businessType.datasource = xhr.response;
      }).catch(error => {
        console.log('ERROR - user-business-types - GET', error);
        this.usersSearchInterface.schema.businessType.datasource = [];
      }),
      this.api.get('user-groups').then(xhr => {
        this.usersSearchInterface.schema.group.datasource = xhr.response;
      }).catch(error => {
        console.log('ERROR - user-groups - GET', error);
        this.usersSearchInterface.schema.group.datasource = [];
      }),
      this.api.get('users/me/clients').then(xhr => {
        this.usersSearchInterface.schema.client.datasource = xhr.response;
      }).catch(error => {
        console.log('ERROR - /me/clients - GET', error);
        this.usersSearchInterface.schema.client.datasource = [];
      }),
      this.api.get('bo/price-lists').then(xhr => {
        this.usersSearchInterface.schema.priceList.datasource = xhr.response;
      }).catch(error => {
        console.log('ERROR - /me/clients - GET', error);
        this.usersSearchInterface.schema.priceList.datasource = [];
      }),
    ] : [ true /* ficca qui dentro eventuali altre promises */ ];
    
    Promise.all(promises).finally(() => {
      this.usersSearchInterface.initialize();
      setTimeout(() => {
        if(!this.isAdvancedUser) this.ricerca();
      }, 500);
    });
  }

  importa() {
    this.api.get(`users/me/billing-profiles/parent`).then(() => {
      this.router.navigate(`anagrafiche/utenti/importazione`);
    }).catch(error => {
      this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'confirm', body: '<strong>Non è possibile procedere con l\'importazione '+mdt.parsers.contextualize(this.api.user, 'utenti')+'.</strong><br>È necessario prima creare un profilo di fatturazione sull\'account principale. Confermi di voler procedere?'}).whenClosed(response => {
        if (!response.wasCancelled) {
          this.router.navigate(`anagrafiche/utenti/${this.api.user.id}/fatturazione`);
        }
      });
    });
  }

  // Activate & Deactivate
  attivazione(record) {
    if (this._attivazionePending) return;
    this._attivazionePending = true;
    this.toast.show('Caricamento in corso', 'loading', true);
    let endpoint = this.api.hasRole('MODIFY_ANY_USER_STATUS') ? `bo/users/${record.uuid}` : `users/me/users/${record.uuid}`;
    new Promise((resolve, reject) => {
      if (record.statusCode === 'ACTIVE') {
        this.dialog.open({
          title: 'Attenzione!',
          class: 'small',
          type: 'confirm',
          body: `Confermi di voler <strong>disattivare</strong> l'utente selezionato?<br>Verrà disattivato il rinnovo per tutti i servizi sottoscritti dal condominio.`
        }).whenClosed(response => {
          if (!response.wasCancelled) resolve();
          else reject();
        });
      } else resolve();
    }).then(() => {
      this.api.patch(endpoint, { statusCode: record.statusCode === 'ACTIVE' ? 'NOT-ACTIVE' : 'ACTIVE' }).then(() => {
        this.usersTableInterface.load().catch(error => { console.log('users-table-interface-load', error); });
        this.toast.show(`Stato utente modificato con successo!`, 'success');
      }).catch(error => { 
        if (error) { console.log('error', error); this.api.dialogError(error); } 
      });
    }).catch(error => { 
      if (error) { console.log('error', error); this.api.dialogError(error); } 
    }).finally(() =>{
      this._attivazionePending = false;
      this.toast.consume();
    });
  }

  // Archiving
  archiviazione(id) {
    return this.dialog.open({
      title: 'Archiviazione utenti',
      class: 'small',
      viewModel: PLATFORM.moduleName('views/anagrafiche/utenti/dialogs/utenti-dialog-archivia-nuovo'),
      viewModelParams: { id }
    }, true).whenClosed(response => {
      if (!response.wasCancelled) {
        this.ricerca();
      }
    });
  }

  modifica(record) {
    this.router.navigateToRoute('anagrafiche/utenti', { id: record.id, view: 'anagrafica' });
  }

  nuovo() {
    this.router.navigateToRoute('anagrafiche/utenti', { id: 'new', view: 'anagrafica' });
  }

  impersonifica(record) {
    this.api.impersonate(record.id).then(() => {}).catch(error => { this.api.dialogError(error); });
  }

  abbonamenti(record) {
    this.router.navigateToRoute('servizi/abbonamenti', { id: record.id });
  }

  ricerca() {
    this.usersSearchInterface.search();
  }

  reset() {
    for (let [k,v] of Object.entries(this.usersSearchInterface.data)) this.usersSearchInterface.data[k] = null;
    this.ricerca();
  }

  initGeo(type, init = false) {
    console.log(`initGeo ${type} ${init?'initialization':''}`);
    if (type === 'countryCode') {
      let url = `geo/countries/${this.usersSearchInterface.data.countryCode}/levels/1/divisions`;
      this.usersSearchInterface.controls.admLvl1.control.schema.datasource.table = url;
      if (this.usersSearchInterface.controls[type].control._initialized) this.usersSearchInterface.data.admLvl1 = null;
    } else if (type === 'admLvl1') {
      let url = `geo/countries/${this.usersSearchInterface.data.countryCode}/levels/2/divisions?parent-uuid=${this.usersSearchInterface.data.admLvl1}`;
      this.usersSearchInterface.controls.admLvl2.control.schema.datasource.table = url;
      if (this.usersSearchInterface.controls[type].control._initialized) this.usersSearchInterface.data.admLvl2 = null;
    } else if (type === 'admLvl2') {
      let url = `geo/countries/${this.usersSearchInterface.data.countryCode}/levels/3/divisions?parent-uuid=${this.usersSearchInterface.data.admLvl2}`;
      this.usersSearchInterface.controls.admLvl3.control.schema.datasource.table = url;
      if (this.usersSearchInterface.controls[type].control._initialized) this.usersSearchInterface.data.admLvl3 = null;
    }
    this.usersSearchInterface.controls[type].control._initialized = true;
  }

  csvDownload() {
    this.toast.show('Caricamento in corso', 'loading', true);

    let query = null;
    if (this.usersSearchInterface?.table?._query) query = `?${decodeURIComponent(decodeURIComponent(this.usersSearchInterface.table._query))}`;
    let path = this.isAdvancedUser ? 'bo/users/export-users.csv' : 'users/export-users/mine.csv';
    let uri = `${path}${query}`;

    this.api.client.createRequest(uri)
    .asGet()
    .withHeader('Accept', 'application/csv')
    .withResponseType('blob')
    .send()
    .then(xhr => {
      let regexp = new RegExp('filename=(.+\.csv$)');
      let matches = xhr.headers.headers['content-disposition'].value.match(regexp);
      let anchor = document.createElement('a');
      let blob = new Blob([xhr.response], { type: 'text/csv' });
      let url = URL.createObjectURL(blob);
      anchor.href = url;
      anchor.download = matches[1];
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }).catch(xhr => {
      console.log('ERROR - export-users GET', xhr);
    }).finally(() => {
      this.toast.consume();
    });
  }

  migrate(record) {
    this.migratePending = true;
    this.dialog.open({
      title: 'Attenzione!',
      class: 'migrate-subuser small',
      viewModel: PLATFORM.moduleName('views/anagrafiche/utenti/dialogs/migrate-subuser'),
      viewModelParams: {uuid: record.uuid}
    }, true).whenClosed(response => {
      if (!response.wasCancelled) this.ricerca();
      this.migratePending = false;
    });
  }

  findMigrationProfile(uuid) {
    this.api.get(`bo/users?uuid-in=${uuid}`).then((xhr) => {
      let profileId = xhr.response[0].id;
      this.router.navigateToRoute('anagrafiche/utenti', {id: profileId, view: 'anagrafica'});
    }).catch((error) => {
      console.error('ERROR - bo/users?uuid-in GET', error);
    });
  }
}
export class SponsorsFormatValueConverter {
  toView(value) {
    if (!value || !value.length) return;
    let sponsors = [];
    value.forEach(sponsor => {
      let sponsorInitials = '['+sponsor.sponsorDisplayName.match(/(^\w{2})/g)[0].toUpperCase()+']';
      sponsors.push(`<span title="${sponsor.sponsorDisplayName}">${sponsorInitials}</span>`);
    });
    return `Sponsors: ${sponsors.join('')}`;
  }
}
