import {inject, customElement, bindable, bindingMode} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaToast, KaDialog} from 'ka-components';

@customElement('md-prefsel')
@inject(Element, Api, KaToast, KaDialog)
export class MdPrefsel {
  @bindable() label = 'preferenze';
  @bindable({defaultBindingMode: bindingMode.twoWay}) value = null;
  @bindable() isOverride = null;
  @bindable() toggleOverride = () => {};
  data = null;
  context = null;

  constructor(element, api, toast, dialog) {
    this.element = element;
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
    this.context = this.api.user.profile?.businessType === 'BUILDING-ADMINISTRATOR' ? 'condominio' : 'account';
  }
  
  attached() {
    if (!this.value) {
      this.value = parseInt(localStorage.getItem('prefsel_id') ?? this.api.user.id ?? null, 10);
    } else {
      this.valueChanged(this.value, null);
    }
    console.log(`prefsel ${this.label} attached value`, this.value);
    if (this.api.user.profile?.businessType === 'BUILDING') this.element.style.display = 'none';
  }

  valueChanged(value, old) {
    if (value === old) return;
    console.log(`prefsel ${this.label} valueChanged from ${old} to ${value}`);
    if (value) {
      localStorage.setItem('prefsel_id', value);
      if (value !== this.api.user.id && localStorage.getItem('prefsel_data')) {
        this.data = JSON.parse(localStorage.getItem('prefsel_data'));
      } else if (value !== this.api.user.id) {
        this.api.get(`users/${value}?include=user-profiles`).then(x => {
          this.data = remapUserData(x.response);
          localStorage.setItem('prefsel_data', JSON.stringify(this.data));
        });
      } else if (value === this.api.user.id) {
        this.data = null;
        localStorage.removeItem('prefsel_data');
      }
    } else {
      this.value = this.api.user.id;
    }
  }
  isOverrideChanged(value) {
    if (value === undefined) {
      this.isOverride = false;
    }
  }

  reset() {
    this.value = this.api.user.id;
  }

  open() {
    this.dialog.open({
      type: 'alert',
      title: 'Seleziona un ' + this.context,
      class: 'md-prefsel-dialog medium',
      viewModel: PLATFORM.moduleName('resources/elements/md-prefsel/md-prefsel-dialog'),
      viewModelParams: { }
    }, true).whenClosed(response => {
      console.log(response);
      if (response.output) {
        let data = response.output;
        if (data.id && data.profile) {
          localStorage.setItem('prefsel_data', JSON.stringify(this.remapUserData(data)));
          this.value = data.id;
        }
      }
    });
  }

  remapUserData(data) {
    return {
      id: data.id,
      active: data.isActive,
      displayName: data.profile[0].displayName
    };
  }
}
