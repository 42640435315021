export async function checkCashbackBillableAmount(scope, fragment) {
  const isConsumed = new Date() > new Date(2025, 1, 28, 20, 0, 0);
  if (['/cashback/transactions'].includes(fragment) || localStorage.getItem('cashback-check-billable-amount') || isConsumed) {
    return checkResult(true);
  }
  localStorage.setItem('cashback-check-billable-amount', 'checked');
  
  const cashbackMovementsResponse = await scope.rest.get('users/me/cashback-movements');
  const cashbackBillableAmount = cashbackMovementsResponse?.data?.meta?.billableAmount;
  if (cashbackMovementsResponse.status !== 200) return checkResult();

  if (cashbackBillableAmount) {
    const dialogResponseWasCancelled = await dialogCashbackBillableAmount(scope);
    if (!dialogResponseWasCancelled) {
      return checkResult(false, '/cashback/transactions');
    }
  }
  return checkResult(true);
}

export function dialogCashbackBillableAmount(scope) {
  return new Promise((resolve) => {
    scope.dialog.open({
      title: 'Attenzione!',
      class: 'medium check-billable-amount',
      viewModel: PLATFORM.moduleName('views/cashback/transactions/dialogs/check-billable-amount')
    }).whenClosed((response) => {
      resolve(response.wasCancelled);   
    });
  });
}

export function checkResult(hasPassed, redirect) {
  const result = {};
  if (hasPassed !== undefined) result.hasPassed = hasPassed;
  if (redirect !== undefined) result.redirect = redirect;
  return result;
}
