import { inject } from 'aurelia-framework';
import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { DialogController } from 'aurelia-dialog';

@inject(Api, KaDialog, KaToast, DialogController)
export class WallAdvice {
  wallAdvice;
  htmlTemplate;
  pendingGetWallAdvice;

  constructor(api, dialog, toast, dialogController) {
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
    this.controller = dialogController;
    this.endpoint;
  }

  activate(params) {
    this.params = params;
    if (this.params.wallAdvice) {
      this.endpoint = "bo/wall-advices"
      this.wallAdvice = params.wallAdvice;
      this.getWallAdvice();
    }
    if (this.params.adviceToShow) {
      this.wallAdvice = this.params.adviceToShow;
      this.endpoint = "users/me/wall-advices";
      this.htmlTemplate = this.wallAdvice.htmlContent;
    }
  }

  attached() {
    const elementHeader = document.querySelector('ux-dialog.wall-advice ux-dialog-header h2');
    const title = elementHeader.appendChild(document.createElement('span'));
    title.classList.add('title');
    title.innerHTML = 'Avviso importante!';
  }

  acknowledgedAdvice() {
    return this.api.patch(`${this.endpoint}/${this.wallAdvice.id}`, { acknowledged: 'YES' }).then(() => {
    }).catch((error) => {
      console.error("Error patching advice acknowledge", error);
    }).finally(() => {
      this.controller.ok();
    })
  }

  getWallAdvice() {
    this.pendingGetWallAdvice = true;
    return this.api.get(`${this.endpoint}/${this.wallAdvice.id}`).then((xhr) => {
      const response = xhr.response;
      this.htmlTemplate = response.htmlContent;
    }).catch((error) => {
      console.error("Get wall advice", error);
      this.toast.show("Impossibile recuperare il contenuto dell'avviso", "error");
    }).finally(() => {
      this.pendingGetWallAdvice = false;
    });
  }
}