import {inject, customElement, bindable, bindingMode, InlineViewStrategy} from 'aurelia-framework';

@customElement('md-input')
@inject(Element)
export class MdInput {

  @bindable({defaultBindingMode: bindingMode.twoWay}) value = null;
  // Reference to record id
  @bindable() record = null;
  // Field configuration overrides
  @bindable() name = null;
  @bindable() alias = null; // Utilizzato per impostare un alias da aggiungere in resource.controls per la gestione errori
  @bindable() label = null;
  @bindable() default = null;
  @bindable() required = null;
  @bindable() readonly = null;
  @bindable() client = null;
  @bindable() description = null;
  @bindable() autocomplete = null;
  @bindable() change = null;
  @bindable() ready = null;
  @bindable() dialogClosed = null;
  @bindable() validation = true;
  @bindable() queryOperator = null;
  @bindable() change = () => {};
  @bindable() submit = () => {};


  constructor(element) {
    this.element = element;
  }

  bind(bindingContext) {
    this.parent = bindingContext;
  }

  attached() {
    let bindValueToParentData = !(this.name && (this.element.hasAttribute('value') || this.element.hasAttribute('value.bind')));
    this.viewStrategy = new InlineViewStrategy(`<template><ka-input-controls record.bind="parent.data.id" config.bind="parent.schema.${this.name}" value.bind="${bindValueToParentData ? `parent.data.${this.name}` : 'value'}" label.bind="label" default.bind="default" description.bind="description" placeholder.bind="placeholder" autocomplete.bind="autocomplete" readonly.bind="readonly||parent.schema.${this.name}.readonly||parent.readonly" required.bind="required||parent.schema.${this.name}.required" view-model.ref="parent.controls['${this.name}']" client.bind="client||parent.client" change.bind="change" ready.bind="ready" dialog-closed.bind="dialogClosed" validation.bind="validation" query-operator.bind="queryOperator" ka-enter.call="enterPressed()"></ka-input-controls></template>`);
    setTimeout(() => {
      if (this.parent && this.parent.controls && this.name && this.alias) {
        this.parent.controls[this.alias] = this.parent.controls[this.name];
      }
    }, 500);
  }

  enterPressed() {
    if (this.submit && typeof this.submit === 'function') this.submit();
  }
}

