import { inject } from 'aurelia-framework';
import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { DialogController } from 'aurelia-dialog';
import { Advices } from '../../../../classes/advices';

@inject(Api, KaDialog, KaToast, DialogController, Advices)
export class EditorWallAdvice {
  wallAdvice;
  isExpiredAdvice;
  
  constructor(api, dialog, toast, dialogController, advices) {
    this.endpoint = 'bo/wall-advices';
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
    this.controller = dialogController;
    this.advices = advices;
  }

  activate(params) {
    this.params = params;
    this.wallAdvice = params.wallAdvice;
    this.init();
  }

  initResource() {
    this.resource = {
      controls: {},
      schema: {
        title: {
          required: true,
          control: 'text',
          label: 'Titolo'
        },
        tag: {
          label: 'tag',
          required: true,
          control: 'text'
        },
        htmlContent: {
          required: true,
          control: 'editor', 
          label: 'Contenuto avviso in formato HTML'
        },
        type: {
          required: true,
          control: 'combo',
          datatext: 'text',
          datapreload: true,
          datavalue: 'value',
          datamultiple: false,
          label: 'Tipologia avviso',
          datasource: [
            { value: 'SIMPLE', text: 'Semplice: nascosto dopo la chiusura.' }, 
            { value: 'REMINDER', text: 'Con Reminder: riproposto ad ogni sessione.' }
          ]
        },
        targetCodes: {
          required: true,
          label: 'Sezioni target',
          control: 'combo',
          datatext: 'text',
          datapreload: true,
          datavalue: 'code',
          datamultiple: true,
         datasource: this.advices.targetRoutes
        },
        startAt: {
          required: true,
          control: 'date',
          label: 'Programmato dal',
        },
        endAt: {
          label: 'Al',
          required: false,
          control: 'date',
        },
        public: {
            label: 'Visibilità',
            required: true,
            control: 'combo',
            datapreload: true,
            datamultiple: false,
            datavalue: "value",
            datatext: "text",
            datasource: [
              { value: 'YES', text: 'Pubblico' },
              { value: 'NO', text: 'Privato' },
            ],
          }
      },
      data: {},
      validate: () => {
        let promises = [];
        Object.values(this.resource.controls).forEach(control => {
          if (!control) return;
          promises.push(new Promise((resolve, reject) => {
            console.log(control);
            if (control.validate)
              control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); });
            else if (control.input?.validate)
              control.input.validate().then(() => resolve()).catch(() => reject('Control didn\'t pass validation'));
          }));
        });
        return Promise.all(promises);
      },
    };
  }

  init() {
    this.initResource();
    if (this.wallAdvice) {
      this.getWallAdvice();
    }
  }

  getWallAdvice() {
    this.pendingGetWallAdvice = true;
    return this.api.get(`${this.endpoint}/${this.wallAdvice.id}`).then((xhr) => {
      const response = xhr.response;
      this.resource.data = { ...response }
      if (this.checkIsExpiredAdvice(response.endAt)) {
        this.isExpiredAdvice = true;
      } else {
        this.isExpiredAdvice = false;
      }
    }).catch((error) => {
      console.error("Get wall advice", error);
      this.toast.show("Impossibile recuperare il contenuto dell'avviso", "error");
    }).finally(() => {
      this.pendingGetWallAdvice = false;
    });
  }

  wallAdvicePreview() {
    return this.dialog.open({
      title: ' ',
      class: 'medium wall-advice',
      viewModelParams: { wallAdvice: this.resource.data },
      viewModel: PLATFORM.moduleName('views/backoffice/avvisi/dialogs/wall-advice-preview')
    });
  }

  editWallAdvice($event) {
    $event.model.busy = true;
    return this.dialogAdviceConfirmation().whenClosed((response) => {
      if (!response.wasCancelled) {
        return this.resource.validate().then(() => {
          return this.api[this.isExpiredAdvice ? 'post' : 'put'](`${this.endpoint}${this.isExpiredAdvice ? '' : '/' + this.wallAdvice.id}`, this.resource.data).then((xhr) => {
            if (this.isExpiredAdvice) {
              const update = new Event("updateActiveList");
              window.dispatchEvent(update);
            }
            this.toast.show("Avviso modificato corretamente!", "success");
            this.controller.ok();
          }).catch((error) => {
            console.error(error);
            this.api.dialogError(error, this.resource.controls);
          }).finally(() => {
            $event.model.busy = false;
          })
        }).catch(() => {
          $event.model.busy = false;
        })
      } else {
        $event.model.busy = false;
      }
    });
  }

  checkIsExpiredAdvice(date) {
    const endDate = new Date(date);
    const currentDate = new Date();
    return endDate < currentDate;
  }

  normalizeStartAtDateTime() {
    let date = new Date(this.resource.data.startAt);
    date.setHours(0,0,0)
    this.resource.data.startAt = date.toISOString().replace(/\.\d{3}/, '');
  }

  normalizeEndAtDateTime() {
    let date = new Date(this.resource.data.endAt);
    date.setHours(23,59,59)
    this.resource.data.endAt = date.toISOString().replace(/\.\d{3}/, '');
  }

  dialogAdviceConfirmation() {
    const currentDate = new Date().getTime();
    const visibilityStartDate = new Date(this.resource.data.startAt).getTime();
    let visibilityEndDate;
    let alreadyVisible;
    if (this.resource.data.endAt) {
      visibilityEndDate = new Date(this.resource.data.endAt).getTime();
      alreadyVisible = currentDate >= visibilityStartDate && currentDate <= visibilityEndDate;
    } else {
      alreadyVisible = currentDate >= visibilityStartDate;
    }
    return this.dialog.open({
      title: 'Attenzione!',
      class: 'confirm-advice',
      type: 'confirm',
      body: `<p class="m-0"><strong>Stai per ${this.wallAdvice ? 'modificare un' : 'creare un nuovo'} avviso!</strong></p><p class="m-0">${(this.resource.data.public === 'YES') ? `${alreadyVisible ? `Sarà visibile agli utenti fin da subito` : `Sarà visibile agli utenti a partire dalla data prescelta`}, confermi di voler procedere?` : 'Non sarà visibile agli utenti finchè rimarrà privato.'}</p>`
    });
  }

  createWallAdvice($event) {
    $event.model.busy = true;
      return this.dialogAdviceConfirmation().whenClosed((response) => {
        if (!response.wasCancelled) {
          return this.resource.validate().then(() => {
            return this.api.post(this.endpoint, this.resource.data).then(() => {
              this.toast.show("Avviso creato corretamente!", "success");
              this.controller.ok();
            }).catch((error) => {
              console.error(error);
              this.api.dialogError(error, this.resource.controls);
            }).finally(() => {
              $event.model.busy = false;
            })
          }).catch(() => {
            $event.model.busy = false;
          })
        } else {
          $event.model.busy = false;
        }
      });
  }
}
