
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';
import mdt from 'services/md-tools';

@inject(Router, Api, KaDialog, KaToast)
export class viewRubrica {
  mdt = mdt;
  contactGroups = null;
  selected = [];

  constructor(router, api, dialog, toast) {
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;

    // Load contact groups
    this.api.get('users/me/contact-groups').then(xhr => {
      let groups = {};
      for (let group of xhr.response) {
        groups[group.id] = group;
      }
      this.contactGroups = groups;
    });
  }

  attached() {
    /* Table interface */
    this.rubricaTableInterface = new TableInterface({
      name: 'rubrica-table',
      client: this.api,
      endpoint: 'users/me/contacts',
      query: null
    });

    /* Table Search */
    this.rubricaSearchInterface = new SearchInterface({
      name: 'rubrica-table-search',
      table: this.rubricaTableInterface,
      schema: {
        textSearch: { 
          control: 'text', 
          label: 'Ricerca libera',
          query: 'text-search'
        },
        countryCode: {
          label: 'Nazione',
          control: 'combo',
          datatext: 'name',
          datavalue: 'code',
          datamultiple: false,
          datapreload: false,
          datasource: { table: `geo/countries`, query: { search: 'text-search', item: 'code' } },
          query: 'country-code-in'
        },
        admLvl1: {
          label: 'Regione',
          control: 'combo',
          datatext: 'endonym',
          datavalue: 'uuid',
          datamultiple: false,
          datapreload: false,
          datasource: { table: null, query: { search: 'text-search', item: 'uuid' } },
          query: 'adm-level-1-uuid-in'
        },
        admLvl2: {
          label: 'Provincia',
          control: 'combo',
          datatext: 'endonym',
          datavalue: 'uuid',
          datamultiple: false,
          datapreload: false,
          datasource: { table: null, query: { search: 'text-search', item: 'uuid' } },
          query: 'adm-level-2-uuid-in'
        },
        admLvl3: {
          label: 'Comune',
          control: 'combo',
          datatext: 'endonym',
          datavalue: 'uuid',
          datamultiple: false,
          datapreload: false,
          datasource: { table: null, query: { search: 'text-search', item: 'uuid' } },
          query: 'adm-level-3-uuid-in'
        },
        group: { 
          control: 'combo',
          label: 'Gruppo',
          datatext: 'name',
          datavalue: 'id',
          datamultiple: true,
          datapreload: true,
          datasource: { table: 'users/me/contact-groups' },
          query: 'group-uuid-in'
        }
      },
      data: {
        countryCode: 'IT',
      }
    });
    this.rubricaTableInterface.initialize().then(() => {
      this.rubricaSearchInterface.initialize().then(() => {
        this.initGeo('countryCode', true);
        setTimeout(() => { this.ricerca(); }, 500);
      }).catch(error => { console.log('rubrica-search-intialize failed', error); });
    }).catch(error => { console.log('rubrica-table-initialize failed', error); });
  }

  ricerca() {
    this.rubricaSearchInterface.search().catch(error => { console.log('rubrica-search-interface failed', error); });
  }

  reset() {
    for (let [k,v] of Object.entries(this.rubricaSearchInterface.data)) this.rubricaSearchInterface.data[k] = null;
    this.ricerca();
  }

  nuovo() {
    this.router.navigateToRoute('anagrafiche/rubrica-dati', { id: 'new' });
  }

  gruppi() {
    this.router.navigateToRoute('anagrafiche/rubrica-gruppi');
  }

  importa() {
    this.router.navigateToRoute('anagrafiche/rubrica-importazione');
  }

  modifica($event, record) {
    $event.stopPropagation();
    this.router.navigateToRoute('anagrafiche/rubrica-dati', { id: record.id });
  }

  elimina($event, record) {
    $event.stopPropagation();
    this.dialog.open({
      title: 'Attenzione!',
      class: 'small',
      viewModel: PLATFORM.moduleName('views/anagrafiche/rubrica/rubrica-dialog-elimina'),
    }, true).whenClosed(response => {
      if (!response.wasCancelled) {
        this.api.delete(`users/me/contacts/${record.id}`).then(() => {
          this.toast.show('Contatto eliminato con successo!', 'success');
          this.ricerca();
        }).catch(x => {
          this.api.dialogError(x);
        });
      }
    });
  }

  esporta() {
    if (!this.selected.length) return;
    this.toast.show('Caricamento in corso', 'loading', true);
    let csv = ['nome;cognome;denominazione;numero;indirizzo;cap;comune;localita;provincia;nazione'];
    for (let record of this.selected) {
      csv.push([
        record.firstname,
        record.lastname,
        record.companyName,
        record.mobilePhoneNumber || record.homePhoneNumber || record.workPhoneNumber || null,
        record.streetAddress,
        record.zipCode,
        record.admLvl3,
        record.locality,
        record.admLvl2,
        record.countryCode,
      ].join(';'));
    }
    csv = csv.join('\n');
    let blob = new Blob([csv], {type: 'text/csv'});
    let anchor = document.createElement('a');
    anchor.download = 'rubrica-invio-tradizionale.csv';
    anchor.href = window.URL.createObjectURL(blob);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    this.toast.consume();
  }

  initGeo(type, init = false) {
    if (type === 'countryCode') {
      let url = `geo/countries/${this.rubricaSearchInterface.data.countryCode}/levels/1/divisions`;
      if (init) {
        this.rubricaSearchInterface.schema.admLvl1.datasource.table = url;
      } else {
        this.searchControlAdmLvl1.input.datasource.table = url;
        this.rubricaSearchInterface.data.admLvl1 = null;
      }
    } else if (type === 'admLvl1') {
      let url = `geo/countries/${this.rubricaSearchInterface.data.countryCode}/levels/2/divisions?parent-uuid=${this.rubricaSearchInterface.data.admLvl1}`;
      if (init) {
        this.rubricaSearchInterface.schema.admLvl2.datasource.table = url;
      } else {
        this.searchControlAdmLvl2.input.datasource.table = url;
        this.rubricaSearchInterface.data.admLvl2 = null;
      }
    } else if (type === 'admLvl2') {
      let url = `geo/countries/${this.rubricaSearchInterface.data.countryCode}/levels/3/divisions?parent-uuid=${this.rubricaSearchInterface.data.admLvl2}`;
      if (init) {
        this.rubricaSearchInterface.schema.admLvl3.datasource.table = url;
      } else {
        this.searchControlAdmLvl3.input.datasource.table = url;
        this.rubricaSearchInterface.data.admLvl3 = null;
      }
    }
  }

  selectContact(record) {
    if (this.selected.find(x => x.id === record.id)) this.selected.splice(this.selected.findIndex(x => x.id === record.id), 1);
    else this.selected.push(record);
  }

  deleteContacts() {
    if (this.selected && this.selected.length) {
      this.dialog.open({
        title: 'Attenzione!',
        class: 'small',
        viewModel: PLATFORM.moduleName('views/anagrafiche/rubrica/rubrica-dialog-elimina'),
        viewModelParams: this.selected
      }, true).whenClosed(response => {
        if (!response.wasCancelled) {
          let promises = []
          this.selected.forEach(contact => {
            promises.push(this.api.delete(`users/me/contacts/${contact.id}`));
          });
          if (promises.length) return Promise.all(promises).then(() => {
            this.toast.show(`${this.selected.length > 1 ? 'Contatti eliminati' : 'Contatto eliminato'} con successo`, 'success');
            this.selected = [];
            this.ricerca();
          }).catch(() => {
            this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'alert', body: 'Impossibile eliminare i contatti selezionati' });
          });
        }
      });
    }
  }

  toggleAllContacts(records) {
    if (records.length > this.selected.length) {
      this.selected = [];
      records.forEach(x => {
        this.selected.push(x);
      });
    } else {
      this.selected = [];
    }
    return;
  }
}
