import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { TableInterface, SearchInterface } from 'classes/md-table';
import { KaDialog, KaToast } from 'ka-components';
import mdt from 'services/md-tools';
import { MavRequest } from '../../../../classes/mavRequest';

@inject(Api, KaDialog, KaToast, Router)
export class vmFatturazione {
  mdt = mdt;
  legalForms = [];
  isInitializing = true;
  isLoading = false;
  
  // Selected (checked) billing profiles
  selectedBillingProfiles = {
    allSelected: false,
    list: [],
    select: (record) => {
      if (record?.payments?.banking?.sepaDirectDebitEnabled) return;
      let selected = [...this.selectedBillingProfiles.list];

      // Referenced to tableInterface.data (record is referenced to selected)
      let profile = this.tableInterface.data.find(x => x.uuid === record.uuid) || null;
      let selectedProfile = selected.find(x => x.uuid === record.uuid) || null;

      // Reset the IBAN field (only newIban)
      profile.payments.banking.newIban = profile.payments.banking.oldIban || null;

      // Remove error messages
      profile.errors = null;

      // Remove errors from the record control
      if (profile?.controls['payments.banking.iban']) profile.controls['payments.banking.iban'].setError();

      // If the record is selected, it removes it, otherwise it adds it
      if (selected.length && selectedProfile) selected.splice(selected.findIndex(x => x.uuid === record.uuid), 1);
      else selected.push(record);

      return this.selectedBillingProfiles.list = [...selected];
    },
    selectAll: () => {
      let records = this.tableInterface.data.filter(x => x.payments?.banking?.sepaDirectDebitEnabled === false) || null;
      if (!records) return;
      let selected = [...this.selectedBillingProfiles.list];
      if (records.length > selected.length) {
        selected = [];
        records.forEach(record => {
          // Referenced to tableInterface.data (record is referenced to selected)
          let profile = this.tableInterface.data.find(x => x.uuid === record.uuid) || null;

          // Reset the IBAN field (only newIban)
          profile.payments.banking.newIban = profile.payments.banking.oldIban || null;

          // Remove error messages
          profile.errors = null;

          // Remove errors from the record control
          if (profile?.controls['payments.banking.iban']) profile.controls['payments.banking.iban'].setError();

          selected.push(record);
        });
        this.selectedBillingProfiles.allSelected = true;
        return this.selectedBillingProfiles.list = [...selected];
      } else {
        selected = [];
        this.selectedBillingProfiles.allSelected = false;
        return this.selectedBillingProfiles.list = [...selected];
      }
    },
    activeSdd: ($event) => {
      let promises = [];
      let selected = [...this.selectedBillingProfiles.list];
      if (!selected.length) return;

      $event.model.busy = true;
      selected.forEach((record, index) => {
        // Referenced to tableInterface.data (record is referenced to selected)
        let profile = this.tableInterface.data.find(x => x.uuid === record.uuid);

        // Delete all errors
        profile.errors = null;

        let data = { payments: { banking: { sepaDirectDebitEnabled: true }}};
        if (profile.payments?.banking?.newIban || profile.payments?.banking?.oldIban) data.payments.banking.iban = profile.payments.banking.newIban || profile.payments.banking.oldIban;

        promises.push(
          this.api.patch(`users/${record.ownerLegacyId}/billing-profiles/${record.uuid}`, data).then(() => {
            profile.payments.banking.sepaDirectDebitEnabled = true;

            // Remove record from selected list
            this.selectedBillingProfiles.list.splice(this.selectedBillingProfiles.list.findIndex(x => x.uuid === record.uuid), 1);
            this.selectedBillingProfiles.allSelected = false;
          }).catch(xhr => {
            console.log('ERROR billing-profiles PATCH', xhr);

            // All the errors received
            let errorPayload = mdt.parsers.apiError(xhr, selected[index].controls, ['payments.banking.iban', 'payments.banking.sepaDirectDebitEnabled']);
            profile.errors = errorPayload.body || null;
          })
        );
      });
      return Promise.all(promises).finally(() => { $event.model.busy = false; });
    }
  }

  // Editor fatturazione
  editor = {
    view: PLATFORM.moduleName('views/anagrafiche/utenti/views/fatturazione-editor'),
    model: null,
    callback: (billingProfile) => {
      this.editor.model = null;
      if (billingProfile) {
        setTimeout(() => { this.edit(billingProfile); }, 0);
      } else {
        if (this.params.id) {
          this.load();
        } else {
          this.search();
        }
      }
    }
  }

  // Payments (Sdi code)
  sdiEnabled = false;
  resource = {
    controls: {},
    schema: {
      sdi: {
        code: {
          label: 'Codice univoco SdI',
          control: 'text'
        },
        genericSubuserCode: {
          control: 'text'
        }
      }
    },
    data: {
      sdi: {
        code: null,
        genericSubuserCode: null
      }
    },
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        control.validator.errors = [];
        control.element.classList.remove('error');
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      this.api.dialogError(error, this.resource.controls);
    }
  }

  constructor(api, dialog, toast, router) {
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
    this.router = router;

    this.privileges = {
      isAdmin: this.api.hasRole('LIST_USER_BILLING_PROFILE'),
      isOperator: this.api.hasRole('LIST_ANY_USER_BILLING_PROFILE')
    };

    this.searchContext = new SearchContext();
    this.mavRequest = new MavRequest(this.privileges.isOperator);
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    this.init();
  }

  async init() {
    if (this.params.id) {
      await this.initLegalForms();
      await this.initTableInterface();
      await this.initSearchContextUser(this.params.id);
      this.load();
    } else {
      await this.initLegalForms();
      await this.initTableInterface();
      await this.initSearchInterface();
      this.search();
    }
  }

  initSearchContextUser(id) {
    if ([String(this.api.user.id), 'me'].includes(id)) return Promise.resolve(this.searchContext.user = this.api.user);
    else return this.api.get(`${ this.privileges.isOperator ? 'bo/users' : 'users/me/users' }?include=user-profiles&id-in=${id}`).then(xhr => {
      let response = xhr.response[0];
      response.profile = response.profile[0];
      this.searchContext.user = response;
    });
  }

  initLegalForms() {
    return this.api.get('legal-forms').then(xhr => {
      xhr.response.forEach(legalForm => {
        this.legalForms[legalForm.code] = legalForm.label;
      });
    });
  }

  initTableInterface() {
    this.tableInterface = new TableInterface({
      name: `anagrafiche-utenti-fatturazione-table-${this.params.id}`,
      client: this.api,
      endpoint: null,
      query: null
    });
    this.tableInterface.parseResponse = (response) => {
      response.forEach(profile => {
        profile.controls = { 'payments.banking.iban': null };
        profile.payments.banking.oldIban = profile.payments.banking.newIban = profile.payments.banking.iban || null;
      });
      return response;
    };

    // reset selected profiles counter & checkboxes
    this.tableInterface.events.addEventListener('dataLoaded', () => {
      this.selectedBillingProfiles.list = [];
      this.selectedBillingProfiles.allSelected = false;
    });

    return this.tableInterface.initialize();
  }

  initSearchInterface() {
    this.searchInterface = new SearchInterface({
      name: 'anagrafiche-utenti-fatturazione-search',
      table: this.tableInterface,
      schema: {
        context: {
          label: 'Quali profili di fatturazione visualizzare?',
          control: 'combo',
          required: true,
          datasource: []
        },
        user: {
          control: 'combo',
          label: this.mdt.parsers.contextualize(this.api.user, 'Utente'),
          datasource: { table: `${ this.privileges.isOperator ? 'bo/users' : 'users/me/users' }?status=ACTIVE&include=user-profiles`, query: { search: 'text-search', item: 'id-in' }},
          datavalue: 'id',
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false
        },
        user_active: {
          control: 'check',
          label: ' ',
          description: `Includi solo ${this.mdt.parsers.contextualize(this.api.user, 'utenti')} attivi`
        },
        fiscal_code: {
          label: 'Codice fiscale/Partita Iva',
          control: 'text',
          query: 'fiscal-code'
        },
        iban: {
          label: 'IBAN',
          control: 'text',
          query: 'iban'
        },
        sdd: {
          label: 'Domiciliazione bancaria (SDD)',
          control: 'combo',
          datasource: [
            { value: 'null', text: 'Tutti' },
            { value: 'YES', text: 'Attiva' },
            { value: 'NO ', text: 'Non attiva' }
          ],
          datamultiple: false,
          query: 'sdd-active'
        },
        mav_authorization_status: {
          control: 'combo',
          datatext: 'text',
          datapreload: true,
          datamultiple: false,
          datavalue: 'status',
          label: 'Richiesta di autorizzazione MAV',
          query: 'mav-self-printing-authorization-status-in',
          datasource: this.mavRequest.setDatasourceMavAuthorizationStatus([{ status: 'NONE', text: 'Nessuna richiesta' }])
        }
      },
      data: {}
    });

    this.setDefaultSearchInterfaceData();
    this.updateContextDatasource();
    this.searchContext.searchInterface = this.searchInterface;
    return this.searchInterface.initialize();
  }

  setDefaultSearchInterfaceData() {
    return this.searchInterface.data = {
      sdd: 'null',
      user_active: true,
      mav_authorization_status: 'null',
      context: this.privileges.isOperator ? 'USER' : 'SUBUSERS'
    }
  }

  load() {
    this.updateTableInterfaceEndpoint();
    this.tableInterface.load().then(() => {
      this.getPaymentPreferences();
    });
  }

  search() {
    this.updateTableInterfaceEndpoint();
    this.updateTableInterfaceQuery();
    this.searchInterface.search().then(() => {
      this.lastSearchContext = new SearchContext(this.searchContext.user, { data: structuredClone(this.searchInterface.data) });
    });
  }

  updateTableInterfaceEndpoint() {
    const suffix = (this.searchContext.isChildUsers && (this.privileges.isOperator || !this.searchContext.hasUser)) ? '/~subs' : '';
    return this.tableInterface.endpoint = (this.privileges.isOperator && !this.searchContext.hasUser) ? 'bo/billing-profiles/' : `users/${this.searchContext.user?.id || 'me'}/billing-profiles${suffix}`;
  }

  updateTableInterfaceQuery() {
    const isOperatorActiveSubusersWorthy = this.privileges.isOperator && this.searchContext.isChildUsers && this.searchInterface.data.user_active;
    const isUserActiveSubusersWorthy = !this.privileges.isOperator && this.searchContext.isChildUsers && !this.searchContext.hasUser && this.searchInterface.data.user_active;
    return this.tableInterface.query = (isOperatorActiveSubusersWorthy || isUserActiveSubusersWorthy) ? `owner-status-in=ACTIVE` : null;
  }

  updateContextDatasource() {
    if (!this.privileges.isOperator) {
      this.searchInterface.schema.context.datasource = [
        { value: 'USER', text: `I profili del mio ${ mdt.parsers.contextualize(this.api.user, 'account')}` },
        { value: 'SUBUSERS', text: `I profili dei miei ${ mdt.parsers.contextualize(this.api.user, 'utenti')}` }
      ];
      this.searchInterface.data.context = 'SUBUSERS';
    } else if (this.searchContext.user?.parentId) {
      this.searchInterface.schema.context.datasource = [
        { value: 'USER', text: `I profili ${ mdt.parsers.contextualize(this.searchContext.user, 'dell\'utente')}` },
      ];
      this.searchInterface.data.context = 'USER';
    } else {
      this.searchInterface.schema.context.datasource = [
        { value: 'USER', text: `I profili ${ mdt.parsers.contextualize(this.searchContext.user, 'dell\'account')}` },
        { value: 'SUBUSERS', text: `I profili dei suoi ${ mdt.parsers.contextualize(this.searchContext.user, 'utenti')}` }
      ];
      this.searchInterface.data.context = 'SUBUSERS';
    }
  }

  searchContextChange() {
    if (!this.privileges.isOperator && this.searchInterface?.data?.context === 'USER') {
      this.searchInterface.data.user = null;
      this.searchContext.user = null;
    }
  }
  async searchContextUserChange() {
    if (this.searchInterface.data.user && this.searchInterface.controls.user.control.valueModel?.profile) {
      this.searchContext.user = Object.assign({}, this.searchInterface.controls.user.control.valueModel, { profile: this.searchInterface.controls.user.control.valueModel.profile[0] });
    } else if (this.searchInterface.data.user) {
      await this.initSearchContextUser(this.searchInterface.data.user);
    } else {
      this.searchContext.user = null;

    }
    this.updateContextDatasource();
  }
  searchActiveUsersChange() {
    return this.searchInterface.schema.user.datasource.table = `${ this.privileges.isOperator ? 'bo/users' : 'users/me/users' }?${this.searchInterface.data.user_active ? 'status=ACTIVE&' : '' }include=user-profiles`;
  }

  resetSearch() {
    this.setDefaultSearchInterfaceData();
    this.search();
  }

  get profileContextInfo() {
    if (!this.lastSearchContext) return;
    let label;
    switch (true) {
      case this.lastSearchContext.isChildUsers:
        label = 'dell\'utente';
        break;
      case this.lastSearchContext.hasUser && !this.lastSearchContext.isUserMaster:
        label = 'dell\'utente';
        break;
      default:
        label = 'dell\'account';
    }
    return this.mdt.parsers.contextualize(this.lastSearchContext?.user || this.api.user, label);
  }

  createProfile() {
    if (this.searchContext.user.parentId) {
      this.isLoading = true;
      this.api.get(`users/${this.searchContext.user.id}/billing-profiles/parent`).then(() => {
        this.isLoading = false;
        this.editor.model = { userId: this.searchContext.user.id, callback: this.editor.callback };
      }).catch(error => {
        console.warn(error);
        this.isLoading = false;
        this.dialog.open({
          title: 'Attenzione!',
          class: 'small',
          type: 'confirm',
          body: '<strong>Non è possibile creare un profilo di fatturazione per questo '+mdt.parsers.contextualize(this.api.user, 'utente')+'.</strong><br><div class="dialog-p mt-2">È necessario prima creare un profilo di fatturazione sull\'account principale. Confermi di voler procedere?</div>'}).whenClosed(response => {
          if (!response.wasCancelled) this.router.navigate(`anagrafiche/utenti/${this.searchContext.user.parentId}/fatturazione`);
        });
      });
    } else {
      this.isLoading = false;
      this.editor.model = { userId: this.searchContext.user.id, callback: this.editor.callback };
    }
  }

  edit(billingProfile) {
    this.editor.model = {
      userId: billingProfile.ownerLegacyId,
      profileUuid: billingProfile.uuid,
      callback: this.editor.callback
    };
  }

  delete(billingProfile) {
    let forceEnabled = this.tableInterface.data.length === 1 && this.api.hasRole('DELETE_LAST_BILLING_PROFILE_ASSOCIATED_TO_ACTIVE_ORDERS');
    this.dialog.open({
      title: 'Attenzione!',
      class: 'delete-billing-profile small',
      viewModel: PLATFORM.moduleName('views/anagrafiche/utenti/dialogs/profilo-delete-billing-profile'),
      viewModelParams: { forceEnabled }
    }, true).whenClosed(response => {
      if (!response.wasCancelled) {
        this.api.delete(`users/${this.searchContext.user.id}/billing-profiles/${billingProfile.uuid}${response.output?'/YES':''}`).then(() => {
          this.toast.show('Profilo eliminato con successo!', 'success');
          if (this.params.id) this.load();
          else this.search();
        }).catch(error => {
          console.error(error);
          this.api.dialogError(error);
        });
      }
    });
  }

  setAsDefault(billingProfile) {
    this.api.patch(`users/${billingProfile.ownerLegacyId}/billing-profiles/${billingProfile.uuid}/default`, {}).then(() => {
      this.toast.show('Profilo aggiornato con successo!', 'success');
      this.search();
    }).catch(error => {
      console.error(error);
      this.api.dialogError(error);
    });
  }

  getPaymentPreferences() {
    return new Promise((resolve, reject) => {
      if (this.searchContext.user.parentId) {
        this.api.get(`users/${this.searchContext.user.id}/preferences`).then(xhr => {
          let data = mdt.preferences.mergeModelData(this.resource.schema, ['payment'], xhr.response);
          // !!! Se apro un condominio, devo gestire il campo sdi con una logica particolare (vedi sezione analoga in pagamenti.js)
          // nel caso l'utente sia il condominio stesso, non faccio nulla
          if ([this.api.user.id, 'me'].includes(this.params.id)) return this.resource.data = data;
          // nel caso sdi = null creo l'oggetto, vuoto.
          if (!data.sdi) data.sdi = { code: null, genericSubuserCode: null };
          // se intercetto, nel response, un sdi.code relativo al condominio, sovrascrivo l'eventuale sdi.code nel payload con quello relativo al condominio.
          if (xhr.response.requested?.payment?.sdi?.code) data.sdi.code = xhr.response.requested.payment.sdi.code;
          // in tutti gli altri casi, sovrascrivo l'eventuale sdi.code nel payload con l'eventuale genericSubuserCode ereditato dall'amministratore.
          else data.sdi.code = data.sdi?.genericSubuserCode;
          resolve(this.resource.data = data);
        }).catch(error => {
          reject(error);
          this.api.dialogError(error);
        });
      } else {
        resolve();
      }
    });
  }

  saveBillingProfileIban(profile) {
    let data = { payments: { banking: { iban: profile.payments.banking.newIban || null }}};
    this.api.patch(`users/${profile.ownerLegacyId}/billing-profiles/${profile.uuid}`, data).then(() => {
      this.toast.show('Codice IBAN impostato con successo', 'success');
      this.searchInterface.search();
    }).catch(xhr => {
      if (profile.controls['payments.banking.iban']) mdt.parsers.apiError(xhr, profile.controls);
    });
  }

  saveCustomSdi($event) {
    $event.model.busy = true;
    this.resource.validate().then(() => {
      let data = { sdi: { code: this.resource.data.sdi.code }};
      this.api.patch(`users/${this.searchContext.user.id}/preferences/payment`, data).then(() => {
        this.toast.show('Modifiche salvate con successo!', 'success');
        this.sdiEnabled = !this.sdiEnabled;
        this.getPaymentPreferences();
      }).catch(error => {
        console.error(error);
        this.resource.error(error);
      }).finally(() => {
        $event.model.busy = false;
      });
    }).catch(() => {
      $event.model.busy = false;
      this.sdiEnabled = !this.sdiEnabled;
      this.toast.show('Errore nel salvataggio delle modifiche!', 'error');
    });
  }

  mavCreateAuthorizationRequest(userId, billingProfileUuid) {
    if (this.resource.data.payments?.banking?.iban && this.billingProfileIban !== this.resource.data.payments.banking.iban) {
      let data = { payments: { banking: { iban: this.resource.data.payments.banking.iban }}}
      this.api.patch(`users/${userId}/billing-profiles/${billingProfileUuid}`, data).then(() => {
        this.isBuildingAdminCreatingMavRequest(userId, billingProfileUuid);
      }).catch(error => {
        console.log(`ERROR billing-profiles - PATCH`, error);
        this.api.dialogError(error);
      });
    } else {
      this.isBuildingAdminCreatingMavRequest(userId, billingProfileUuid);
    }
  }

  isBuildingAdminCreatingMavRequest(userId, billingProfileUuid) {
    if (this.api.user.profile.businessType === 'BUILDING-ADMINISTRATOR' && userId === 'me') {
      this.dialog.open({
        title: 'Attenzione!',
        class: 'small',
        type: 'confirm',
        body: `<span>Stai inviando una richiesta di autorizzazione stampa in proprio MAV su una posizione bancaria dichiarata come appartenente allo studio.<span class="d-block mt-3">Se invece intendi avviarla per pagamenti legati ad una posizione condominiale è necessario partire dalla posizione di fatturazione del condominio.</span><strong class="d-block mt-3">Continuando attiverai una richiesta di autorizzazione stampa in proprio MAV per pagamenti verso lo Studio.</strong></span>`}).whenClosed(response => {
        if (!response.wasCancelled) this.sendMavAuthorizationRequest(userId, billingProfileUuid);
      });
    } else {
      this.sendMavAuthorizationRequest(userId, billingProfileUuid);
    }
  }

  sendMavAuthorizationRequest(userId, billingProfileUuid) {
    return this.api.post(`users/${userId}/billing-profiles/${billingProfileUuid}/mav-order-self-printing-authorizations`, {}).then(() => {
      this.dialog.open({
        title: 'Attenzione!',
        class: 'small',
        type: 'alert',
        body: '<p class="m-0"><strong>La richiesta è stata inoltrata correttamente!</strong><span class="d-block">L\'approvazione e l\'attivazione di una richiesta potrà richiedere dai 3 ai 5 giorni lavorativi.</span></p>'
      }).whenClosed(() => {
        this.params.id ? this.load() : this.search();
      });
    }).catch(error => {
      console.log('ERROR mav-order-self-printing-authorizations - POST', error);
      this.api.dialogError(error);
    });
  }
}

class SearchContext {
  #user = null;
  #searchInterface = null;
  constructor(user, searchInterface) {
    if (user) this.#user = user;
    if (searchInterface) this.#searchInterface = searchInterface;
  }
  set searchInterface(value) {
    this.#searchInterface = value;
  }
  set user(value) {
    return this.#user = value;
  }
  get user() {
    return /*this.#searchInterface?.controls?.user?.control?.valueModel ||*/ this.#user || null;
  }
  get isRequestedUser() {
    return this.#searchInterface?.data?.context === 'USER';
  }
  get isChildUsers() {
    return this.#searchInterface?.data?.context === 'SUBUSERS';
  }
  get hasUser() {
    return this.user !== null;
  }
  get isUserMaster() {
    return this.user?.group === 'MASTER_USER';
  }
}
