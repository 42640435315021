import {PLATFORM, inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaToast} from 'ka-components';

@inject(Api, KaToast)
export class vmRicariche {
  tabs = [
    {
      id: 'ricariche',
      label: 'Ricariche',
      vm: PLATFORM.moduleName('views/servizi/ricariche/tabs/ricariche-elenco')
    }
  ];

  constructor(api, toast) {
    this.api = api;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    this.privilege();
  }

  privilege() {
    this.privileges = { tabs: { elenco: true }, buttons: {} };
    this.privileges.isOperator = this.api.hasRole('LIST_ANY_USER_SHOP_CATALOG_REFILL_PACKAGE');
  }
}
