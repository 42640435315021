import { Aurelia, inject, observable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import mdt from 'services/md-tools';

@inject(Aurelia, Router, Api, KaDialog, KaToast)
export class vmListiniElenco {
  mdt = mdt;
  pricelists = null;
  pricelistDefault = null;
  pricelistsPending = false;
  subusers = null;
  allSubusers = null;
  @observable() searchSubusers = null;

  searchSubusersChanged(value) {
    this.data.searchSubusers = value;
    sessionStorage.setItem(btoa('price-lists'), JSON.stringify(this.data));
    this.subusers = value ? this.allSubusers.filter(x => x.profile[0].displayName.toLowerCase().includes(value.toLowerCase())) : this.allSubusers;

    // DOM
    let oldSelectedSubuser = document.querySelector('.subusers > .selected');
    oldSelectedSubuser?.classList.remove('selected');

    if (!value) this.data.selectedSubuser = null;
    if (value && this.data.selectedSubuser?.id && this.subusers.find(x => x.id === this.data.selectedSubuser.id)) {
      // DOM
      setTimeout(() => {
        let newSelectedSubuser = document.getElementById(this.data.selectedSubuser.id);
        newSelectedSubuser?.classList.add("selected");
      }, 0);
    }
  }

  constructor(aurelia, router, api, dialog, toast) {
    this.aurelia = aurelia;
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(model) {
    this.params = model.params;
    this.privileges = model.privileges;
    this.tools = model.tools;
    this.legacyLink = `https://${ENVIRONMENT.APP_DEBUG === 'true' ? 'beta.multidialogo.it' : ENVIRONMENT.APP_DEBUG === 'false' ? 'go.multidialogo.it' : ''}/scriba/utenti/box_listino_mi.html?price-list-code=`
  }

  attached() {
    this.schema = {
      context: {
        label: 'Quali listini visualizzare?',
        control: 'combo',
        datasource: [{ value: 'ME', text: 'I miei listini' }, { value: 'SUBUSERS', text: `I listini dei miei ${this.mdt.parsers.contextualize(this.api.user, 'utenti')}` }],
        datavalue: 'value',
        datatext: 'text',
        datamultiple: false,
        datapreload: true,
        required: true
      },
      user: {
        label: 'Utente',
        control: 'combo',
        datasource: { table: 'bo/users?include=user-profiles', query: { search: 'text-search', item: 'id-in' }},
        datavalue: 'id',
        datatext: 'profile[0].displayName',
        datamultiple: false,
        datapreload: false,
        required: false
      }
    }
    this.data = JSON.parse(sessionStorage.getItem(btoa('price-lists'))) || { context: !this.privileges.isOperator ? 'ME' : null, user: this.params.id, selectedUser: null, selectedSubuser: null, searchSubusers: null };
    if (this.privileges.isParent) this.getSubusers();
  }

  setContext() {
    if (this.data.context === 'ME') {
      this.data.selectedSubuser = null;
      this.data.searchSubusers = null;

      // DOM
      let oldSelectedSubuser = document.querySelector('.subusers > .selected');
      oldSelectedSubuser?.classList.remove('selected');

      this.getPriceLists();
    } else if (this.data.context === 'SUBUSERS' && !this.data.selectedSubuser) this.pricelists = null;
    sessionStorage.setItem(btoa('price-lists'), JSON.stringify(this.data));
  }

  getUser() {
    if (this.privileges.isOperator) {
      if (this.data.user) {
        this.api.get(`users/${this.data.user}?include=user-profiles`).then(xhr => {
          this.data.selectedUser = { id: xhr.response.id, group: xhr.response.group, businessType: { profile: { businessType: xhr.response.profile[0].businessType }}, displayName: xhr.response.profile[0].displayName };
          sessionStorage.setItem(btoa('price-lists'), JSON.stringify(this.data));
          this.getPriceLists();
        });
      } else { 
        this.data.selectedUser = null;
        sessionStorage.setItem(btoa('price-lists'), JSON.stringify(this.data));
      }
    }
  }

  getSubusers() {
    this.api.get('users/me/users?include=user-profiles&status=ACTIVE').then(xhr => {
      this.allSubusers = this.subusers = xhr.response;
      this.searchSubusers = this.data.searchSubusers;
      if (this.data.selectedSubuser) this.selectSubuser(null);
    }).catch(error => {
      console.log('ERROR - subusers - GET', error);
    });
  }

  selectSubuser(subuser) {
    if (subuser) {
      this.data.selectedSubuser = { id: subuser.id, displayName: subuser.profile[0].displayName };
    }
    else subuser = this.data.selectedSubuser;
    sessionStorage.setItem(btoa('price-lists'), JSON.stringify(this.data));
    
    // DOM
    setTimeout(() => {
      let oldSelectedSubuser = document.querySelector('.subusers > .selected');
      let newSelectedSubuser = document.getElementById(subuser?.id);
      oldSelectedSubuser?.classList.remove('selected');
      newSelectedSubuser?.classList.add("selected");
    }, 0)

    this.getPriceLists();
  }

  getPriceLists() {
    this.pricelistsPending = true;
    let user = null;
    let url = `price-list-schedules`;
    if (this.privileges.isOperator) {
      user = `users/${this.data.selectedUser.id}`;
      this.api.get(`bo/${user}/${url}`).then(xhr => {
        this.pricelists = xhr.response;
        this.pricelists.forEach(pricelist => { pricelist.createdAt = this.aurelia.resources.valueConverters.dateFormat.toView(pricelist.createdAt, 'DD/MM/YYYY'); });
        this.pricelistDefault = xhr.originalResponse.meta.priceListDefault;
        this.pricelistsPending = false;
      }).catch(error => {
        this.api.dialogError(error);
        this.pricelistsPending = false;
        console.log('ERROR - bo/ price-list-schedules - GET', error);
      });
    } else {
      if (this.api.user?.id || this.data.selectedSubuser?.id) {
        user = `users/${this.data.context === 'ME' ? this.api.user.id :  this.data.selectedSubuser.id}`;
        this.api.get(`${user}/${url}/current`).then(xhr => {
          this.pricelists = [];
          this.pricelists.push(xhr.response);
          this.api.get(`${user}/${url}/next`).then(xhr => {
            this.pricelists.push(xhr.response);
          }).catch(error => {
            console.log('ERROR - promise price-list-schedules/next - GET', error);
          }).finally(() => {
            this.pricelistsPending = false;
          });
        }).catch(error => {
          console.log('ERROR - price-list-schedules/current - GET', error);
          this.pricelistsPending = false;
        });
      }
    }
  }

  newPricelistSchedule() {
    this.dialog.open({
      title: 'Pianifica nuovo intervallo',
      class: 'listini-pianificazione schedule medium',
      viewModel: PLATFORM.moduleName('views/servizi/listini/dialogs/listini-pianificazione'),
      viewModelParams: { userId: this.data.selectedSubuser?.id || this.data.selectedUser?.id }
    }, true).whenClosed(response => {
      if (!response.wasCancelled) this.getPriceLists();
    }); 
  }

  subusersPricelistSchedule() {
    this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'confirm', body: `Confermi di voler applicare questa pianificazione su tutti i ${this.mdt.parsers.contextualize(this.data.selectedUser.businessType, 'utenti')} dell'utente <strong>${this.data.selectedUser.displayName}?</strong>`}).whenClosed(response => {
      if (!response.wasCancelled) {
        this.api.post(`bo/users/${this.data.selectedUser?.id || 'me'}/bulk-subusers-price-list-schedules`, {}).then(() => {
          this.toast.show(`Pianificazioni sottoutenti aggiornate con successo!`, 'success');
        }).catch(error => {
          console.log('ERROR bulk-subusers-price-list-schedules POST', error);
          this.api.dialogError(error);
        })
      }
    });
  }
}
