import { inject, CompositionEngine, Container, ViewSlot } from 'aurelia-framework';
import { KaDialog } from 'ka-components';
import mdt from 'services/md-tools';
import moment from 'moment';

export class TableInterface {
  inizialized = false;
  configurables = ['name', 'client', 'endpoint', 'query', 'data', 'limit'];
  endpoint = null;
  query = null;
  limit = 10;
  offset = 0;
  total = 0;
  sort = null;
  data = null;
  isLoading = false;

  constructor(config) {
    // Thisify config
    for (let attribute of this.configurables) {
      if (typeof config[attribute] !== 'undefined') {
        this[attribute] = config[attribute];
      } else if (typeof this[attribute] === 'undefined') throw new Error(`TableInterface construct fail! Missing required configuration attribute "${attribute}"`);
    }
    // Get session stored data
    Object.assign(this, JSON.parse(sessionStorage.getItem(btoa(`${this.name}-data`))) || {});

    // Custom events
    this.events = document.createTextNode(null);
    this.events.dataLoad = new CustomEvent('dataLoad', { resource: this });
    this.events.dataLoaded = new CustomEvent('dataLoaded', { resource: this });

  }

  initialize() {
    let promises = []; // promises.push(this.api.get());
    return Promise.all(promises).then(xhrs => {
      this.initialized = true;
      console.log('TableInterface initialized');
    }).catch(error => {
      console.error('TableInterface initialization failed', error);
    });
  }

  load(params = null, sort = null) {
    if (!this.initialized) return Promise.reject('TableInterface was not yet initialized when the load() function was called!');
    this.isLoading = true;
    
    // Handle query params
    let query = new URLSearchParams('');
    if (params) {
      query = new URLSearchParams(this.query || '');
      for (const [key, value] of Object.entries(params)) {
        query.set(key, value);
      }
    } else if (this._query) {
      query = new URLSearchParams(this._query);
    } else if (this.query) {
      query = new URLSearchParams(this.query);
    }
    this._query = query.toString();
    
    // Handle sort
    this.sort = sort || this.sort || [];
    let sorts = [];
    this.sort.forEach(item => {
      sorts.push(`${item.order === 'desc' ? '-' : ''}${item.name}`);
    });
    if (sorts.length) {
      sorts = sorts.join(',');
      query.set('sort', sorts);
    }

    // Handle limit and offset
    if (this.limit) query.set('limit', this.limit);
    if (this.offset) query.set('offset', this.offset);

    if (this.loadRequest && this.loadRequest.cancel) {
      this.loadRequest.cancel();
    }

    // Finally make the api call
    this.events.dispatchEvent(this.events.dataLoad);
    this.loadRequest = this.client.get(`${this.endpoint}?${this.parseQuery(query)}`);
    return this.loadRequest.then(xhr => {
      this.data = this.parseResponse(xhr.response);
      this.total = (xhr.headers && xhr.headers.headers && xhr.headers.headers['x-total-count']) ? xhr.headers.headers['x-total-count'].value : this.data.length;
      sessionStorage.setItem(btoa(`${this.name}-data`), JSON.stringify({ limit: this.limit, offset: this.offset, sort: this.sort }));
      this.events.dispatchEvent(this.events.dataLoaded);
      this.isLoading = false;
      return xhr;
    }, xhr => {
      this.client.dialogError(xhr, this.searchInterface?.controls || {});
    }).catch(error => {
      console.error(error);
      this.data = null;
      this.isLoading = false;
    });
  }

  reset() {
    this.data = null;
    this.total = 0;
  }

  parseQuery(query) {
    return query;
  }

  parseResponse(response) {
    return response;
  }
}

export class SearchInterface {
  active = false; // Used for highlighting search icon on table
  inizialized = false;
  configurables = ['name', 'table', 'schema', 'data'];
  controls = {}; // Contains auto-referenced controls
  last = { params: {} } // Reference to last search query

  constructor(config) {
    // Thisify config
    for (let attribute of this.configurables) {
      if (typeof config[attribute] !== 'undefined') {
        this[attribute] = config[attribute];
      } else if (typeof this[attribute] === 'undefined') throw new Error(`SearchInterface construct fail! Missing required configuration attribute "${attribute}"`);
    }
    // Get session stored data
    this.data = JSON.parse(sessionStorage.getItem(btoa(`${this.name}-data`))) || this.data || {};
    // Self reference inside TableInterface
    if (this.table) this.table.searchInterface = this;
  }

  initialize() {
    let promises = []; // promises.push(this.api.get());
    return Promise.all(promises).then(xhrs => {
      this.initialized = true;
    }).catch(error => {
      console.error('SearchInterface initialization failed', error);
    });
  }

  validate(controls = null) {
    let promises = [];
    controls = controls || Object.values(this.controls);
    controls.forEach(control => {
      if (control && control.value !== undefined) { // Control can be null if in the meantime view removes it (eg: with an if.bind)
        if (control.setError) control.setError(); // Reset in case control is a ka-control (not ka-input) and there is already an error manually attached to the control
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }).catch(error => reject(error)); }));
      }
    });
    return Promise.all(promises);
  }

  search(data) {
    if (!this.initialized) return Promise.reject('SearchInterface was not yet initialized when the search() function was called!');
    let params = {};
    return new Promise((resolve, reject) => {
      this.validate().then(() => {
        for (let [k,v] of Object.entries(data || this.data)) {
          if (v && v !== 'null' && this.schema[k]?.query) {
            if (this.schema[k].control === 'date' && this.schema[k].query.includes('-gte')) {
              v = moment.utc(v).local().startOf('day').utc().format();
            } else if (this.schema[k].control === 'date' && this.schema[k].query.includes('-lte')) {
              v = moment.utc(v).local().endOf('day').utc().format();
            }
            params[this.schema[k].query] = encodeURIComponent(v);
          }
        }
        if (sessionStorage.getItem(btoa(`${this.name}-data`)) !== JSON.stringify(this.data)) {
          sessionStorage.setItem(btoa(`${this.name}-data`), JSON.stringify(this.data));
          this.table.offset = 0; // Reset offset only if search data has changed
        }
        if (Object.keys(params).length) {
          this.active = true;
          this.last.params = params;
          this.table.load(params).then(xhr => resolve(xhr)).catch(error => reject(error));
        } else {
          this.active = false;
          this.last.params = {};
          this.table.load({}).then(xhr => resolve(xhr)).catch(error => reject(error)); //return this.reset(); // Not sure what it is for...
        }
      }).catch(error => reject(error));
    });
  }

  reset(soft = false) {
    for (let key of Object.keys(this.data)) { this.data[key] = null; }
    if (!soft) {
      sessionStorage.removeItem(btoa(`${this.name}-data`));
      this.table.offset = 0;
      this.active = false;
      this.last.params = {};
      return this.table.load({});
    } else {
      return Promise.resolve();
    }
  }
}

@inject(CompositionEngine, Container)
export class TableSidebar {
  constructor(compositionEngine, container) {
    this.compositionEngine = compositionEngine;
    this.container = container;
  }
  open(params) {
    if (this.isOpen) return this.close();

    // Add backdrop
    this.backdrop = document.createElement('div');
    this.backdrop.classList.add('md-table-backdrop');
    this.backdrop.addEventListener('click', event => { if (event.target === this.backdrop) this.close(); });
    document.getElementsByTagName('body')[0].insertBefore(this.backdrop, document.getElementsByTagName('body')[0].children[0]);

    // Add sidebar
    this.sidebar = document.createElement('div');
    this.sidebar.classList.add('md-table-sidebar', 'off');
    this.backdrop.appendChild(this.sidebar);

    // Compose
    this.context = {
      container: this.container.createChild(),
      viewModel: params.viewModel,
      model: params.viewModelParams,
      host: this.sidebar,
      bindingContext: null,
      viewResources: null,
      viewSlot: new ViewSlot(this.sidebar, true)
    };
    this.compositionEngine.compose(this.context).then(composition => {
      this.composition = composition;
      this.composition.viewModel.close = () => { this.close(); };
      this.context.viewSlot.attached();
      // Timeout used for css transition
      setTimeout(() => { this.sidebar.classList.remove('off'); }, 100);
      this.isOpen = true;
    });
  }
  close() {
    // Timeout used for css transition
    this.sidebar.classList.add('off');
    setTimeout(() => {
      document.getElementsByTagName('body')[0].removeChild(this.backdrop);
      this.context.viewSlot.detached();
      this.isOpen = false;
    }, 500);
  }
}

export class TableSelectionInterface {
  constructor(config) {
    this.table = config.table;
    this.recordsByUuid = {};
    this.addLoadSuccessListener();
  }

  get selectedRecords() {
    return Object.values(this.recordsByUuid).filter(record => record._selection.isSelected);
  }
  get selectedCount() {
    return this.selectedRecords.length;
  }
  get isEveryVisibleRecordSelected() {
    return this.table.data?.every(record => record._selection.isDisabled) ? false : this.table.data?.filter(record => !record._selection.isDisabled).every(record => record._selection.isSelected);
  }
  set isEveryVisibleRecordSelected(value) {
    this.table.data.forEach((record) => {
      if (!record._selection.isDisabled) record._selection.isSelected = value;
    });
  }
  get isEveryVisibleRecordDisabled() {
    return this.table.data?.every(record => record._selection.isDisabled);
  }

  addLoadSuccessListener() {
    return this.table.events.addEventListener('dataLoaded', () => {
      this.table.data.forEach((record) => {
        record._selection = Object.assign({ isDisabled: false, isSelected: false }, this.recordsByUuid[record.id]?._selection || {});
        this.recordsByUuid[record.id] = record;
      });
    });
  }

  reset(hard) {
    return Object.values(this.recordsByUuid).forEach((record) => {
      if (hard) {
        record._selection.isDisabled = false;
        record._selection.isSelected = false;
      }
      Object.keys(record._selection).filter(key => !['isSelected', 'isDisabled'].includes(key)).forEach((key) => {
        record._selection[key] = null;
      });
    });
  }
}
