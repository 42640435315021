import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { PLATFORM, inject } from 'aurelia-framework';

@inject(Api, KaDialog, KaToast)
export class vmAbbonamenti {
  showMigrationsTab = null;
  tabs = [
    {
      id: 'gestione',
      label: 'Gestione servizi',
      vm: PLATFORM.moduleName('views/servizi/abbonamenti/tabs/abbonamenti-gestione')
    },
    {
      id: 'attivabili',
      label: 'Servizi attivabili',
      vm: PLATFORM.moduleName('views/servizi/abbonamenti/tabs/abbonamenti-attivabili')
    },
    {
      id: 'volture',
      label: 'volture',
      vm: PLATFORM.moduleName('views/servizi/abbonamenti/tabs/volture')
    },
    {
      id: 'attivi',
      label: 'Servizi attivi',
      vm: PLATFORM.moduleName('views/servizi/abbonamenti/tabs/abbonamenti-attivi')
    }
  ];

  constructor(api, kaDialog, kaToast) {
    this.api = api;
    this.toast = kaToast;
    this.dialog = kaDialog;
    this.tools = {
      copyText: (text, context = 'Testo') => {
        if (!text) return;
        navigator.clipboard.writeText(text);
        this.toast.show(`${context} copiato con successo!`, 'success');
      },
      dialogCashbackSubscriptionRequired: ($event, action = 'activation') => {
        return this.dialog.open({
          title: 'Attenzione!',
          class: 'small',
          viewModel: PLATFORM.moduleName('views/servizi/abbonamenti/dialogs/abbonamenti-cashback-contract-subscription-required'),
          viewModelParams: { action }
        }, true).whenClosed(() => {
          $event.model.busy = false;
        });
      }
    }
  }

  async activate(params) {
    this.params = params;
    this.privileges = {
      isOperator: this.api.hasRole('LIST_ANY_USER'),
      isMaster: this.api.user.group === 'MASTER_USER',
      isBasicUser: this.api.user.group === 'BASIC_USER'
    };
    let promises = [this.showOrHideMigrationsTab()];
    if (this.params.id) promises.push(this.getUser);
    if (!this.privileges.isOperator && this.privileges.isMaster) promises.push(this.getLastServiceContractSubscribed());
    return Promise.allSettled(promises).then((xhrs) => {
      return this.setPrivileges();
    });
  }

  async getUser() {
    return this.api.get(`users/${this.params.id}?include=user-profiles`).then((xhr) => {
      let response = xhr.response;
      if (response.profile && Array.isArray(response.profile)) response.profile = response.profile[0];
      resolve(this.requestedUserData = response);
    }).catch((xhr) => {
      console.error('GET users', xhr);
    });
  }

  // the following method shows or hides tab that contains the not acknowledged flat services activations
  async showOrHideMigrationsTab() {
    return Promise.all([
      this.api.get(`users/${this.api.user.id}/flat-services-activations?acknowledged=NO&limit=0`),
      this.api.get(`users/${this.api.user.id}/subusers/flat-services-activations?acknowledged=NO&limit=0`)
    ]).then((responses) => {
      const [mineResponse, subusersResponse] = responses;
      const mineHasElements = mineResponse.response.length > 0;
      const subusersHasElements = subusersResponse.response.length > 0;
      this.showMigrationsTab = (mineHasElements || subusersHasElements);
    }).catch((error) => {
      console.error('Failure to check presence of at least one element in either response', error);
    });
  }

  setPrivileges() {
    this.privileges.tabs = {};
    this.privileges.tabs = { attivi: false, attivabili: false, gestione: false, volture: false };
    if (this.api.hasRole(['LIST_USER_FLAT_SERVICE_SUBSCRIPTIONS', 'LIST_ANY_USER_FLAT_SERVICE_SUBSCRIPTION'])) {
      this.privileges.tabs.attivi = true;     
      if (this.privileges.isMaster && this.showMigrationsTab) {
        this.privileges.tabs.volture = true;
      }
    }
    if (!this.privileges.isOperator && this.api.hasRole(['CREATE_USER_FLAT_SERVICE_SUBSCRIPTIONS'])) {
      this.privileges.tabs.attivabili = true;
    }
    if (this.api.hasRole(['LIST_ANY_FLAT_SERVICE'])) {
      this.privileges.tabs.gestione = true;
    }
  }

  getLastServiceContractSubscribed() {
    return this.api.get('users/me/cashback-contracts/last-accepted-flat-service').then((xhr) => {
      const response = xhr.response;
      this.privileges.lastAcceptedServiceContract = {
        ...response,
        isCashbackContractNeverBeenSigned: false
      };
    }).catch((error) => {
      console.log('GET last-flat-service', error);
      if (error.statusCode === 404) {
        return this.getLastMultinvioContractSubscribed();
      } else {
        this.privileges.lastAcceptedServiceContract = null;
      }
    });
  }

  getLastMultinvioContractSubscribed() {
    return this.api.get('users/me/cashback-contracts/last-accepted-multinvio-volumes').then((xhr) => {
      const response = xhr.response;
      if (response?.rescindedAt) {
        this.privileges.lastAcceptedServiceContract = {
          ...response,
          isCashbackContractNeverBeenSigned: false
        };
      } else {
        this.privileges.lastAcceptedServiceContract = { isCashbackContractNeverBeenSigned: true };
      }    
    }).catch((error) => {
      console.log('GET last-accepted-multinvio-volumes', error);
      this.privileges.lastAcceptedServiceContract = (error.statusCode === 404) ? { isCashbackContractNeverBeenSigned: true } : null;
    });
  }
}
