import {inject, observable} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import {DialogController} from 'aurelia-dialog';
import mdt from 'services/md-tools';

@inject(Api, KaDialog, KaToast, DialogController)
export class UtentiDialogCreateRequest {
  mdt = mdt;
  pending = false;
  
  resource = {
    controls: {},
    schema: {
      context: {
        control: 'combo',
        label: 'Chi è il proprietario del profilo di fatturazione?',
        datasource: [],
        datavalue: 'value',
        datatext: 'text',
        datamultiple: false,
        datapreload: true,
        required: true
      },
      subuser: { 
        control: 'combo', 
        label: null,
        datasource: { table: 'users/me/users?include=user-profiles&status=ACTIVE', query: { search: 'text-search', item: 'id-in'} },
        datavalue: 'id', 
        datatext: 'profile[0].displayName',
        datamultiple: false,
        datapreload: false,
        required: true
      },
      billingProfile: {
        control: 'combo', 
        label: 'Seleziona un profilo di fatturazione',
        datasource: [],
        datavalue: 'uuid', 
        datatext: 'identifier',
        datamultiple: false,
        datapreload: true,
        required: true
      }
    },
    data: {},
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    }
  }

  constructor(api, dialog, toast, dialogController) {
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
    this.controller = dialogController;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate() {
    this.pending = true;
    this.resource.schema.context.datasource = [
      { value: 'USER', text: 'Me stesso'},
      { value: 'SUBUSER', text: `Un mio ${this.mdt.parsers.contextualize(this.api.user, 'sottoutente')}` }
    ];
    this.resource.schema.subuser.label = `Seleziona un ${this.mdt.parsers.contextualize(this.api.user, 'sottoutente')}`;
    this.resource.data.context = 'USER';
  }

  setUserId(mode = null) {
    let userId = this.resource.data?.context === 'USER' ? 'me' : this.resource.data?.context === 'SUBUSER' ? this.resource.data.subuser : null;
    if (mode !== 'soft') {
      this.pending = true;
      return this.userIdChanged(userId);
    } else return userId;
  }

  userIdChanged(userId) {
    this.resource.data.billingProfile = null;
    this.resource.schema.billingProfile.datasource = [];
    if (userId === 'me') {
      this.resource.data.subuser = null;
      this.getBillingProfiles(userId);
    } else if (this.resource.data?.subuser) {
      this.getBillingProfiles(userId);
    } else if (!this.resource.data?.subuser) {
      setTimeout(() => { this.pending = false; }, 600);
    }
  }

  subuserChanged(userId = null) {
    if (this.resource.data?.context === 'SUBUSER' && this.resource.data.subuser) {
      this.pending = true;
      this.setUserId();
    }
  }

  getBillingProfiles(userId) {
    this.api.get(`users/${userId}/billing-profiles`).then(xhr => {
      let billingProfiles = xhr.response;
      billingProfiles.forEach(profile => {
        profile.identifier = `${profile.label} - IBAN: ${profile.payments?.banking?.iban || 'mancante'}`
      });
      this.resource.schema.billingProfile.datasource = billingProfiles;
      this.pending = false;
    }).catch(error => {
      console.log('ERROR - billing-profiles - GET', error);
      this.controller.cancel();
      this.api.dialogError(error);
    });
  }

  send($event, userId = null) {
    $event.model.busy = true;
    this.resource.validate().then(() => {
      userId = this.setUserId('soft');
      this.isBuildingAdminCreatingMavRequest($event, userId);
    }).catch(() => {
      $event.model.busy = false;
    });
  }

  isBuildingAdminCreatingMavRequest($event, userId) {
    if (this.api.user.profile.businessType === 'BUILDING-ADMINISTRATOR' && userId === 'me') {
      this.dialog.open({ 
        title: 'Attenzione!', 
        class: 'small', 
        type: 'confirm', 
        body: `<span>Stai inviando una richiesta di autorizzazione stampa in proprio MAV su una posizione bancaria dichiarata come appartenente allo studio.<span class="d-block mt-3">Se invece intendi avviarla per pagamenti legati ad una posizione condominiale è necessario partire dalla posizione di fatturazione del condominio.</span><strong class="d-block mt-3">Continuando attiverai una richiesta di autorizzazione stampa in proprio MAV per pagamenti verso lo Studio.</strong></span>`}).whenClosed(response => {
        if (!response.wasCancelled) this.sendMavAuthorizationRequest($event, userId);
        else $event.model.busy = false;
      });
    } else {
      this.sendMavAuthorizationRequest($event, userId);
    }
  }

  sendMavAuthorizationRequest($event, userId) {
    return this.api.post(`users/${userId}/billing-profiles/${this.resource.data.billingProfile}/mav-order-self-printing-authorizations`, {}).then(() => {
      this.controller.ok();
    }).catch(error => {
      console.log('ERROR mav-order-self-printing-authorizations - POST', error);
      this.api.dialogError(error);
    }).finally(() => {
      $event.model.busy = false;
    });
  }
}
