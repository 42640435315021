import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { DialogController } from 'aurelia-dialog';

export class DialogCreditTransfer {
  resource = {
    data: {
      amount: 0
    },
    controls: {},
    schema: { amount: { control: 'number', label: 'Importo in €', required: true }},
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        control.validator.errors = [];
        control.element.classList.remove('error');
        promises.push(new Promise((resolve, reject) => { 
          control.validate().then((result) => { 
            if (result.valid) {
              resolve();
            } else {
              reject('Control didn\'t pass validation');
            }
          }); 
        }));
      });
      return Promise.all(promises);
    },
    error: (error) => {
      this.api.dialogError(error, this.resource.controls);
    }
  }

  static inject = [Api, DialogController, KaDialog, KaToast];
  constructor(api, controller, KaDialog, kaToast) {
    this.api = api;
    this.dialog = KaDialog;
    this.toast = kaToast;
    this.controller = controller;
  }

  activate(params) {
    this.params = params;
  }

  async saveTransferCreditMovements($event) {
    $event.model.busy = true;
    const data = this.parseData(this.resource.data);

    return this.resource.validate().then(() => {
      return this.api.post('users/me/cashback-transfer-credit-movements', data).then(() => {
        this.toast.show('Trasferimento avvenuto con successo!', 'success');
        this.controller.ok();
      }).catch((error) => {
        console.error('Get cashback-transfer-credit-movements', error);
        this.resource.error(error);
      }).finally(() => {
        $event.model.busy = false;
      });
    }).catch(() => {
      $event.model.busy = false;
    });
  }

  parseData(data) {
    let clone = structuredClone(data);
    return clone = {
      ...clone,
      amount: Number(clone.amount)
    }
  }
}
