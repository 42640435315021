import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {KaToast} from 'ka-components';

@inject(Api, KaToast)
export class DialogTransactionConfirm {
  check = {
    schema: {
      confirm: {
        label: 'Accetta e prosegui',
        description: 'Confermi di aver compreso i termini dell\'operazione?',
        control: 'check',
      }
    },
    data: {}
  };

  constructor(api, kaToast) {
    this.api = api;
    this.toast = kaToast;
  }

  activate(params) {
    this.params = params;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }
}
