import { Api } from "../services/api";
import { inject } from "aurelia-framework";


@inject(Api)
export class Advices {
  constructor(api) {
    this.api = api;
    this.startUpdateInterval = false;
    this.updateIntervalDuration = 30 * 60 * 1000;
    this.targetRoutes = [
      {
        code: 'DASHBOARD',
        href: 'dashboard',
        text: "Dashboard",
      },
      {
        code: 'CASHBACK',
        href: "cashback/contracts",
        text: 'Sezione contratti cashback'
      },
      {
        code: 'FLAT-SERVICE-SUBSCRIPTIONS',
        href: 'servizi/abbonamenti',
        text: 'Sezione attivazione servizi'
      },
      {
        code: 'QUEUE-REPORTS',
        href: 'spedizioni/reportistica',
        text: 'Sezione spedizioni reportistica'
      },
      {
        code : "SUBUSERS-PROFILES",
        href : "anagrafiche/utenti",
        text : "Sezione anagrafiche utenti"
      }
    ]
  }

  get activeAdvices() {
    return JSON.parse(localStorage.getItem('wall-advices')) || [];
  }

  set activeAdvices(value) {
    return localStorage.setItem('wall-advices', JSON.stringify(value));
  }

  async initialize() {
    if (this.updateInterval) return;
    this.updateInterval = setInterval(() => {
      this.updateAdvices();
    }, this.updateIntervalDuration);
    return await this.updateAdvices();
  }

  async updateAdvices() {
    return await this.api.get("users/me/wall-advices").then((xhr) => {
      const newAdvices = [];
      xhr.response.forEach(advice => {
        if (!this.activeAdvices.some(x => x.id === advice.id)) {
          advice.hasBeenSeen = false;
          newAdvices.push(advice);
        };
      })
      return this.activeAdvices = [...this.activeAdvices, ...newAdvices];
    }).catch((error) => {
      console.error("Error updating user advices list", error);
    })
  }
  
  get(fragment) {
    const route = this.targetRoutes.find(route => route.href === fragment);
    if (!route) return;
    const targetAdvices = this.activeAdvices.filter(advice => advice.targetCodes.includes(route.code) && !advice.hasBeenSeen);
    return targetAdvices.length ? targetAdvices : null;
  }

  remove(advice) {
    this.activeAdvices = this.activeAdvices.filter(x => x.id !== advice.id);
  }
  setAsSeen(advice) {
    this.activeAdvices = this.activeAdvices.map(x => {
      if (x.id === advice.id) x.hasBeenSeen = true;
      return x;
    });
  }
}