import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { DialogController } from 'aurelia-dialog';

export class DialogInsertMovement {
  resource = {
    controls: {},
    data: {
      amount: 0,
      direction: 'IN'
    },
    schema: {
      user: {
        control: 'combo', 
        label: 'Utente',
        datavalue: 'id', 
        datapreload: false,
        datamultiple: false,
        datatext: 'profile[0].displayName',
        datasource: { table: 'bo/users?include=user-profiles&status=ACTIVE&user-group-in=MASTER_USER', query: { search: 'text-search', item: 'id-in' }}
      },
      amount: { 
        required: true,
        label: 'Importo in €', 
        control: 'number'
      },
      direction: {
        control: 'combo', 
        datatext: 'text',
        datapreload: true,
        datavalue: 'value', 
        label: 'Tipologia',
        datamultiple: false,
        datasource: [{ text: 'Entrata', value: 'IN' }, { text: 'Uscita', value: 'OUT' }]
      },
      reason: {
        required: true,
        control: 'text', 
        label: 'Causale'
      }
    },
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        control.validator.errors = [];
        control.element.classList.remove('error');
        promises.push(new Promise((resolve, reject) => { 
          control.validate().then((result) => { 
            if (result.valid) {
              resolve();
            } else {
              reject('Control didn\'t pass validation');
            }
          }); 
        }));
      });
      return Promise.all(promises);
    },
    error: (error) => {
      this.api.dialogError(error, this.resource.controls);
    }
  }

  static inject = [Api, DialogController, KaDialog, KaToast];
  constructor(api, controller, KaDialog, kaToast) {
    this.api = api;
    this.dialog = KaDialog;
    this.toast = kaToast;
    this.controller = controller;
  }

  activate(params) {
    this.params = params;
    this.userId = this.params.userId;
    if (this.userId) {
      this.resource.data.user = this.userId;
      this.resource.schema.user.readonly = true;
    }
  }

  async saveInsertCreditMovement($event) {
    $event.model.busy = true;
    const data = this.parseData(this.resource.data);
    return this.resource.validate().then(() => {
      return this.api.post(`bo/users/${this.resource.data.user}/cashback-movements`, data).then(() => {
        this.toast.show('Trasferimento avvenuto con successo!', 'success');
        this.controller.ok();
      }).catch((error) => {
        console.error('Get cashback-transfer-credit-movements', error);
        this.resource.error(error);
      }).finally(() => {
        $event.model.busy = false;
      });
    }).catch(() => {
      $event.model.busy = false;
    });
  }

  parseData(data) {
    let clone = structuredClone(data);
    delete clone.user;
    return clone = {
      ...clone,
      amount: Number(clone.amount)
    }
  }
}
