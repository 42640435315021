import { Api } from 'services/api';
import { Router } from 'aurelia-router';
import { inject } from 'aurelia-framework';
import { KaDialog, KaToast } from 'ka-components';
import { TableInterface, SearchInterface}  from 'classes/md-table';

@inject(Api, Router, KaDialog, KaToast)
export class viewExpiredAdvices {
  
  constructor(api, router, kaDialog, kaToast) {
    this.api = api;
    this.toast = kaToast;
    this.router = router;
    this.dialog = kaDialog;
  }

  activate(model) {
    this.tools = model.tools;
  }

  attached() {
    this.init();
  }

  init() {
    this.initTableInterface();
    this.initSearchInterface().then(() => {
      this.search();
    });
  }

  initTableInterface() {
    this.tableInterface = new TableInterface({
      client: this.api,
      name: 'table-wall-advices',
      endpoint: "bo/wall-advices",
      query: "status-in=EXPIRED",
    });

    return this.tableInterface.initialize();
  }

  initSearchInterface() {
    this.searchInterface = new SearchInterface({
      name: 'table-expiredAdvices-search',
      table: this.tableInterface,
      schema: {
        title: {
          control: 'text',
          label: 'Titolo',
          query: 'title-like'
        },
        tag: {
          label: 'tag',
          control: 'text',
          query: "tag"
        },
        startAt: {
          control: 'date',
          label: 'Programmato dal',
          query: 'active-at-gte'
        },
        endAt: {
          label: 'al',
          control: 'date',
          query: 'active-at-lte'
        },
      },
      data: {}
    });

    return this.searchInterface.initialize();
  }

  reset() {
    this.searchInterface.reset();
  }

  async search() {
    const data = structuredClone(this.searchInterface.data);
    if (data.startAt) {
      data.startAt = this.tools.normalizeStartAtDateTime(this.searchInterface.data.startAt);
    }
    if (data.endAt) {
      data.endAt = this.tools.normalizeEndAtDateTime(this.searchInterface.data.endAt);
    }
    return await this.searchInterface.search(data);
  }
}