import { inject, customElement, bindable } from 'aurelia-framework';

@customElement('md-accordion-button')
@inject(Element)
export class MdAccordionButton {
  @bindable() expandText;
  @bindable() collapseText;
  @bindable() target;
  @bindable() state;
  @bindable() initDelay = 1000;

  constructor(element) {
    this.element = element;
  }

  get isCollapsed() {
    return this.element.classList.contains('collapsed');
  }

  attached() {
    this.targetElement = document.getElementById(this.target);
    this.targetElement.classList.add('md_accordion_target');
    if (this.state === 'collapsed') {
      this.targetElement.style.height = '0';
      this.toggle(); 
    } else {
      setTimeout(() => {
        this.resetHeight(this.targetElement);
      }, this.initDelay)
    }
  }

  toggle() {
    const routerView = document.querySelector('router-view');
    routerView.style.overflow = 'visible';
    this.resetHeight(this.targetElement);
    this.targetElement.classList.toggle('collapsed');
    this.element.classList.toggle('collapsed');
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      routerView.style.removeProperty('overflow');
    }, 1000);
    if (this.isCollapsed) {
      this.targetElement.style.height = 0;
    }
  }

  resetHeight(element) {
    element.style.height = element.scrollHeight + 'px';
  }
}
