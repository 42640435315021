import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog} from 'ka-components';
import moment from 'moment';

@inject(Api, KaDialog)
export class viewArchiviazione {
  archives = [];
  data = {
    yearFrom: moment().subtract(6, 'months').format('YYYY'),
    monthFrom: moment().subtract(6, 'months').format('MM'),
    yearTo: moment().format('YYYY'),
    monthTo: moment().format('MM')
  };
  datasources = {
    years: Array.from({length: 11}, (v, i) => { let y = new Date().getFullYear() - 11 + i + 1; return {text: y, value: y} }),
    months: Array.from({length: 12}, (v, i) => { let m = moment(i+1, 'M'); return {text: m.locale('it').format('MMMM'), value: m.format('MM')} })
  };


  constructor(api, dialog) {
    this.api = api;
    this.dialog = dialog;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    this.load();
  }

  load() {
    this.api.get('bo/mi-queue/archive-files', this.data).then(x => {
      konsole.debug(x.response);
      this.archives = x.response.months;
    });
  }

  archivia(record, test) {
    this.dialog.open({
      title: 'Archiviazione',
      class: 'small',
      viewModel: PLATFORM.moduleName('views/backoffice/archiviazione-dialog'),
      viewModelParams: { record }
    }, true).whenClosed(response => {
      this.load();
    });
  }
}
