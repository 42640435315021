export async function checkUserProfileData(scope, fragment) {
  if (/\/(anagrafiche|gestione)\/(utenti|profilo)\/me\/anagrafica/.test(fragment)) return checkResult(false, fragment);
  const profile = scope.data.profile;
  const missingProperties = [];
  const requiredProperties = ['businessType', 'companyLegalFormCode', 'countryCode'];
  if (profile.location?.countryCode === 'IT') {
    requiredProperties.push('admLvl1', 'admLvl2', 'admLvl3', 'streetAddress', 'zipCode');
  }
  requiredProperties.forEach((property) => {
    const profileProperty = profile[property] || profile.location?.[property];
    if (!(profileProperty || '').trim().length) missingProperties.push(property);
  });
  if (missingProperties.length) {
    await dialogMissingProperties(scope, missingProperties);
    return checkResult(false, '/gestione/profilo/me/anagrafica');
  }
  return checkResult(true);
}

export function dialogMissingProperties(scope, missingProperties) {
  return new Promise((resolve) => {
    scope.dialog.open({
      title: `Dati profilo utente incompleti`,
      class: 'small',
      viewModelParams: missingProperties,
      viewModel: PLATFORM.moduleName('views/accesso/dialog-missing-properties')
    }).whenClosed(() => {
      resolve();
    });
  });
}

export function checkResult(hasPassed, redirect) {
  const result = {};
  if (hasPassed !== undefined) result.hasPassed = hasPassed;
  if (redirect !== undefined) result.redirect = redirect;
  return result;
}
