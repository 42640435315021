import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import mdt from 'services/md-tools';

@inject(Api, KaDialog, KaToast)
export class DialogDepositTransaction {
  resource = {
    controls: {},
    schema: {
      value: {
        label: 'Importo da movimentare',
        control: 'number',
        required: true
      },
      type: {
        label: 'Tipologia del movimento',
        control: 'combo',
        datasource: [
          { value: 'CREDIT', text: 'Entrata'},
          { value: 'DEBIT', text: 'Uscita'}
        ],
        datavalue: 'value',
        datatext: 'text',
        datapreload: true,
        required: true
      },
      note: {
        label: 'Causale',
        control: 'text',
        required: true
      }
    },
    data: {
      value: 0,
      type: 'CREDIT',
    },
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        console.log(control);
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      let errorPayload = mdt.parsers.apiError(error, this.resource.controls);
      if (errorPayload) this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>'+errorPayload.title+':</strong><div style="margin-top:1rem;border:1px solid #e0e0e0;padding:1rem;max-height:400px;overflow:auto;background-color:#ffffff">' + errorPayload.body + '</div>'});
    }
  }

  constructor(api, dialog, kaToast) {
    this.api = api;
    this.dialog = dialog;
    this.toast = kaToast;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
  }

  save() {
    this.resource.validate().then(() => {
      this.dialog.open({
        title: 'Attenzione!',
        class: 'small',
        viewModel: PLATFORM.moduleName('views/servizi/credito/dialog-transaction-confirm'),
        viewModelParams: { cause: 'DEPOSIT' }
      }, true).whenClosed(response => {
        if (!response.wasCancelled) {
          this.api.post(`bo/users/${this.params.userId}/bailment-deposit-transactions`, this.resource.data).then(() => {
            this.toast.show(`Movimento inserito con successo!`, 'success');
            this.params.dialog.ok();
          }).catch(error => {
            console.error();
            this.resource.error(error);
          });
        }
      });
    });
  }
}
