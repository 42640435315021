import { Api } from 'services/api';
import mdt from 'services/md-tools';
import { inject } from 'aurelia-framework';
import { KaDialog, KaToast } from 'ka-components';
import { TableInterface, SearchInterface}  from 'classes/md-table';

@inject(Api, KaDialog, KaToast)
export class viewSubscriptions {
  mdt = mdt;
  serviceContract;
  multinvioContract;
  serviceContractPending = true;
  multinvioContractPending = true;
  constructor(api, kaDialog, kaToast) {
    this.api = api;
    this.toast = kaToast;
    this.dialog = kaDialog;
  }

  activate(model) {
    this.tools = model.tools;
    this.params = model.params;
  }

  attached() {
    this.init();
  }

  init() {
    // Table interface
    this.tableInterface = new TableInterface({
      name: 'table-cashback-subscriptions',
      client: this.api,
      endpoint: 'bo/cashback-contract-acceptances'
    });
    this.tableInterface.initialize();

    // INIT SearchInterface
    this.searchInterface = new SearchInterface({
      name: 'table-subscriptions-search',
      table: this.tableInterface,
      schema: {
        user: {
          control: 'combo',
          label: this.mdt.parsers.contextualize(this.api.user, 'Utente'),
          datasource: { table: 'bo/users?include=user-profiles', query: { search: 'text-search', item: 'uuid-in'} },
          datavalue: 'uuid',
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false,
          query: 'user-uuid'
        },
        type: {
          control: 'combo',
          label: 'Tipologia contratto',
          datasource: [
            { text: 'Qualsiaisi', value: 'null' },
            { text: 'Servizi', value: 'FLAT-SERVICE' },
            { text: 'MultInvio', value: 'MULTINVIO-VOLUMES' }
          ],
          datavalue: 'value',
          datatext: 'text',
          query: 'contract-type'
        }
      },
      data: {
        type: 'null'
      }
    });

    this.searchInterface.initialize().then(() => {
      return this.searchInterface.search();
    });
  }

  reset() {
    this.searchInterface.reset();
    this.searchInterface.data.type = 'null';
  }

  dialogCashbackContractRescission() {
    return this.dialog.open({
      class: 'medium contract-rescission',
      title: 'Recedi contratto',
      viewModelParams: { userUuid: this.searchInterface.data.user, contractType: this.searchInterface.data.type },
      viewModel: PLATFORM.moduleName('views/cashback/contracts/dialogs/contract-rescission')
    }).whenClosed((response) => {
      if (!response.wasCancelled) {
        this.search();
        this.controllerTabRequests.search();
      }
    });
  }
}
