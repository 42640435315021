import { Api } from 'services/api';
import mdt from 'services/md-tools';
import { Router } from 'aurelia-router';
import { inject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { KaToast, KaDialog } from 'ka-components';
import { MdDatasources } from 'services/md-datasources';
import { TableInterface, SearchInterface } from 'classes/md-table';

@inject(Router, Api, KaToast, KaDialog, DialogService, MdDatasources)
export class ViewPending {
  mdt = mdt;
  isOperator;
  contextPath;
  pendingSearch;
  paymentMethods;
  contextDatasource;

  constructor(router, api, toast, dialog, dialogService, mdDatasources) {
    this.router = router;
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
    this.dialogService = dialogService;
    this.mdDatasources = mdDatasources;
    this.isOperator = this.api.hasRole('LIST_ANY_QUEUE');
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    this.paymentMethods = this.setPaymentMethod();
    this.setContextPath();
    this.init();
  }

  setContextPath() {
    let sessionContext = JSON.parse(sessionStorage.getItem(btoa('pending-queues-table-search-data')))?.context || 'USER';
    this.contextPath = sessionContext === 'USER' ? '' : (sessionContext === 'SUBUSERS' ? '~subs' : '~all');
  }

  setPaymentMethod() {
    const map = new Map();
    const paymentMethods = this.mdDatasources.paymentMethods;
    paymentMethods.forEach((method) => { map.set(method.code, method); });
    return Object.fromEntries(map.entries());
  }

  changeContext(search = this.pendingSearch === undefined) {
    let path = '~all';
    const context = this.searchInterface?.data?.context;
    if (!this.isOperator && !context) this.searchInterface.data.context = 'USER&SUBUSERS';
    else if (this.isOperator || context === 'USER') path = '';
    else if (context === 'SUBUSERS') path = '~subs';
    this.contextPath = path;
    if (search) this.search();
  }

  setEndpoint() {
    const owner = this.searchInterface?.data?.owner;
    let endpoint = `users/me/queue-outlines/${this.contextPath}`;
    if (owner) endpoint = `users/${owner}/queue-outlines`;
    else if (this.isOperator && !owner) endpoint = `bo/queue-outlines`;
    return endpoint;
  }

  setContextDatasource() {
    const datasource = [];
    const utenti = mdt.parsers.contextualize(this.api.user, 'utenti');
    const account = mdt.parsers.contextualize(this.api.user, 'account');
    const _datasource = {
      'USER': { text: `Le spedizioni del mio ${account}` },
      'SUBUSERS': { text: `Le spedizioni dei miei ${utenti}` },
      'USER&SUBUSERS': { text: `Tutte` }
    }
    Object.entries(_datasource).forEach(([key, value]) => {
      datasource.push({ value: key, text: value.text });
    });
    return datasource
  }

  setPaymentMethodDatasource() {
    const datasource = [{ text: 'Qualsiasi', value: 'null' }];
    const paymentMethods = this.mdDatasources.paymentMethods;
    paymentMethods.forEach((method) => {
      const IsPaymentMethodAllowed = ['SDD', 'E-PAYMENT', 'BANK-TRANSFER'].includes(method.code);
      if (IsPaymentMethodAllowed) datasource.push({ text: method.label, value: method.code });
    });
    return datasource;
  }

  init() {
    // Queues table interface
    this.tableInterface = new TableInterface({
      name: 'pending-queues-table',
      client: this.api,
      query: 'status=ACCEPTANCE'
    });
    this.tableInterface.initialize();

    // Queues Search interface
    this.searchInterface = new SearchInterface({
      name: 'pending-queues-table-search',
      table: this.tableInterface,
      schema: {
        createdAtGte: {
          label: 'Spedizione creata dal',
          control: 'date',
          query: 'created-at-gte'
        },
        createdAtLte: {
          label: 'al',
          control: 'date',
          query: 'created-at-lte'
        },
        totalGrossGte: {
          label: 'Importo minimo',
          control: 'number',
          query: 'total-gross-gte'
        },
        totalGrossLte: {
          label: 'Importo massimo',
          control: 'number',
          query: 'total-gross-lte'
        },
        context: {
          label: 'Quali spedizioni visualizzare?',
          control: 'combo',
          required: true,
          datasource: this.setContextDatasource()
        },
        recipient: {
          label: 'Destinatario',
          control: 'text',
          query: 'recipient-text-search-like'
        },
        owner: {
          control: 'combo',
          label: this.isOperator ? 'Utente' : mdt.parsers.contextualize(this.api.user, 'Utente'),
          datasource: {
            table: `${this.isOperator ? 'bo/users' : 'users/me/users'}?include=user-profiles`,
            query: { search: 'text-search', item: 'id-in' }
          },
          datavalue: 'id',
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false
        },
        paymentMethod: {
          label: 'Metodo di pagamento',
          control: 'combo',
          datasource: this.setPaymentMethodDatasource(),
          query: 'payment-method'
        },
        shortConfirmationCode: {
          control: 'combo',
          label: 'Codice di conferma pagamento',
          datasource: {
            table: this.api.hasRole('LIST_ANY_QUEUE') ? 'bo/queue-outlines?status=ACCEPTANCE' : 'users/me/queue-outlines/~all?status=ACCEPTANCE',
            query: { search: 'short-confirmation-code', item: 'short-confirmation-code' }
          },
          datavalue: 'shortConfirmationCode',
          datatext: 'shortConfirmationCode',
          datamultiple: false,
          datapreload: false,
          query: 'short-confirmation-code'
        },
        shipment: {
          label: 'Spedizione',
          control: 'text',
          query: 'text-search-like'
        },
      },
      data: {
        totalGrossGte: 0,
        paymentMethod: 'null',
        context: 'USER&SUBUSERS'
      }
    });

    this.searchInterface.data = {
      ...this.searchInterface.data,
      ...(this.isOperator && { createdAtGte: this.setDefaultDateRange(60) }),
      ...(this.isOperator && { createdAtLte: this.setDefaultDateRange() }),
   }

    this.searchInterface.initialize()
      .then(() => { if (this.isOperator) this.changeContext(true); });
  }

  async search() {
    this.pendingSearch = true;
    this.tableInterface.endpoint = this.setEndpoint();
    return this.searchInterface.search()
      .then(() => { if (!this.isOperator) this.badgeUpdate(); })
      .finally(() => { this.pendingSearch = false; });
  }

  async badgeUpdate() {
    const xhr = await this.api.get(`users/me/queue-outlines/~all?status=ACCEPTANCE`);
    return localStorage.setItem('queue-outlines', JSON.stringify({ totalQueuesPendingPayment: xhr.originalResponse.meta.total }));
  }

  async reset() {
    return this.searchInterface.reset(true)
      .then(() => {
        const data = structuredClone(this.searchInterface.data);
        data.totalGrossGte = 0;
        data.paymentMethod = 'null';
        this.searchInterface.data = data;
      })
      .finally(() => {
        this.changeContext(true);
      });
  }

  userConfirmOrder(record, $event) {
    if ($event) $event.model.busy = true;
    this.api.get(`users/me/cross-login-tokens/current`).then(xhr => {
      let token = xhr.response.token;
      this.dialogService.open({
        viewModel: PLATFORM.moduleName('views/app/dialog-senderui'),
        model: { iframe: `/senderui/?owner=${record.owner.id}&ts=${token}#/costs/${record.owner.id}/${record.uuid}` },
        lock: true
      }).whenClosed(() => {
        if ($event) $event.model.busy = false;
        this.search();
      });
    }).catch((xhr) => {
      console.error('cross-login-tokens/current', xhr);
      if ($event) $event.model.busy = false;
    }).finally(() => {
      if ($event) setTimeout(() => { $event.model.busy = false; }, 5000);
    });
  }

  backofficeConfirmOrder($event, record) {
    $event.model.busy = true;
    this.dialog.open({
      title: 'Attenzione!',
      class: 'small',
      type: 'confirm',
      body: `Sei sicuro di voler confermare l'ordine?`
    }).whenClosed(response => {
      if (!response.wasCancelled) {
        const data = { status: "PAYMENT-CONFIRMED" };
        this.api.patch(`bo/users/${record.owner.id}/orders/queues/${record.uuid}/current`, data).then(() => {
          this.search();
          this.toast.show(`Ordine confermato con successo!`, 'success');
        }).catch(xhr => {
          console.log('Error - PATCH orders/queues', xhr);
          this.toast.show(`Errore durante l'apertura della spedizione!`, 'error');
        }).finally(() => {
          $event.model.busy = false;
        });
      } else $event.model.busy = false;
    });
  }

  isThereAPrececkout(record, reason) {
    let messageHtml;
    if (reason === 'edit') messageHtml = `<p class="m-0 p-0">La seguente operazione <strong>farà tornare la spedizione allo stato di bozza, cancellando l'attuale spedizione in attesa di pagamento.</strong> Desideri procedere?</p>`;
    if (reason === 'delete') messageHtml = `<p class="m-0 p-0">La spedizione sarà cancellata <strong>definitivamente</strong>. Sei sicuro di voler procedere alla cancellazione?</p>`;
    this.api.get(`users/${record.owner.id}/queues/${record.uuid}/pre-checkout/current`).then(xhr => {
      if (xhr.response.confirmedAt) {
        if (xhr.response.paymentMethod === 'BANK-TRANSFER') return this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'alert', body: `<p class="m-0 p-0">L'operatore ha già confermato la ricezione del pagamento. <strong>La spedizione non può più essere cancellata o modificata.</strong> Potrai comunque annullarla dalla sezione Reportistica.</p>` });
        else return this.toast.show('Spedizione che presenta anomalie!', 'error');
      } else {
        if (xhr.response.paymentMethod === 'BANK-TRANSFER') messageHtml = `<p class="m-0 p-0 mb-3"><strong style="color: #ed5a23">Attenzione!</strong> Hai impostato il pagamento tramite bonifico per questa spedizione. Ti ricordiamo di annullare qualsiasi pagamento.</p>${messageHtml}`;
        if (reason === 'edit') return this.editQueue(record, messageHtml);
        if (reason === 'delete') return this.deleteQueue(record, messageHtml);
      }
    }).catch(xhr => {
      if (xhr.statusCode === 404) {
        if (reason === 'edit') return this.editQueue(record, messageHtml);
        if (reason === 'delete') return this.deleteQueue(record, messageHtml);
      } else {
        console.error('Error - GET pre-checkout/current', xhr);
        return this.toast.show('Spedizione che presenta anomalie!', 'error');
      }
    });
  }

  // !IMPORTANT this only changes the status of the queue
  deleteQueue(record, messageHtml) {
    this.dialog.open({
      title: 'Cancella spedizione',
      class: 'small',
      type: 'confirm',
      body: messageHtml
    }).whenClosed(response => {
      if (!response.wasCancelled) {
        this.api.patch(`users/${record.owner.id}/queues/${record.uuid}`, { status: 'ABORTED-BY-USER' }).then(() => {
          this.search();
          this.toast.show('Spedizione eliminata con successo!', 'success');
        }).catch(xhr => {
          console.error('Error - PATCH queue status', xhr);
          this.api.dialogError(xhr);
        });
      }
    });
  }

  // !IMPORTANT this permanently deletes the queue and returns to its draft
  editQueue(record, messageHtml) {
    this.dialog.open({
      title: 'Modifica spedizione',
      class: 'medium',
      type: 'confirm',
      body: messageHtml
    }).whenClosed((response) => {
      if (!response.wasCancelled) {
        this.api.delete(`users/${record.owner.id}/queues/${record.uuid}`).then(() => {
          this.search();
          this.api.get(`users/me/cross-login-tokens/current`).then(xhr => {
            let token = xhr.response.token;
            this.dialogService.open({
              viewModel: PLATFORM.moduleName('views/app/dialog-senderui'),
              model: { iframe: `/senderui/?owner=${record.owner.id}&draft=${record.draftUuid}&ts=${token}#/recipients` },
              lock: true
            });
          }).catch(xhr => {
            console.error('Error - GET cross-login-tokens/current', xhr);
            this.toast.show('Spedizione che presenta anomalie!', 'error');
          })
        }).catch(xhr => {
          console.error('Error - DELETE queues', xhr);
          this.api.dialogError(xhr);
        });
      }
    }
    );
  }

  bankTransferData(queue) {
    this.dialog.open({
      title: `Pagamento`,
      class: 'medium bank-transfer-data',
      viewModel: PLATFORM.moduleName('views/spedizioni/dialogs/bank-transfer-data'),
      viewModelParams: { queue }
    }, true);
  }

  copyText(text, item) {
    navigator.clipboard.writeText(text);
    this.toast.show(`${item} copiato con successo!`, 'success');
  }

  setDefaultDateRange(days) {
    // If "days"(integer), subtracts days from today's date.
    const currentDate = new Date();

    if (days) {
      currentDate.setDate(currentDate.getDate() - days);
    }
    return currentDate.toISOString();
  }

  updatePayment(record) {
    if (!record.loading) {
      record.loading = true;
      const queueUuid = record.uuid;
      const queueOwnerId = record.owner?.id;
      this.api.get(`users/${queueOwnerId}/queue-outlines/${queueUuid}`).then((xhr) => {
        const response = xhr.response;
        Object.assign(record, response);
        if (response.status === 'ACCEPTANCE') {
          return this.router.navigate(`spedizioni/reportistica?ka-tab-main=inviate`);
        }
        this.toast.show('Stato del pagamento aggiornato!', 'success');
      }).catch((error) => {
        console.log('GET queue-outlines ', error);
        this.toast.show('Errore durante l\'aggiornamento!', 'error');
      }).finally(() => {
        setTimeout(() => {
          record.loading = false;
        }, 3500);
      });
    }
  }
}
