import {inject} from 'aurelia-framework';
import {Api} from 'services/api';

@inject(Api)
export class Logout {

  constructor(api) {
    api.logout();
  }
}
