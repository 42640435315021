import {inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import mdt from 'services/md-tools';

@inject(Api, KaDialog, KaToast)
export class DialogIntentsProvideCertifiedDocuments {
  mdt = mdt;
  uploadSessionUuid = null;

  constructor(api, dialog, toast) {
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    this.documents = this.parseDocuments(this.params.documents);
    sessionStorage.setItem(btoa(`intents-reload`), JSON.stringify({ reload: false }));
  }

  parseDocuments(documents) {
    let _documents = [];
    documents.forEach(document => {
      // Translate label
      document.label = codesTranslator.tr(document.code);

      // Create an array with no duplicates
      for (let i = 0; i < document.errors.MISSING ; i++) {
        this.hasMissingDocuments = true;
        let missingDocument = Object.assign({ _errors: { MISSING: true, PENDING: false }}, document);
        _documents.push(missingDocument);
      }
      for (let i = 0; i < document.errors.PENDING ; i++) {
        this.hasPendingDocuments = true;
        let pendingDocument = Object.assign({ _errors: { MISSING: false, PENDING: true }}, document);
        _documents.push(pendingDocument);
      }
    });
    return _documents;
  }

  edit(document) {
    document.resource = new DocumentResource(this.api, this.dialog);
    document.resource.schema.file.label = document.label;
    document.edit = true;
  }

  save(document, $event) {
    document.resource.validate().then(() => {
      $event.model.busy = true;
      this.uploadSession().then(() => {
        this.upload(document.resource.data.file[0]).then(file => {
          let data = { uploadedFileUuid: file.id, documentType: document.code.match(/\[(.*)\]/)[1] }
          if (document.placeholder) data.placeholder = document.placeholder;
          this.api.post(`users/${this.params.userId}/certifiable-document-buckets/current/certifiable-documents`, data).then(() => {
            document.saved = true;
            document.edit = false;
            sessionStorage.setItem(btoa(`intents-reload`), JSON.stringify({ reload: false }));
            this.toast.show(`Documento caricato con successo!`, 'success');
          }).catch(error => {
            console.log('ERROR - certifiable-documents - POST', error);
            document.resource.error(error);
          }).finally(() => {
            $event.model.busy = false;
          });
        });
      });
    });
  }

  uploadSession() {
    return this.api.get(`users/${this.params.userId}/certifiable-document-buckets/current`).then(xhr => {
      this.uploadSessionUuid = xhr.response.uploadSessionUuid;
    }).catch(error => {
      console.log('ERROR - certifiable-document-buckets/current GET', error);
      this.toast.show(`Errore durante il caricamento!`, 'error');
    });
  }

  upload(file) {
    let endpoint = `users/${this.params.userId}/upload-sessions/${this.uploadSessionUuid}/uploaded-files`;
    return new Promise((resolve, reject) => {
      let attachment = {};
      new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          attachment.filename = file.name;
          attachment.fileData = reader.result;
          resolve(true);
        };
        reader.onerror = error => { reject(error); };
      }).then(() => {
        this.api.post(endpoint, attachment).then(xhr => {
          // Build object attachment
          attachment.mimeType = file.type;
          attachment.size = file.size;
          attachment.id = xhr.response.id;
          delete attachment.fileData;
          resolve(attachment);
        }).catch(error => {
          console.log('ERROR - uploaded-files POST', error);
          this.toast.show(`Errore durante il caricamento!`, 'error');
          reject()
        });
      }).catch(error => {
        console.log('ERROR - uploaded files PROMISE', error);
        this.toast.show(`Errore durante il caricamento!`, 'error');
        reject()
      });
    });
  }

  downloadStaticResource(record) {
    const filename = record.templateFileName;
    const anchor = document.createElement('a');
    anchor.download = `${record.templateFileName}`;
    anchor.href = `/static/downloads/${filename}`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    this.toast.show('File scaricato con successo', 'success');
  }
}

export class DocumentResource {
  controls = {};
  schema = {
    file: {
      label: null,
      control: 'file',
      required: true
    }
  };
  data = {};

  constructor(api, dialog) {
    this.api = api;
    this.dialog = dialog;
  }

  validate() {
    let promises = [];
    Object.values(this.controls).forEach(control => {
      console.log(control);
      promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
    });
    return Promise.all(promises);
  }
  
  error(error) {
    this.api.dialogError(error, this.controls, ['documentType']);
  }
}
export const codesTranslator = {
  codes: {
    'BANK-ACCOUNT-OWNERSHIP-CERT' : {
      'IT' : 'Attestazione titolarità del conto corrente',
      'EN' : 'Bank account ownership certification'
    },
    'COMPANY-REPORT' : {
      'IT' : 'Visura camerale',
      'EN' : 'Company report'
    },
    'FISCAL-CODE-CERTIFICATION' : {
      'IT' : 'Certificato attribuzione codice fiscale',
      'EN' : 'Fiscal code certification'
    },
    'ID-CARD' : {
      'IT' : 'Carta d\'identità',
      'EN' : 'Identity card'
    },
    'SN-CARD' : {
      'IT' : 'Codice fiscale o tessera sanitaria',
      'EN' : 'Security number card'
    },
    'SUBUSER-MANDATE' : {
      'IT' : 'Certificazione attribuzione mandato',
      'EN' : 'Subuser mandate'
    },
    'VAT-CODE-CERTIFICATION' : {
      'IT' : 'Certificazione attribuzione partita iva',
      'EN' : 'Vat code attribution certificate'
    },
    'MAV-VECTOR-CONTRACT' : {
      'IT' : 'Accordo tra MultiDialogo e Banca Popolare di Sondrio',
      'EN' : 'Agreement between MultiDialogo and Banca Popolare di Sondrio'
    }
  },
  tr: (string) => {
    let lang = string.match(/\.(.*)\./)[1];
    let code = string.match(/\[(.*)\]/)[1];
    if (lang && code && codesTranslator.codes[code] && codesTranslator.codes[code][lang]) return codesTranslator.codes[code][lang];
    else return string;
  }
}
