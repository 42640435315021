import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { KaToast, KaDialog } from 'ka-components';
import { DialogService } from 'aurelia-dialog';
import { TableInterface, SearchInterface } from 'classes/md-table';
import mdt from 'services/md-tools';

@inject(Router, Api, KaToast, KaDialog, DialogService)
export class ViewPending {
  mdt = mdt;
  context = null;
  select = {
    recordsSelected: {},
    someRecordsSelected: false,
    allRecordsSelected: false,
    someRecords: (record) => {
      this.select.recordsSelected[record.id] = !this.select.recordsSelected[record.id];
      this.select.allRecordsSelected = Object.values(this.select.recordsSelected).every(x => x === true);
      this.select.someRecordsSelected = Object.values(this.select.recordsSelected).some(x => x === true);
    },
    allRecords: () => {
      this.select.allRecordsSelected = !this.select.allRecordsSelected;
      this.select.someRecordsSelected = this.select.allRecordsSelected;
      Object.keys(this.select.recordsSelected).forEach((key) => {
        this.select.recordsSelected[key] = this.select.allRecordsSelected ? true : false;
      });
    },
    parseRecordsSelected: (stack, records) => {
      Object.keys(stack).forEach(key => {
        if (!records.find(x => x.id === key)) {
          delete stack[key];
          delete stack.__observers__[key];
        }
      });
      return stack;
    }
  }

  constructor(router, api, toast, dialog, dialogService) {
    this.router = router;
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
    this.dialogService = dialogService;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    this.updateCounter();
    let sessionContext = JSON.parse(sessionStorage.getItem(btoa('queue-drafts-table-search-data')))?.context || 'USER';
    this.context = sessionContext === 'USER' ? '' : (sessionContext === 'SUBUSERS' ? '~subs' : '~all');

    // Queues table interface
    this.tableInterface = new TableInterface({
      name: 'queue-drafts-table',
      client: this.api,
      endpoint: null,
      query: 'statusCode-in=ACTIVE'
    });
    this.tableInterface.initialize().catch(error => {
      return console.log('tableInterface initialization failed', error);
    });

    // Queues Search interface
    this.searchInterface = new SearchInterface({
      name: 'queue-drafts-table-search',
      table: this.tableInterface,
      schema: {
        createdAtGte: {
          label: 'Spedizione creata dal',
          control: 'date',
          query: 'created-at-gte'
        },
        createdAtLte: {
          label: 'al',
          control: 'date',
          query: 'created-at-lte'
        },
        totalGrossGte: {
          label: 'Importo minimo',
          control: 'number',
          query: 'total-gross-gte'
        },
        totalGrossLte: {
          label: 'Importo massimo',
          control: 'number',
          query: 'total-gross-lte'
        },
        context: {
          label: 'Quali spedizioni visualizzare?',
          control: 'combo',
          required: true,
          datasource: [
            {
              value: 'USER&SUBUSERS',
              text: `Tutte`,
            },
            {
              value: 'USER',
              text: `Le spedizioni del mio ${mdt.parsers.contextualize(this.api.user, 'account')}`,
            },
            {
              value: 'SUBUSERS',
              text: `Le spedizioni dei miei ${mdt.parsers.contextualize(this.api.user, 'utenti')}`,
            }
          ]
        },
        recipient: {
          label: 'Destinatario',
          control: 'text',
          query: 'search-recipients-text'
        },
        owner: {
          control: 'combo',
          label: mdt.parsers.contextualize(this.api.user, 'Utente'),
          datasource: {
            table: 'users/me/users?include=user-profiles',
            query: { search: 'text-search', item: 'id-in' }
          },
          datavalue: 'id',
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false
        },
        payment_method: {
          label: 'Metodo di pagamento',
          control: 'combo',
          datasource: [
            { value: 'SDD', text: 'SDD' },
            { value: null, text: 'Qualsiasi' },
          ],
          query: 'payment-method'
        },
        send_code: {
          label: 'Codice spedizione',
          control: 'text',
          query: 'sendcode'
        },
        short_confirmation_code: {
          label: 'Codice di conferma pagamento',
          control: 'text',
          query: 'short-confirmation-code'
        },
        topic: {
          label: 'Promemoria',
          control: 'text',
          query: 'topic'
        }
      },
      data: {
        context: 'USER&SUBUSERS'
      }
    });

    this.tableInterface.parseResponse = (response) => {
      // I remove from the recordsSelected the records that are not actually displayed
      this.select.recordsSelected = this.select.parseRecordsSelected(this.select.recordsSelected, response);
      this.select.someRecordsSelected = this.select.allRecordsSelected = false;
      return response;
    };

    this.searchInterface.initialize().then(() => {
      this.searchInterface.data.totalGrossGte = 0;
      return this.search();
    }).catch(error => {
      return console.log('searchInterface initialization failed', error);
    });
  }

  async search() {
    this.setEndpoint();
    return this.searchInterface.search();
  }

  setEndpoint() {
    let contextSuffix = this.searchInterface?.data?.context === 'USER' ? '' :
      this.searchInterface?.data?.context === 'SUBUSERS' && !this.searchInterface.data.owner ? '~subs' :
        this.searchInterface?.data?.context === 'SUBUSERS' && this.searchInterface.data.owner ? '' : '~all';

    this.searchInterface.data.owner = this.searchInterface?.data?.context !== 'SUBUSERS' ? null : this.searchInterface.data.owner;
    let endpoint = `users/${this.searchInterface.data.owner || 'me'}/queue-drafts-outlines/${contextSuffix}`;
    return this.tableInterface.endpoint = endpoint;
  }

  updateCounter() {
    return this.api.get(`users/me/queue-drafts-outlines/~all?limit=1&statusCode-in=ACTIVE`).then((xhr) => {
      localStorage.setItem('queue-drafts-outlines', JSON.stringify({ totalQueueDrafts: xhr.originalResponse.meta.total }));
    });
  }

  reset() {
    this.searchInterface.reset();
    this.searchInterface.data.context = 'USER&SUBUSERS';
    this.search();
  }

  copyText(text, item) {
    navigator.clipboard.writeText(text);
    this.toast.show(`${item} copiato con successo!`, 'success');
  }

  // !IMPORTANT this permanently deletes the queue and returns to its draft
  editDraft(record) {
    this.api.get(`users/me/cross-login-tokens/current`).then(xhr => {
      let token = xhr.response.token;
      this.dialogService.open({
        viewModel: PLATFORM.moduleName('views/app/dialog-senderui'),
        model: { iframe: `/senderui/?owner=${record.owner.userId}&draft=${record.id}&ts=${token}#/recipients` },
        lock: true
      }).whenClosed(() => {
        this.updateCounter().then(() => { this.search(); });
      });
    }).catch(error => {
      console.error('cross-login-tokens/current', error);
      this.toast.show(`Errore durante l'apertura della bozza!`, 'error');
    })
  }

  deleteDraft(record) {
    this.dialog.open({
      title: 'Elimina bozza',
      class: 'large',
      type: 'confirm',
      body: '<p class="m-0 p-0">La bozze verrà eliminata.<br><strong>Vuoi procedere?</strong></p>'
    })
      .whenClosed(response => {
        if (!response.wasCancelled) {
          this.api.delete(`users/${record.owner.userId}/queue-drafts/${record.id}`).then(() => {
            this.updateCounter().then(() => { this.search(); });
            this.toast.show('Bozza eliminata con successo!', 'success');
          }).catch(error => {
            console.error('Error DELETE queue-drafts', error);
            this.api.dialogError(error);
          });
        }
      });
  }

  deleteSelectedDrafts($event) {
    $event.model.busy = true;
    this.dialog.open({
      title: 'Elimina bozze',
      class: 'large',
      type: 'confirm',
      body: '<p class="m-0 p-0">Le bozze selezionate verranno eliminate.<br><strong>Vuoi procedere?</strong></p>'
    })
      .whenClosed(response => {
        if (!response.wasCancelled) {
          let promises = [];
          let selectedDrafts = Object.entries(this.select.recordsSelected).filter(x => x[1] === true);
          selectedDrafts.forEach(selected => {
            let draft = this.tableInterface.data.find(x => x.id === selected[0]) || null;
            if (draft) promises.push(this.api.delete(`users/${draft.owner.userId}/queue-drafts/${draft.id}`));
          });

          Promise.all(promises).then(() => {
            this.toast.show('Bozze eliminate con successo!', 'success');
            this.updateCounter().then(() => { this.search().finally(() => { $event.model.busy = false; }); });
          }).catch(error => {
            console.error('Error DELETE queue-drafts', error);
            $event.model.busy = false;
            this.toast.show('Errore in fase di eliminazione bozze!', 'error');
          });
        } else $event.model.busy = false;
      })
  }
}
