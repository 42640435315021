import {inject, bindable} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import mdt from 'services/md-tools';
import {KaToast, KaDialog} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';
import environment from 'environment';

@inject(Api, KaToast, KaDialog)
export class ImpostazioniSms {
  @bindable() prefsel = null;
  environment = environment;
  dataset = {
    smsAliases: {}
  };
  
  constructor(api, toast, dialog) {
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
  }
 
  attached() {
    this.smsAliases.tableInterface = new TableInterface({ name: 'preferences-sms-aliases-table', client: this.api, endpoint: null, query: null });
    this.smsAliases.tableInterface.initialize();
  }

  prefselChanged(value, old) {
    if (value === old) return;
    console.log('PREFSEL changed', value);
    setTimeout(() => {
      this.preferences.isOverride = null;
      this.preferences.reload();
      this.smsAliases.reload();
    }, 0);
  }

  preferences = {
    endpoint: null,
    schema: {
      type: null,
      display: { control: 'combo', label: 'Tipo visualizzazione mittente', datasource: [{value: 'NUMBER', text: 'Numero'}, {value: 'ALIAS', text: 'Alias'}], datavalue: 'value', datatext: 'text', datapreload: true },
      prefix: { control: 'combo', label: 'Prefisso', datasource: [{value: '+39', text: '+39'}], datavalue: 'value', datatext: 'text', datapreload: true },
      numberUuid: {control: 'combo', label: 'Numero di cellulare', datasource: {}, datatext: 'number', datavalue: 'uuid', datamultiple: false, datapreload: true },
      aliasUuid: { control: 'combo', label: 'Alias', datasource: {}, datatext: 'displayName', datavalue: 'uuid', datamultiple: false, datapreload: true }
    },
    data: null,
    aliases: [],
    reload: () => {
      this.preferences.data = null;
      this.preferences.schema.numberUuid.datasource = { table: `users/${this.prefsel}/preferences/mobile-numbers?status=opted-in` }; 
      this.preferences.schema.aliasUuid.datasource = { table: `users/${this.prefsel}/preferences/sender/sms-aliases?statusCode-in=COMPLETED` }; 
      this.preferences.isCal = String(this.prefsel) !== String(this.api.user.id);
      this.api.get(`users/${this.prefsel}/preferences`).then(x => {
        if (this.preferences.isOverride === null) this.preferences.isOverride = (this.preferences.isCal && x.response.requested?.sender?.sms) || (!this.preferences.isCal && x.response.current?.sender?.sms);
        this.preferences.data = mdt.preferences.mergeModelData(this.preferences.schema, ['sender', 'sms'], x.response);
        this.preferences._data = JSON.parse(JSON.stringify(this.preferences.data));
      });
    },
    override: ($event) => {
      $event.model.busy = true;
      this.api.patch(`users/${this.prefsel}/preferences/sender/sms`, {enableOverride: !this.preferences.isOverride}).then(x => {
        this.preferences.isOverride = !this.preferences.isOverride;
      }).catch(e => {}).finally(() => {
        this.preferences.reload();
        $event.model.busy = false;
      });
    },
    save: ($event) => {
      $event.model.busy = true;
      let data = mdt.helpers.diffObject(this.preferences._data, this.preferences.data);
      if (data && Object.entries(data).length) {
        this.preferences.resource.save(data, `users/${this.prefsel}/preferences/sender/sms`, 'patch').then(x => {
          this.toast.show('Modifiche salvate con successo!', 'success');
          this.preferences.reload();
        }).catch(e => {
          this.toast.show('Errore nel salvataggio delle modifiche!', 'error');
        }).finally(() => {
          $event.model.busy = false;
        });
      }
      else $event.model.busy = false;
    }
  };
  smsAliases = {
    endpoint: null,
    schema: {
      displayName: {
        label: 'Mittente SMS',
        control: 'text'
      },
      companyName: {
        label: 'Azienda',
        control: 'text'
      },
      vatCode: {
        label: 'Partita IVA',
        control: 'text'
      },
      fiscalCode: {
        label: 'Codice fiscale',
        control: 'text'
      },
      streetAddress: {
        label: 'Indirizzo',
        control: 'text'
      },
      zipCode: {
        label: 'CAP',
        control: 'text'
      },
      admLvl3: {
        label: 'Città',
        control: 'text'
      },
      admLvl2: {
        label: 'Provincia',
        control: 'text'
      },
      country: {
        label: 'Nazione',
        control: 'combo',
        datasource: {
          table: 'geo/countries',
          query: {
            search: 'text-search',
            item: 'code'
          }
        },
        datatext: 'name',
        datavalue: 'code',
        datapreload: false
      },
      email: {
        label: 'Email',
        control: 'text'
      },
      pec: {
        label: 'PEC',
        control: 'text'
      },
      phoneNumber: {
        label: 'Telefono',
        control: 'text'
      },
      requestedAt: {
        label: 'Data richiesta',
        control: 'date',
        datatype: 'datetime',
        utc: true
      },
      status: {
        label: 'Stato attivazione',
        control: 'combo',
        datasource: [{
          text: 'In attesa di registrazione',
          value: 'PENDING'
        }, {
          text: 'Richiesta registrata',
          value: 'REGISTERED'
        }, {
          text: 'Attivo',
          value: 'COMPLETED'
        }],
        datatext: 'text',
        datavalue: 'value',
        datapreload: true
      }
    },
    data: {},
    tableInterface: null,
    toggle: () => {
      this.smsAliases.isToggle = !!!this.smsAliases.isToggle || false;
      if (this.smsAliases.isToggle) {
        this.smsAliases.data.companyName = String(this.prefsel) === String(this.api.user.id) ? this.api.user.displayName : this.prefSelector.data?.displayName || null;
        this.smsAliases.data.displayName = String(this.prefsel) === String(this.api.user.id) ? this.api.user.displayName : this.prefSelector.data?.displayName || null;
        this.smsAliases.data.country = 'Italy';
      }
    },
    reset: () => {
      Object.keys(this.smsAliases.data).forEach(k => this.smsAliases.data[k] = null);
    },
    reload: () => {
      this.smsAliases.isCal = String(this.prefsel) !== String(this.api.user.id);
      this.smsAliases.endpoint = `users/${this.prefsel}/preferences/sender/sms-aliases`;
      this.smsAliases.tableInterface.endpoint = `users/${this.prefsel}/preferences/sender/sms-aliases`;
      if (this.smsAliases.tableInterface && !this.smsAliases.tableInterface.isLoading) this.smsAliases.tableInterface.load();
    },
    send: ($event) => {
      $event.model.busy = true;
      this.smsAliases.resource.save().then(() => {
        this.toast.show('Richiesta inviata con successo!', 'success');
        this.smsAliases.tableInterface.load();
        this.smsAliases.toggle();
        this.smsAliases.reset();
     }).finally(() => {
      $event.model.busy = false;
     });
    },
    delete: (record) => {
      console.log('RECORD', record);
      this.dialog.open({
        title: 'Attenzione!',
        class: 'small',
        type: 'confirm',
        body: 'Confermi di voler eliminare l\'Alias sms selezionato selezionato?'
      }, true).whenClosed(response => {
        if (!response.wasCancelled) {
          this.api.delete(`${this.smsAliases.endpoint}/${record.id}`).then(() => {
            this.toast.show('Record eliminato con successo!', 'success');
            this.smsAliases.tableInterface.load();
          }).catch(x => {
            this.api.dialogError(x);
          });
        }
      });
    }
  };
}
