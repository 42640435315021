import {Aurelia, inject, observable} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import {TableInterface, SearchInterface} from 'classes/md-table';
import mdt from 'services/md-tools';

@inject(Aurelia, Router, Api, KaDialog, KaToast)
export class vmElencoNotificheUtenti {
  mdt = mdt;
  pending = null;
  subusers = null;
  _subusers = null;
  @observable() searchSubuser = null;

  constructor(aurelia, router, api, dialog, toast) {
    this.aurelia = aurelia;
    this.router = router;
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
  }

  activate(model) {
    this.params = model.params;
    this.privileges = model.privileges;
    this.tools = model.tools;

    // INIT notifications TableInterface
    this.userNotificationsTableInterface = new TableInterface({
      name: 'userNotificationsTableInterface',
      client: this.api,
      endpoint: null
    });
    this.userNotificationsTableInterface.initialize().catch(error => { console.log('userNotificationsTableInterface initialization failed', error) });

    // INIT SearchInterface
    this.userNotificationsSearchInterface = new SearchInterface({
      name: 'userNotificationsSearchInterface',
      table: this.userNotificationsTableInterface,
      schema: {
        readBy: {
          control: 'combo',  
          label: 'Stato di lettura della notifica', 
          datasource: [{ value: 'null', text: 'Tutte le notifiche' }, { value: 'NOT-ME', text: 'Da leggere' }],
          datavalue: 'value', 
          datatext: 'text', 
          datapreload: true,
          datamultiple: false,
          query: 'read-by'
        },
        user: {
          label: 'Utente',
          control: 'combo',
          datasource: { table: 'bo/users?include=user-profiles', query: { search: 'text-search', item: 'id-in' }},
          datavalue: 'id',
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false,
          required: false
        }
      },
      data: {
        readBy: 'NOT-ME',
        user: null
      }
    });
    this.userNotificationsSearchInterface.initialize().then(() => {
      this.tools.getAlerts().then(alerts => {
        this.subusers = [...alerts];
        this.user = JSON.parse(localStorage.getItem(btoa('ui-notifications-user'))) || (this.privileges.isOperator ? { legacyOwnerId: null, ownerDisplayName: null } : null);
        this.searchSubuser = localStorage.getItem(btoa('ui-notifications-subuser')) ? JSON.parse(localStorage.getItem(btoa('ui-notifications-subuser'))).searchSubuser : null;
        if (!this.privileges.isOperator && this.user) this.setSubuser();
        else this.pending = false;
      });
    }).catch(error => { 
      console.log('searchInterface initialization failed', error);
    });
  }

  searchSubuserChanged(value) {
    let oldSelectedSubuser = document.querySelector('.subusers > .selected');
    oldSelectedSubuser?.classList.remove('selected');
    this._subusers = value ? this.subusers.filter(x => x.ownerDisplayName.toLowerCase().includes(value.toLowerCase())) : this.subusers;
    localStorage.setItem(btoa('ui-notifications-subuser'), JSON.stringify({ searchSubuser: value || null }));

    if (this._subusers.filter(x => x?.legacyOwnerId === this.user?.legacyOwnerId).length) {
      setTimeout(() => {
        let newSelectedSubuser = this.user?.legacyOwnerId ? document.getElementById(this.user.legacyOwnerId) : null;
        newSelectedSubuser?.classList.add("selected");
      }, 0);
    }
  }

  searchUser() {
    if (this.userNotificationsSearchInterface.data.user) {
      this.user = { legacyOwnerId: this.userNotificationsSearchInterface.data.user, ownerDisplayName: null };
      this.tools.getUser(this.user).then(() => {
        this.pending = true;
        this.getNotifications();
        this.user = JSON.parse(localStorage.getItem(btoa('ui-notifications-user'))) || null;
      }).catch(() => { 
        this.pending = false;
      });
    } else {
      this.pending = true;
      setTimeout(() => {
        this.user = { legacyOwnerId: null, ownerDisplayName: null };
        localStorage.setItem(btoa('ui-notifications-user'), JSON.stringify(this.user));
        this.userNotificationsTableInterface.data = [];
        this.pending = false;
      }, 200);
    }
  }

  setSubuser(user) {
    // Select subuser
    setTimeout(() => {
      let oldSelectedSubuser = document.querySelector('.subusers > .selected');
      let newSelectedSubuser = null;
      if (user) newSelectedSubuser = document.getElementById(user.legacyOwnerId);
      else if (this.user) newSelectedSubuser = document.getElementById(this.user.legacyOwnerId);
      oldSelectedSubuser?.classList.remove('selected');
      newSelectedSubuser?.classList.add("selected");
    }, 0);

    if (user) {
      this.tools.getUser(user).then(() => {
        this.user = JSON.parse(localStorage.getItem(btoa('ui-notifications-user'))) || null;
        this.getNotifications();
      });
    } else {
      this.getNotifications();
    }
  }

  readByChanged() {
    this.pending = true;
    this.getNotifications();
  }

  getNotifications() {
    if (!this.user || !this.user.legacyOwnerId) return this.pending = false;
    this.userNotificationsTableInterface.endpoint = `users/${this.user.legacyOwnerId}/ui-notifications`;
    return this.userNotificationsSearchInterface.search().catch(error => {
      console.log('ERROR searchInterface search', error);
      this.toast.show('Errore durante il caricamento delle notifiche!', 'error');
    }).finally(() => {
      this.pending = false;
    });
  }

  preview(notification, $event) {
    if (!notification.readByMeAt) $event.model.busy = true;
    this.tools.preview(notification, this.user).then(load => {
      this.pending = true;
      $event.model.busy = false;
      if (load) {
        this.tools.getAlerts().then(alerts => {
          this.subusers = [...alerts];
          this.getNotifications();
        }).catch(() => { 
          this.pending = false;
         });
      } else {
        this.pending = false;
      }
    }).catch(() => { 
      $event.model.busy = false;
    });
  }

  notificationsReadStatus(notification) {
    this.pending = true;
    this.tools.notificationsReadStatus(notification, this.user).then(() => {
      this.tools.getAlerts().then(alerts => {
        this.subusers = [...alerts];
        this.getNotifications();
      }).catch(() => { this.pending = false; });
    }).catch(() => { this.pending = false; });
  }

  setAllAsRead(userNotificationsTableInterface) {
    this.pending = true;
    this.tools.setAllAsRead(userNotificationsTableInterface).finally(() => {
      this.tools.getAlerts().then(alerts => {
        this.subusers = [...alerts];
        this.getNotifications(); 
      }).catch(() => { this.pending = false; });
    }).catch(() => { this.pending = false; });
  }

  takeOver(notification, $event) {
    this.pending = true;
    $event.model.busy = true;
    this.tools.takeOver(notification).then(() => {
      this.tools.getAlerts().then(() => { 
        this.getNotifications().then(() => {
          $event.model.busy = false;
        }).catch(() => { 
          this.pending = false;
          $event.model.busy = false;
        });
      }).catch(() => { 
        this.pending = false;
        $event.model.busy = false;
      });
    }).catch(() => { 
      this.pending = false;
      $event.model.busy = false;
    });
  }
}
