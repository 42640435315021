import {inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {Toolbar} from '../app/app-toolbar';

@inject(Api, Toolbar)
export class Impostazioni {

  constructor(api, toolbar) {
    this.api = api;
    this.modules = toolbar.items.find(x => x.settings === true).nav;
  }

  attached() {
  }
}
