import { PLATFORM, inject } from 'aurelia-framework';
import { Api } from 'services/api';
import { KaToast } from 'ka-components';

@inject(Api, KaToast)
export class vmPromozioni {
  tabs = [
    {
      id: 'assegnate',
      label: 'Assegnazione promozioni',
      vm: PLATFORM.moduleName('views/servizi/promozioni/tabs/promozioni-assegnate')
    }, {
      id: 'gestione',
      label: 'Gestione promozioni',
      vm: PLATFORM.moduleName('views/servizi/promozioni/tabs/promozioni-gestione')
    }
  ];
  tools = {
    copyText: (text, context = 'Testo') => {
      if (!text) return;
      navigator.clipboard.writeText(text);
      this.toast.show(`${context} copiato con successo!`, 'success');
    }
  }

  constructor(api, toast) {
    this.api = api;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    this.privilege();
  }

  privilege() {
    this.privileges = { tabs: { assegnate: true, gestione: false }, buttons: {} };
    this.privileges.isOperator = this.api.hasRole('LIST_ANY_USER_ASSOCIATED_PROMOTION');

    if (this.api.hasRole('LIST_PROMOTIONS')) {
      this.privileges.tabs.gestione = true;
    }
  }
}
