import { Api } from 'services/api';
import { DialogController } from 'aurelia-dialog';

export class DialogMessageStatuses {
  message;
  isLoading;
  messageStatuses = {};

  static inject = [Api, DialogController];
  constructor(api, controller) {
    this.api = api;
    this.controller = controller;
  }

  activate(params) {
    this.message = params.message;
  }

  attached() {
    this.init();
  }

  init() {
    this.isLoading = true;
    return this.getMessageStatuses();
  }

  getMessageStatuses() {
    return this.api.get(`queue-message-status-codes`).then(xhr => {
      let response = xhr.response;
      response.forEach(status => { this.messageStatuses[status.code] = status; });
    }).catch(xhr => {
      console.error('Error - GET queue-messages-statuses', xhr);
      this.api.dialogError(xhr);
      this.controller.cancel();
    }).finally(() => {
      this.isLoading = false;
    });
  }
}
