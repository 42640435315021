import {inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import mdt from 'services/md-tools';

@inject(Api, KaDialog, KaToast)
export class DialogIntentsProvideLegalForm {
  mdt = mdt;
  pending = true;
  legalForms = null;
  legalFormTypes = null;
  data = {};
  schema = {
    companyLegalFormCode: {
      label: 'Forma legale',
      control: 'combo',
      datatext: 'label',
      datavalue: 'code', 
      datamultiple: false,
      datapreload: true,
      datasource: null
    }
  }

  constructor(api, dialog, toast) {
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    sessionStorage.setItem(btoa(`intents-reload`), JSON.stringify({ reload: false }));

    // Build ka-componet datasource before opening the dialog
    let companyLegalFormCodeDatasource = null;
    let promises = [
      this.api.get('legal-form-types').then(xhr => { this.legalFormTypes = xhr.response; }),
      this.api.get('legal-forms').then(xhr => { this.legalForms = xhr.response; })
    ];

    // User display name
    if (this.params.userId !== this.api.user.id) promises.push(this.getUser());

    Promise.all(promises).then(() => {
      if (this.legalFormTypes?.length && this.legalForms?.length) {
        let optgroup = {};
        companyLegalFormCodeDatasource = [];
        this.legalFormTypes.forEach(type => {
          optgroup = { code: type.code, label: type.label, optgroup: true };
          companyLegalFormCodeDatasource.push(optgroup);
          let formsForThisType = this.legalForms.filter(x => x.type === type.code);

          if (formsForThisType.length) {
            formsForThisType.forEach(form => {
              form = { code: form.code, label: form.label };
              companyLegalFormCodeDatasource.push(form);
            });
          }
        });
        this.schema.companyLegalFormCode.datasource = companyLegalFormCodeDatasource;
      }
    }).catch(errors => {
      console.log(`ERRORS - promises GET`, errors);
      this.api.dialogError(errors);
    }).finally(() => {
      setTimeout(() => { this.pending = false; }, 500);
    });
  }

  getUser() {
    return new Promise((resolve, reject) => {
      this.api.get(`users/${user.legacyOwnerId}?include=user-profiles`).then(xhr => {
        this.data.userDisplayName = xhr.response.profile[0].displayName;
        return resolve();
      }).catch(() => {
        console.log('ERROR user-profile GET', error);
        this.toast.show(`Errore durante il caricamento!`, 'error');
        return reject();
      });
    }); 
  }

  save($event) {
    $event.model.busy = true;
    let data = { profile: this.data };
    let endpoint = this.params.parentId ? `users/me/users/${this.params.userId}` : `users/${this.params.userId}`;
    this.api.patch(endpoint, data).then(() => {
      this.params.saved = true;
      this.params.edit = false;
      sessionStorage.setItem(btoa(`intents-reload`), JSON.stringify({ reload: false }));
      this.toast.show(`Forma legale salvata con successo!`, 'success');
    }).catch(error => {
      console.log('ERROR users/me/users - PATCH', error);
      this.api.dialogError(error);
    }).finally(() => {
      $event.model.busy = false;
    });
  }
}
