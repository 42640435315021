import { inject, bindable } from 'aurelia-framework';
import { activationStrategy } from 'aurelia-router';
import { Api } from 'services/api';
import { MdDatasources } from 'services/md-datasources';
import mdt from 'services/md-tools';
import { KaToast, KaDialog } from 'ka-components';
import { TableInterface } from 'classes/md-table';
import environment from 'environment';

@inject(Api, MdDatasources, KaToast, KaDialog)
export class ImpostazioniPosta {
  @bindable() prefsel = null;
  envelopeLogoUuid;
  dataset = {
    postalOrders: {},
    watermarks: {}
  };
  data = {
    postalOrders: {},
    watermarks: {}
  };
  environment = environment;
  constructor(api, MdDatasources, toast, dialog) {
    this.api = api;
    this.mdDatasources = MdDatasources;
    this.toast = toast;
    this.dialog = dialog;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
    this.envelopeLogoUuid = params?.envelopeLogoUuid;
  }
 
  attached() {
    this.postalOrders.tableInterface = new TableInterface({ 
      name: 'preferences-postal-orders-table', 
      client: this.api, 
      endpoint: null, 
      query: null 
    });
    this.postalOrders.tableInterface.initialize();

    this.watermarks.tableInterface = new TableInterface({ 
      name: 'preferences-watermarks-table', 
      client: this.api, 
      endpoint: 'users/me/preferences/sender/letter/watermarks', 
      query: null 
    });
    this.watermarks.tableInterface.initialize().then(() => { 
      this.watermarks.tableInterface.load(); 
    });

    this.watermarks.tableInterface.events.addEventListener('dataLoaded', () => {
      for (let record of this.watermarks.tableInterface.data) {
        this.watermarks.previews[record.uuid] = '';
        if (record.previews && record.previews[0]) {
          this.api.get(this.watermarks.uriBuild(record.previews[0].webPath)).then(x => {
            this.watermarks.previews[record.uuid] = x.response.fileData;
          });
        }
      }
    });
  }

  prefselChanged(value, old) {
    if (value === old) return;
    setTimeout(() => {
      this.preferences.isOverride = null;
      this.preferences.reload();
      this.postalOrders.reload();
    }, 0);
  }

  preferences = {
    endpoint: null,
    schema: {
      print: {
        frontBack: { control: 'check', label: 'Stampa Fronte/Retro' },
        colorMode: { control: 'check', label: 'Stampa a colori' },
        sheetFormat: { control: 'combo', label: 'Formato', datasource: [{value: 'A3'}, {value: 'A4'}], datavalue: 'value', datatext: 'value', datapreload: true },
        weight: { control: 'combo', label: 'Grammatura', datasource: [{value: 80, text: '80 g/m²'}], datavalue: 'value', datatext: 'text', datapreload: true },
        staple: { control: 'check', label: 'Pinzatura' }
      },
      postage: {
        code: { control: 'combo', label: 'Affrancatura', datasource: [/* array loaded from this.preferences.reload() function */], datavalue: 'code', datatext: 'description', datapreload: true },
        vectors: [{
          postageCode: null,
          vector: null
        }],
        vectorEnablings: [{
          vector: null,
          enabledAt: null,
          disabledAt: null
        }],
        expectReceipt: { control: 'check', label: 'Desidero ricevere una scansione digitale della cartolina di ritorno' },
        topicOnReturnReceipt: { control: 'check', label: 'Riportare il promemoria invio nelle cartoline di ritorno' }
      },
      streetAddress: { control: 'text', label: 'Indirizzo' },
      zipCode: { control: 'text', label: 'CAP' },
      displayName: { control: 'text', label: 'Mittente' },
      admLvl2: { control: 'text', label: 'Provincia' },
      admLvl3: { control: 'text', label: 'Città' },
      countryCode: { control: 'text', label: 'Nazione' },
      emailForwarding: { control: 'check', label: 'Anticipa tramite mail la comunicazione inviata per posta' }
    },
    datasources: { vectors: [], postages: [] },
    data: {},
    nexive: {
      enabled: false,
      enabledAt: null
    },
    reload: () => {
      this.preferences.isCal = String(this.prefsel) !== String(this.api.user.id);
      this.api.get(`users/${this.prefsel}/preferences`).then(x => {
        if (this.preferences.isOverride === null) this.preferences.isOverride = (this.preferences.isCal && x.response.requested?.sender?.letter) || (!this.preferences.isCal && (x.response.current?.sender?.letter && !(x.response.current.sender.letter.watermarks && Object.keys(x.response.current.sender.letter).length === 1) ));
        this.preferences.data = mdt.preferences.mergeModelData(this.preferences.schema, ['sender', 'letter'], x.response);
        // Adjust some of the values
        // data.postage.vectors
        let _prioritaria = this.preferences.data.postage.vectors.find(x => x.postageCode === 'P');
        let _raccomandata = this.preferences.data.postage.vectors.find(x => x.postageCode === 'R');
        let vectors = {
          prioritaria: _prioritaria ? _prioritaria.vector.toUpperCase() : null,
          raccomandata: _raccomandata ? _raccomandata.vector.toUpperCase() : null
        };
        this.preferences.data.vectors = vectors;
        this.preferences.processPostages(false);
        this.preferences.processVectors();
        // other
        this.preferences.data.postage.topicOnReturnReceipt = (this.preferences.data.postage.topicOnReturnReceipt || false) === true;
        this.preferences.data.print.colorMode = this.preferences.data.print.colorMode === 'color';
        this.preferences._data = JSON.parse(JSON.stringify(this.preferences.data));
        
        //console.log('nexive', nexive, this.preferences.data.postage.vectorEnablings);
        
      });
    },
    override: ($event) => {
      $event.model.busy = true;
      this.api.patch(`users/${this.prefsel}/preferences/sender/letter`, {enableOverride: !this.preferences.isOverride}).then(x => {
        this.preferences.isOverride = !this.preferences.isOverride;
      }).catch(e => {}).finally(() => {
        this.preferences.reload();
        $event.model.busy = false;
      });
    },
    save: ($event) => {
      $event.model.busy = true;
      let data = mdt.helpers.diffObject(this.preferences._data, this.preferences.data);
      if (data.vectors) {
        data.postage.vectors = [];
        if (data.vectors.prioritaria) data.postage.vectors.push({postageCode: 'P', vector: data.vectors.prioritaria});
        if (data.vectors.raccomandata) data.postage.vectors.push({postageCode: 'R', vector: data.vectors.raccomandata});
        delete(data.vectors);
      }
      if (data.print && typeof data.print.colorMode !== undefined) data.print.colorMode = data.print.colorMode ? 'color' : 'bw';
      if (data && Object.entries(data).length) {
        this.preferences.resource.save(data, `users/${this.prefsel}/preferences/sender/letter`, 'patch').then(x => {
          this.toast.show('Modifiche salvate con successo!', 'success');
          this.preferences.reload();
        }).catch(e => {
          this.toast.show('Errore nel salvataggio delle modifiche!', 'error');
        }).finally(() => {
          $event.model.busy = false;
        });
      }
      else $event.model.busy = false;
    },
    processVectors: () => {
      const postalServices = this.mdDatasources?.postalServices;
      if (postalServices?.vectors) {
        let nexive = this.preferences.data.postage.vectorEnablings.filter(x => x.vector.toUpperCase() === 'NEXIVE' && x.enabledAt);
        let vectors = JSON.parse(JSON.stringify(this.mdDatasources.postalServices.vectors));
        if (nexive.length === 1) {
          this.preferences.nexive = { enabled: true, enabledAt: nexive[0].enabledAt };
          this.preferences.datasources.vectors = vectors;
        } else {
          this.preferences.nexive = { enabled: false, enabledAt: null };
          let nexiveIndex = vectors.findIndex(x => x.code === 'NEXIVE');
          if (nexiveIndex >= 0) vectors.splice(nexiveIndex, 1);
          this.preferences.datasources.vectors = vectors;
        }
      }
    },
    processPostages: (reset = true) => {
      const postalServices = this.mdDatasources?.postalServices;
      if (postalServices?.postages) {
        const vectors = this.preferences.data.vectors;
        let postages = [];
        if (vectors.prioritaria) {
          Array.prototype.push.apply(postages, this.mdDatasources.postalServices.postages.filter(x => x.vector.code === vectors.prioritaria && x.code.startsWith('P')));
        }
        if (vectors.raccomandata) {
          Array.prototype.push.apply(postages, this.mdDatasources.postalServices.postages.filter(x => x.vector.code === vectors.raccomandata && (x.code.startsWith('R') || x.code.startsWith('AR'))));
        }
        this.preferences.datasources.postages = postages;
        this.preferences.resource.controls['postage.code'].datasource = this.preferences.datasources.postages;
        if (reset) this.preferences.data.postage.code = null;
      }
    },
    toggleNexive: ($event) => {
      $event.model.busy = true;
      this.api.patch(`users/${this.prefsel}/preferences/sender/letter/postage/vector-enablings`, { vector: 'NEXIVE', enabled: !this.preferences.nexive.enabled }).then(x => {
        this.preferences.reload();
      }).catch(e => {}).finally(() => {
        $event.model.busy = false;
      });
    }
  };

  postalOrders = {
    endpoint: null,
    schema: {
      statusCode: {
        label: 'Stato',
        control: 'combo',
        datasource: [{
          text: 'Inviato',
          value: 'PENDING'
        }, {
          text: 'In revisione',
          value: 'PENDING_REVISION'
        }, {
          text: 'In attesa di approvazione',
          value: 'PENDING_POST_OFFICE_REVISION'
        }, {
          text: 'Approvato',
          value: 'APPROVED'
        }, {
          text: 'Rifiutato',
          value: 'REFUSED'
        }],
        datatext: 'text',
        datavalue: 'value',
        datapreload: true
      },
      createdAt: {
        label: 'Data inserimento',
        control: 'date',
        datatype: 'datetime',
        utc: true
      },
      postalAccount: {
        label: 'Numero conto corrente',
        control: 'number',
        min: 12
      },
      postalAccountHolder: {
        label: 'Intestatario conto corrente',
        control: 'text'
      }
    },
    data: {},
    prefsel: null,
    tableInterface: null,
    toggle: () => {
      this.postalOrders.isToggle = !!!this.postalOrders.isToggle || false;
      if (!this.postalOrders.isToggle) {
        this.postalOrders.prefsel = null;
      }
    },
    reset: () => {
      Object.keys(this.postalOrders.data).forEach(k => this.postalOrders.data[k] = null);
    },
    reload: () => {
      this.postalOrders.isCal = String(this.prefsel) !== String(this.api.user.id);
      this.postalOrders.tableInterface.endpoint = `users/${this.prefsel}/preferences/sender/payments/postal-orders`;
      this.postalOrders.tableInterface.load();
    },
    confirm: () => {
      if (this.api.user.id === this.prefsel) {
        this.dialog.open({
          title: 'Attenzione!',
          class: 'small',
          viewModel: PLATFORM.moduleName('views/impostazioni/posta-dialog-confirm'),
        }, true).whenClosed(response => {
          console.log(response);
          if (!response.wasCancelled) {
            if (response.output) {
              this.prefsel = response.output.id;
            }
            this.postalOrders.data.postalAccountHolder = String(this.prefsel) === String(this.api.user.id) ? this.api.user.displayName : response.output.displayName;
            this.postalOrders.prefsel = this.prefsel;
          }
        });
      } else {
        this.postalOrders.data.postalAccountHolder = String(this.prefsel) === String(this.api.user.id) ? this.api.user.displayName : this.prefSelector?.data?.displayName;
        this.postalOrders.prefsel = this.prefsel;
      }
    },
    send: ($event) => {
      $event.model.busy = true;
      this.postalOrders.resource.save(this.postalOrders.data, `users/${this.prefsel}/preferences/sender/payments/postal-orders`, 'post').then(x => {
        this.toast.show('Richiesta inviata con successo!', 'success');
        this.postalOrders.tableInterface.load();
        this.postalOrders.toggle();
        this.postalOrders.reset();
      }).catch(e => {
        this.toast.show('Errore nell\'invio della richiesta!', 'error');
      }).finally(() => {
        $event.model.busy = false;
      });
    },
    delete: (record) => {
      this.dialog.open({
        title: 'Attenzione!',
        class: 'small',
        type: 'confirm',
        body: 'Confermi di voler eliminare il bollettino selezionato?'
      }, true).whenClosed(response => {
        if (!response.wasCancelled) {
          this.api.delete(`users/${this.prefsel}/preferences/sender/payments/postal-orders/${record.uuid}`).then(() => {
            this.toast.show('Record eliminato con successo!', 'success');
            this.postalOrders.tableInterface.load();
          }).catch(x => {
            this.api.dialogError(x);
          });
        }
      });
    }
  };

  watermarks = {
    endpoint: null,
    schema: {
      statusCode: {
        label: 'Stato ',
        control: 'combo',
        datasource: [{
          text: 'Caricato',
          value: 'PENDING'
        }, {
          text: 'In attesa di approvazione',
          value: 'PENDING_APPROVAL'
        }, {
          text: 'In attesa di revisione',
          value: 'PENDING_REVISION'
        }, {
          text: 'In attesa di conferma',
          value: 'PENDING_CONFIRMATION'
        }, {
          text: 'Approvato',
          value: 'APPROVED'
        }, {
          text: 'Rifiutato',
          value: 'REFUSED'
        }],
        datatext: 'text',
        datavalue: 'value',
        datapreload: true
      },
      label: {
        label: 'Titolo',
        control: 'text'
      },
      createdAt: {
        label: 'Data inserimento',
        control: 'date',
        datatype: 'datetime',
        utc: true
      },
      default: {
        label: 'Predefinito',
        control: 'check'
      },
      text: {
        label: 'Testo',
        control: 'textarea'
      },
      colorMode: {
        label: 'Colori',
        control: 'combo',
        datasource: [{
          text: 'Colori',
          value: 'color'
        }, {
          text: 'Bianco e nero',
          value: 'bw'
        }],
        datatext: 'text',
        datavalue: 'value',
        datapreload: true
      },
      /* notificationEmail: {
        label: 'Email notifica',
        control: 'text'
      }, */
      fileUri: {
        label: 'File',
        control: 'file'
      },
      fileData: {
        label: 'File immagine del logo',
        control: 'file'
      }
    },
    data: {},
    previews: {},
    tableInterface: null,
    toggle: () => {
      this.watermarks.isToggle = !!!this.watermarks.isToggle || false;
    },
    reset: () => {
      Object.keys(this.watermarks.data).forEach(k => this.watermarks.data[k] = null);
    },
    send: ($event) => {
      $event.model.busy = true;
      this.watermarks.resource.save(this.watermarks.data, `users/me/preferences/sender/letter/watermarks`, 'post').then(x => {
        this.toast.show('Richiesta inviata con successo!', 'success');
        this.watermarks.tableInterface.load();
        this.watermarks.toggle();
        this.watermarks.reset();
        this.dialog.open({
          title: 'Caricamento riuscito!',
          class: 'small',
          type: 'confirm',
          body: `Il logo deve essere inviato per approvazione presso il centro stampa prima di poter essere utilizzato.<br><strong>Vuoi inviarlo adesso?</strong>`
        }, true).whenClosed(response => {
          if (!response.wasCancelled) {
            this.watermarks.setStatus(x.response, 'PENDING_APPROVAL');
          }
        });
      }).catch(e => {
        this.toast.show('Errore nell\'invio della richiesta!', 'error');
      }).finally(() => {
        $event.model.busy = false;
      });
    },
    setDefault: (record) => {
      this.api.patch(`users/me/preferences/sender/letter/watermarks/${record.uuid}`, {default: true}).then(() => {
        this.toast.show('Record aggiornato con successo!', 'success');
        this.watermarks.tableInterface.load();
      }).catch(x => {
        this.api.dialogError(x);
      });
    },
    setStatus: (record, status) => {
      this.api.patch(`users/me/preferences/sender/letter/watermarks/${record.uuid}`, {statusCode: status}).then(() => {
        this.toast.show('Record aggiornato con successo!', 'success');
        this.watermarks.tableInterface.load();
        if (status === 'APPROVED') {
          this.dialog.open({
            title: 'Logo approvato!',
            class: 'small',
            type: 'confirm',
            body: `Vuoi impostare questo logo come predefinito?`
          }, true).whenClosed(response => {
            if (!response.wasCancelled) {
              this.watermarks.setDefault(record);
            }
          });
        }
        
      }).catch(x => {
        this.api.dialogError(x);
      });
    },
    delete: (record) => {
      this.dialog.open({
        title: 'Attenzione!',
        class: 'small',
        type: 'confirm',
        body: 'Confermi di voler eliminare il logo selezionato?'
      }, true).whenClosed(response => {
        if (!response.wasCancelled) {
          this.api.delete(`users/me/preferences/sender/letter/watermarks/${record.uuid}`).then(() => {
            this.toast.show('Record eliminato con successo!', 'success');
            this.watermarks.tableInterface.load();
          }).catch(x => {
            this.api.dialogError(x);
          });
        }
      });
    },
    download: (record) => {
      if (!record.fileUri) return;
      this.toast.show('Caricamento in corso', 'loading', true);
      this.api.get(this.watermarks.uriBuild(record.fileUri)).then(x => {
        let anchor = document.createElement('a');
        anchor.download = `${x.response.filename}`;
        anchor.href = x.response.fileData;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        this.toast.consume();
      });
    },
    uriBuild: (path, absolute = false) => {
      path = path.replace(this.api.baseUrl, '');
      path = path.replace('/api/v0.0.1/', '');
      return absolute ? this.api.baseUrl + path : path;
    },
    getImage: (path) => {
      this.toast.show('Caricamento in corso', 'loading', true);
      this.api.get(this.watermarks.uriBuild(path)).then(x => {
        let anchor = document.createElement('a');
        anchor.download = `${x.response.filename}`;
        anchor.href = x.response.fileData;
        //anchor.target = '_blank';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        this.toast.consume();
      });
    }
  };

}
