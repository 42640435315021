import { Aurelia, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { TableInterface, SearchInterface } from 'classes/md-table';
import { KaDialog, KaToast } from 'ka-components';
import mdt from 'services/md-tools';

@inject(Aurelia, Router, Api, KaDialog, KaToast)
export class Segnalatori {
  isLoading = false;
  contextUser = null; // contains data of user on which we are operating
  uploadSessionUuid = null;
  
  constructor(aurelia, router, api, dialog, toast) {
    this.aurelia = aurelia;
    this.api = api;
    this.router = router;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    this.initTableInterface().then(() => {
      if (!this.params.id) {
        this.initSearchInterface();
      } else {
        this.search();
      }
    });
  }

  initTableInterface() {
    this.tableInterface = new TableInterface({
      name: 'anagrafiche-utenti-documenti-table',
      client: this.api,
      endpoint: null//`users/${this.params.id}/uploadable-certifiable-document-types`
    });
    return this.tableInterface.initialize().catch(error => { console.log('tableInterface initialization failed', error) });
  }

  initSearchInterface() {
    this.searchInterface = new SearchInterface({
      name: 'anagrafiche-utenti-documenti-search',
      table: this.tableInterface,
      schema: {
        context: {
          control: 'combo',
          label: 'Quali documenti visualizzare?',
          datasource: [
            { value: 'USER', text: 'I miei documenti' },
            { value: 'SUBUSER', text: 'I documenti dei miei ' + mdt.parsers.contextualize(this.api.user, 'utenti') }
          ],
          datavalue: 'value',
          datatext: 'text',
          datamultiple: false,
          datapreload: true,
          required: true
        },
        subuser: { 
          control: 'combo', 
          label: mdt.parsers.contextualize(this.api.user, 'Utente'),
          datasource: { table: 'users/me/users?include=user-profiles', query: { search: 'text-search', item: 'id-in'} },
          datavalue: 'id', 
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false,
          required: true
        }
      },
      data: { context: 'SUBUSER' }
    });
    return this.searchInterface.initialize().then(() => {
      this.search();
    }).catch(error => { console.log('searchInterface initialization failed', error) });
  }

  search() {
    if (!this.params.id && this.searchInterface?.data?.context === 'USER') {
      this.contextUser = { id: 'me' };
      this.searchInterface.data.subuser = null;
    } else if (!this.params.id && this.searchInterface?.data?.context === 'SUBUSER') {
      this.contextUser = { id: this.searchInterface.data.subuser || null };
    } else {
      this.contextUser = { id: this.params.id || null };
    }
    if (!this.contextUser?.id) return;

    this.isLoading = true;
    this.tableInterface.endpoint = `users/${this.contextUser.id}/uploadable-certifiable-document-types`;
    this.fetchUserData().then(() => {
      (this.params.id ? this.tableInterface.load() : this.searchInterface.search()).then(() => {
        this.alterData(this.tableInterface.data).then(data => {
          this.tableInterface.data = data;
        });
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.isLoading = false;
      });
    });
  }

  fetchUserData() {
    return new Promise((resolve, reject) => {
      console.log('context', this.searchInterface?.data?.context);
      if (this.params.id) {
        resolve(); // this.contextUser.profile not needed if this.params.id
      } else if (this.searchInterface?.data?.context === 'USER') {
        resolve(this.contextUser.profile = this.api.user.profile);
      } else if (this.searchInterface?.data?.context === 'SUBUSER') {
        this.api.get(`users/me/users?include=user-profiles&id-in=${this.contextUser.id}`).then(xhr => {
          resolve(this.contextUser.profile = xhr.response[0]?.profile[0]);
        }).catch(error => {
          reject(error);
        });
      }
    }); 
  }

  alterData(data) {
    return new Promise((resolve, reject) => {
      data.forEach(documentType => { 
        documentType.documentsBucket = [];
        documentType.notRefusedDocumentsCount = 0;
        if (documentType.code === 'SUBUSER-MANDATE' && this.api.user.profile.businessType === 'BUILDING-ADMINISTRATOR') {
          documentType.label = 'Verbale di nomina amministratore';
        }
      });
      // Certifiable-document-buckets
      this.api.get(`users/${this.contextUser.id}/certifiable-document-buckets/current`).then(xhr => {
        let currentCertifiableDocumentBuckets = xhr.response;
        this.uploadSessionUuid = currentCertifiableDocumentBuckets.uploadSessionUuid;
        if (currentCertifiableDocumentBuckets.documents && currentCertifiableDocumentBuckets.documents.length) {
          currentCertifiableDocumentBuckets.documents.forEach(document => {
            // Approval/rejection date parse 
            if (document.approvedAt) document.approvedAt = this.aurelia.resources.valueConverters.dateFormat.toView(document.approvedAt);
            if (document.refusedAt) document.refusedAt = this.aurelia.resources.valueConverters.dateFormat.toView(document.refusedAt);

            if (data.find(documentType => documentType.code === document.code)) {        
              // Counting of non-REFUSED documents
              if (!document.refusedAt) data.find(documentType => documentType.code === document.code).notRefusedDocumentsCount++;
              data.find(documentType => documentType.code === document.code).documentsBucket.push(document);             
            }
          });
        }
        resolve(data);
      }).catch(error => {
        this.api.dialogError(error);
        reject(error);
      });
    });
  }

  upload(type, document) {
    this.dialog.open({
      title: `${document ? 'Sostituisci' : 'Aggiungi'} documento`,
      class: 'small utenti-dialog-documenti-upload',
      viewModel: PLATFORM.moduleName('views/anagrafiche/utenti/dialogs/profilo-document-upload'),
      viewModelParams: { userId: this.contextUser.id, uploadSessionUuid: this.uploadSessionUuid, type, document }
    }, true).whenClosed(response => {
      if (!response.wasCancelled) {
        this.search();
        this.toast.show(`Documento ${document ? 'sostituito' : 'caricato'} con successo!`, 'success');
      }
    });
  }

  approval(type, document) {
    if (document && document.uploadedAt) {
      this.dialog.open({
        title: `Stato documento`,
        class: 'small documenti-approval',
        viewModel: PLATFORM.moduleName('views/backoffice/documenti/dialogs/documenti-approval'),
        viewModelParams: { userId: this.contextUser.id, type, document }
      }, true).whenClosed(response => {
        let certification = response.output === 'APPROVED' ? 'approvato' : 'rifiutato';
        if (!response.wasCancelled) {
          this.search();
          this.toast.show(`Documento ${certification} con successo!`, 'success');
        }
      });
    }
  }

  delete(type, document) {
    if (document.uuid) {
      this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'confirm', body: `<strong>${type.label}.</strong><br>Confermi di voler cancellare questo documento?`}).whenClosed(response => {
        if (!response.wasCancelled) {
          this.api.delete(`users/${this.contextUser.id}/certifiable-document-buckets/current/certifiable-documents/${document.uuid}`).then(() => {
            this.toast.show(`Documento eliminato con successo!`, 'success');
            this.search();
          }).catch(error => {
            console.log('ERROR certifiable-documents - DELETE', error);
            this.api.dialogError(error);
          })
        }
      });
    }
  }

  download(file) {
    if (!file.downloadUri) return;

    // Uri build
    let uri = file.downloadUri;
    uri = uri.replace(ENVIRONMENT.APP_API_BASE_URL, '');
    uri = uri.replace('/api/v0.0.1/', '');

    this.api.get(uri).then(xhr => {
      let anchor = document.createElement('a');
      anchor.download = `${xhr.response.filename}`;
      anchor.href = xhr.response.fileData;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      this.toast.show(`Documento scaricato con successo!`, 'success');
    }, xhr => {
      let error = JSON.parse(xhr.response);
      this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>Impossibile scaricare il file!</strong><br>Errore server' + (error.detail ? `: ${error.detail}` : '') });
    })
  }

  downloadStaticResource(record) {
    const filename = record.templateFileName;
    const anchor = document.createElement('a');
    anchor.download = `${record.templateFileName}`;
    anchor.href = `/static/downloads/${filename}`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    this.toast.show('File scaricato con successo', 'success');
  }

  toggleType(type) {
    let typeRow = document.getElementById(type.code);
    typeRow.classList.toggle('open');
    let documentRows = document.querySelectorAll(`tr[data-type="${type.code}"]`);
    documentRows.forEach(row => {
      setTimeout(() => {
        if (typeRow.classList.contains('open') && !row.classList.contains('visible')) row.classList.add('visible');
        else if (!typeRow.classList.contains('open') && row.classList.contains('visible')) row.classList.remove('visible');
      }, 200);
    });
  }
}
