import {inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {KaDialog} from 'ka-components';

@inject(DialogController, KaDialog)
export class DialogModalSenderUI {
  constructor(controller, dialog){
    this.controller = controller;
    this.dialog = dialog;
  }
  activate(model) {
    this.model = model;
  }
  close() {
    if (window.senderui?.SenderUI.checkDraftSaved) {
      window.senderui.SenderUI.checkDraftSaved().then(payload => {
        this.controller.cancel();
      }).catch(error => {
        this.dialog.open({ title: 'Attenzione!', class: 'small', type: 'confirm', body: '<strong>Confermi di voler chiudere questa finestra?</strong><br>Le modifiche sulla bozza corrente andranno perse!'}).whenClosed(response => {
          if (!response.wasCancelled) {
            if (window.senderui.SenderUI.draft?.reset) window.senderui.SenderUI.draft.reset();
            this.controller.cancel();
          }
        });
      });
    } else this.controller.cancel();
  }
}
