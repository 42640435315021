import {inject, InlineViewStrategy} from 'aurelia-framework';
import {Router, activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import mdt from 'services/md-tools';

import {KaDialog, KaToast} from 'ka-components';
import datasets from 'md-datasets.json';
import environment from '../../../environment';

@inject(Router, Api, KaDialog, KaToast)
export class viewRubricaDati {
  endpoint = 'users/me/contacts';
  environment = environment;
  mdt = mdt;
  contact = {
    loaded: false,
    endpoint: 'users/me/contacts',
    query: null,
    schema: {
      firstname: {
        control: 'text', 
        label: 'Nome'
      },
      lastname: {
        control: 'text', 
        label: 'Cognome'
      },
      nickname: {
        control: 'text', 
        label: 'Soprannome'
      },
      companyName: {
        control: 'text', 
        label: 'Denominazione'
      },
      jobTitle: { 
        label: 'Titolo',
        control: 'combo',
        datatext: 'text',
        datavalue: 'value', 
        datamultiple: false,
        datapreload: true,
        datasource: [{ value: 'Sig.', text: 'Sig.' }, { value: 'Sig.ra', text: 'Sig.ra' }, { value: 'Arch.', text: 'Arch.' }, { value: 'Avv.', text: 'Avv.' }, { value: 'Dott.', text: 'Dott.' }, { value: 'Dott.ssa', text: 'Dott.ssa' }, { value: 'Geom.', text: 'Geom.' }, { value: 'Ing.', text: 'Ing.' }, { value: 'Prof.', text: 'Prof.' }, { value: 'Prof.ssa', text: 'Prof.ssa' }, { value: 'P.I.', text: 'P.I.' }, { value: 'Rag.', text: 'Rag.' }, { value: 'Cav.', text: 'Cav.' }, { value: 'Comm.', text: 'Comm.' }],
      },
      countryCode: {
        label: 'Nazione',
        control: 'combo',
        datatext: 'name',
        datavalue: 'code',
        datamultiple: false,
        datapreload: false,
        datasource: { table: `geo/countries`, query: { search: 'text-search', item: 'code' } },
      },
      admLvl1Uuid: {
        label: 'Regione',
        control: 'combo',
        datatext: 'endonym',
        datavalue: 'uuid',
        datamultiple: false,
        datapreload: false,
        datasource: { table: null, query: { search: 'text-search', item: 'uuid' } },
      },
      admLvl2Uuid: {
        label: 'Provincia',
        control: 'combo',
        datatext: 'endonym',
        datavalue: 'uuid',
        datamultiple: false,
        datapreload: false,
        datasource: { table: null, query: { search: 'text-search', item: 'uuid' } },
      },
      admLvl3Uuid: {
        label: 'Comune',
        control: 'combo',
        datatext: 'endonym',
        datavalue: 'uuid',
        datamultiple: false,
        datapreload: false,
        datasource: { table: null, query: { search: 'text-search', item: 'uuid' } },
      },
      locality: {
        control: 'text', 
        label: 'Località'
      },
      addressee: {
        control: 'text', 
        label: 'Presso'
      },
      streetAddress: {
        control: 'text', 
        label: 'Indirizzo'
      },
      zipCode: {
        control: 'text', 
        label: 'Cap'
      },
      fiscalCode: {
        control: 'text', 
        label: 'Codice Fiscale'
      },
      vatCode: {
        control: 'text', 
        label: 'Partita IVA'
      },
      homePhoneNumberInternationalPrefix: {
        control: 'text', 
        label: 'Telefono'
      },
      homePhoneNumber: {
        control: 'text', 
        label: 'Telefono'
      },
      workPhoneNumberInternationalPrefix: {
        control: 'text', 
        label: 'Telefono lavoro'
      },
      workPhoneNumber: {
        control: 'text', 
        label: 'Telefono lavoro'
      },
      mobilePhoneNumberInternationalPrefix: {
        control: 'text', 
        label: 'Cellulare'
      },
      mobilePhoneNumber: {
        control: 'text', 
        label: 'Cellulare (verrà utilizzato per invio Sms)'
      },
      faxNumberInternationalPrefix: {
        control: 'text', 
        label: 'Fax'
      },
      faxNumber: {
        control: 'text', 
        label: 'Fax'
      },
      emailAddress: {
        control: 'text', 
        label: 'Email'
      },
      certifiedEmailAddress: {
        control: 'text', 
        label: 'Pec'
      },
      webSiteUrl: {
        control: 'text', 
        label: 'Web'
      },
      contactGroups: { 
        control: 'combo',
        label: 'Gruppo',
        datatext: 'name',
        datavalue: 'id',
        datamultiple: true,
        datapreload: true,
        datasource: { table: 'users/me/contact-groups' }
      },
      notes: {
        control: 'textarea', 
        label: 'Note'
      },
      newsletterOptIn: {
        label: 'Consenti Newsletter',
        control: 'check',
      },
      newsletterOptInDate: {
        label: 'Data Consenso',
        control: 'date'
      },
      uniqueCode: {
        label: 'Unique Code',
        control: 'text' 
      }
    },
    data: {
      countryCode: 'IT',
      newsletterOptInDate: null,
      homePhoneNumberInternationalPrefix: '+39',
      workPhoneNumberInternationalPrefix: '+39',
      mobilePhoneNumberInternationalPrefix: '+39',
      faxNumberInternationalPrefix: '+39'
    },
    save: ($event) => {
      $event.model.busy = true;
      let data = JSON.parse(JSON.stringify(this.contact.data));
      ['homePhoneNumber', 'workPhoneNumber', 'mobilePhoneNumber', 'faxNumber'].forEach(field => {
        if (data[field]) {
          data[field] = (String(data[`${field}InternationalPrefix`] || '+39') + String(data[field])).replace(/\s+/g, "");
        }
        delete data[`${field}InternationalPrefix`];
      });
      if (data.contactGroups === null) {
        data.contactGroups = [];
      }
      if (data.id) {
        this.contact.resource.save(data, `users/me/contacts/${data.id}`, 'patch').then(x => {
          this.toast.show('Modifiche salvate con successo!', 'success');
          this.load();
          this.router.navigateToRoute('anagrafiche/rubrica');
        }).catch(e => {
          this.toast.show('Errore nel salvataggio delle modifiche!', 'error');
        }).finally(() => {
          $event.model.busy = false;
        });
      } else {
        this.contact.resource.save(data, 'users/me/contacts', 'post').then(x => {
          this.toast.show('Contatto creato con successo!', 'success');
          this.router.navigateToRoute('anagrafiche/rubrica');
        }).catch(e => {
          console.error(e);
          this.toast.show('Errore nella creazione del contatto!', 'error');
        }).finally(() => {
          $event.model.busy = false;
        });
      }
    }
  };

  constructor(router, api, dialog, toast) {
    this.api = api;
    this.router = router;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    this.load();
  }

  load() {
    if (this.params.id && this.params.id !== 'new') {
      this.pending = true;
      this.api.get(`users/me/contacts/${this.params.id}`).then(xhr => {
        let data = xhr.response;
        ['homePhoneNumber', 'workPhoneNumber', 'mobilePhoneNumber', 'faxNumber'].forEach(field => {
          data[`${field}InternationalPrefix`] = data[`${field}InternationalPrefix`] || '+39';
          data[field] = String((data[field] || '').replace(data[`${field}InternationalPrefix`], '')).trim();
        });
        this.contact.data = data;
      }).catch(error => {
        console.log('ERROR - users/me/contacts GET', error);
        this.api.dialogError(error);
      }).finally(() => {
        this.pending = false;
      });
    }
  }

  annulla() {
    this.router.navigateToRoute('anagrafiche/rubrica');
  }

  setGeo(field, init = false) {
    let childField, childControl, childSchema, childData, countryCode, admLvls, lvl;
    if (field === 'countryCode') {
      childField = 'admLvl1Uuid';
      lvl = 1;
    } else if (field === 'admLvl1Uuid') {
      childField = 'admLvl2Uuid';
      lvl = 2;
    } else if (field === 'admLvl2Uuid') {
      childField = 'admLvl3Uuid';
      lvl = 3;
    }
    
    childControl = this.contact.resource.controls[childField];
    childSchema = this.contact.schema[childField];
    childData = this.contact.data;
    countryCode = this.contact.data['countryCode'];
    admLvls = this.contact.resource.controls['countryCode'].objectValue?.administrativeLevels;
    
    if (countryCode) {
      let url = `geo/countries/${countryCode}/levels/${lvl}/divisions` + (lvl > 1 ? `?parent-uuid=${childData[field]}` : '');
      if (admLvls && admLvls.find(x => x.level === lvl)) {
        childSchema.enabled = true;
      } else if (admLvls && !admLvls.find(x => x.level === lvl)) {
        childSchema.enabled = false;
      }
      if (init) {
        childSchema.datasource.table = url;
      } else if (childControl) {
        childControl.datasource.table = url;
        childData[childField] = null;
      } else {
        childSchema.datasource.table = url;
        childData[childField] = null;
      }
    } else if (!init) {
      childData[childField] = null;
    }
  }
}
