import { inject } from 'aurelia-framework';
import { Api } from 'services/api';
import { KaToast, KaDialog } from 'ka-components';
import { TableInterface, SearchInterface } from 'classes/md-table';
import mdt from 'services/md-tools';
import { vmAgreement } from '../../_agreement/agreement';
@inject(Api, KaToast, KaDialog)
export class LandingPages {
  endpoint = 'bo/prospect-landing-pages';
  editEnabled = false;
  priceLists = null;

  constructor(api, toast, dialog) {
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
  }

  attached() {
    this.getPriceLists().then(() => { this.initTableInterface(); });
  }

  async initTableInterface() {
    this.tableInterface = new TableInterface({
      name: 'landing-pages-table',
      client: this.api,
      endpoint: this.endpoint,
    });

    this.tableInterface.parseResponse = (response) => {
      this.response = response;
      this.response.forEach(element => { element.controls = {}; });
      return this.response;
    };

    this.tableInterface.events.addEventListener('dataLoad', () => {
      this.editEnabled = false;
    });
    this.tableInterface.events.addEventListener('dataLoaded', () => {
      setTimeout(() => { this.editEnabled = true; }, 500);
    });

    this.searchInterface = new SearchInterface({
      name: 'landing-pages-search',
      table: this.tableInterface,
      schema: {
        referralCode: {
          label: 'Codice referral',
          control: 'text',
          datavalue: 'code',
          datatext: 'label',
          datamultiple: false,
          query: 'referral-code-like'
        },
        listCode: {
          label: 'Codice listino',
          control: 'combo',
          datasource: this.priceLists,
          datavalue: 'code',
          datatext: 'label',
          datamultiple: false,
          query: 'price-list-code-in'
        },
        searchStatus: {
          label: 'Stato',
          control: 'combo',
          datasource: [
            { value: 'ACTIVE', text: 'Attiva' },
            { value: 'NOT-ACTIVE', text: 'Non attiva' },
            { value: 'DELETED', text: 'Cancellata' },
          ],
          datavalue: 'value',
          datatext: 'text',
          datapreload: true,
          query: 'status'
        },
        sponsorUuid: {
          label: '',
          control: 'combo',  
          datasource: { 
            table: `bo/users?include=user-profiles&country-code-in=IT&status=ACTIVE&user-group-in=SPONSOR`, 
            query: { search: 'text-search', item: 'uuid-in'} 
          },
          datavalue: 'uuid', 
          datatext: 'profile[0].displayName',
          datamultiple: false,
        },
        description: {
          label: '',
          control: 'text'
        },
        definitivePriceListCode: {
          label: '',
          control: 'combo',
          datasource: this.priceLists,
          datavalue: 'code',
          datatext: 'label',
          datamultiple: false,
          datapreload: true
        },
        status: {
          label: '',
          control: 'combo',
          datasource: [
            { value: 'ACTIVE', text: 'Attiva' },
            { value: 'NOT-ACTIVE', text: 'Non attiva' },
            { value: 'DELETED', text: 'Cancellata' },
          ],
          datavalue: 'value',
          datatext: 'text',
          datapreload: true,
        },
        temporaryPriceListCode: {
          label: '',
          control: 'combo',
          datasource: this.priceLists,
          datavalue: 'code',
          datatext: 'label',
          datamultiple: false,
          datapreload: true
        },
        temporaryPriceListCodeDurationInDays: {
          label: '',
          control: 'number'
        }
      },
      data: {}
    });

    try {
      await this.tableInterface.initialize();
      this.searchInterface.initialize().then(() => {
        this.searchInterface.search();
      }).catch(error => { console.log('searchInterface initialization failed', error) });
    } catch (error) {
      console.error('tableInterface initialization failed', error);
    }
  }

  getPriceLists() {
    return this.api.get('bo/price-lists').then((xhr) => {
      this.priceLists = xhr.response;
    }).catch((error) => {
      this.priceLists = [];
      console.error('Error GET bo/price-lists', error);
    });
  }

  edit(record) {
    if (!this.editEnabled || !record.referralCode || record.referralCode === undefined) return;
    setTimeout(() => {
      let recordFormatted = {
        description: record.description,
        sponsorUuid: record.sponsorUuid,
        status: record.status,
        temporaryPriceListCode: record.temporaryPriceListCode || null,
        definitivePriceListCode: record.definitivePriceListCode,
        ...(!record.sponsorUuid && { agreementText: null }),
      };

      if (record.temporaryPriceListCodeDurationInDays) recordFormatted.temporaryPriceListCodeDurationInDays = record.temporaryPriceListCodeDurationInDays;
    
      this.api.patch(`bo/prospect-landing-pages/${record.referralCode}`, recordFormatted).then(() => {
        this.searchInterface.search();
      }).catch((error) => {
        console.error(error);
        let errorPayload = mdt.parsers.apiError(error, record.controls);
        if (errorPayload) this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>'+errorPayload.title+':</strong><div style="margin-top:1rem;border:1px solid #e0e0e0;padding:1rem;max-height:400px;overflow:auto;background-color:#ffffff">' + errorPayload.body + '</div>'});
      })
    }, 0);
  }

  addResellerTag(record) {
    this.dialog.open({
      title: 'Associa nuovo segnalatore',
      class: 'profilo-add-reseller small',
      viewModel: PLATFORM.moduleName('views/anagrafiche/landing-pages/dialogs/add-reseller-tag'),
      viewModelParams: { referralCode: record.referralCode }
    }, true).whenClosed((response) => {
      if (!response.wasCancelled) {
        this.toast.show('Segnalatore aggiunto con successo', 'success');
        this.tableInterface.load();
      }
    });
  }

  deleteResellerTag(tag) {
    this.dialog.open({
      title: 'Attenzione!',
      class: 'small',
      type: 'confirm',
      body: `Confermi di voler rimuovere questo segnalatore?`
    }).whenClosed((response) => {
        if (!response.wasCancelled) {
          this.api.delete(`bo/prospect-landing-pages/${tag.prospectLandingPageReferralCode}/reseller-tags/${tag.uuid} `).then(() => {
            this.toast.show('Segnalatore eliminato con successo', 'success');
            this.tableInterface.load();
          }).catch(error => {
            console.error(error);
          });
        }
      });
  }

  openSponsorDialog(record) {
    return this.dialog.open({
      title: 'Accordo con Sponsor',
      class: 'sponsor-agreement',
      viewModel: PLATFORM.moduleName('views/anagrafiche/landing-pages/dialogs/sponsor-agreement'),
      viewModelParams: { 
        referralCode: record.referralCode,
        agreementText: record.agreementText
      },
    }).whenClosed((response) => {
        if (!response.wasCancelled) {
          this.toast.show('Accordo modificato con successo', 'success');
          this.tableInterface.load();
        }
      });
  }
}