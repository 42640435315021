import { DateFormatValueConverter } from "../resources/value-converters/date-format";

export class MavRequest {
  dateFormat;
  isOperator;
  statuses = {
    'PENDING-DOCUMENT-INTERNAL-APPROVAL': {
      text: 'In attesa di approvazione interna'
    },
    'PENDING-DOCUMENT-DOWNLOAD': {
      text: 'In attesa di approvazione bancaria'
    },  
    'PENDING-APPROVAL': {
      text: 'In attesa di approvazione bancaria'
    },
    'PENDING-ACTIVATION': {
      text: 'In attesa di attivazione'
    },
    'ACTIVE': {
      text: 'Richiesta attivata'
    },
    'REFUSED': {
      text: 'Richiesta rifiutata'
    },
    'DELETED': {
      text: 'Richiesta cancellata'
    }
  }

  constructor(isOperator) {
    this.isOperator = isOperator;
    this.dateFormat = new DateFormatValueConverter();
  }
  
  displayText(record) {
    const status = record.status || record.payments?.banking?.mavSelfPrintingAuthorizationStatus;
  
    if (status && Object.keys(this.statuses).includes(status)) {
      if (this.isOperator) {
        if (status === 'PENDING-ACTIVATION') {
          const approvalDate = record.approvedAt || record.payments?.banking?.mavApprovedAt;
          if (approvalDate) return `${this.statuses['PENDING-ACTIVATION'].text} (approvata il ${this.dateFormat.toView(approvalDate, 'DD/MM/YYYY')})`;
        } else if (status === 'ACTIVE') {
          const activationDate = record.activatedAt || record.payments?.banking?.mavActivatedAt;
          if (activationDate) return `${this.statuses['ACTIVE'].text} il ${this.dateFormat.toView(activationDate, 'DD/MM/YYYY')}`;
        }
      }
  
      return this.statuses[status].text;
    }
  }

  setDatasourceMavAuthorizationStatus(datasource = []) {
    datasource.unshift({ status: 'null', text: 'Tutte' });
    Object.entries(this.statuses).forEach((status) => {
      const [key, value] = status;
      if (key !== 'DELETED') {
        if (['PENDING-APPROVAL', 'PENDING-DOCUMENT-DOWNLOAD'].includes(key)) {
          if (!datasource.some((obj) => 'PENDING-APPROVAL,PENDING-DOCUMENT-DOWNLOAD' === obj.status)) {
            datasource.push({ status: 'PENDING-APPROVAL,PENDING-DOCUMENT-DOWNLOAD', text: value.text });
          }
        } else {
          datasource.push({ status: key, text: value.text });
        }  
      }  
    });

    return datasource;
  }
}
