import { Api } from './api';
import { inject } from 'aurelia-framework';

@inject(Api)
export class MdDatasources {
  paymentMethods;
  flatServiceTypes;
  multifattureSoftwares;

  constructor(api) {
    this.api = api;
  }

  initialize() {
    if (this.initialized || !this.api.isAuthenticated) return Promise.resolve(); // only run once, only when user is authenticated
    this.postalServices = {
      vectors: [],
      postages: []
    };
    this.multifattureSoftwares = [];
    this.paymentMethods = [];
    let promises = [
      this.initPostalServices(),
      this.initMultifattureSoftwares(),
      this.initPaymentMethods(),
      this.initFlatServiceTypes()
    ];
    return Promise.all(promises).then(() => {
      this.initialized = true;
    })
  }

  initPostalServices() {
    return new Promise((resolve, reject) => {
      this.api.get('postal-services/vectors').then(xhr => {
        let promises = [];
        xhr.response.forEach(vector => {
          //this.postalServices[vector.code] = Object.assign({}, vector, { postages: []});
          this.postalServices.vectors.push(vector);
          promises.push(this.api.get(`postal-services/${vector.code}/postages`).then(xhr => {
            xhr.response.forEach(postage => {
              this.postalServices.postages.push(postage);
            });
          }));
        });
        Promise.all(promises).then(() => {
          resolve(this.postalServices);
        }).catch(error => {
          reject(error);
        });
      }).catch(error => {
        reject(error);
      });
    });
  }

  initMultifattureSoftwares() {
    if (!this.api.hasRole('LIST_CLIENTS')) return Promise.resolve();
    return this.api.get('users/me/clients').then((xhr) => {
      const response = xhr.response;
      this.multifattureSoftwares = response;
    });
  }

  initPaymentMethods() {
    return this.api.get('payment-methods').then((xhr) => {
      const response = xhr.response;
      this.paymentMethods = response;
    });
  }

  initFlatServiceTypes() {
    return this.api.get('flat-service-types').then((xhr) => {
      const response = xhr.response;
      this.flatServiceTypes = response;
    });
  }
}
