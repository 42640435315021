import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {TableInterface, SearchInterface} from 'classes/md-table';

@inject(Api)
export class viewBounces {
  constructor(api) {
    this.api = api;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
  }
 
  attached() {
    /* Table interface */
    this.bouncesTableInterface = new TableInterface({
      name: 'table-bounces',
      client: this.api,
      endpoint: 'bo/email-bounces',
      query: null
    });

    /* Table Search */
    this.bouncesSearchInterface = new SearchInterface({
      name: 'bounces-table-search',
      table: this.bouncesTableInterface,
      schema: {
        email: { 
          control: 'text', 
          label: 'Email',
          query: 'emailLike'
        }
      },
      data: {}
    });

    /* Initialize & Search function */
    this.bouncesTableInterface.initialize().then(() => {
      this.bouncesSearchInterface.initialize().then(() => {
        this.bouncesSearchInterface.search().catch(error => { console.error('search bounces failed', error); });
      }).catch(error => { console.error('table-search-initialize bounces failed', error); });
    }).catch(error => { console.error('table-interface-initialize bounces failed', error); });
  }
}
