import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { inject } from 'aurelia-framework';

@inject(Api, KaDialog, KaToast)
export class viewUserSubscriptions {
  multinvioContract;
  flatServiceContract;
  controllerTabUserArchive;
  pendingGetMultinvioContract = true;
  pendingGetFlatServiceContract = true;
  pendingSubscriptionMultinvioContract = false;
  pendingSubscriptionFlatServiceContract = false;
  clauses = {
    'FLAT-SERVICE' : {
      schema: {
        clause1: {
          readonly: true,
          control: 'check',
          description: 'Confermo di aver letto e accettato le condizioni dell’iniziativa promozionale Cashback Multidialogo.',
        },
        clause2: {
          readonly: true,
          control: 'check',
          description: 'Ai sensi degli artt.1341 e 1342 del c.c approvo specificamente le seguenti clausole:',
        }
      },
      data: {
        clause1: false,
        clause2: false
      }
    },
    'MULTINVIO-VOLUMES' : {
      schema: {
        clause1: {
          readonly: true,
          control: 'check',
          description: 'Confermo di aver letto e accettato le condizioni dell’iniziativa promozionale Cashback Multidialogo.',
        },
        clause2: {
          readonly: true,
          control: 'check',
          description: 'Ai sensi degli artt.1341 e 1342 del c.c approvo specificamente le seguenti clausole:',
        }
      },
      data: {
        clause1: false,
        clause2: false
      }
    }
  }

  constructor(api, kaDialog, kaToast) {
    this.api = api;
    this.toast = kaToast;
    this.dialog = kaDialog;
  }

  activate(model) {
    this.model = model;
    model.tab.controller = this;
    this.parent = model.$parent;
  }

  attached() {
    this.init();
    this.controllerTabUserArchive = this.parent.tabs.find((tab) => tab.id === 'userArchive').controller;
  }

  init() {
    this.toast.consume();
    return Promise.all([
      this.getLatSubscriptedMultinvioContract(),
      this.getLastSubscriptedFlatServiceContract()
    ]);
  }

  setClausesData(contract) {
    const type = contract.type;
    const acceptedAt = contract.acceptedAt;
    Object.keys(this.clauses[type].schema).forEach((key) => {
      this.clauses[type].data[key] = !!acceptedAt;
    });
  }

  setClausesReadonly(contract) {
    const type = contract.type;
    Object.keys(this.clauses[type].schema).forEach((key) => {
      this.clauses[type].schema[key].readonly = contract.acceptedAt ? true : !this.clauses[type].schema[key].readonly;
    });
  }

  getLastSubscriptedFlatServiceContract() {
    this.pendingFlatServiceContract = true;
    return this.api.get('users/me/cashback-contracts/last-accepted-flat-service').then((xhr) => {
      const response = xhr.response;
      if (response.hasNext === "YES") {
        this.getNextFlatServiceContract(response.gracePeriodEndAt);
      } else {
        this.setClausesData(response);
        this.setClausesReadonly(response);
        this.flatServiceContract = response;
        this.pendingFlatServiceContract = false;
      }
    }).catch((error) => {
      if (error.statusCode === 404) {
        this.getNextFlatServiceContract();
      } else {
        console.log('GET last-accepted-flat-service', error);
        this.pendingFlatServiceContract = false;
        this.toast.show('Errore caricamento contratto!', 'error');
      }
    });
  }

  getNextFlatServiceContract(gracePeriodEndAt = null) {
    return this.api.get('users/me/cashback-contracts/next-flat-service').then((xhr) => {
      const response = xhr.response;
      this.setClausesReadonly(response);
      this.flatServiceContract = response;
      this.flatServiceContract.lastContractGracePeriodEndsOn = gracePeriodEndAt;
    }).catch((error) => {
      console.log('GET next-flat-service', error);
      this.toast.show('Errore caricamento contratto!', 'error');
    }).finally(() => {
      this.pendingFlatServiceContract = false;
    });
  }

  getLatSubscriptedMultinvioContract() {
    this.pendingMultinvioContract = true;
    return this.api.get('users/me/cashback-contracts/last-accepted-multinvio-volumes').then((xhr) => {
      const response = xhr.response;
      if (response.hasNext === "YES") {
        this.getNextMultinvioContract(response.gracePeriodEndAt);
      } else {
        this.setClausesData(response);
        this.setClausesReadonly(response);
        this.multinvioContract = response;
        this.pendingMultinvioContract = false;
      }
    }).catch((error) => {
      if (error.statusCode === 404) {
        this.getNextMultinvioContract();
      } else {
        console.log('GET last-accepted-multinvio-volumes', error);
        this.pendingMultinvioContract = false;
        this.toast.show('Errore caricamento contratto!', 'error');
      }
    });
  }

  getNextMultinvioContract(gracePeriodEndAt = null) {
    return this.api.get('users/me/cashback-contracts/next-multinvio-volumes').then((xhr) => {
      const response = xhr.response;  
      this.setClausesReadonly(response);
      this.multinvioContract = response;
      this.multinvioContract.lastContractGracePeriodEndsOn = gracePeriodEndAt;
    }).catch((error) => {
      console.log('GET next-multinvio-volumes', error);
      this.toast.show('Errore caricamento contratto!', 'error');
    }).finally(() => {
      this.pendingMultinvioContract = false;
    });
  }

  setEndpointDownloadPdf(type) {
    let context = 'next';
    if (type === 'FLAT-SERVICE' && this.flatServiceContract?.acceptedAt) context = 'last-accepted';
    else if (type === 'MULTINVIO-VOLUMES' && this.multinvioContract?.acceptedAt) context = 'last-accepted';
    return `users/me/cashback-contracts/${context}-${type.toLowerCase()}.pdf`;
  }
   
  downloadPdfContract($event, type) {
    $event.model.busy = true;
    const uri = this.setEndpointDownloadPdf(type);

    return this.api.client.createRequest(uri)
      .asGet()
      .withHeader("Accept", "application/pdf")
      .withHeader("Content-Type", "application/pdf")
      .withResponseType('blob')
      .send()
      .then((xhr) => {
        const regexp = new RegExp('filename=(.+\.pdf$)');
        const matches = xhr.headers.headers['content-disposition'].value.match(regexp);
        const anchor = document.createElement("a");
        const blob = new Blob([xhr.response], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        anchor.href = url;
        anchor.download = matches[1];
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        this.toast.show("Contratto scaricato correttamente", 'success');
      }).catch((error) => {
        console.log("impossibile scaricare il pdf", error);
        this.toast.show('impossibile scaricare il contratto', 'error');
      }).finally(() => {
        $event.model.busy = false;
      })
  }

  subscribeContract(event, contract) {
    if (contract.type) {
      event.model.busy = true;
      const storageSubscriptions = JSON.parse(localStorage.getItem('cashback-contracts-subscriptions'));
      if (contract.type === 'FLAT-SERVICE') this.pendingSubscriptionFlatServiceContract = true;
      else this.pendingSubscriptionMultinvioContract = true;
      return this.api.post(`users/me/cashback-contracts/next-${contract?.type.toLowerCase()}/acceptances`, {}).then(() => {
        this.toast.show('Contratto cashback accettato!', 'success');
        if (storageSubscriptions) {
          storageSubscriptions[contract.type] = false;
          localStorage.setItem('cashback-contracts-subscriptions', JSON.stringify(storageSubscriptions));
        }
        this.controllerTabUserArchive.init();
        if (contract.type === 'FLAT-SERVICE') this.getLastSubscriptedFlatServiceContract();
        else this.getLatSubscriptedMultinvioContract();
      }).catch((error) => {
        console.log('POST cashback-contracts', error);
        this.api.dialogError(error);
      }).finally(() => {
        event.model.busy = false;
        this.pendingSubscriptionMultinvioContract = false;
        this.pendingSubscriptionFlatServiceContract = false;
      });
    }
  }
}
