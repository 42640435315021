import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {MdDatasources} from 'services/md-datasources';

@inject(Api, MdDatasources)
export class ServiceDialogAbbonamentiAttivazione {
  schema = {
    payment: {
      label: 'Metodo di pagamento',
      control: 'combo',
      datasource: [],
      datapreload: true,
      datamultiple: false,
      datavalue: 'code',
      datatext: 'label'
    },
    billing: {
      label: 'Profilo di fatturazione',
      control: 'combo',
      datasource: [],
      datapreload: true,
      datamultiple: false,
      datavalue: 'value',
      datatext: 'text'
    }
  };
  data = {};
  error = null;
  
  constructor(api, MdDatasources) {
    this.api = api;
    this.mdDatasources = MdDatasources;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
    this.payeeUserId = params.payableByUserId;

    // Payment methods
    this.schema.payment.datasource = this.mdDatasources.paymentMethods.filter(x => params.service.paymentMethods.includes(x.code));
    if (this.schema.payment.datasource.length) this.data.payment = this.schema.payment.datasource[0].code;

    // Get billing profiles
    this.api.get(`users/${this.payeeUserId}/billing-profiles`).then((xhr) => {
      const response = xhr.response;
      if (!response.length) throw new Error('Zero length');
      let billingDatasource = [];
      response.forEach((value) => {
        billingDatasource.push({ value: value.uuid, text: `${value.name || value.label || value.lasttName || value.firstName} - ${value.taxCode || value.fiscalCode || value.vatCode}${value.default ? ' (predefinito)' : ''}`});
        if (value.default) this.data.billing = value.uuid;
      });
      this.schema.billing.datasource = billingDatasource;
    }).catch(() => {
      this.error = { message: 'Nessun profilo di fatturazione trovato da associare al pagamento del servizio' };
    });
    
  }
}
