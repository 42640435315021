import {inject, customElement, bindable} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaToast, KaDialog} from 'ka-components';
import mdt from 'services/md-tools';
import moment from 'moment';

@customElement('md-resource')
@inject(Element, Api, KaToast, KaDialog)
export class MdResource {
  @bindable() data = {};
  @bindable() schema = {};
  @bindable() controls = {};
  @bindable() endpoint = {};

  constructor(element, api, toast, dialog) {
    this.element = element;
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
    // CUSTOM EVENTS
    this.events = document.createTextNode(null);
    this.events.schemaLoaded = new CustomEvent('schemaLoaded', { resource: this });
    this.events.dataLoaded = new CustomEvent('dataLoaded', { resource: this });
    this.events.dataSaved = new CustomEvent('dataSaved', { resource: this });
    this.events.dataSaveError = new CustomEvent('dataSaveError', { resource: this });
  }

  bind(bindingContext, overrideContext) {
    this.parentContext = bindingContext;
  }

  attached() {
    this.init();
  }

  init() {
    konsole.debug('MdResource controls', this.controls);
  }

  validate() {
    let promises = [];
    for (let [field, item] of Object.entries(this.controls)) {
      if (!item) {
        konsole.warn(`Field ${field} was not found!`);
        continue;
      }
      if (!item.validator) {
        konsole.warn(`Field ${field} validator was not found!`);
        continue;
      }
      item.validator.errors = [];
      item.element.classList.remove('error');
      promises.push(new Promise((resolve, reject) => {
        item.validate().then(result => {
          if (!result.valid) {
            reject(item.validator.errors);
          } else {
            resolve(result);
          }
        }).catch(error => {
          konsole.error(`Resource validation error on field ${field}`, error);
          reject(error);
        });
      }));
    }
    return Promise.all(promises);
  }

  load(endpoint = this.endpoint) {
    return this.api['get'](endpoint).then(xhr => {
      let response = xhr.response;
      if (response && !Array.isArray(response)) {
        for (let [field, item] of Object.entries(response)) {
          if (this.controls[field] && this.controls[field].constructor?.name !== 'KaControl' && (this.controls[field].control === 'date' || (this.controls[field].schema && this.controls[field].schema.control === 'date') ) && item) {
            response[field] = moment.utc(item).local().format('YYYY-MM-DD');
          } else {
            if (typeof item === 'undefined') console.error(`Input ${field} has undefined value. Skipping...`);
            else response[field] = JSON.parse(JSON.stringify(item));
          }
        }
      }
      this.data = response;
      this.events.dispatchEvent(this.events.dataLoaded);
    });
  }

  save(data = this.data, endpoint = this.endpoint, method = 'post') {
    return new Promise((resolve, reject) => {
      this.validate().then(() => {
        let promises = [];
        data = Object.assign({}, data);
        if (method !== 'patch') data = mdt.helpers.removeEmptyAttributes(data);
        else data = mdt.helpers.nullifyEmptyStringAttributes(data);
        if (!data) throw 'Nessun dato da inviare!';
        for (let [field, item] of Object.entries(data)) {
          if (typeof item === 'undefined') {
            console.error(`Data ${field} has undefined value. Skipping...`);
            delete data[field];
          } else if (typeof this.schema[field] === 'undefined') {
            console.error(`Data ${field} has undefined schema. Skipping...`);
            delete data[field];
          } else {
            promises.push(new Promise((resolve, reject) => {
              // Parse file
              if (this.controls[field] && (this.controls[field].control === 'file' || (this.controls[field].schema && this.controls[field].schema.control === 'file') ) && item && item.length && item[0] && item[0].name) {
                let reader = new FileReader();
                reader.readAsDataURL(item[0]);
                reader.onload = () => {
                  data[field] = reader.result;
                  resolve(true);
                };
                reader.onerror = (error) => {
                  console.error(`Input ${field} cannot read file. Skipping...`);
                };
              }
              // Parse date
              /* else if (this.controls[field] && (this.controls[field].control === 'date' || (this.controls[field].schema && this.controls[field].schema.control === 'date') ) && item) {
                data[field] = moment(item, 'YYYY-MM-DD').hour(moment().hour()).minute(moment().minute()).utc().format();
                resolve(true);
              }  */
              else if (this.schema[field] && this.schema[field].control === 'list') {
                data[field] = Array.isArray(item) ? item : JSON.parse(item);
                resolve(true);
              } else {
                data[field] = JSON.parse(JSON.stringify(item));
                resolve(true);
              }
            }));
          }
        }
        Promise.all(promises).then(() => {
          this.api[method](endpoint, data).then(x => {
            this.events.dispatchEvent(this.events.dataSaved);
            resolve(x);
          }).catch(error => {
            this.events.dispatchEvent(this.events.dataSaveError);
            let errorPayload = mdt.parsers.apiError(error, this.controls);
            if (errorPayload) this.dialog.open({ title: 'Attenzione!', class: 'large', type: 'alert', body: '<strong>'+errorPayload.title+':</strong><div style="margin-top:1rem;border:1px solid #e0e0e0;padding:1rem;max-height:400px;overflow:auto;background-color:#ffffff">' + errorPayload.body + '</div>'});
            reject(error);
          });
        });
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
