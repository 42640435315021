export class FilesizeFormatValueConverter {
  // the value is expressed in kB
  // If the value exceeds 100 kB it will be converted to MB
  toView(value) {
    const units = { 'kB' :  1, 'MB' : 1000 }
    value = Number(value);
    if (value > 100) {
      value = parseFloat(value / units['MB']).toFixed(2)
      return `${value} ${Object.keys(units)[1]}`;
    } else {
      return `${value} ${Object.keys(units)[0]}`;
    }
  }
}
