import {inject} from 'aurelia-framework';
import {MdDatasources} from 'services/md-datasources';
@inject(MdDatasources)
export class ComboFormatValueConverter {
  constructor(mdDatasources) {
    this.mdDatasources = mdDatasources;
  }
  toView(value, control, mdDatasourceReference) {
    if (!value) return;
    let datasource;
    if (mdDatasourceReference && this.mdDatasources[mdDatasourceReference]) {
      datasource = this.mdDatasources[mdDatasourceReference];
    } else {
      datasource = control.datasource;
    }
    if (!Array.isArray(datasource)) return value;
    return (datasource.find(x => x[control.datavalue] === value) || {})[control.datatext] || value;
  }
}
