import { Aurelia, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { MdDatasources } from 'services/md-datasources';
import { KaDialog, KaToast } from 'ka-components';
import mdt from 'services/md-tools';

@inject(Aurelia, Router, Api, MdDatasources, KaDialog, KaToast)
export class Pagamenti {
  mdt = mdt;
  user = null;
  isInitializing = true;
  isLoading = true;
  writeEnabled = false;
  customSdiEnabled = false;
  masterUserCreditSharingCheck = false;
  resource = {
    controls: {},
    schema: {
      debtGuarantorDisplayName: {
        label: 'Garante del deposito',
        control: 'text',  
      },
      debtGuarantorUuid: {
        label: 'Garante del deposito', 
        control: 'combo',  
        datatext: 'profile[0].displayName',
        datavalue: 'uuid', 
        datamultiple: false,
        datapreload: false,
        datasource: { table: `bo/users?include=user-profiles&user-group-in=MASTER_USER`, query: { search: 'text-search', item: 'uuid-in'} },
        query: 'parent-uuid-in'
      },
      enableCreditPartialUsage: {
        label: 'Utilizza credito residuo per il pagamento delle spedizioni',
        description: 'Attiva',
        control: 'check'
      },
      enableToPayQueueDutiesForSubuserAtCheckout: {
        control: 'check',
        description: 'Attiva' 
      },
      masterUserCreditSharing: { 
        description: 'Attiva',
        control: 'check'
      },
      sdi: {
        code: {
          label: 'Codice univoco SdI per il mio studio',
          control: 'text' 
        },
        genericSubuserCode: {
          control: 'text' 
        }
      },
      registerBillsAsManagementMovement: {
        label: 'Le fatture delle spedizioni verranno registrate come movimento di gestione, senza la suddivisione per costi personali',
        description: 'Attiva',
        control: 'check' 
      }
    },
    data: {},
  }

  constructor(aurelia, router, api, mdDatasources, dialog, toast) {
    this.aurelia = aurelia;
    this.api = api;
    this.mdDatasources = mdDatasources;
    this.router = router;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    if (this.params.id) this.init();
  }

  init() {
    this.isOperator = this.api.hasRole('LIST_ANY_USER');
    this.writeEnabled = this.api.hasRole(['MODIFY_ANY_USER_PRIVILEGED_PREFERENCES', 'MODIFY_USERS_PRIVILEDGED_PREFERENCES']) ? true : false;
    if (this.resource.schema.multifatture?.accountingClientSoftwareUuid?.datasource) this.resource.schema.multifatture.accountingClientSoftwareUuid.datasource = this.mdDatasources.multifattureSoftwares;
    this.getUser();
  }

  getUser() {
    this.api.get(`users/${this.params.id}?include=user-profiles`).then(xhr => {
      this.user = xhr.response;
      this.user.profile = this.user.profile[0];
      this.resource.schema.enableToPayQueueDutiesForSubuserAtCheckout.label = `Utilizza credito dello studio per il pagamento delle spedizioni dei miei ${mdt.parsers.contextualize(this.user, 'utenti')}`;
      this.resource.schema.masterUserCreditSharing.label = `Sblocco automatico delle spedizioni dei miei ${mdt.parsers.contextualize(this.user, 'utenti')} con il credito del mio ${mdt.parsers.contextualize(this.user, 'account')}`;
      this.resource.schema.sdi.genericSubuserCode.label = `Codice univoco SdI per i miei ${mdt.parsers.contextualize(this.user, 'utenti')}`;
      this.load();
    }).catch(error => {
      console.log('ERROR user-profile GET', error);
      this.api.dialogError(error);
    });
  }

  load() {
    this.isLoading = true;
    this.api.get(`users/${this.params.id}/preferences`).then(xhr => {
      this.resource.data = mdt.preferences.mergeModelData(this.resource.schema, ['payment'], xhr.response);

      if (this.user.group === 'BASIC_USER') {
        /* Se apro un condominio, devo gestire il campo sdi (sdi.code e sdi.genericSubuserCode) con una logica particolare
        Il frontend mostra un unico campo "Campo univoco SdI" bindato a this.payments.data.sdi.code
        L'endpoint GET /users/{id}/preferences ritorna uno stack di impostazioni che la funzione mergeModelData() mergia secondo un criterio gerarchico di priorità
        Il risultato è un payload in cui il valore di sdi può essere:
        - sdi: null
        - sdi: {code: 'CODICE1', genericSubuserCode: 'CODICE2'}
        - sdi: {code: 'CODICE1', genericSubuserCode: null}
        - sdi: {code: null, genericSubuserCode: 'CODICE2'}
        Essendo il payload this.payments.data il risultato di un merge di preferenze dell'utente condominio con preferenze dell'amministratore e altre preferenze di default, non ho modo
        di distinguere se un codice sdi.code è originariamente settato sul condominio o sul suo amministratore, a meno che non vada a leggere nel response originale:
        se x.response.requested.payment.sdi.code è valorizzato, il codice è settato sul condominio, altrimenti l'eventuale sdi.code che mi ritrovo nel payload è ereditato dal suo amministratore */
        
        // Nel caso l'utente sia il condominio stesso, non faccio nulla
        if (this.user.id === this.api.user.id) return this.resource.data = data;
        // nel caso sdi = null, creo l'oggetto, vuoto
        if (!this.resource.data.sdi) this.resource.data.sdi = { code: null, genericSubuserCode: null };
        // Se intercetto, nel response, un sdi.code relativo al condominio, sovrascrivo l'eventuale sdi.code nel payload con quello relativo al condominio
        if (xhr.response.requested?.payment?.sdi?.code) this.resource.data.sdi.code = xhr.response.requested.payment.sdi.code;
        // In tutti gli altri casi, sovrascrivo l'eventuale sdi.code nel payload con l'eventuale genericSubuserCode ereditato dall'amministratore
        else this.resource.data.sdi.code = this.resource.data.sdi?.genericSubuserCode;
      }  
      this.resource._data = JSON.parse(JSON.stringify(this.resource.data));
      this.masterUserCreditSharingCheck = this.resource.data.masterUserCreditSharing?.scopes?.includes('QUEUE-DUTIES');
    }).catch(error => {
      console.log('ERROR /preferences (payments) GET', error);
      this.api.dialogError(error);
    }).finally(() => {
      this.isInitializing = this.isLoading = false;
    });
  }

  save ($event) {
    if (!this.params.id) return;
    let data = this.resource.data;
    if (this.masterUserCreditSharingCheck === true) {
      if (!data.masterUserCreditSharing) data.masterUserCreditSharing = { scopes: []};
      if (data.masterUserCreditSharing.scopes.indexOf('QUEUE-DUTIES') < 0) data.masterUserCreditSharing.scopes.push('QUEUE-DUTIES');
    } else if (this.masterUserCreditSharingCheck === false) {
      if (!data.masterUserCreditSharing) data.masterUserCreditSharing = { scopes: []};
      if (data.masterUserCreditSharing.scopes.indexOf('QUEUE-DUTIES') > -1) data.masterUserCreditSharing.scopes.splice(data.masterUserCreditSharing.scopes.indexOf('QUEUE-DUTIES'), 1);
    }
    data = mdt.helpers.diffObject(this.resource._data, this.resource.data);
    if (data.debtGuarantorDisplayName) delete data.debtGuarantorDisplayName;
    if (data && Object.entries(data).length) {
      $event.model.busy = true;
      this.api.patch(`users/${this.params?.id}/preferences/payment`, data).then(() => {
        this.toast.show('Modifiche salvate con successo!', 'success');
        this.load();
      }).catch(error => {
        console.log('ERROR - /preferences/payment (save) - PATCH', error);
        this.resource.error(error);
      }).finally(() => {
        $event.model.busy = false;
      });
    } else $event.model.busy = false;
  }
}
