import { Api } from 'services/api';
import { KaToast, KaDialog } from 'ka-components';
import { inject } from 'aurelia-framework';
import { MdDatasources } from 'services/md-datasources';
import { TableInterface, SearchInterface, TableSelectionInterface } from 'classes/md-table';
import mdt from 'services/md-tools';

@inject(Api, MdDatasources, KaToast, KaDialog)
export class ServiceDialogAbbonamentiAttivazioneSottoutenti {
  service;
  mdt = mdt;
  activations;
  isServicePayableBy;
  totalActivationsCount;
  pendingBillingProfiles;
  pendingActivation = false;
  failedActivationsCount = 0;
  successfulActivationsCount = 0;
  userBillingProfileDatasource = [];
  recordBillingProfilePending = false;
  resourcePayment = {
    schema: {
      payment: {
        label: 'Metodo di pagamento',
        control: 'combo',
        datasource: [],
        datapreload: true,
        datamultiple: false,
        datavalue: 'code',
        datatext: 'label'
      }
    },
    data: {}
  }
  resourceBillingProfile = {
    controls: {},
    schema: {
      billingProfile: {
        label: 'Profilo di fatturazione',
        control: 'combo',
        datasource: [],
        datapreload: true,
        datamultiple: false,
        datavalue: 'value',
        datatext: 'text'
      }
    },
    data: {}
  }
  
  constructor(api, MdDatasources, toast, dialog) {
    this.api = api;
    this.toast = toast;
    this.dialog = dialog;
    this.mdDatasources = MdDatasources;
  }

  activate(params) {
    this.params = params;
    this.service = this.params.service;
    this.isServicePayableBy = this.params.payable;
    this.init();
  }

  async init() {
    // Payment methods
    this.resourcePayment.schema.payment.datasource = this.mdDatasources.paymentMethods.filter(paymentMethod => this.service.paymentMethods.includes(paymentMethod.code));
    if (this.resourcePayment.schema.payment.datasource.length) this.resourcePayment.data.payment = this.resourcePayment.schema.payment.datasource[0].code;

    return this.setUserBillingProfile().then(() => {
      this.initTableInterface();
      this.initTableSelectionInterface();
      this.initSearchInterface().then(() => {
        return this.searchInterface.reset();
      })
    })
  }

  // I make this call at the beginning because if the Master does not have a billing profile he must make one
  setUserBillingProfile() {
    return new Promise((resolve, reject) => {
      if (this.isServicePayableBy === 'MASTER') {
        return this.api.get(`users/me/billing-profiles`).then(xhr => {
          const response = xhr.response;
          if (!response.length) {
            this.dialog.open({ 
              title: 'Attenzione!', 
              class: ',medium', 
              type: 'alert', 
              body: 'È necessario impostare un profilo di fatturazione' 
            }).whenClosed(() => {
              // if it does not have a billing profile I send it to the billing view
              location.href = '/gestione/profilo/me/fatturazione';
            });
          } else {
            response.forEach(billingProfile => {
              this.userBillingProfileDatasource.push(this.parseBillingProfileDatasource(billingProfile));
            });
          }
          return resolve();
        }).catch(xhr => { 
          console.error(xhr);
          this.api.dialogError(xhr);
          this.controller.cancel();
          return reject();
        });
      } else resolve();
    });
  }

  initTableInterface() {
    this.tableInterface = new TableInterface({
      limit: 100, 
      client: this.api,
      endpoint: 'users/me/users',
      name: 'activable-flat-subusers-table',
      query: `without-subscriptions-with-type-code=${this.service.type}&include=user-profiles&status=ACTIVE`
    });

    this.tableInterface.parseResponse = (records) => {
      if (records.length) {
        this.pendingBillingProfiles = true;
        records.forEach((record, index) => {
          record._selection = {};
          record._resource = structuredClone(this.resourceBillingProfile);

          // Checks if it is the last record to end the loading state
          const isLastRecord = records.length === (index + 1);

          // if the service is payable by MASTER. User billing profiles only.
          if (this.isServicePayableBy === 'MASTER') {
            record._selection.isDisabled = false;
            record._resource.schema.billingProfile.datasource = this.userBillingProfileDatasource;
            record._resource.data.billingProfile = (this.userBillingProfileDatasource.find(x => x.default === true) || { value: null }).value;
            if (isLastRecord) this.pendingBillingProfiles = false;
          } 
          
          // if the service is payable by SUBUSER. Subuser billing profiles only.
          else {
            let promises = [];
            promises.push(this.setEachSubuserBillingProfile(record));

            // Checks if it is the last record to end the loading state
            const isLastRecord = records.length === (index + 1);
            if (isLastRecord) this.getAllSubuserBillingProfiles(promises);
          }
        });
      }

      return records;
    };

    return this.tableInterface.initialize();
  }

  initTableSelectionInterface() {
    return this.tableSelectionInterface = new TableSelectionInterface({ table: this.tableInterface });
  }

  initSearchInterface() {
    // INIT SearchInterface
    this.searchInterface = new SearchInterface({
      name: 'activable-flat-subusers-table-search',
      table: this.tableInterface,
      schema: {
        textSearch: { 
          control: 'text', 
          label: `Denominazione, ID, CF o IVA del ${this.mdt.parsers.contextualize(this.api.user, 'utente')}`,
          query: 'text-search'
        }
      },
      data: {}
    });

    return this.searchInterface.initialize();
  }

  setEachSubuserBillingProfile(record) {  
    return this.api.get(`users/${record.id}/billing-profiles`).then(xhr => {
      const response = xhr.response;
      if (response.length) {
        let datasource = [];
        response.forEach(billingProfile => {
          datasource.push(this.parseBillingProfileDatasource(billingProfile));
        });
        record._selection.isDisabled = false;
        record._resource.schema.billingProfile.datasource = datasource;
        if (response.find(billingProfile => billingProfile.default)) record._resource.data.billingProfile = response.find(billingProfile => billingProfile.default).uuid;
      } else {
        record._selection.isDisabled = true;
        record._resource.schema.billingProfile.datasource = [{ value: 'NONE', text: 'Nessun profilo di fatturazione disponibile' }];
        record._resource.data.billingProfile = 'NONE';
      }
    }).catch(xhr => {
      console.log(`${record.id}/billing-profiles`, xhr);
      record._selection.isDisabled = true;
    });
  }

  getAllSubuserBillingProfiles(promises) {
    Promise.any(promises).then(() => {
      this.pendingBillingProfiles = false;
    }).catch((xhrs) => {
      console.log('getAllSubuserBillingProfiles', xhrs);
    });
  }

  parseBillingProfileDatasource(billingProfile) {
    return { 
      value: billingProfile.uuid,
      default: !!billingProfile.default,
      text: `${billingProfile.name || billingProfile.label || billingProfile.lasttName || billingProfile.firstName} - ${billingProfile.taxCode || billingProfile.fiscalCode || billingProfile.vatCode}${billingProfile.default ? ' (predefinito)' : ''}`
    }
  }

  async activateSelectedRecords() {
    if (!this.pendingActivation) {
      let promises = [];
      this.activations = [];
      this.pendingActivation = true;
      this.totalActivationsCount = this.tableSelectionInterface.selectedRecords.length;

      this.tableSelectionInterface.selectedRecords.forEach(record => {
        record._selection.feedback = {};
        record._selection.isSelected = false;
        record._selection.isDisabled = true;

        let data = {
          paymentMethod: this.resourcePayment.data.payment,
          flatServiceUuid: this.service.id,
          payeeBillingProfileUuid: record._resource.data.billingProfile,
          payeeUserUuid: this.isServicePayableBy === 'MASTER' ? this.api.user.uuid : record.uuid
        }
        if (this.service.autoRenewable === true && (data.paymentMethod === 'SDD' || data.paymentMethod === 'ON-SIGHT')) {
          data.autoRenew = 'ENABLED';
        }
        
        promises.push(this.api.post(`users/${record.id}/flat-services-activations`, data).then(() => {
          this.failedActivationsCount += 1;
          record._selection.feedback.status = 'success';
        }).catch(xhr => {
          console.log(`${record.id}/flat-services-activations`, xhr);
          this.successfulActivationsCount += 1;
          record._selection.feedback.status = 'error';
          this.setFailedActivationDetail(xhr, record);
        }).finally(() => {
          this.activations.push(record);
        }));
      });

      return Promise.all(promises).finally(() => { this.pendingActivation = false; });
    }
  }

  setFailedActivationDetail(xhr, record) {
    let error = JSON.stringify(xhr);
    // Change the names of the fields, on which there is an error on the server side, before showing them to the user (WRIKE:830139458)
    error = error.replace(/paymentMethod/g, 'Problemi con il metodo di pagamento scelto');
    try { 
      return record._failedActivationXhr = JSON.parse(error); 
    } catch {
      return 'Dettaglio errore non disponibile';
    };
  }

  showFailedActivationDetail(record) { 
    if (record._failedActivationXhr) return this.api.dialogError(record._failedActivationXhr);
  }

  exportActivationsReport() {
    if (this.activations.length) {
      let csv = [`id servizio;id ${this.mdt.parsers.contextualize(this.api.user, 'utente')};denominazione;cf/iva;tipo pagamento;id account fatturazione;id profilo fatturazione;stato attivazione;messaggio errore`];
      this.activations.forEach(record => {
        csv.push([
          `"${this.service.id}"`,
          `"${record.id}"`,
          `"${record.profile[0].displayName}"`,
          `"${record.profile[0].fiscalCode || record.profile[0].vatCode}"`,
          `"${this.resourcePayment.data.payment}"`,
          `"${this.isServicePayableBy === 'MASTER' ? this.api.user.uuid : record.uuid}"`,
          `"${record._resource.data.billingProfile}"`,
          `"${record._selection.feedback.status === 'error' ? 'Non attivato' : 'Attivato'}"`,
          `"${record._failedActivationXhr ? this.parseFailedActivationDetailMessage(record._failedActivationXhr) : ''}"`
        ].join(';').trim());
      });
      csv = csv.join('\n');
      let blob = new Blob([csv], {type: 'text/csv'});
      let anchor = document.createElement('a');
      anchor.download = 'report-attivazione-abbonamenti.csv';
      anchor.href = window.URL.createObjectURL(blob);
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
  }

  parseFailedActivationDetailMessage(xhr) {
    let errorDetail;
    try { 
      return errorDetail = JSON.parse(xhr?.response)?.detail;
    } catch { 
      return errorDetail = 'Dettaglio errore non disponibile';
    }
  }
}
