import {inject} from 'aurelia-framework';
import {Router, activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';
import {Navigation} from '../app/app-navigation'

@inject(Router, Api, Navigation)
export class Dashboard {
  constructor(router, api, navigation) {
    this.api = api;
    this.router = router;
    this.navigation = navigation;
  }
  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate() {}

  attached() {
    if (this.api.user.group === 'MASTER_USER') this.sendEnabled = true;
  }
}
