import {PLATFORM, inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaToast} from 'ka-components';

@inject(Api, KaToast)
export class vmPromozioni {
  tabs = [
    {
      id: 'elenco',
      label: 'Listini utente',
      vm: PLATFORM.moduleName('views/servizi/listini/tabs/listini-elenco')
    }
  ];

  tools = {
    copyText: (text, context = 'Testo') => {
      if (!text) return;
      navigator.clipboard.writeText(text);
      this.toast.show(`${context} copiato con successo!`, 'success');
    }
  }

  constructor(api, toast) {
    this.api = api;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    this.privilege();
  }

  privilege() {
    this.privileges = { tabs: { elenco: true }};
    this.privileges.isOperator = this.privileges.isParent;
    if (this.api.hasRole('LIST_ANY_USER_PRICE_LIST_SCHEDULE')) this.privileges.isOperator = true;
    else if (this.api.hasRole('LIST_SUB_USER_PRICE_LIST_SCHEDULES')) this.privileges.isParent = true;
  }
}
