import {inject} from 'aurelia-framework';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'services/api';

@inject(Api)
export class RubricaDialogElimina {
  multipleRecords = false;
  
  constructor(api) {
    this.api = api;
  }

  determineActivationStrategy() {
    return activationStrategy.replace;
  }

  activate(params) {
    this.params = params;
    if (params && params.length) {
      if (params.length !== 1) this.multipleRecords = true;
    }
  }
}
