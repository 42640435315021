import { inject } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { Api } from 'services/api';
import { MdUser } from 'services/md-user';
import { KaToast } from 'ka-components';
import mdt from 'services/md-tools';

@inject(Api, DialogService, MdUser, KaToast)
export class Navigation {
  constructor(api, dialog, MdUser, kaToast) {
    this.api = api;
    this.dialog = dialog;
    this.mdUser = MdUser;
    this.toast = kaToast;
    this.items = [];
    this.init();
  }
  init() {
    this.items = [
      {
        hidden: true,
        label: 'Offerta',
        href: 'offerta',
        route: 'landing/offerta/:code',
        moduleId: PLATFORM.moduleName('views/landing/offerta')
      }, {
        hidden: true,
        label: 'Registrazione',
        href: 'registrazione',
        route: ['registrazione/prospetto/:prospectUuid?', 'registrazione'],
        moduleId: PLATFORM.moduleName('views/landing/registrazione')
      }, {
        hidden: true,
        label: 'Login',
        name: 'login',
        href: 'login',
        route: ['', 'login'],
        moduleId: PLATFORM.moduleName('views/accesso/login')
      }, {
        hidden: true,
        label: 'Recupero password',
        href: 'password-recovery',
        route: ['password-recovery/:token?'],
        moduleId: PLATFORM.moduleName('views/accesso/recupero')
      }, {
        hidden: true,
        label: 'Conferma registrazione',
        href: 'email-confirm',
        route: 'email-confirm/:code',
        moduleId: PLATFORM.moduleName('views/landing/email-confirm')
      }, {
        hidden: true,
        authRequired: true,
        label: 'Profilo',
        icon: null,
        href: 'gestione/profilo',
        route: 'gestione/profilo/:id?/:view?',
        moduleId: PLATFORM.moduleName('views/anagrafiche/utenti/utenti')
      }, {
        hidden: true,
        authGroups: ['MASTER_USER', 'BASIC_USER'],
        label: 'Impostazioni',
        icon: 'settings',
        href: 'impostazioni',
        route: ['impostazioni'],
        moduleId: PLATFORM.moduleName('views/impostazioni/impostazioni')
      }, {
        hidden: true,
        authRequired: true,
        label: 'Dashboard',
        icon: 'dashboard',
        href: 'dashboard',
        route: ['dashboard'],
        moduleId: PLATFORM.moduleName('views/dashboard/dashboard')
      }, {
        authRequired: true,
        label: 'Anagrafiche',
        icon: 'recent_actors',
        href: null,
        collapsed: false,
        nav: [{
          hidden: !!this.api?.user?.parentId,
          label: mdt.parsers.contextualize(this.api.user, 'Utenti'),
          href: 'anagrafiche/utenti',
          route: ['anagrafiche/utenti/:id/:view?', 'anagrafiche/utenti/:view?', 'gestione/profilo/me'],
          moduleId: PLATFORM.moduleName('views/anagrafiche/utenti/utenti'),
        }, {
          authRoles: ['LIST_ANY_PROSPECT_LANDING_PAGE'],
          label: 'Pagine di registrazione',
          icon: null,
          href: 'anagrafiche/landing-pages',
          moduleId: PLATFORM.moduleName('views/anagrafiche/landing-pages/landing-pages')
        }, {
          authRoles: ['LIST_UNKNOWN_IBAN_COORDINATES'],
          label: 'Revisione ABI/CAB',
          icon: null,
          href: 'anagrafiche/revisione-abi-cab',
          moduleId: PLATFORM.moduleName('views/anagrafiche/revisione-abi-cab/revisione-abi-cab')
        }, {
          authGroups: ['MASTER_USER', 'BASIC_USER'],
          label: 'Rubrica',
          icon: null,
          href: 'anagrafiche/rubrica',
          moduleId: PLATFORM.moduleName('views/anagrafiche/rubrica/rubrica')
        }, {
          hidden: true,
          label: 'Rubrica',
          icon: null,
          href: 'anagrafiche/rubrica-importazione',
          moduleId: PLATFORM.moduleName('views/anagrafiche/rubrica/rubrica-importazione')
        }, {
          hidden: true,
          label: 'Rubrica',
          icon: null,
          href: 'anagrafiche/rubrica-dati',
          route: 'anagrafiche/rubrica-dati/:id?',
          moduleId: PLATFORM.moduleName('views/anagrafiche/rubrica/rubrica-dati')
        }, {
          hidden: true,
          label: 'Rubrica',
          icon: null,
          href: 'anagrafiche/rubrica-gruppi',
          route: 'anagrafiche/rubrica-gruppi/:id?',
          moduleId: PLATFORM.moduleName('views/anagrafiche/rubrica/rubrica-gruppi')
        }]
      }, {
        label: 'Riscossioni e rate',
        icon: 'wallet',
        href: null,
        collapsed: true,
        nav: [{
          authRoles: ['LIST_ANY_MAV_ORDER_SELF_PRINTING_AUTHORIZATION'],
          label: 'Richiesta autorizzazioni',
          icon: null,
          href: 'riscossioni/autorizzazioni',
          route: 'riscossioni/autorizzazioni',
          moduleId: PLATFORM.moduleName('views/riscossioni/autorizzazioni/autorizzazioni')
        },
        {
          authRoles: ['LIST_MAV_ORDER_SELF_PRINTING_AUTHORIZATION', 'LIST_ANY_MAV_ORDER_SELF_PRINTING_AUTHORIZATION'],
          label: 'Scarica esiti di pagamento',
          icon: null,
          href: 'riscossioni/pagamento',
          route: 'riscossioni/pagamento/:ka-tab?',
          moduleId: PLATFORM.moduleName('views/riscossioni/pagamento/pagamento')
        }]
      }, {
        authGroups: ['ADMIN', 'BACKOFFICE', 'EXECUTIVE_OPERATOR', 'MASTER_USER', 'BASIC_USER'],
        label: 'Servizi',
        icon: 'shopping_cart',
        href: null,
        collapsed: !this.api.hasRole('LIST_ANY_USER'),
        nav: [{
          authRoles: ['LIST_FLAT_SERVICES'],
          label: 'Attivazione servizi',
          icon: null,
          href: 'servizi/abbonamenti',
          route: 'servizi/abbonamenti/:id?',
          moduleId: PLATFORM.moduleName('views/servizi/abbonamenti/abbonamenti')
        }, {
          authGroups: ['ADMIN', 'BACKOFFICE', 'EXECUTIVE_OPERATOR', 'MASTER_USER', 'BASIC_USER'],
          label: 'Ricariche per spedizioni',
          icon: null,
          href: 'servizi/ricariche',
          route: 'servizi/ricariche',
          moduleId: PLATFORM.moduleName('views/servizi/ricariche/ricariche')
        }, {
          authRoles: ['LIST_USER_PRICE_LIST_SCHEDULES'],
          label: 'Listino prezzi spedizioni',
          icon: null,
          href: 'servizi/listini',
          route: 'servizi/listini',
          moduleId: PLATFORM.moduleName('views/servizi/listini/listini')
        }, {
          authRoles: ['CREATE_ANY_USER_PROMOTION_ASSOCIATION', 'CREATE_PROMOTIONS'],
          label: 'Promozioni',
          icon: null,
          href: 'servizi/promozioni',
          route: 'servizi/promozioni',
          moduleId: PLATFORM.moduleName('views/servizi/promozioni/promozioni')
        }, {
          authRoles: ['LIST_ANY_PROMOTION_OFFER', 'LIST_ANY_USER_PROMOTION_OFFER_ACTIVATION'],
          label: 'Offerte',
          icon: null,
          href: 'backoffice/offerte',
          route: 'backoffice/offerte',
          moduleId: PLATFORM.moduleName('views/backoffice/offerte/offerte')
        }, {
          authRoles: ['LIST_USER_CREDIT_TRANSACTION', 'LIST_USER_BAILMENT_DEPOSIT_TRANSACTION'],
          label: 'Credito',
          icon: null,
          href: null,
          collapsed: true,
          nav: [{
            authRoles: ['LIST_USER_CREDIT_TRANSACTION'],
            label: 'Crediti',
            title: 'Crediti',
            icon: null,
            href: 'servizi/credito/crediti',
            route: ['servizi/credito/crediti'],
            moduleId: PLATFORM.moduleName('views/servizi/credito/crediti')
          }, {
            authRoles: ['LIST_USER_BAILMENT_DEPOSIT_TRANSACTION'],
            label: 'Deposito cauzionale',
            title: 'Deposito cauzionale',
            icon: null,
            href: 'servizi/credito/deposito',
            route: ['servizi/credito/deposito/:user?'],
            moduleId: PLATFORM.moduleName('views/servizi/credito/deposito')
          }]
        }]
      }, {
        href: null,
        collapsed: false,
        label: 'Cashback',
        authRequired: true,
        icon: 'currency_exchange',
        hidden: this.mdUser?.isCashbackHidden,
        authRoles: [
          'LIST_CASHBACK_CONTRACTS',
          'LIST_USER_CASHBACK_CONTRACTS',
          'LIST_USER_CASHBACK_MOVEMENTS',
          'LIST_USER_CASHBACK_WITHDRAWAL_REQUESTS'
        ],
        // authRoles: ['LIST_CASHBACK_CONTRACTS', 'LIST_USER_CASHBACK_CONTRACTS'],
        nav: [{
          label: 'Contratti cashback',
          href: 'cashback/contracts',
          route: ['cashback/contracts'],
          moduleId: PLATFORM.moduleName('views/cashback/contracts/contracts'),
          authRoles: [
            'LIST_CASHBACK_CONTRACTS',
            'LIST_USER_CASHBACK_CONTRACTS'
          ]
        }, {
          label: 'Movimenti e saldo cashback',
          // href: null,
          // call: () => { this.toLegacyCashback(); }
          href: 'cashback/transactions',
          route: ['cashback/transactions'],
          moduleId: PLATFORM.moduleName('views/cashback/transactions/transactions'),
          authRoles: [
            'LIST_USER_CASHBACK_MOVEMENTS',
            'LIST_USER_CASHBACK_WITHDRAWAL_REQUESTS'
          ]
        }]
      }, {
        label: 'Spedizioni',
        icon: 'markunread_mailbox',
        href: null,
        collapsed: false,
        nav: [{
          label: 'Multi-invio',
          icon: null,
          href: null,
          nav: [{
            authGroups: ['MASTER_USER', 'BASIC_USER'],
            label: 'Nuovo invio',
            icon: null,
            href: null,
            call: () => { this.openSenderui('multicanale'); }
          }, {
            authRoles: ['LIST_QUEUE'],
            label: 'Bozze',
            href: 'spedizioni/drafts',
            route: 'spedizioni/drafts',
            moduleId: PLATFORM.moduleName('views/spedizioni/drafts'),
            badge: {
              promise: (resolve, reject) => {
                let storage = localStorage.getItem('queue-drafts-outlines') || null;
                if (!storage) {
                  this.api.get('users/me/queue-drafts-outlines/~all?statusCode-in=ACTIVE').then((xhr) => {
                    localStorage.setItem('queue-drafts-outlines', JSON.stringify({ totalQueueDrafts: xhr.originalResponse.meta.total || null }));
                    resolve(xhr.originalResponse.meta.total);
                  }).catch(() => {
                    reject();
                  });
                } else {
                  let updatedCounter = JSON.parse(localStorage.getItem('queue-drafts-outlines')).totalQueueDrafts;
                  resolve(updatedCounter);
                }
              }
            }
          }, {
            authRoles: ['LIST_ANY_QUEUE', 'BETA_HAS_PENDING-PAY'],
            label: 'In attesa di pagamento',
            href: 'spedizioni/pending',
            route: 'spedizioni/pending',
            moduleId: PLATFORM.moduleName('views/spedizioni/pending'),
            badge: {
              promise: (resolve, reject) => {
                if (!this.api.hasRole('BETA_HAS_PENDING-PAY') || this.api.hasRole('LIST_ANY_QUEUE')) reject();
                let storage = localStorage.getItem('queue-outlines') || null;
                if (!storage) {
                  this.api.get('users/me/queue-outlines/~all?status=ACCEPTANCE').then((xhr) => {
                    localStorage.setItem('queue-outlines', JSON.stringify({ totalQueuesPendingPayment: xhr.originalResponse.meta.total || null }));
                    resolve(xhr.originalResponse.meta.total);
                  }).catch(() => {
                    reject();
                  });
                } else {
                  let updatedCounter = JSON.parse(localStorage.getItem('queue-outlines')).totalQueuesPendingPayment;
                  resolve(updatedCounter);
                }
              }
            }
          }, {
            authRequired: true,
            authRoles: ['LIST_ANY_QUEUE', 'BETA_HAS_REPORTS'],
            label: 'Reportistica',
            href: 'spedizioni/reportistica',
            route: 'spedizioni/reportistica',
            moduleId: PLATFORM.moduleName('views/spedizioni/report/report')
          }, {
            authRequired: true,
            authRoles: ['LIST_QUEUE_MESSAGE'],
            hidden: true,
            label: 'Reportistica',
            href: 'spedizioni/reportistica-dettaglio',
            route: ['spedizioni/reportistica/:queueOwnerId/:queueUuid'],
            moduleId: PLATFORM.moduleName('views/spedizioni/report/report-detail')
          }]
        }, {
          authGroups: ['MASTER_USER', 'BASIC_USER'],
          label: 'SMS',
          icon: null,
          href: null,
          nav: [{
            label: 'Nuovo invio',
            icon: null,
            href: null,
            call: () => { this.openSenderui('sms'); }
          }]
        }, {
          authGroups: ['ADMIN', 'BACKOFFICE', 'EXECUTIVE_OPERATOR'],
          label: 'Alias Sms',
          icon: null,
          href: 'backoffice/alias',
          route: ['backoffice/alias/:id?'],
          moduleId: PLATFORM.moduleName('views/backoffice/alias')
        }, {
          authGroups: ['ADMIN', 'BACKOFFICE', 'EXECUTIVE_OPERATOR'],
          label: 'Bounces',
          icon: null,
          href: 'backoffice/bounces',
          moduleId: PLATFORM.moduleName('views/backoffice/bounces')
        }]
      }, /* {
        label: 'Contabilità',
        icon: 'assignment',
        href: null,
        collapsed: false,
        nav: [{
          authRequired: true,
          label: 'Ordini',
          href: 'contabilita/orders',
          route: 'contabilita/orders',
          moduleId: PLATFORM.moduleName('views/contabilita/orders')
        }]
      }, */ {
        authGroups: ['MASTER_USER'],
        label: 'MultiCerta',
        icon: 'verified_user',
        href: null,
        collapsed: false,
        nav: [{
          authGroups: ['_MASTER_USER'],
          label: 'Reportistica',
          icon: null,
          href: 'multicerta/report',
          nav: null
        }, {
          authRequired: true,
          label: 'Invita',
          title: 'Multicerta',
          icon: null,
          href: 'multicerta/invite',
          route: ['multicerta/invite'],
          moduleId: PLATFORM.moduleName('views/multicerta/invite')
        }]
      }, {
        authGroups: ['MASTER_USER'],
        label: 'MultiFatture',
        icon: 'euro_symbol',
        href: null,
        call: () => { this.openMultifatture(); }
      }, {
        authGroups: ['MASTER_USER'],
        label: 'Multinforma',
        icon: 'menu_book',
        href: null,
        call: () => { this.openMultinforma(); }
      }, {
        authRoles: ['LIST_TAX_WITHHOLDING_TRANSMISSION', 'LIST_ANY_TAX_WITHHOLDING_TRANSMISSION'],
        label: 'MultiCaf',
        icon: 'format_indent_increase',
        href: null,
        collapsed: true,
        nav: [{
          authRequired: true,
          label: this.api.hasRole('LIST_ANY_TAX_WITHHOLDING_TRANSMISSION') ? 'Elenco C.U. caricate' : 'C.U.',
          title: 'Multicaf',
          icon: null,
          href: 'multicaf/CU',
          route: ['multicaf/elenco', 'multicaf/CU'],
          moduleId: PLATFORM.moduleName('views/multicaf/CU')
        }, {
          authRequired: true,
          label: this.api.hasRole('LIST_ANY_TAX_WITHHOLDING_TRANSMISSION') ? 'Elenco 770 caricati' : '770',
          title: 'Multicaf',
          icon: null,
          href: 'multicaf/770',
          route: ['multicaf/770'],
          moduleId: PLATFORM.moduleName('views/multicaf/770')
        }, {
          authRequired: true,
          label: this.api.hasRole('LIST_ANY_TAX_WITHHOLDING_TRANSMISSION') ? 'Elenco F24 caricati' : 'F24',
          title: 'Multicaf',
          icon: null,
          href: 'multicaf/F24',
          route: ['multicaf/F24'],
          moduleId: PLATFORM.moduleName('views/multicaf/F24')
        }, {
          authRequired: true,
          label: 'Detrazioni',
          icon: null,
          href: null,
          collapsed: true,
          nav: [{
            authRequired: true,
            label: this.api.hasRole('LIST_ANY_TAX_WITHHOLDING_TRANSMISSION') ? 'Elenco detrazioni parti comuni caricate' : 'Detrazioni parti comuni',
            title: 'Multicaf',
            icon: null,
            href: 'multicaf/SAC',
            route: ['multicaf/SAC'],
            moduleId: PLATFORM.moduleName('views/multicaf/SAC')
          }, {
            authRequired: true,
            label: this.api.hasRole('LIST_ANY_TAX_WITHHOLDING_TRANSMISSION') ? 'Elenco detrazioni CIR caricate' : 'Detrazioni CIR',
            title: 'Multicaf',
            icon: null,
            href: 'multicaf/CIR',
            route: ['multicaf/CIR'],
            moduleId: PLATFORM.moduleName('views/multicaf/CIR')
          }]
        }]
      }, {
        authRoles: ['LIST_RESELLER_COMMISSIONS'],
        label: 'Compensi',
        icon: 'savings',
        href: 'anagrafiche/utenti/me/compensi?ka-tab-main=utenti-associati'
      }, {
        label: 'Sistema',
        icon: 'settings',
        href: null,
        collapsed: false,
        nav: [{
          authGroups: ['ADMIN'],
          label: 'Agreements',
          icon: null,
          href: 'backoffice/agreements',
          route: ['backoffice/agreements/:id?'],
          moduleId: PLATFORM.moduleName('views/backoffice/agreements')
        }, {
          authGroups: ['ADMIN'],
          label: 'Archiviazione S3',
          icon: null,
          href: 'backoffice/archiviazione',
          moduleId: PLATFORM.moduleName('views/backoffice/archiviazione')
        }, {
          authRoles: ['LIST_ANY_USER_SYSTEM_NOTIFICATION'],
          label: 'Notifiche di sistema',
          icon: null,
          href: 'backoffice/notifiche',
          moduleId: PLATFORM.moduleName('views/backoffice/notifiche-sistema/notifiche')
        },
        {
          authRoles: ['LIST_WALL_ADVICES'],
          label: 'Avvisi Wall',
          icon: null,
          href: 'backoffice/avvisi',
          moduleId: PLATFORM.moduleName('views/backoffice/avvisi/wall-advices')
        },
        {
          authRoles: ['LIST_ANY_MTRAX_OUTBOX_MESSAGE'],
          label: 'MultiTrax Outbox',
          icon: null,
          href: 'backoffice/multitrax',
          moduleId: PLATFORM.moduleName('views/backoffice/multitrax/multitrax')
        }]
      },
      {
        hidden: true,
        label: 'Cancella account',
        href: 'account/delete',
        route: ['account/delete'],
        moduleId: PLATFORM.moduleName('views/account/delete/delete')
      }
    ];
  }
  openMultifatture() {
    this.api.get('users/me/cross-login-tokens/current').then(xhr => {
      let token = xhr.response.token;
      window.open(`${ENVIRONMENT.APP_URL_MULTIFATTURE}home?st=${token}`);
    }, xhr => {}).catch(error => { console.error(error) }).finally(() => {});
  }

  openMultinforma() {
      this.api.get('users/me/cross-login-tokens/current').then(xhr => {
        let token = xhr.response.token;
        window.open(`${ENVIRONMENT.APP_URL_MULTINFORMA}?token=${token}`);
      }, xhr => {}).catch(error => { console.error(error) }).finally(() => {});
  } 

  openSenderui(queueType = null) {
    const checkAgreements = this.mdUser.checkFlow.getCheck('checkAgreements');
    if (checkAgreements && !checkAgreements.hasPassed) {
      console.warn('Cannot open SenderUI because of failed check on agreements', checkAgreements);
    } else {
      return this.api.get('users/me/cross-login-tokens/current').then((xhr) => {
        const response = xhr.response;
        const token = response.token;
        const iframe = `/senderui/?owner=${this.api.user.id}&ts=${token}#/home` + (queueType ? `/${queueType}` : '');
        this.dialog.open({
          lock: true,
          model: { iframe },
          viewModel: PLATFORM.moduleName('views/app/dialog-senderui')
        });
      }).catch((error) => {
        console.error('cross-login-tokens/current', error);
      });
    }
  }
}
