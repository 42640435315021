import {inject} from 'aurelia-framework';
import {Api} from 'services/api';
import {KaDialog, KaToast} from 'ka-components';
import mdt from 'services/md-tools';

@inject(Api, KaDialog, KaToast)
export class DialogIntentsProvideIban {
  mdt = mdt;
  pending = false;
  resource = {
    controls: {},
    schema: {
      iban: {
        label: 'IBAN',
        control: 'text',
        required: true,
        min: 6,
        max: 27
      }
    }, 
    data: {},
    validate: () => {
      let promises = [];
      Object.values(this.resource.controls).forEach(control => {
        promises.push(new Promise((resolve, reject) => { control.validate().then(result => { if (result.valid) resolve(); else reject('Control didn\'t pass validation'); }); }));
      });
      return Promise.all(promises);
    },
    error: error => {
      this.api.dialogError(error, this.resource.controls);
    }
  }

  constructor(api, dialog, toast) {
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(params) {
    this.params = params;
    sessionStorage.setItem(btoa(`intents-reload`), JSON.stringify({ reload: false }));
  }

  save($event) {
    $event.model.busy = true;
    this.resource.validate().then(() => {
      let data = { payments: { banking: { iban: this.resource.data.iban } } };
      if (this.params.legalFormCode) data.legalFormCode = this.params.legalFormCode;
      this.api.patch(`users/${this.params.billingProfileOwnerId || this.params.userId}/billing-profiles/${this.params.billingProfileUuid}`, data).then(() => {
        this.params.saved = true;
        this.params.edit = false;
        this.toast.show(`Codice IBAN salvato con successo!`, 'success');
        sessionStorage.setItem(btoa(`intents-reload`), JSON.stringify({ reload: false }));
      }).catch(error => {
        console.log(`ERROR billing-profiles - PATCH`, error);
        this.resource.error(error);
      }).finally(() => {
        $event.model.busy = false;
      });
    }).catch(() => {
      $event.model.busy = false;
    });
  } 
}
