import { Api } from 'services/api';
import { inject } from 'aurelia-framework';
import { MdDatasources } from 'services/md-datasources';

@inject(Api, MdDatasources)
export class ServiceDialogAbbonamentiAutorinnovo {
  updateDatasourcePending;
  resource = {
    controls: {},
    schema: {
      autoRenewPaymentMethod: {
        control: 'combo',
        label: 'Metodo di pagamento dell\'autorinnovo',
        datasource: [],
        datapreload: true,
        datamultiple: false,
        datavalue: 'code',
        datatext: 'label'
      }
    },
    data: {}
  }
  
  constructor(api, MdDatasources) {
    this.api = api;
    this.mdDatasources = MdDatasources;
  }

  activate(params) {
    this.params = params;
  }

  attached() {
    if (this.params.autoRenew === 'DISABLED') this.updateDatasource();
  }

  updateDatasource() {
    this.updateDatasourcePending = true;
    this.api.get(`users/${this.params.ownerId || 'me'}/flat-services-activations/${this.params.id}`).then((xhr) => {
      let response = xhr.response;
      if (response.allowedAutoRenewPaymentMethods?.length) {
        const filteredPaymentMethods = this.mdDatasources.paymentMethods.filter(method => response.allowedAutoRenewPaymentMethods.includes(method.code));
        if (filteredPaymentMethods.length) {
          this.resource.schema.autoRenewPaymentMethod = Object.assign({}, this.resource.schema.autoRenewPaymentMethod, { datasource: filteredPaymentMethods });
          this.resource.data.autoRenewPaymentMethod = this.resource.schema.autoRenewPaymentMethod.datasource[0]?.code;
        }
      }
    }).catch((error) => {
      console.log('GET flat-services-activations', error);
    }).finally(() => {
      this.updateDatasourcePending = false;
    });
  }
}
