import { Api } from 'services/api';
import { Router } from 'aurelia-router';
import { PLATFORM, inject } from 'aurelia-framework';
import { KaToast } from 'ka-components';

@inject(Api, Router, KaToast)
export class vmTransactions {
  privileges = {};
  tabs = [
    {
      id: 'movements',
      label: 'Movimenti',
      vm: PLATFORM.moduleName('views/cashback/transactions/tabs/movements')
    },
    {
      id: 'requests',
      label: 'Richieste',
      vm: PLATFORM.moduleName('views/cashback/transactions/tabs/requests')
    }
  ];

  constructor(api, router, KaToast) {
    this.api = api;
    this.router = router;
    this.toast = KaToast;
    this.privileges.tabs = this.tabs.reduce((accumulator, value) => ({ ...accumulator, [value.id]: false }), {});
    this.tools = {
      downloadDocument: ($event, userId, id) => {
        $event.model.busy = true;
        this.toast.show('Caricamento in corso', 'loading');
        let uri = `/users/${userId || 'me'}/cashback-withdrawal-requests/${id}/downloads/invoice.pdf`;
    
        return this.api.client.createRequest(uri)
        .asGet()
        .withHeader('Accept', 'application/pdf')
        .withHeader('Content-Type', 'application/pdf')
        .withResponseType('blob')
        .send()
        .then((xhr) => {
          let regexp = new RegExp('filename=(.+\.pdf$)', 'i');
          let matches = xhr.headers.headers['content-disposition'].value.match(regexp);
          let anchor = document.createElement('a');
          let blob = new Blob([xhr.response], { type: 'application/pdf' });
          let url = URL.createObjectURL(blob);
    
          anchor.href = url;
          anchor.download = matches?.[1] || "Fattura per richiesta trasferimento cashback.pdf",
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          this.toast.show(`Documento scaricato con successo!`, 'success');
        }).catch((error) => {
          this.toast.show(`Impossibile scaricare il file!`, 'error');
          console.log('Get downloads/invoice', error);
        }).finally(() => {
          $event.model.busy = false;
        });
      }
    }
  }

  activate(params) {
    this.params = params;
    this.privilege();
  }

  privilege() {
    this.privileges.isOperator = this.api.hasRole('LIST_ANY_USER_CASHBACK_MOVEMENT', 'LIST_ANY_USER_CASHBACK_WITHDRAWAL_REQUEST');
    
    if (this.api.hasRole('LIST_USER_CASHBACK_MOVEMENTS', 'LIST_ANY_USER_CASHBACK_MOVEMENT')) {
      ['movements'].forEach((id) => { this.privileges.tabs[id] = true; });
    }
    if (this.api.hasRole('LIST_USER_CASHBACK_WITHDRAWAL_REQUESTS', 'LIST_ANY_USER_CASHBACK_WITHDRAWAL_REQUEST')) {
      ['requests'].forEach((id) => { this.privileges.tabs[id] = true; });
    }
  }
}
