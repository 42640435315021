import { Api } from 'services/api';
import { Router } from 'aurelia-router';
import { MdUser } from 'services/md-user';
import { KaDialog, KaToast } from 'ka-components';
import { PLATFORM, inject } from 'aurelia-framework';

@inject(Api, KaDialog, KaToast, MdUser, Router)
export class vmContracts {
  privileges = {};
  tabs = [
    {
      id: 'subscriptions',
      label: 'Sottoscrizioni',
      vm: PLATFORM.moduleName('views/cashback/contracts/tabs/subscriptions')
    },
    {
      id: 'serviceContracts',
      label: 'Contratti servizi',
      vm: PLATFORM.moduleName('views/cashback/contracts/tabs/service-contracts')
    },
    {
      id: 'multinvioContracts',
      label: 'Contratti MultInvio',
      vm: PLATFORM.moduleName('views/cashback/contracts/tabs/multinvio-contracts')
    },
    {
      id: 'userSubscriptions',
      label: 'Sottoscrizioni',
      vm: PLATFORM.moduleName('views/cashback/contracts/tabs/user-subscriptions')
    },
    {
      id: 'userArchive',
      label: 'Archivio',
      vm: PLATFORM.moduleName('views/cashback/contracts/tabs/user-archive')
    }
  ];

  constructor(api, kaDialog, kaToast, mdUser, router) {
    this.api = api;
    this.mdUser = mdUser;
    this.router = router;
    this.toast = kaToast;
    this.dialog = kaDialog;
    this.privileges.tabs = this.tabs.reduce((accumulator, value) => ({ ...accumulator, [value.id]: false }), {});
    this.tools = {
      openDialogContractDetail: (record, url) => {
        if (!url) url = `bo/cashback-contracts/${record.id || record.contractUuid}`;
        const htmlTemplate = record.userRenderableHtml || null;
        const version = record.version || record.contractVersion;
        const type = record.type && ['FLAT-SERVICE', 'MULTINVIO-VOLUMES'].includes(record.type) ? record.type : record.contractType;
        const title = `Contratto cashback per ${type === 'FLAT-SERVICE' ? 'sottoscrizioni servizi' : 'spedizioni MultInvio'} - ${version}`;
        this.dialog.open({
          title: title,
          class: 'contract-detail',
          viewModelParams: { url, htmlTemplate },
          viewModel: PLATFORM.moduleName('views/cashback/contracts/dialogs/contract-detail')
        });
      },
      copyText: (text, item) => {
        return navigator.clipboard.writeText(text),
        this.toast.show(`${item} copiato con successo!`, 'success');
      }
    }
  }

  activate(params) {
    this.params = params;
    this.privilege();
  }

  privilege() {
    // Users who cannot access the cashback section (https://www.wrike.com/open.htm?id=1509130485)
    if (this.mdUser?.isCashbackHidden) return this.router.navigateToRoute('dashboard');

    const isOperator = this.api.hasRole('LIST_ANY_USER');

    if (!isOperator && this.api.hasRole('LIST_USER_CASHBACK_CONTRACTS')) {
      ['userSubscriptions'].forEach((id) => { this.privileges.tabs[id] = true; });
    }
    if (this.api.hasRole('LIST_CASHBACK_CONTRACTS')) {
      ['serviceContracts', 'multinvioContracts'].forEach((id) => { this.privileges.tabs[id] = true; });
    }
    if (!isOperator && this.api.hasRole('LIST_USER_CASHBACK_CONTRACT_ACCEPTANCES')) {
      ['userArchive'].forEach((id) => { this.privileges.tabs[id] = true; });
    }
    if (this.api.hasRole('LIST_ANY_USER_CASHBACK_CONTRACT_ACCEPTANCE')) {
      ['subscriptions'].forEach((id) => { this.privileges.tabs[id] = true; });
    }
  }
}
